import { useQuery } from 'react-query';
import API from 'utils/api';
import { Blockchain } from '../types/Blockchain';

type Trait = {
  key: string;
  value: string;
};

export type TokenGate = {
  id: string;
  shopId: string;
  name: string;
  productIds: string[];
  templateApplied: boolean;
  blockchain: Blockchain;
  contractAddress: string;
  updatedAt: string;
  createdAt: string;
  usage: number;
  print: string | null;
  traits: {
    including: Trait[] | null;
    excluding: Trait[] | null;
  } | null;
  startedAt: string;
  endedAt: string | null;
  allowFutureOwners: boolean;
  snapshotAt: string | null;
  artBlocksProjectId: number | null;
  maxPerWalletPerToken: number;
  maxPerToken: number | null;
  discountPercentage: string | null;
  discountExist: boolean;
  matchAll: boolean;
};

export default function useGetTokenGate(id: string, enabled: boolean) {
  return useQuery<TokenGate>(
    ['tokenGates', id],
    async () => {
      const response = await API().get(`shop/tokenGate/${id}`);

      return response.data;
    },
    {
      enabled,
    }
  );
}
