import { Card, Link, TextStyle } from '@shopify/polaris';
import React, { useCallback } from 'react';
import { useAppBridge } from 'containers/AppBridge';

const ShippingCard = () => {
  const { host, openInNewTab } = useAppBridge();

  const navigateToShipping = useCallback(() => {
    const url = `https://${host}/admin/settings/shipping`;
    openInNewTab(url);
  }, [host, openInNewTab]);

  return (
    <Card
      title="Shipping"
      actions={[
        {
          content: 'Configure shipping',
          onAction: navigateToShipping,
        },
      ]}
    >
      <Card.Section>
        <TextStyle>
          Shipping configuration varies depending on your Shopify plan. We
          strongly recommend you configure shipping as detailed{' '}
          <Link url="https://help.verisart.com/en/articles/9508349-setting-up-shipping-for-print-service">
            here
          </Link>
          .
        </TextStyle>
      </Card.Section>
    </Card>
  );
};

export default ShippingCard;
