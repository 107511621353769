import React, { useEffect } from 'react';
import create from 'zustand';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import LoadingPage from './auth/LoadingPage';
import { gtmContainerId } from './constants';
import { trackPageView } from './lib/tracking';

declare global {
  interface Window {
    google_tag_manager: unknown;
  }
}

const log = (message: string) => console.log('[GoogleTagManager]', message);

const loadGoogleTagManager = async (): Promise<void> => {
  if (!gtmContainerId()) {
    log('No GTM container id resolved, GTM disabled');
    return;
  }

  if (window.google_tag_manager) {
    return;
  }

  return new Promise((resolve, reject) => {
    const gtmLoaded = 'gtm:loaded';
    const gtmError = 'gtm:error';
    let resolved = false;
    window.addEventListener(gtmLoaded, () => {
      if (!resolved) {
        resolved = true;
        resolve();
      }
    });

    window.addEventListener(gtmError, () => {
      if (!resolved) {
        log('Loading error');
        resolved = true;
        reject();
      }
    });

    setTimeout(() => {
      if (!resolved) {
        log('Loading timeout');
        resolved = true;
        reject();
      }
    }, 5000);

    const script = document.createElement('script');
    script.innerText = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;
      j.addEventListener('load', function() {
        d.dispatchEvent(new CustomEvent('${gtmLoaded}',{bubbles:true}));
      });
      j.addEventListener('error', function() {
        d.dispatchEvent(new CustomEvent('${gtmError}',{bubbles:true}));
      });
      f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmContainerId()}');`;
    document.head.append(script);
  });
};

const useTagManagerStore = create((set) => {
  loadGoogleTagManager().then(
    () => set({ loaded: true, loading: false }),
    () => set({ error: true, loaded: false, loading: false })
  );

  return {
    error: false,
    loaded: !!window.google_tag_manager,
    loading: false,
  };
});

const GoogleTagManager: React.FC = ({ children }) => {
  const { error, loaded } = useTagManagerStore();

  useEffect(() => {
    if (loaded) {
      trackPageView(window.location.pathname);
    }
  }, [loaded]);

  return !loaded && !error ? (
    <AppProvider i18n={enTranslations}>
      <LoadingPage />
    </AppProvider>
  ) : (
    <>{children}</>
  );
};

export default GoogleTagManager;
