import React, { useState, useCallback, useEffect, useMemo } from 'react';
import CardElement from 'pages/ProductForm/CardElement';
import t from 'lib/translation';
import {
  ResourceList,
  ResourceItem,
  Stack,
  TextStyle,
  Checkbox,
  Icon,
  Button,
} from '@shopify/polaris';
import { LockMajor } from '@shopify/polaris-icons';
import { useFormContext } from 'react-hook-form';
import { DropsFormValues } from '../DropForm';
import style from './DropTokengate.module.scss';
import { TokenGate } from 'hooks/useGetTokenGate';
import { Contract } from '../../../hooks/useGetContracts';

export type DropTokengateProps = {
  showModal: () => void;
  disabled: boolean;
  tokenGateData: TokenGate[];
  contracts: Contract[];
};

/**
 * Shows tokengates for a drop.
 *
 * Note that tokengates are not actually passed in the create/update call to Drops.
 * They're just created directly as drops and this form will just show what is currently linked.
 */
const DropTokengate: React.FC<DropTokengateProps> = ({
  showModal,
  disabled,
  tokenGateData,
  contracts,
}) => {
  const { watch } = useFormContext<DropsFormValues>();

  const [checked, setChecked] = useState(false);
  const handleChange = useCallback((newChecked) => {
    setChecked(newChecked);
  }, []);

  const contract = watch('contract');

  const contractObject = useMemo(
    () => contracts.find((c) => c.id === contract),
    [contracts, contract]
  );

  const associatedTokengates = tokenGateData.filter(
    (tokengate) => tokengate.contractAddress === contractObject?.address
  );

  useEffect(() => {
    associatedTokengates.length > 0 && setChecked(true);
  }, [associatedTokengates, setChecked]);

  useEffect(() => {
    !contract && setChecked(false);
  }, [contract, setChecked]);

  return (
    <CardElement title={t('dropForm.enhance.subtitleTwo')}>
      <Checkbox
        disabled={(disabled || associatedTokengates.length > 0) && !contract}
        label={t('dropForm.enhance.tokengateCheckbox')}
        checked={checked}
        onChange={handleChange}
      />

      {checked && (
        <div className={style['mt-4']}>
          <>
            {associatedTokengates.length > 0 && (
              <Stack alignment="fill">
                <Stack.Item fill>
                  <TextStyle>{t('dropForm.enhance.subtitleTwo')}</TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <Button plain onClick={() => showModal()}>
                    {t('dropForm.enhance.createTokengate')}
                  </Button>
                </Stack.Item>
              </Stack>
            )}
            <div className={`${style['mt-4']} ${style['no-hover']}`}>
              <ResourceList
                resourceName={{ singular: 'Tokengate', plural: 'Tokengates' }}
                items={
                  associatedTokengates.map((tokengate, i) => {
                    return {
                      key: `${tokengate.contractAddress}-${i}`,
                      value: tokengate.contractAddress!,
                      label: tokengate.name,
                    };
                  }) || []
                }
                renderItem={(item) => {
                  const { key, value, label } = item;

                  return (
                    <ResourceItem key={key} id={value} url={''}>
                      <Stack wrap={false}>
                        <Icon source={LockMajor} />
                        <TextStyle variation="strong">{label}</TextStyle>
                      </Stack>
                    </ResourceItem>
                  );
                }}
              />
            </div>
            {associatedTokengates.length === 0 && (
              <div className={style.center}>
                <Stack.Item>
                  <Button plain onClick={() => showModal()}>
                    {t('dropForm.enhance.createTokengate')}
                  </Button>
                </Stack.Item>
              </div>
            )}
          </>
        </div>
      )}
    </CardElement>
  );
};

export default DropTokengate;
