import { useMutation, useQueryClient } from 'react-query';
import { Blockchain } from 'types/Blockchain';
import API, { sleep } from 'utils/api';

export type ImportDropRequest = {
  blockchain: Blockchain;
  contractAddress: string;
  tokenId: string;
};

type ImportDropPollResponse = {
  isExistingDrop: boolean;
  productId: number;
};

export default function useImportNFT() {
  const queryCache = useQueryClient();

  return useMutation<ImportDropPollResponse, unknown, ImportDropRequest>(
    async (request) => {
      const res = await API().post('/shop/drop/import', request);
      const asyncID = res.data.asyncID;
      while (true) {
        const pollResult = await API().get(`/shop/drop/import/${asyncID}`);
        if (pollResult.status === 200) return pollResult.data;
        await sleep(2000);
      }
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('drop');
      },
    }
  );
}
