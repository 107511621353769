import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { AxiosError } from 'axios';
import { TokenGate } from './useGetTokenGate';
import { GatingMode } from './useCreateTokenGate';

export type CreateTokenGateForPrint = {
  name: string;
  blockchain: string;
  contract: string;
  startedAt: string;
  maxPerWalletPerToken: number;
  allowFutureOwners: boolean;
  traits: {
    including?: { key: string; value: string }[];
    excluding?: { key: string; value: string }[];
  } | null;
  maxPerToken: number | null;
  gatingMode: GatingMode;
  endedAt: string | null;
  snapshotAt: string | null;
  artBlocksProjectId: number | null;
};

export type CreatePrintDropProps = {
  price: number;
  gate: CreateTokenGateForPrint;
  thumbnail?: string | null;
  artistId?: string;
};

export type Print = {
  id: string;
  shopId: string;
  productId: string;
  productCertificateTitle?: string;
  productCertificateDescription?: string;
  productDescription?: string;
  productTitle?: string;
  tokenGate?: TokenGate;
  updatedAt: string;
  name: string;
  price: number;
  artistId: string;
  status: 'ACTIVE' | 'DRAFT';
  templateApplied: boolean;
};

export default function useCreatePrintProduct() {
  const queryCache = useQueryClient();

  return useMutation<Print, AxiosError, CreatePrintDropProps>(
    async ({ price, gate, thumbnail, artistId }) => {
      const response = await API().post('/shop/print', {
        price,
        gate,
        thumbnail,
        artistId,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('prints');
      },
    }
  );
}
