import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

export default function useUpdateTokengateDiscount() {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) =>
      (await API().post(`/shop/tokenGateDiscount/${id}`)).data,
    {
      onSuccess: (_, id) => {
        queryClient.invalidateQueries(['tokenGates', id]);
      },
      onError: (error) => {
        console.error('Error updating token gate discount', error);
      },
    }
  );
}
