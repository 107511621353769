import React, { useEffect } from 'react';
import useChangePlan from '../../hooks/useChangePlan';
import LoadingPage from '../../auth/LoadingPage';
import { useAppBridge } from 'containers/AppBridge';

/**
 * Page which corrects any subscription problems by asking the backend for instructions
 */
export const FixSubscriptionPage: React.FC = () => {
  const appBridge = useAppBridge();
  const { mutateAsync: changePlan } = useChangePlan();

  useEffect(() => {
    const doChangePlan = async () => {
      // The mutation automatically redirects if required
      const result = await changePlan({
        planType: null,
        redirectPath: 'plan',
      });

      if (!result.confirmationUrl) {
        appBridge.redirect('App', '/');
      }
      // else the redirect will happen automatically in useChangePlan()
    };
    doChangePlan();
  }, [appBridge, changePlan]);

  return <LoadingPage />;
};
