import {
  Banner,
  FormLayout,
  Layout,
  Page,
  Stack,
  Thumbnail,
  Button,
  Link,
} from '@shopify/polaris';
import {
  NonPhysicalObjects,
  ObjectType,
  ObjectTypeOptions,
} from 'components/ObjectTypeSelector/objectTypeOptions';
import VariantsDisplaySection from 'components/VariantsDisplaySection';
import VariantTitleThumbnail from 'components/VariantTitleThumbnail';
import { MetafieldsType } from 'hooks/useProductFormValues';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { retrieveErrorMessage } from 'utils/validationMessaging';
import AssetRenderer from './AssetRenderer';
import CardElement from './CardElement';
import MetafieldErrorBanner from './MetafieldErrorBanner';
import {
  artistOptions,
  FailedToast,
  isEditionType,
  SuccessfulToast,
} from './MetafieldFormLoader';
import {
  MetafieldFormValues,
  ProductFormShowInterface,
} from './ProductFormShow';
import t from '../../lib/translation';
import ActivateNftBanner from '../../components/ActivateNftBanner';
import SelectInput from '../../components/forms/SelectInput';
import TextInput from '../../components/forms/TextInput';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import { useOpenWindowOnVerisartDotCom } from '../../utils/mainWebsiteLogin';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';
import { Blockchain, isMainnet } from 'types/Blockchain';

const VariantFormShow: React.FC<ProductFormShowInterface> = ({
  productFormValues,
  relatedArtistsData,
  onSubmit,
}) => {
  const { mintingAvailable, giftingEnabled, paidEnabled } =
    useMainnetMintingEnabled();

  // Human readable errors per metafield
  const initialErrors: { [metafield in MetafieldsType]: string } = {} as {
    [metafield in MetafieldsType]: string;
  };

  // Original error codes per metafield
  const initialErrorCodes: { [metafield in MetafieldsType]: string } = {} as {
    [metafield in MetafieldsType]: string;
  };

  const initialValues: MetafieldFormValues = {} as MetafieldFormValues;
  const { defaultType } =
    useParams<{ defaultType: 'nft' | 'certificate' | undefined }>();

  (Object.keys(productFormValues.metafields) as MetafieldsType[]).forEach(
    (metafield) => {
      const value = productFormValues.metafields[metafield];
      if (value?.issue?.[0] !== undefined) {
        initialErrors[metafield] = retrieveErrorMessage(value.issue[0]);
        initialErrorCodes[metafield] = value.issue[0];
      }
      initialValues[metafield] = value?.finalValue?.[0] ?? null;
    }
  );

  const { id } = useParams<{ id: string }>();

  const methods = useForm<MetafieldFormValues>({
    defaultValues: {
      ...initialValues,
      ...{
        qr_linkable:
          productFormValues.metafields.qr_linkable?.value?.[0] ?? 'false',
        is_this_edition:
          productFormValues.metafields.edition_type?.value?.[0] !== undefined &&
          isEditionType(productFormValues.metafields.edition_type.value?.[0])
            ? 'yes'
            : 'no',
        product_trigger_level: productFormValues.triggerLevel as 'OFF' &
          'NFT' &
          'CERTIFICATE',
        status: productFormValues.product.status,
      },
    },
  });

  useEffect(() => {
    //set errors for metafileds on the first render only
    (Object.keys(initialErrors) as MetafieldsType[]).forEach((metafield) => {
      methods.setError(metafield, {
        // We're overloading the `type` field with the error code. The docs of react-hook-form are a bit unclear
        // if this is OK or not, but it seems to work fine. (They let you set an arbitrary string)
        type: initialErrorCodes[metafield],
        message: initialErrors[metafield],
      });
    });
    // We only want to render the errors on the very first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessfulToastShowing, setIsSuccessfulToastShowing] =
    useState(false);
  const [isFailedToastShowing, setIsFailedToastShowing] = useState(false);
  const [bannerError, setBannerError] = useState<string | null>(null);

  const productTriggerLevel = methods.watch('product_trigger_level');
  const objectType = methods.watch('object_type');
  const artistId = methods.watch('artist_id');
  const blockchain = methods.watch('blockchain') as Blockchain;
  const isNft = productTriggerLevel === 'NFT';

  // Note that at some point we will want to support changing this through the AssetRenderer
  const [mainAsset] = useState<string | undefined>(
    productFormValues.metafields?.image_url?.value?.[0] ??
      productFormValues.metafields?.image_url?.inheritedValue?.[0] ??
      (productFormValues.product.mainMedia == null
        ? undefined
        : productFormValues.product.mainMedia)
  );

  const currentRelationship = relatedArtistsData.relatedArtist.find(
    (artist) => artist.id === artistId
  );

  const giftingWarning = mintingAvailable && giftingEnabled && !paidEnabled;

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const historyGoBack = useHistoryGoBack();

  return (
    <div style={{ marginBottom: '2rem' }}>
      <FormProvider {...methods}>
        <Page
          breadcrumbs={[
            {
              content: 'Products',
              onAction: () =>
                historyGoBack(defaultType ? `/${defaultType}` : `/nft`),
            },
          ]}
          title={productFormValues.product.name}
          thumbnail={
            <Thumbnail
              alt=""
              size="medium"
              source={productFormValues?.product.thumbnail!!}
            />
          }
          primaryAction={{
            content: isSubmitting ? 'Loading' : 'Save',
            disabled: isSubmitting,
            onAction: () => {
              methods.clearErrors();
              methods.handleSubmit(
                onSubmit(
                  setIsSubmitting,
                  () => setIsSuccessfulToastShowing(true),
                  () => setIsFailedToastShowing(false),
                  methods.setError
                )
              )();
            },
          }}
        >
          {defaultType === 'nft' && <ActivateNftBanner />}
          <form>
            <>
              <Layout>
                {bannerError && (
                  <Banner onDismiss={() => setBannerError(null)}>
                    {bannerError}
                  </Banner>
                )}
                <MetafieldErrorBanner
                  blockchain={undefined}
                  artistRelationships={relatedArtistsData.relatedArtist}
                />
                {giftingWarning && (
                  <Layout.Section>
                    <Banner
                      title={t('info.giftingModeWarning.title')}
                      status="warning"
                    >
                      {t('info.giftingModeWarning.description')}{' '}
                      <Link
                        url="https://help.verisart.com/en/articles/6616531-what-does-test-mode-mean"
                        external
                      >
                        {t('info.giftingModeWarning.link')}
                      </Link>
                    </Banner>
                  </Layout.Section>
                )}
              </Layout>
              <div style={{ marginTop: '1rem' }}>
                <Layout>
                  <Layout.Section secondary>
                    <VariantTitleThumbnail
                      imgUrl={
                        productFormValues.product.thumbnail ||
                        '/images/placeholder.png'
                      }
                      title={productFormValues.product.name}
                      linkTo={`/product-metadata/${
                        productFormValues.product.id
                      }${defaultType !== undefined ? '/' + defaultType : ''}`}
                      numberOfVariants={
                        productFormValues.product.variants.length
                      }
                    />
                    {productFormValues.product.variants.length > 0 && (
                      <VariantsDisplaySection
                        variantList={productFormValues.product.variants}
                        currentVariant={id}
                        parentImage={productFormValues.product.thumbnail}
                      />
                    )}
                  </Layout.Section>
                  <Layout.Section>
                    <CardElement>
                      <AssetRenderer title={'Media File'} asset={mainAsset} />
                      <SelectInput
                        name={'artist_id'}
                        label={'Creator'}
                        validator={(value) => {
                          const relationship =
                            relatedArtistsData.relatedArtist.find(
                              (artist) => artist.id === value
                            );
                          // It's only not authorized if the chain is mainnet. We allow PENDING on testnet.
                          if (
                            relationship?.status === 'PENDING' &&
                            blockchain &&
                            isMainnet(blockchain)
                          ) {
                            return t(
                              'errors.metafields.general.relationship_not_authorized'
                            );
                          } else {
                            return true;
                          }
                        }}
                        //@ts-ignore
                        options={artistOptions(relatedArtistsData)}
                        noDefault
                        error={
                          methods.formState.errors.artist_id?.message ? (
                            <Stack spacing={'extraTight'}>
                              <span>
                                {' '}
                                {
                                  methods.formState.errors.artist_id?.message
                                }{' '}
                              </span>
                              {currentRelationship?.type === 'SELF' && (
                                <Button
                                  plain
                                  destructive
                                  monochrome
                                  external
                                  onClick={() =>
                                    openWindowOnVerisartDotCom(
                                      '/artists?verifyNow=true'
                                    )
                                  }
                                >
                                  {t('creatorsSection.completeSetup')}
                                </Button>
                              )}
                            </Stack>
                          ) : undefined
                        }
                        customOnChange={() => methods.clearErrors('artist_id')}
                      />
                      <TextInput
                        name={'title'}
                        label={'Title'}
                        placeholder={
                          productFormValues.metafields?.title
                            ?.inheritedValue?.[0]
                        }
                        error={methods.formState.errors.title?.message}
                        customOnChange={() => methods.clearErrors('title')}
                      />
                      <TextInput
                        name={'description'}
                        label={'Description'}
                        placeholder={
                          productFormValues.metafields?.description
                            ?.inheritedValue?.[0]
                        }
                        textbox={4}
                        error={methods.formState.errors.description?.message}
                        customOnChange={() =>
                          methods.clearErrors('description')
                        }
                      />
                      <Stack>
                        {!isNft && (
                          <Stack.Item fill>
                            <SelectInput
                              name="object_type"
                              label="Object Type"
                              placeholder={
                                productFormValues.metafields?.object_type
                                  ?.inheritedValue?.[0]
                              }
                              options={ObjectTypeOptions.map(function (
                                objectTypeOption
                              ) {
                                return {
                                  value: objectTypeOption.object_type,
                                  label: objectTypeOption.label,
                                };
                              })}
                              error={
                                methods.formState.errors?.object_type?.message
                              }
                              customOnChange={() =>
                                methods.clearErrors('object_type')
                              }
                            />
                          </Stack.Item>
                        )}
                        <Stack.Item>
                          <TextInput
                            name="production_year"
                            label="Production Year"
                            placeholder={
                              productFormValues.metafields?.production_year
                                ?.inheritedValue?.[0]
                            }
                            error={
                              methods.formState.errors.production_year?.message
                            }
                            customOnChange={() =>
                              methods.clearErrors('production_year')
                            }
                          />
                        </Stack.Item>
                      </Stack>
                      {!isNft && (
                        <FormLayout>
                          <FormLayout.Group condensed>
                            <TextInput
                              name="width"
                              label="Width"
                              placeholder={
                                productFormValues.metafields?.width
                                  ?.inheritedValue?.[0]
                              }
                              error={methods.formState.errors.width?.message}
                              customOnChange={() =>
                                methods.clearErrors('width')
                              }
                            />
                            <TextInput
                              name="height"
                              label="Height"
                              placeholder={
                                productFormValues.metafields?.height
                                  ?.inheritedValue?.[0]
                              }
                              error={methods.formState.errors.height?.message}
                              customOnChange={() =>
                                methods.clearErrors('height')
                              }
                            />
                            <TextInput
                              name="depth"
                              label="Depth"
                              placeholder={
                                productFormValues.metafields?.depth
                                  ?.inheritedValue?.[0]
                              }
                              error={methods.formState.errors.depth?.message}
                              customOnChange={() =>
                                methods.clearErrors('depth')
                              }
                            />
                            <SelectInput
                              name="dimension_unit"
                              label="Unit"
                              options={
                                !NonPhysicalObjects.includes(
                                  objectType as ObjectType
                                )
                                  ? [
                                      { value: 'INCH', label: 'inch' },
                                      { value: 'CM', label: 'cm' },
                                    ]
                                  : [{ value: 'PIXEL', label: 'Pixels' }]
                              }
                              placeholder={
                                productFormValues.metafields?.dimension_unit
                                  ?.inheritedValue?.[0]
                              }
                              error={
                                methods.formState.errors.dimension_unit?.message
                              }
                              customOnChange={() =>
                                methods.clearErrors('dimension_unit')
                              }
                            />
                          </FormLayout.Group>
                        </FormLayout>
                      )}
                    </CardElement>
                    {productTriggerLevel === 'NFT' && (
                      <CardElement title="Traits">
                        <TextInput
                          name="traits"
                          label="Enter JSON code"
                          textbox={8}
                          placeholder="[
                    {“trait_type”: “Background”,
                    “value”: “LEAVES”},
                    
                    {“trait_type”: “Eyes”,
                    “value”: “GREEN”},
                    
                    {“trait_type”: “Hair”,
                    “value”: “LONG”},
                    
                    {“trait_type”: “Body”,
                    “value”: “Round”},
                    
                    {“trait_type”: “Teeth”,
                    “value”: “Gold”}
                    ]"
                          helpText="Must be in JSON format"
                          labelAction={{
                            content: 'See example',
                            url: 'https://help.verisart.com/en/articles/6176930-configuring-nfts-in-shopify#h_e263e52ebd',
                          }}
                          error={methods.formState.errors.traits?.message}
                          customOnChange={() => methods.clearErrors('traits')}
                        />
                      </CardElement>
                    )}
                  </Layout.Section>
                </Layout>
              </div>
            </>
          </form>
          {SuccessfulToast(
            isSuccessfulToastShowing,
            setIsSuccessfulToastShowing
          )}
          {FailedToast(isFailedToastShowing, setIsFailedToastShowing)}
        </Page>
      </FormProvider>
    </div>
  );
};

export default VariantFormShow;
