import React, { useContext } from 'react';
import { Toast } from '@shopify/app-bridge/actions';
import { ShopifyAppBridgeWrapper } from './shopify/ShopifyAppBridgeWrapper';
import { WooAppBridgeWrapper } from './woo/WooAppBridgeWrapper';

export type Platform = 'Shopify' | 'Woo';

declare global {
  interface Window {
    verisartShopId: string | undefined;
    verisartPlatform: Platform | undefined;
  }
}

export const getPlatform = (): Platform => {
  if (!window.verisartPlatform) {
    const search = new URLSearchParams(window.location.search);
    const platformParam = search.get('platform') as Platform | null;

    // Shopify may not inject in a platform param, so we will default to Shopify
    window.verisartPlatform = platformParam ?? 'Shopify';
  }
  return window.verisartPlatform;
};

export const getShopDomain = (): string => {
  if (!window.verisartShopId) {
    const search = new URLSearchParams(window.location.search);
    window.verisartShopId = search.get('shop') as string;
  }

  return window.verisartShopId;
};

export type RedirectPassThroughAction = 'App' | 'Remote';
export type RedirectAdminAction = 'Admin';
export type RedirectAdminResource =
  | { type: 'product'; id: string; newContext: boolean }
  | { type: 'nft_application'; apiKey: string; callbackUrl: string }
  | { type: 'subscription' }
  | { type: 'checkout' }
  | { type: 'shipping' };

interface RedirectFunctions {
  (action: RedirectPassThroughAction, to: string): void;
  (action: RedirectAdminAction, resource: RedirectAdminResource): void;
}

export interface AppBridgeContextProps {
  apiKey: string;
  host: string;
  redirect: RedirectFunctions;
  showToast: (options: Toast.Options) => void;

  /**
   * Return the shop specific secret or session token.
   * This is NOT the Verisart session token, but it can be used to exchange for one.
   */
  getSessionToken: () => Promise<string>;
  platform: Platform;
  openInNewTab: (url: string) => void;
}

// Create the context
export const AppBridgeContext = React.createContext<AppBridgeContextProps>({
  apiKey: '',
  host: '',
  redirect: () => {},
  showToast: () => {},
  getSessionToken: async () => '',
  platform: 'Shopify',
  openInNewTab: () => {},
});

export const useAppBridge = () => {
  const context = useContext(AppBridgeContext);
  if (!context) {
    throw new Error('useAppBridge must be used within an AppBridgeProvider');
  }
  return context;
};

interface AppBridgeProps {
  children: React.ReactNode;
}

const AppBridge: React.FC<AppBridgeProps> = ({ children }) => {
  if (getPlatform() === 'Woo') {
    return <WooAppBridgeWrapper>{children}</WooAppBridgeWrapper>;
  } else {
    return <ShopifyAppBridgeWrapper>{children}</ShopifyAppBridgeWrapper>;
  }
};

export default AppBridge;
