import * as React from 'react';
import { FormLayout } from '@shopify/polaris';
import TextInput from 'components/forms/TextInput';
import { UseFormReturn } from 'react-hook-form';
import { ContractsFormValues } from './ContractsForm';
import t from 'lib/translation';

type ContractsFormProps = {
  methods: UseFormReturn<ContractsFormValues>;
  formType: 'Create' | 'Edit';
};

const ContractsFormInputs: React.FC<ContractsFormProps> = ({
  methods,
  formType,
}) => {
  return (
    <FormLayout>
      <TextInput
        name="contractName"
        label={t('createContractForm.contractName.title')}
        placeholder={t('createContractForm.placeHolders.name')}
        helpText={t('createContractForm.helpText.name')}
        required={
          formType === 'Create'
            ? t('createContractForm.contractName.errorMessage')
            : undefined
        }
        maxLength={32}
        error={methods.formState.errors?.contractName?.message}
        disabled={formType === 'Edit'}
      />
      <TextInput
        name="contractSymbol"
        label={t('createContractForm.contractSymbol.title')}
        placeholder={t('createContractForm.placeHolders.symbol')}
        helpText={t('createContractForm.helpText.symbol')}
        required={
          formType === 'Create'
            ? t('createContractForm.contractSymbol.errorMessage')
            : undefined
        }
        maxLength={6}
        error={methods.formState.errors?.contractSymbol?.message}
        disabled={formType === 'Edit'}
      />
      <TextInput
        name="description"
        label={t('createContractForm.description.title')}
        helpText={t('createContractForm.helpText.description')}
        required={t('createContractForm.description.errorMessage')}
        maxLength={1000}
        error={methods.formState.errors?.description?.message}
        textbox={3}
      />
    </FormLayout>
  );
};

export default ContractsFormInputs;
