import React from 'react';
import DashboardPage from './DashboardPage';
import useBrowserState, {
  PRINT_UI_BANNER_BROWSER_STATE,
  GETTING_STARTER_BANNER_BROWSER_STATE,
} from '../../hooks/useBrowserState';

export const DashboardPageLoader: React.FC = () => {
  const { browserState, patchBrowserState } = useBrowserState();

  if (browserState.isLoading) {
    return <></>;
  }

  return (
    <DashboardPage
      dismissGettingStartedBanner={
        browserState?.data?.browserState?.[GETTING_STARTER_BANNER_BROWSER_STATE]
          ? undefined
          : (dismissValue: string) =>
              patchBrowserState({
                [GETTING_STARTER_BANNER_BROWSER_STATE]: dismissValue,
              })
      }
      dismissPrintUiBanner={
        browserState?.data?.browserState?.[PRINT_UI_BANNER_BROWSER_STATE]
          ? undefined
          : (dismissValue: string) =>
              patchBrowserState({
                [PRINT_UI_BANNER_BROWSER_STATE]: dismissValue,
              })
      }
    />
  );
};
