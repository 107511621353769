import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import API from 'utils/api';
import { setShopifyDomain } from '../lib/tracking';
import { getShopDomain } from '../containers/AppBridge';

export type Shop = {
  shop: {
    id: string;
    defaultMetafields?: {
      [key: string]: string;
    };
    shopDomain: string;
    planType: string;
    businessVerified: null | string;
    individualVerified: null | string;
    contactEmail: string;
    customizationTemplateId: string;
    emailCustomization?: {
      fromEmail?: string;
      logo: string;
      buttonColor: string;
      body: string;
      nftBody: string;
      viewNftBody: string;
      buttonTextColor: string;
    };
    orderPreference: string;
    accountId: string | null;
    dummyCognito: boolean;
    nftSalesEligibilityReapplyDate: string | null;
    needsDepaySetup: boolean;
    verisartEmail: string | null;
    verisartCognitoUsername: string | null;
  };
};

export default function useShop(
  options?: UseQueryOptions<Shop, unknown, Shop>
): UseQueryResult<Shop> {
  return useQuery(
    'shop',
    () =>
      API()
        .get('/shop/me')
        .then((res) => res.data),
    {
      ...options,
      onSuccess(data: Shop) {
        const { shop } = data;
        if (shop) {
          setShopifyDomain(getShopDomain(), shop.planType);
        }
        options?.onSuccess?.(data);
      },
    }
  );
}
