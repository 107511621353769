import React, { useEffect, useState } from 'react';
import {
  TextStyle,
  ResourceItem,
  Card,
  Thumbnail,
  ResourceList,
  Button,
  SkeletonBodyText,
  Spinner,
  Stack,
  Badge,
} from '@shopify/polaris';
import styles from './TokenGateListItem.module.scss';
import { MinusIcon, PlusIcon } from 'components/JsxIcons';
import BlockchainAddress from 'components/BlockchainAddress';
import { useProduct } from 'hooks/useProduct';
import { Blockchain } from 'types/Blockchain';
import formatDate from 'utils/formatDate';
import t from 'lib/translation';
import { TokenGate } from '../../hooks/useGetTokenGate';
import { getApiUrl } from '../../constants';
import { downloadFile } from 'utils/download';
import { useHistory } from 'react-router-dom';
import { useAppBridge } from 'containers/AppBridge';
import BlockchainLogo from '../BlockchainLogo';

export type TokenGateListItemProps = {
  gateId: string;
  name: string;
  productIds: string[];
  chain: Blockchain;
  address: string;
  consumed?: string;
  updatedAt: string;
  usage: number;
};

const TokenGateListItem: React.FC<TokenGate> = ({
  productIds,
  name,
  blockchain,
  contractAddress,
  id,
  updatedAt,
  usage,
  templateApplied,
  discountPercentage,
  discountExist,
}) => {
  const appBridge = useAppBridge();
  const history = useHistory();
  const [csvState, setCsvState] = useState<'idle' | 'preparing'>('idle');

  const [expanded, setExpanded] = useState(false);

  const [isDeleted, setIsDeleted] = useState(false);

  const {
    data: product,
    isLoading,
    isError,
    error,
  } = useProduct(productIds[0], expanded && !isDeleted);

  useEffect(() => {
    if (error instanceof Error && error.message.indexOf('404') !== -1) {
      setIsDeleted(true);
    }
  }, [error]);

  function renderItem(item: { id: string; name: string; image: string }) {
    const { id: itemId, name: itemName, image: itemImage } = item;

    return (
      <ResourceItem
        verticalAlignment="center"
        id={itemId}
        onClick={() => {
          appBridge.redirect('Admin', {
            type: 'product',
            id: itemId.toString(),
            newContext: true,
          });
        }}
        media={
          <Thumbnail
            alt={name}
            source={itemImage || '/images/placeholder.png'}
          />
        }
        accessibilityLabel={`View details for ${itemName}`}
      >
        <div className={`${styles.space}`}>
          <div className={`${styles.block}`}>
            <h3>
              {itemName ? (
                <TextStyle variation="strong">{itemName}</TextStyle>
              ) : (
                <SkeletonBodyText />
              )}
            </h3>
          </div>
          <Button
            onClick={() => {
              appBridge.redirect('Admin', {
                type: 'product',
                id: itemId.toString(),
                newContext: false,
              });
            }}
          >
            View
          </Button>
        </div>
      </ResourceItem>
    );
  }

  return (
    <>
      <ResourceItem
        verticalAlignment="center"
        id={productIds[0]}
        onClick={() => setExpanded(!expanded)}
        media={<BlockchainLogo blockchain={blockchain} />}
      >
        <div className={`${styles.row}`}>
          <div>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
          </div>

          <div className={`${styles.expander}`}>
            {!templateApplied ||
            (discountPercentage !== null && !discountExist) ? (
              <Badge status={'critical'}>{t('productActionRequired')}</Badge>
            ) : (
              <div></div>
            )}
            <div>
              <p>{usage} redeemed</p>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button onClick={() => history.push(`/tokengates/edit/${id}`)}>
                {t('tokenGatePage.list.edit')}
              </Button>
            </div>
            {expanded ? <MinusIcon /> : <PlusIcon />}
          </div>
        </div>
      </ResourceItem>
      {expanded && (
        <Card sectioned subdued>
          <div className={`${styles['row-at-tablet']}`}>
            <div
              className={`${styles['space-y-md']} ${styles['row-half']} ${styles['detail-pair']} `}
            >
              <div className={`${styles['space-y-md']} ${styles['row-half']}`}>
                <div className={`${styles.column}`}>
                  <TextStyle variation="subdued">
                    {t('tokenGatePage.list.conditions')}
                  </TextStyle>
                  <BlockchainAddress
                    address={contractAddress}
                    blockchain={blockchain}
                  />
                </div>
                <div className={`${styles['space-y']}`}>
                  <TextStyle variation="subdued">
                    {t('tokenGatePage.list.redemptionLimit')}
                  </TextStyle>
                  <p>1</p>
                </div>
                <div>
                  <TextStyle variation="subdued">
                    {t('tokenGatePage.list.redemptions')}
                  </TextStyle>
                  <Stack alignment="center" spacing="tight">
                    <span>{usage}</span>
                    {csvState === 'idle' && (
                      <a
                        className="Polaris-Link Polaris-Link--removeUnderline"
                        download
                        href={`${getApiUrl()}/shop/tokenGate/${id}/download`}
                        onClick={async (e) => {
                          e.preventDefault();
                          setCsvState('preparing');
                          try {
                            await downloadFile(e.currentTarget.href, {
                              name: name
                                .toLowerCase()
                                .trim()
                                .replaceAll(' ', '-'),
                              extension: 'csv',
                            });
                          } finally {
                            setCsvState('idle');
                          }
                        }}
                      >
                        {t('tokenGatePage.list.csvDownload')}
                      </a>
                    )}
                    <div
                      className={csvState !== 'preparing' ? styles.hidden : ''}
                    >
                      <Spinner size="small" />
                    </div>
                  </Stack>
                </div>
              </div>
              <div
                className={`${styles['space-y-md']} ${styles['space-y-md-mobile']} ${styles['row-half']}`}
              >
                <div>
                  <TextStyle variation="subdued">
                    {t('tokenGatePage.list.chain')}
                  </TextStyle>
                  <p>{t(`blockchain.${blockchain}`)}</p>
                </div>
                {updatedAt && (
                  <div>
                    <TextStyle variation="subdued">
                      {t('tokenGatePage.list.lastUpdated')}
                    </TextStyle>
                    <p>{formatDate(updatedAt, true)}</p>
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles['row-half']}`}>
              <TextStyle variation="subdued">
                {t('tokenGatePage.list.products')}
              </TextStyle>
              {isError ? (
                <div className={`${styles.notFoundWrapper}`}>
                  <Thumbnail alt={name} source={'/images/placeholder.png'} />
                  <h3>
                    <TextStyle variation="strong">
                      {isDeleted ? 'Product deleted ' : 'Error'}
                    </TextStyle>
                  </h3>
                </div>
              ) : (
                <ResourceList
                  loading={isLoading}
                  items={
                    isLoading
                      ? [
                          {
                            id: productIds[0],
                            image: '/images/placeholder.png',
                            name: undefined,
                          },
                        ]
                      : [
                          {
                            id: productIds[0],
                            image: product?.thumbnailUrl,
                            name: product?.title,
                          },
                        ]
                  }
                  renderItem={renderItem}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default TokenGateListItem;
