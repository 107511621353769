import { Page } from '@shopify/polaris';
import React from 'react';
import TokenGateForm, { TokenGateFormProps } from './TokenGateForm';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';

type TokenGateFormPageType = Omit<TokenGateFormProps, 'setSaveCallback'>;

const TokenGateFormPageWrapper: React.FC<TokenGateFormPageType> = (props) => {
  const historyGoBack = useHistoryGoBack();
  return (
    <Page
      breadcrumbs={[
        {
          content: 'Token Gates',
          onAction: () => historyGoBack('/tokengates'),
        },
      ]}
      title={`${props.formType} tokengate`}
    >
      <TokenGateForm {...props} />
    </Page>
  );
};

export default TokenGateFormPageWrapper;
