import * as React from 'react';
import { TextStyle, Card, DataTable, Badge } from '@shopify/polaris';
import {
  ManagedRelationshipData,
  ArtistRelationshipStatus,
} from 'types/Artist';
import styles from './CreatorsTable.module.scss';
import { formatArtistName } from '../../utils/formatArtistName';

type OverrideStatus = string | null;

interface CreatorTableProps {
  rows: ManagedRelationshipData[];
  overrideStatus?: OverrideStatus;
  emptyTableText?: string;
}

const CreatorsTable = ({
  rows,
  overrideStatus,
  emptyTableText,
}: CreatorTableProps) => {
  const determineBadgeStatus = (status: ArtistRelationshipStatus) => {
    switch (status) {
      case 'PENDING':
        return 'attention';
      case 'AUTHORIZED':
        return 'success';
      case 'REJECTED':
        return 'attention';
      default:
        return 'attention';
    }
  };

  const determineBadge = (status: ArtistRelationshipStatus) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'AUTHORIZED':
        return 'Active';
      case 'REJECTED':
        return 'Rejected';
      default:
        return 'Pending';
    }
  };

  const generateBadge = (status: ArtistRelationshipStatus) => {
    if (overrideStatus) return <Badge status="warning">{overrideStatus}</Badge>;
    if (status === 'DISABLED') return <Badge>Disabled</Badge>;
    return (
      <Badge status={determineBadgeStatus(status)}>
        {determineBadge(status)}
      </Badge>
    );
  };

  const generateRows = (rawData: ManagedRelationshipData[]) => {
    if (rawData?.length === 0)
      return [
        [
          <div className={`${styles['empty-table']}`}>
            <TextStyle variation="subdued">{emptyTableText}</TextStyle>
          </div>,
        ],
      ];
    return rawData?.map((row: ManagedRelationshipData) => {
      return [
        <div className={`${styles['name-column']}`}>
          <TextStyle variation="strong">
            {formatArtistName({
              firstName: row?.attributes?.name?.firstName,
              lastName: row?.attributes?.name?.lastName ?? '',
            })}
          </TextStyle>
        </div>,
        row?.artistId,
        generateBadge(row?.status),
      ];
    });
  };

  return (
    <div>
      <Card>
        <DataTable
          columnContentTypes={['text', 'text', 'text']}
          headings={['Artist Name', 'Artist ID', 'Status']}
          rows={generateRows(rows) ?? []}
        />
      </Card>
    </div>
  );
};

export default CreatorsTable;
