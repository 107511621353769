import React from 'react';
import { TextStyle, Card, Button, Heading } from '@shopify/polaris';
import styles from './SimpleEmptyState.module.scss';

export type SimpleEmptyStateProps = {
  title: string;
  subtitle: string;
  buttonText: string;
  buttonAction: () => void;
};

const SimpleEmptyState = ({
  title,
  subtitle,
  buttonText,
  buttonAction,
}: SimpleEmptyStateProps) => {
  return (
    <Card>
      <div className={`${styles.container}`}>
        <Heading>{title}</Heading>
        <TextStyle>{subtitle}</TextStyle>
        <Button primary fullWidth={false} onClick={() => buttonAction()}>
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

export default SimpleEmptyState;
