import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';

export type RecoverEmailResponse = {
  email: string;
};

export default function useRecoverWalletEmail(): UseQueryResult<RecoverEmailResponse> {
  return useQuery('recoverWalletEmail', async () => {
    const response = await API().get('/custodialWallet/default/email');
    return response.data;
  });
}
