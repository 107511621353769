import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  FormLayout,
  Layout,
  Page,
  Stack,
  Subheading,
  TextStyle,
  hsbToRgb,
  rgbString,
  rgbToHsb,
} from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import useParentPath from 'utils/useParentPath';
import t from 'lib/translation';
import { Controller, useForm, useFormState } from 'react-hook-form';
import FromEmailField from 'components/FromEmailField';
import useEmailIdentitityVerificationStatus from '../../hooks/useEmailIdentityVerificationStatus';
import ImageFileUpload, { ImageFileUploadController } from './ImageFileUpload';
import useShop from 'hooks/useShop';
import rgbToObject from '../../utils/rgbToObject';
import ColorPicker from 'components/ColorPickerWithText';
import useContextualSaveBar from 'hooks/useContextualSaveBar';
import withSaveToast from 'hooks/withSaveToast';
import usePatchShop from 'hooks/usePatchShop';
import { useAppBridge } from 'containers/AppBridge';

export const defaultFromEmail = 'Verisart <support@verisart.com>';

export type EmailSettingsFormInputs = {
  fromEmail: string;
  logo: string;
  buttonColor: string;
  buttonTextColor: string;
};

const EmailGlobalCustomization = () => {
  const history = useHistory();
  const parentPath = useParentPath();
  const back = () => history.replace(parentPath);
  const shopQuery = useShop();
  const { mutateAsync: updateShop } = usePatchShop();

  const defaultValues = {
    fromEmail:
      shopQuery.data?.shop?.emailCustomization?.fromEmail ?? defaultFromEmail,
    logo: shopQuery.data?.shop?.emailCustomization?.logo ?? '',
    buttonColor: shopQuery.data?.shop?.emailCustomization?.buttonColor ?? '',
    buttonTextColor:
      shopQuery.data?.shop?.emailCustomization?.buttonTextColor ?? '',
  };

  const [controller] = useState(new ImageFileUploadController());
  const form = useForm({
    defaultValues,
  });
  const app = useAppBridge();
  const { handleSubmit } = form;
  const onSave = useCallback(
    async () =>
      withSaveToast(
        async () => {
          let formValid = true;
          await controller.uploadAllFiles();
          await handleSubmit(
            async (data) => {
              await updateShop({
                emailCustomization: {
                  ...data,
                },
              });
            },
            () => {
              formValid = false;
            }
          )();
          return formValid
            ? ''
            : t('settings.page.toastSettingsValidationFailed');
        },
        app.showToast,
        {
          successMessage: t(
            'settings.emailCustomization.globalCustomizationToast'
          ),
        }
      ),
    [handleSubmit, app, controller, updateShop]
  );

  useContextualSaveBar(form, form.handleSubmit(onSave));

  // @typescript-eslint/no-shadow
  const { isSubmitSuccessful, isDirty } = useFormState({
    control: form.control,
  });
  const { reset, getValues } = form;
  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues, isDirty]);

  const emailIdentityVerificationQuery = useEmailIdentitityVerificationStatus();

  const emailVerificationStatus = {
    requiresVerification:
      emailIdentityVerificationQuery.data?.requiresVerification,
    emailAddressVerified:
      emailIdentityVerificationQuery.data?.emailAddressVerified,
  };

  return (
    <Page
      title="Global email customization"
      breadcrumbs={[{ content: t('settings.page.title'), onAction: back }]}
    >
      <Layout>
        <Layout.Section fullWidth>
          <Stack vertical spacing="loose">
            <TextStyle variation="subdued">
              {t('settings.emailCustomization.GlobalCustomizationsubheading')}
            </TextStyle>
            <Card sectioned>
              <FormLayout>
                <Controller
                  name="fromEmail"
                  control={form.control}
                  rules={{
                    validate(value: string) {
                      let [name, address] = value.split(' <');
                      if (!address) {
                        address = name;
                      } else if (address.endsWith('>')) {
                        address = address.slice(0, -1);
                      } else {
                        return t(
                          'settings.emailCustomization.errorEmailAddressInvalid'
                        );
                      }

                      const input = document.createElement('input');
                      input.type = 'email';
                      input.value = address;
                      if (!input.checkValidity()) {
                        return t(
                          'settings.emailCustomization.errorEmailAddressInvalid'
                        );
                      }
                    },
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                    formState: { isSubmitting },
                  }) => (
                    <FromEmailField
                      disabled={isSubmitting}
                      email={value}
                      verificationRequired={
                        value !== defaultFromEmail &&
                        emailVerificationStatus.requiresVerification
                      }
                      verified={
                        value !== defaultFromEmail &&
                        emailVerificationStatus.emailAddressVerified
                      }
                      onEmailChange={onChange}
                      onReset={() =>
                        form.setValue('fromEmail', defaultFromEmail, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: false,
                        })
                      }
                      errorMessage={error?.message}
                    />
                  )}
                />
                <Subheading>
                  {t('settings.emailCustomization.designTitle')}
                </Subheading>
                <Controller
                  name="logo"
                  control={form.control}
                  render={({
                    field: { value, onChange, name },
                    formState: { isDirty: controlIsDirty, isSubmitting },
                  }) => (
                    <ImageFileUpload
                      controller={controller}
                      disabled={isSubmitting}
                      name={name}
                      label={t('settings.emailCustomization.logo')}
                      hint={t('settings.emailCustomization.logoUpload')}
                      initialPreview={value}
                      onFileSelected={onChange}
                      onFileUploaded={onChange}
                      reset={!controlIsDirty}
                      secondaryActions={
                        value
                          ? [
                              {
                                id: 'reset',
                                content: t(
                                  'settings.emailCustomization.logoClear'
                                ),
                                onClick({ clear }) {
                                  onChange('');
                                  clear();
                                },
                              },
                            ]
                          : undefined
                      }
                    />
                  )}
                />
                <Stack vertical spacing="extraLoose">
                  <Stack vertical spacing="tight">
                    <Stack>
                      <Controller
                        name="buttonColor"
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          formState: { isSubmitting },
                        }) => (
                          <ColorPicker
                            label={t('settings.emailCustomization.buttonColor')}
                            hint={t(
                              'settings.emailCustomization.buttonColorHint'
                            )}
                            background={rgbToHsb(rgbToObject(value))}
                            disabled={isSubmitting}
                            handleColorChange={(color) => {
                              onChange(rgbString(hsbToRgb(color)));
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="buttonTextColor"
                        control={form.control}
                        render={({
                          field: { value, onChange },
                          formState: { isSubmitting },
                        }) => (
                          <ColorPicker
                            label={t(
                              'settings.emailCustomization.buttonTextColor'
                            )}
                            hint={t(
                              'settings.emailCustomization.buttonTextColorHint'
                            )}
                            background={rgbToHsb(rgbToObject(value))}
                            disabled={isSubmitting}
                            handleColorChange={(color) => {
                              onChange(rgbString(hsbToRgb(color)));
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </FormLayout>
            </Card>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default EmailGlobalCustomization;
