import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { ContractsFormValues } from 'components/ContractsForm';

export default function useCreateContract() {
  const queryCache = useQueryClient();

  return useMutation(
    async ({
      contractName,
      contractSymbol,
      description,
      chain,
    }: ContractsFormValues) =>
      API()
        .post('/nft/contract/deploy', {
          blockchain: chain,
          name: contractName,
          symbol: contractSymbol,
          description: description,
          type: 'REGULAR',
        })
        .then((res) => {
          return res.data;
        }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('contract');
      },
    }
  );
}
