import { useQuery } from 'react-query';
import API from '../utils/api';
import { GetDropsResponse } from './useGetDrops';
import { UseQueryOptions } from 'react-query/types/react/types';

export default function useGetDrop(
  id: string,
  options?: UseQueryOptions<GetDropsResponse>
) {
  return useQuery<GetDropsResponse>(
    ['drop', id],
    async () => (await API().get(`shop/drop/${id}`)).data,
    options
  );
}
