import { DropZone, BannerStatus, Button, TextStyle } from '@shopify/polaris';
import { DropZoneFileType } from '@shopify/polaris/build/ts/latest/src/components/DropZone';
import React, { useState } from 'react';
import ArrowUpwards from 'assets/svgComponents/ArrowUpwards';
import t from 'lib/translation';
import styles from './AssetRenderer.module.scss';
import { FileLoader } from '../collectorRewards/FileLoader';
import {
  coaPresentationalExtensions,
  fileMatchesExtension,
  nftAssetExtensions,
  nftImageExtensions,
} from '../../../utils/validation';
import { isAxiosError } from '../../SettingsPage/AdvancedEmailPage';

export type DropZoneFileTypes =
  | 'nft_images'
  | 'nft_assets'
  | 'coa_presentational';

interface DropZoneWithLoaderProps {
  fileTypes: DropZoneFileTypes;
  disabled?: boolean;
  uploadFile: (file: File) => Promise<void>;
  setError: (
    message: string,
    title?: string,
    action?: { text: string; url: string },
    status?: BannerStatus
  ) => void;
}

const DropZoneWithLoader: React.FC<DropZoneWithLoaderProps> = ({
  setError,
  uploadFile,
  fileTypes,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadFiles, setUploadedFiles] = useState<File[]>([]);

  const handleDropZoneDrop = async (
    _dropFiles: File[],
    acceptedFiles: File[],
    _rejectedFiles: File[]
  ) => {
    try {
      setIsLoading(true);
      if (_rejectedFiles.length === 1) {
        setError(
          'File ' +
            _rejectedFiles[0].name +
            ` is not of type .jpg .png .gif${
              type === 'file' ? ' .mp4 .mov .glb .gltf' : ''
            }`
        );
      } else if (acceptedFiles.length === 1) {
        setUploadedFiles([acceptedFiles[0]]);
        await uploadFile(acceptedFiles[0]);
      }
    } catch (e: unknown) {
      if (
        isAxiosError(e) &&
        e?.response?.data?.message?.includes('media.too.large')
      ) {
        setError(t('productsSection.errors.tooLarge'));
      } else {
        setError(t('productsSection.errors.general'));
      }
    } finally {
      setUploadedFiles([]);
      setIsLoading(false);
    }
  };

  const uploadedFilesWidget = uploadFiles.length > 0 && (
    <FileLoader files={uploadFiles} />
  );

  const contentBeforeUpload = !uploadFiles.length && (
    <div
      style={{
        position: 'relative',
        display: isLoading ? 'none' : 'block',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '30px',
        width: '190px',
        textAlign: 'center',
      }}
    >
      <ArrowUpwards />
      <div className={styles.buttonWrap}>
        <Button disabled={disabled}>{t('productForm.uploadButtomText')}</Button>
      </div>
      <TextStyle variation={'subdued'}>
        {fileTypes === 'nft_images'
          ? t('productForm.dropzoneNFTImagesText')
          : fileTypes === 'nft_assets'
          ? t('productForm.dropzoneNFTAssetsText')
          : t('productForm.dropzoneCOAPresentationalText')}
      </TextStyle>
    </div>
  );

  const accepts =
    fileTypes === 'nft_images'
      ? nftImageExtensions
      : fileTypes === 'nft_assets'
      ? nftAssetExtensions
      : coaPresentationalExtensions;
  const type: DropZoneFileType = fileTypes === 'nft_images' ? 'image' : 'file';
  const validator = (file: File) => fileMatchesExtension(file, accepts);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div className={styles.dropWrap}>
        <DropZone
          customValidator={validator}
          type={type}
          onDrop={handleDropZoneDrop}
          disabled={disabled}
        >
          {uploadedFilesWidget}
          {contentBeforeUpload}
        </DropZone>
      </div>
    </div>
  );
};

export default DropZoneWithLoader;
