import * as React from 'react';
import styles from './GlobalBanners.module.scss';
import ActivateNftBanner from 'components/ActivateNftBanner';
import ConnectedLegacyPlanBanner from 'components/LegacyPlanBanner/LegacyPlanBanner';
import ConnectedQuotaExceededBanner from 'components/QuotaExceededBanner/QuotaExceededBanner';
import { Banners } from 'types/Banners';
import LinkedSuccessBanner from '../LinkedSuccessBanner';

interface GlobalBannersProps {
  displayedBanners?: Banners[];
}

const GlobalBanners: React.FC<GlobalBannersProps> = ({ displayedBanners }) => {
  return (
    <>
      <div className={`${styles.spacer}`}>
        {displayedBanners?.includes(Banners.NFT_STATUS) && (
          <ActivateNftBanner />
        )}
        {displayedBanners?.includes(Banners.LEGACY_PLAN) && (
          <ConnectedLegacyPlanBanner />
        )}
        {displayedBanners?.includes(Banners.QUOTA_EXCEEDED) && (
          <ConnectedQuotaExceededBanner />
        )}
        {displayedBanners?.includes(Banners.LINKED_SUCCESS) && (
          <LinkedSuccessBanner />
        )}
      </div>
    </>
  );
};

export default GlobalBanners;
