import { useEffect, useMemo, useRef } from 'react';

interface UseResizeObserver {
  targetRef: (ref: HTMLElement | null) => void;
}

export const useResizeObserver = (
  onSizeChanged: (contentRect: DOMRectReadOnly) => void
): UseResizeObserver => {
  const target = useRef<HTMLElement | null>(null);
  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const contentRect = entries.find(
          (entry) => entry.target === target.current
        )?.contentRect;
        if (contentRect) {
          onSizeChanged(contentRect);
        }
      }),
    [onSizeChanged]
  );

  useEffect(() => {
    if (target.current) {
      observer.observe(target.current);
      return () => observer.disconnect();
    }
  }, [observer]);

  return {
    targetRef: (ref) => (target.current = ref),
  };
};
