import Footer from 'components/Footer';
import React, { useState } from 'react';
import PrintFormPageWrapper from 'components/PrintForm/PrintFormPageWrapper';

const PrintsFormPage = () => {
  const [formType, setFormType] = useState<'Create' | 'Edit'>('Create');

  return (
    <>
      <PrintFormPageWrapper
        formType={formType}
        onSuccess={() => setFormType('Edit')}
      />
      <Footer />
    </>
  );
};

export default PrintsFormPage;
