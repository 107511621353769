import { useQuery } from 'react-query';
import API from 'utils/api';

export interface ProductMinimal {
  id: number;
  title: string;
  thumbnailUrl: string | null;
  description: string;
  price: number | null;
  currency: string | null;
}

// HACK: For some reason the /products/<id> endpoint returns a different shape than the /products?ids=xxx endpoint
export const useProduct = (id: string, fetch?: boolean) => {
  const {
    data: dataResult,
    isLoading,
    isError,
    error,
  } = useQuery<ProductMinimal>(
    [`products`, id],
    async () => {
      const res = await API().get(`/shopify/products/${id}`);

      if (res.status === 404) {
        throw new Error('Product not found');
      }

      return res.data;
    },
    {
      enabled: fetch !== undefined ? fetch : !!id,
    }
  );

  return {
    data: dataResult,
    isLoading,
    isError,
    error,
  };
};
