import React, { useEffect } from 'react';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import PricingPage from '../components/Pricing/PricingPage';
import { Spinner } from '@shopify/polaris';
import useRefreshSubscription from '../hooks/useRefreshSubscription';

const ConnectedPricingPage = () => {
  const { mutateAsync: refreshPlan } = useRefreshSubscription();

  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    (async () => {
      try {
        const search = new URLSearchParams(window.location.search);
        if (search.get('refresh_plan') === 'true') {
          await refreshPlan();
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [refreshPlan]);

  const { data } = useSubscriptionDetails();

  if (data && !loading) {
    return <PricingPage data={data} />;
  } else {
    return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Spinner accessibilityLabel="Loading" size="large" />
      </div>
    );
  }
};

export default ConnectedPricingPage;
