import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  TextField,
  Select,
  Stack,
  SelectGroup,
  SelectOption,
} from '@shopify/polaris';
import { AddNewWallet, AddRecipient, RecipientToAdd } from './RoyaltySplitForm';
import { isValidEthereumAddress } from './utils';
import { v4 as uuidv4 } from 'uuid';
import bigDecimal from 'js-big-decimal';
import t from 'lib/translation';
import { WalletAddress } from '../../hooks/useGetRecipients';

export interface EditRecipient {
  id?: string;
  name?: string;
  wallet: string;
  share: bigDecimal;
  uuid: string;
}

interface RecipientModalProps {
  walletsAlreadyIncluded: string[];
  totalPercentage: bigDecimal;
  close: () => void;
  // Recipients already added
  recipients: CustomRecipient[];
  // If the modal is used to edit a recipient, pre-populate the fields with this recipient's details
  editRecipient?: EditRecipient;
  addRecipient?: (
    share: bigDecimal,
    addNewRecipient?: RecipientToAdd,
    addNewWallet?: AddNewWallet,
    addRecipient?: AddRecipient
  ) => void;
}

export interface CustomRecipient {
  id: string;
  walletAddresses: WalletAddress[];
  name: string;
  isArtist: boolean;
}

export type SelectOptions = (SelectOption | SelectGroup)[];

const padWithDefault = (options: SelectOptions): SelectOptions => {
  return [{ label: 'Select', value: '' }, ...options];
};

export const RecipientModal: React.FC<RecipientModalProps> = ({
  walletsAlreadyIncluded,
  close,
  recipients,
  addRecipient,
  totalPercentage,
  editRecipient,
}) => {
  // Display States
  const [addNewRecipient, setAddNewRecipient] = useState(
    editRecipient !== undefined && editRecipient.id === undefined
  );
  const [addNewWallet, setAddNewWallet] = useState(
    editRecipient !== undefined &&
      !recipients.some((recipient) =>
        recipient.walletAddresses.some(
          (recipientWallet) => recipientWallet.address === editRecipient?.wallet
        )
      )
  );

  // Field States
  const [name, setName] = useState<string>(
    editRecipient &&
      editRecipient.id === undefined &&
      editRecipient.name !== undefined
      ? editRecipient.name
      : ''
  );
  const [recipientOrArtistId, setRecipientOrArtistId] = useState<string>(
    editRecipient && editRecipient.id !== undefined ? editRecipient.id : ''
  );
  const [wallet, setWallet] = useState<string>(
    editRecipient ? editRecipient.wallet : ''
  );
  const [share, setShare] = useState<string>(
    editRecipient ? editRecipient.share.getValue() : ''
  );

  // Error States
  const [nameInError, setNameInError] = useState<undefined | string>(undefined);
  const [walletInError, setWalletInError] = useState<undefined | string>(
    undefined
  );
  const [shareInError, setShareInError] = useState<undefined | string>(
    undefined
  );

  // Provide the list of artists/recipients
  const recipientOptions: SelectOptions = [
    {
      value: '',
      label: 'Select',
    },
    {
      title: t('royaltySplitPage.addRecipientModal.creators'),
      options: recipients
        .filter((it) => it.isArtist)
        .map((it) => ({ value: it.id, label: it.name })),
    },
    {
      title: t('royaltySplitPage.addRecipientModal.representers'),
      options: recipients
        .filter((it) => !it.isArtist)
        .map((it) => ({ value: it.id, label: it.name })),
    },
  ];

  // We take care of initialising these when the [recipientOrArtistId] changes
  const [walletOptions, setWalletOptions] = useState<SelectOptions>([]);

  // If we add a new recipient, by definition we must associate a unique new wallet with him
  useEffect(() => {
    if (addNewRecipient) {
      setAddNewWallet(true);
    }
  }, [addNewRecipient]);

  const resetAndClose = useCallback(() => {
    setName('');
    setWallet('');
    setRecipientOrArtistId('');
    setShare('');
    setAddNewRecipient(false);
    setAddNewWallet(false);
    close();
  }, [
    setName,
    setWallet,
    setRecipientOrArtistId,
    setShare,
    setAddNewWallet,
    setAddNewRecipient,
    close,
  ]);

  // Every time we choose a new recipient, we must filter the available wallets to be his
  useEffect(() => {
    if (!addNewWallet) {
      const artistOrRecipient = recipients.find(
        (it) => it.id === recipientOrArtistId
      );

      if (artistOrRecipient) {
        const wallets = artistOrRecipient.walletAddresses;

        const hardLinkedWallets = wallets.filter((it) => it.hardLinked);
        const softLinkedWallets = wallets.filter((it) => !it.hardLinked);

        let options: {
          title: string;
          options: { value: string; label: string }[];
        }[] = [];

        if (artistOrRecipient.isArtist) {
          if (softLinkedWallets.length !== 0) {
            options = [
              ...options,
              {
                title: t('royaltySplitPage.addRecipientModal.verifiedWallets'),
                options: softLinkedWallets.map((it) => ({
                  label: it.address,
                  value: it.address,
                })),
              },
            ];
          }
          if (hardLinkedWallets.length !== 0) {
            options = [
              ...options,
              {
                title: t(
                  'royaltySplitPage.addRecipientModal.unverifiedWallets'
                ),
                options: hardLinkedWallets.map((it) => ({
                  label: it.address,
                  value: it.address,
                })),
              },
            ];
          }
          setWalletOptions(padWithDefault(options));
        } else {
          setWalletOptions([
            { label: 'Select', value: '' },
            ...wallets.map((it) => ({ value: it.address, label: it.address })),
          ]);
        }
        if (hardLinkedWallets.length + softLinkedWallets.length === 1) {
          setWallet(
            hardLinkedWallets.length === 1
              ? hardLinkedWallets[0].address
              : softLinkedWallets[0].address
          );
        }
      } else {
        setWalletOptions(padWithDefault([]));
      }
    }
  }, [recipients, recipientOrArtistId, addNewWallet]);

  return (
    <Modal
      open={addRecipient !== undefined}
      onClose={() => {
        resetAndClose();
      }}
      title={t('royaltySplitPage.addRecipientModal.title')}
      primaryAction={{
        content: t('royaltySplitPage.addRecipientModal.button'),
        onAction: () => {
          //Validation
          if (recipientOrArtistId === '' && name === '') {
            setNameInError(
              t('royaltySplitPage.addRecipientModal.errors.name_required')
            );
            return;
          }
          if (wallet === '' || !isValidEthereumAddress(wallet)) {
            setWalletInError(
              t('royaltySplitPage.addRecipientModal.errors.address_not_valid')
            );
            return;
          }
          if (share === '' || isNaN(parseFloat(share))) {
            setShareInError(
              t('royaltySplitPage.addRecipientModal.errors.share_bad_input')
            );
            return;
          } else if (share !== '' && !isNaN(parseFloat(share))) {
            //Check the percentages don't go above 100
            const split: string[] = share.split('.');
            if (
              new bigDecimal(share).add(totalPercentage) > new bigDecimal('100')
            ) {
              setShareInError(
                t('royaltySplitPage.addRecipientModal.errors.share_above_100')
              );
              return;
              //Check that the share does not have more than 2 decimal places of accuracy
            } else if (split.length === 2 && split[1].length > 2) {
              setShareInError(
                t('royaltySplitPage.addRecipientModal.errors.share_precision')
              );
              return;
            }
          }
          if (walletsAlreadyIncluded.includes(wallet)) {
            setWalletInError(
              t(
                'royaltySplitPage.addRecipientModal.errors.wallet_already_added'
              )
            );
            return;
          }

          if (addNewRecipient) {
            addRecipient!!(new bigDecimal(share), {
              name,
              wallet,
              uuid: uuidv4(),
            });
            resetAndClose();
          } else if (addNewWallet) {
            addRecipient!!(new bigDecimal(share), undefined, {
              id: recipientOrArtistId,
              wallet,
              uuid: uuidv4(),
            });
            resetAndClose();
          } else {
            addRecipient!!(new bigDecimal(share), undefined, undefined, {
              id: recipientOrArtistId,
              wallet,
            });
            resetAndClose();
          }
        },
      }}
    >
      <Modal.Section>
        <Stack vertical>
          {addNewRecipient ? (
            <TextField
              onFocus={() => setNameInError(undefined)}
              error={nameInError}
              labelAction={{
                content: t(
                  'royaltySplitPage.addRecipientModal.labels.recipient.addExisting'
                ),
                onAction: () => {
                  setAddNewRecipient(false);
                  setWallet('');
                  setName('');
                },
              }}
              value={name}
              onChange={(value) => setName(value)}
              label={t(
                'royaltySplitPage.addRecipientModal.labels.recipient.title'
              )}
              autoComplete={'off'}
            />
          ) : (
            <Select
              options={recipientOptions}
              onFocus={() => setNameInError(undefined)}
              error={nameInError}
              labelAction={{
                content: t(
                  'royaltySplitPage.addRecipientModal.labels.recipient.addNew'
                ),
                onAction: () => {
                  setAddNewRecipient(true);
                  setRecipientOrArtistId('');
                  setWallet('');
                },
              }}
              label={t(
                'royaltySplitPage.addRecipientModal.labels.recipient.title'
              )}
              value={recipientOrArtistId}
              onChange={(value) => setRecipientOrArtistId(value)}
            />
          )}
          {addNewWallet ? (
            <TextField
              onFocus={() => setWalletInError(undefined)}
              error={walletInError}
              labelAction={
                !addNewRecipient
                  ? {
                      content: t(
                        'royaltySplitPage.addRecipientModal.labels.wallet.labels.addExisting'
                      ),
                      onAction: () => {
                        setAddNewWallet(false);
                      },
                    }
                  : undefined
              }
              value={wallet}
              onChange={(value) => setWallet(value)}
              label={t(
                'royaltySplitPage.addRecipientModal.labels.wallet.labels.title'
              )}
              autoComplete={'off'}
            />
          ) : (
            <Select
              onFocus={() => setWalletInError(undefined)}
              error={walletInError}
              labelAction={{
                content: t(
                  'royaltySplitPage.addRecipientModal.labels.wallet.labels.addNew'
                ),
                onAction: () => {
                  setAddNewWallet(true);
                  setWallet('');
                },
              }}
              label={t(
                'royaltySplitPage.addRecipientModal.labels.wallet.labels.title'
              )}
              value={wallet}
              onChange={(value) => setWallet(value)}
              options={walletOptions}
            />
          )}
          <TextField
            onFocus={() => setShareInError(undefined)}
            error={shareInError}
            value={share}
            onChange={(value) => setShare(value)}
            label={t(
              'royaltySplitPage.addRecipientModal.labels.share.labels.title'
            )}
            autoComplete={'off'}
            helpText={t(
              'royaltySplitPage.addRecipientModal.labels.share.labels.helpText'
            )}
          />
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
