import { Platform } from '../containers/AppBridge';

export type VerisartPanel = 'depay' | 'usage_charges';
export type VerisartPage = 'tokengates' | 'prints';

type DisabledPanelsType = {
  [key in Platform]: VerisartPanel[];
};

type DisabledPagesType = {
  [key in Platform]: VerisartPage[];
};

const disabledPanelsByPlatform: DisabledPanelsType = {
  Woo: ['depay', 'usage_charges'],
  Shopify: [],
};

const disabledPagesByPlatform: DisabledPagesType = {
  Woo: ['tokengates', 'prints'],
  Shopify: [],
};

export const isPageDisabled = (
  page: VerisartPage,
  platform: Platform
): boolean => {
  return disabledPagesByPlatform[platform].includes(page);
};

export const isPanelDisabled = (
  panel: VerisartPanel,
  platform: Platform
): boolean => {
  return disabledPanelsByPlatform[platform].includes(panel);
};
