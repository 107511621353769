import { Select, SelectGroup, SelectOption } from '@shopify/polaris';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Error } from '@shopify/polaris/build/ts/latest/src/types';

interface ControllerSelectInputProps {
  name: string;
  label: string;
  placeholder?: string;
  helpText?: string;
  textbox?: number;
  labelAction?: { content: string; url: string };
  customOnChange?: (val: string) => void;
  noDefault?: boolean;

  error?: Error;

  prefix?: React.ReactNode;

  options: (SelectOption | SelectGroup)[];
  required?: string;
  disabled?: boolean;
  customDefault?: SelectOption;
  customOnFocus?: () => void;
  validator?: (value: string) => string | boolean;
  valueOverride?: string;
}

const ControllerSelectInput: React.FC<ControllerSelectInputProps> = ({
  options,
  name,
  label,
  helpText,
  customOnChange,
  noDefault,
  error,
  required,
  disabled,
  customDefault,
  customOnFocus,
  validator,
  valueOverride,
}) => {
  return (
    <Controller
      name={name}
      rules={{ required: disabled ? false : required, validate: validator }}
      render={({ field: { value, onChange } }) => (
        <Select
          onFocus={customOnFocus}
          value={value === null ? '' : value}
          onChange={(fieldValue) => {
            if (customOnChange) {
              customOnChange(fieldValue);
            }
            onChange(fieldValue);
          }}
          label={label}
          options={
            noDefault === true
              ? options
              : (
                  [
                    customDefault
                      ? customDefault
                      : valueOverride !== undefined
                      ? { label: valueOverride, value: valueOverride }
                      : { label: 'Select', value: '' },
                  ] as (SelectOption | SelectGroup)[]
                ).concat(options)
          }
          error={error}
          helpText={helpText ?? ''}
          disabled={disabled}
        />
      )}
    />
  );
};

export default ControllerSelectInput;
