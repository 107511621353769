import { Spinner } from '@shopify/polaris';
import Footer from 'components/Footer';
import useGetTokenGate from 'hooks/useGetTokenGate';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import styles from './EditTokenGatePage.module.scss';
import TokenGateFormPageWrapper from '../../components/TokenGateForm/TokenGateFormPageWrapper';

const EditTokenGateFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const [deleted, setDeleted] = useState(false);
  const { data, isLoading } = useGetTokenGate(id, !deleted);

  return (
    <>
      {isLoading ? (
        <div className={`${styles.center}`}>
          <Spinner accessibilityLabel="Spinner" size="large" />
        </div>
      ) : (
        <TokenGateFormPageWrapper
          formType="Edit"
          data={data}
          id={id}
          onDelete={() => setDeleted(true)}
        />
      )}
      <Footer />
    </>
  );
};

export default EditTokenGateFormPage;
