import React from 'react';
import { Controller, Validate, useFormContext } from 'react-hook-form';
import { DateTimeInput } from './DateTimeInput';
import { DateTime } from 'luxon';
import { TextStyle } from '@shopify/polaris';

interface ControlledDateTimeInputProps<TFieldValue> {
  name: string;
  timeLabel: string;
  dateLabel: string;
  hintLabel?: string;
  disabled?: boolean;
  validate?: Validate<TFieldValue, Record<string, string>>;
}

// const formatTime = (value?: string): DateTime | undefined => {
//   if (value) {
//     return DateTime.fromISO(value).set({ second: 0, millisecond: 0 });
//   }
// }

export const ControlledDateTimeInput: React.FC<
  ControlledDateTimeInputProps<string>
> = ({ name, timeLabel, dateLabel, hintLabel, disabled, validate }) => {
  const methods = useFormContext();
  const error = methods.formState.errors[name]?.message;

  return (
    <>
      <Controller
        rules={{
          validate,
        }}
        name={name}
        render={({ field }) => (
          <DateTimeInput
            value={DateTime.fromISO(field.value).set({
              second: 0,
              millisecond: 0,
            })}
            onChange={(value) => {
              field.onChange(value);
              methods.clearErrors(name);
            }}
            timeLabel={timeLabel}
            dateLabel={dateLabel}
            disabled={disabled}
          />
        )}
      />
      {hintLabel && (
        <>
          <TextStyle variation="subdued">{hintLabel}</TextStyle>
          <br />
        </>
      )}
      {error && <TextStyle variation="negative">{error}</TextStyle>}
    </>
  );
};
