import React, { useEffect, useState } from 'react';
import { Card, Filters, ResourceList } from '@shopify/polaris';
import TokenGateListItem from 'components/TokenGateListItem';
import { LocalPagination, determinePageSize } from 'components/LocalPagination';
import styles from './TokenGateList.module.scss';
import { TokenGate } from '../../hooks/useGetTokenGate';
import { SortOptions, SortType } from '../../types/Utils';
import { orderBy } from 'lodash';

const TokenGateList: React.FC<{ tokenGates: TokenGate[] }> = ({
  tokenGates,
}) => {
  const pageSize = 10;
  const numberOfPages = Math.ceil(tokenGates.length / pageSize);

  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [currentPage, setCurrentPage] = useState(1);
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [tokengatesAvailable, setTokengatesAvailable] =
    useState<TokenGate[]>(tokenGates);
  const [tokengatesAvailableAndSorted, setTokengatesAvailableAndSorted] =
    useState<TokenGate[]>(tokenGates);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setTokengatesAvailable(
        tokenGates.filter((tokengate) =>
          tokengate.name.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
    } else {
      setTokengatesAvailable(tokenGates);
    }
  }, [queryValue, tokenGates]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setTokengatesAvailableAndSorted(
          orderBy(tokengatesAvailable, (t) => t.name.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setTokengatesAvailableAndSorted(
          orderBy(tokengatesAvailable, 'createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setTokengatesAvailableAndSorted(
          orderBy(tokengatesAvailable, 'updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, tokengatesAvailable]);

  return (
    <>
      <Card>
        <ResourceList
          sortValue={sortValue}
          sortOptions={SortOptions}
          onSortChange={(selected: SortType) => {
            setSortValue(selected);
          }}
          filterControl={filterControl}
          items={determinePageSize(
            tokengatesAvailableAndSorted,
            currentPage,
            pageSize,
            numberOfPages
          )}
          renderItem={(item) => <TokenGateListItem {...(item as TokenGate)} />}
        />
      </Card>
      <div className={`${styles.pagination}`}>
        <LocalPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numberOfPages={numberOfPages}
        />
      </div>
    </>
  );
};

export default TokenGateList;
