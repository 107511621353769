import { useQuery } from 'react-query';
import { Shop } from 'hooks/useShop';
import API from 'utils/api';

export type CustomizeData = {
  logo?: string;
  background?: string;
  issuerName?: string;
  issuerNote?: string;
  issuerLink?: string;
  templateName?: string;
  subheading?: string;
  id?: string;
  templateId: string;
  link?: string;
};

export type CustomizationResponse = {
  customizations: CustomizeData[];
  shopTemplateId: string;
};

export default function useCustomization() {
  return useQuery<CustomizationResponse>('customize', async () => {
    const customizations = await API()
      .get('/customize/me')
      .then((res) => res.data);

    const shopTemplateId = await API()
      .get('/shop/me')
      .then((res: { data: Shop }) => res.data.shop.customizationTemplateId);

    return {
      customizations,
      shopTemplateId,
    };
  });
}
