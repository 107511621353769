import React, { useEffect, useMemo, useState } from 'react';
import { Provider as ShopifyAppBridgeProvider } from '@shopify/app-bridge-react';
import useShopifyInstall from 'hooks/useShopifyInstall';
import LoadingPage from 'auth/LoadingPage';
import { useHistory, useLocation } from 'react-router-dom';
import ShopifyDriver from './ShopifyDriver';
import { getShopDomain } from '../AppBridge';

interface ShopifyAppBridgeWrapperProps {
  children: React.ReactNode;
}

export const ShopifyAppBridgeWrapper: React.FC<
  ShopifyAppBridgeWrapperProps
> = ({ children }) => {
  // Setting up the app bridge router
  const location = useLocation();
  const history = useHistory();
  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  const [redirecting, setRedirecting] = useState(true);
  const {
    authorizationUrl,
    apiKey,
    isLoading: isLoadingAuthorizationUrl,
    authorizationNeeded,
  } = useShopifyInstall();

  // Load the host from the window URLs (note we need to use state because after the first redirect
  // it will be lost)
  const [hostBase64, setHostBase64] = useState<string | undefined>();
  const [host, setHost] = useState<string | undefined>();
  useEffect(() => {
    const tmp = new URLSearchParams(window.location.search).get('host');
    if (tmp) {
      setHostBase64(tmp);
      setHost(atob(tmp));
    } else {
      console.error('No host param found on URL');
    }
  }, []);

  useEffect(() => {
    if (authorizationNeeded && authorizationUrl) {
      window.location.assign(authorizationUrl);
    } else {
      setRedirecting(false);
    }
  }, [authorizationUrl, authorizationNeeded]);

  if (isLoadingAuthorizationUrl || redirecting || !host || !hostBase64) {
    return <LoadingPage />;
  } else {
    const config = {
      apiKey,
      host: hostBase64,
      shopOrigin: getShopDomain(),
      forceRedirect: true,
    };

    return (
      <ShopifyAppBridgeProvider config={config} router={router}>
        <ShopifyDriver apiKey={apiKey} host={host}>
          {children}
        </ShopifyDriver>
      </ShopifyAppBridgeProvider>
    );
  }
};
