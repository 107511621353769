import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

interface SetProductsCertifiedResult {
  productsAlreadyInDrop: number[];
  productsCertified: number[];
}
interface SetProductsCertifiedRequest {
  productIds: number[];
}

export default function useCertifyProducts() {
  const queryCache = useQueryClient();

  return useMutation<
    SetProductsCertifiedResult,
    AxiosError,
    SetProductsCertifiedRequest
  >(
    async ({ productIds }) => {
      const response = await API().post('/shopify/certificates', {
        productIds: productIds,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('certificates');
      },
    }
  );
}
