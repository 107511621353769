import React from 'react';
import { RoyaltySplitPage } from './RoyaltySplitPage';
import { useGetRoyaltyAddresses } from '../../hooks/useGetRoyaltyAddresses';
import LoadingState from 'pages/LoadingState';

export const RoyaltySplitPageLoader: React.FC = () => {
  const { data, error } = useGetRoyaltyAddresses();

  if (error) {
    throw error;
  }

  return (
    <>
      {!data ? (
        <LoadingState />
      ) : (
        <RoyaltySplitPage
          verisartWallet={data.verisartWallet}
          royaltyContracts={data.royaltyAddresses}
          shopWallet={data.shopWallet}
        />
      )}
    </>
  );
};
