import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import {
  Page,
  Layout,
  Card,
  DisplayText,
  TextStyle,
  Button,
  ResourceList,
  Filters,
} from '@shopify/polaris';
import t from 'lib/translation';
import { PaymentSplitterContractRenderable } from '../../hooks/useProductFormValues';
import { RoyaltySplitItem } from './RoyaltySplitItem';
import {
  determinePageSize,
  LocalPagination,
} from '../../components/LocalPagination';
import style from './RoyaltySplit.module.scss';
import { useHistory } from 'react-router-dom';
import { RoyaltyAddressWithPayees } from '../../hooks/useGetRoyaltyAddresses';
import { SortOptions, SortType } from '../../types/Utils';

interface RoyaltySplitPageProps {
  verisartWallet: string | null;
  shopWallet: string | null;
  royaltyContracts: RoyaltyAddressWithPayees[];
}

export const RoyaltySplitPage: React.FC<RoyaltySplitPageProps> = ({
  royaltyContracts,
  verisartWallet,
  shopWallet,
}) => {
  const history = useHistory();

  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const pageSize = 10;
  const numberOfPages = Math.ceil(royaltyContracts.length / pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [contractsAvailable, setContractsAvailable] =
    useState<RoyaltyAddressWithPayees[]>(royaltyContracts);
  const [contractsAvailableAndSorted, setContractsAvailableAndSorted] =
    useState<RoyaltyAddressWithPayees[]>(royaltyContracts);

  useEffect(() => {
    if (queryValue) {
      setContractsAvailable(
        royaltyContracts.filter((royaltyContract) =>
          (royaltyContract.name || '')
            .toLowerCase()
            .startsWith(queryValue.toLowerCase())
        )
      );
    } else {
      setContractsAvailable(royaltyContracts);
    }
  }, [queryValue, royaltyContracts]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, (c) => c.name?.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, contractsAvailable]);

  return (
    <Page
      title={t('royaltySplitPage.title')}
      primaryAction={
        royaltyContracts.length !== 0
          ? {
              content: t('royaltySplitPage.actionText'),
              onAction() {
                history.push('/royalty-splits-create');
              },
            }
          : null
      }
    >
      {royaltyContracts.length === 0 ? (
        <Layout>
          <Layout.Section>
            <Card>
              <div className={style['royalty-split-contracts-card']}>
                <DisplayText element="h2" size="medium">
                  {t('royaltySplitPage.emptyState.title')}
                </DisplayText>
                <TextStyle variation={'subdued'}>
                  {t('royaltySplitPage.emptyState.subtitle')}
                </TextStyle>
                <div>
                  <Button
                    onClick={() => history.push('/royalty-splits-create')}
                    primary
                  >
                    {t('royaltySplitPage.emptyState.ctaText')}
                  </Button>
                </div>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      ) : (
        <Layout>
          <Layout.Section>
            <Card>
              <ResourceList
                sortValue={sortValue}
                sortOptions={SortOptions}
                onSortChange={(selected: SortType) => {
                  setSortValue(selected);
                }}
                items={determinePageSize(
                  contractsAvailableAndSorted,
                  currentPage,
                  pageSize,
                  numberOfPages
                )}
                filterControl={
                  <Filters
                    queryValue={queryValue}
                    filters={[]}
                    onQueryChange={(value) => setQueryValue(value)}
                    onQueryClear={() => setQueryValue(undefined)}
                    onClearAll={() => setQueryValue(undefined)}
                  />
                }
                renderItem={(item) => (
                  <RoyaltySplitItem
                    {...(item as PaymentSplitterContractRenderable)}
                    key={(item as PaymentSplitterContractRenderable).id}
                    verisartWallet={verisartWallet}
                    shopWallet={shopWallet}
                  />
                )}
              />
            </Card>
            <div className={style['local-pagination']}>
              <LocalPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={numberOfPages}
              />
            </div>
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
};
