import React from 'react';
import { Card, Page } from '@shopify/polaris';
import Footer from 'components/Footer';
import EmptyStateContractsListPage from 'components/EmptyStatePage';
import t from 'lib/translation';
import useGetContracts from 'hooks/useGetContracts';
import styles from './ContractsListPage.module.scss';
import ContractsList from 'components/ContractsList';
import { useHistory } from 'react-router-dom';

const ContractsListPage = () => {
  const { data } = useGetContracts('ALL');

  const history = useHistory();

  const createContract = () => history.push(`/contracts/create`);

  return (
    <>
      <Page
        title={t('contractsPage.title')}
        primaryAction={{
          content: t('contractsPage.createButton'),
          onAction: async () => createContract(),
        }}
      >
        {data && data?.contracts?.length === 0 ? (
          <>
            <EmptyStateContractsListPage
              heading={'Create a new contract today'}
              body={'Create a new contract to sell NFTs'}
              linkText={'Create Contract'}
              linkAction={createContract}
            />
          </>
        ) : (
          <div className={`${styles['mx-4']}`}>
            <Card>
              <ContractsList contracts={data?.contracts || []} />
            </Card>
          </div>
        )}
      </Page>
      <Footer />
    </>
  );
};

export default ContractsListPage;
