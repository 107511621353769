import * as React from 'react';

const Tick = () => (
  <svg width={20} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.084c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10Zm15.207-1.793a1 1 0 0 0-1.414-1.414L9 11.67 6.707 9.377a1 1 0 1 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l5.5-5.5Z"
      fill="#007F5F"
    />
  </svg>
);

export default Tick;
