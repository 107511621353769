import { Banner, Layout, Stack, Button } from '@shopify/polaris';
import React, { useState } from 'react';
import t from 'lib/translation';
import { metafieldGuideUrl } from '../../../constants';
import { MetafieldsType } from 'hooks/useProductFormValues';
import { useFormContext } from 'react-hook-form';
import { MetafieldFormValues } from '../ProductFormShow';
import { RelationshipDataDetails } from 'types/Artist';
import { useOpenWindowOnVerisartDotCom } from '../../../utils/mainWebsiteLogin';
import { GetReadyToSellModal } from '../ProductInfo/GetReadToSellModal';
import { Blockchain } from '../../../types/Blockchain';

function metafieldToReadableField(
  metafield: MetafieldsType,
  error: string | undefined
) {
  return t(`metafields.${metafield}`, metafield) + ': ' + error;
}

interface MetafieldErrorBannerProps {
  blockchain: Blockchain | undefined;
  artistRelationships: Array<RelationshipDataDetails>;
}

export const MetafieldErrorBanner: React.FC<MetafieldErrorBannerProps> = ({
  blockchain,
  artistRelationships,
}) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<MetafieldFormValues>();
  const artistId = watch('artist_id');

  const [getReadyToSellModal, setGetReadyToSellModal] = useState(false);
  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const definedErrors = (Object.keys(errors) as MetafieldsType[])
    .map((metafield: MetafieldsType) => ({
      metafield,
      errorMessage: errors[metafield]?.message,
      errorCode: errors[metafield]?.type,
    }))
    .filter((it) => it.errorMessage && it.errorCode) as {
    metafield: MetafieldsType;
    errorMessage: string;
    errorCode: string;
  }[];

  // Special case for sold existing NFTs
  if (
    definedErrors.length === 1 &&
    definedErrors[0].errorCode === 'token_id_existing.nft_already_sold'
  ) {
    return (
      <Layout.Section>
        <Banner title={t('productsSection.incorrectMetaFields.titleUnsoldNFT')}>
          <p>{t('productsSection.incorrectMetaFields.messageUnsoldNFT')}</p>
          <ul>{null}</ul>
        </Banner>
      </Layout.Section>
    );
  }

  const toShow = definedErrors.map((error) => {
    const key: MetafieldsType = error.metafield;
    const errorMessage = error.errorMessage;
    const errorCode = error.errorCode;
    if (key === 'artist_id') {
      // TODO: I have a feeling none of this is necessary and that the server is already
      //       returning the correct error which is already set in errors but I'm scared to touch this code
      const currentRelationship = artistRelationships.find(
        (relationship) => relationship.id === artistId
      );
      if (currentRelationship && currentRelationship.type === 'SELF') {
        return (
          <li>
            <Stack spacing={'extraTight'}>
              <span>
                {metafieldToReadableField(key, 'Creator not verified')}
              </span>
              <Button
                external
                monochrome
                plain
                onClick={() =>
                  openWindowOnVerisartDotCom('/artists?verifyNow=true')
                }
              >
                Complete setup
              </Button>
            </Stack>
          </li>
        );
      }
    } else if (
      errorCode === 'token_id_existing.wallet_cant_transfer_existing'
    ) {
      return (
        <li>
          <span>{`${metafieldToReadableField(key, errorMessage)}. `}</span>
          <Button plain onClick={() => setGetReadyToSellModal(true)}>
            {t(
              'productsSection.incorrectMetaFields.existingNFTPermissionsResolveNow'
            )}
          </Button>
        </li>
      );
    } else if (
      errorCode === 'image_url.media_type_not_supported' &&
      blockchain
    ) {
      return (
        <li>
          <span>
            {metafieldToReadableField(
              key,
              t('productsSection.errors.NFTFileTypes')
            )}
          </span>
        </li>
      );
    } else if (
      errorCode === 'image_url.media_type_not_supported' &&
      !blockchain
    ) {
      return (
        <li>
          <span>
            {metafieldToReadableField(
              key,
              t('productsSection.errors.COAFileTypes')
            )}
          </span>
        </li>
      );
    } else if (errorCode === 'image_url.required' && blockchain) {
      return (
        <li>
          <span>
            {metafieldToReadableField(
              key,
              t('productsSection.errors.imageMissingNFT')
            )}
          </span>
        </li>
      );
    } else if (errorCode === 'image_url.required' && !blockchain) {
      return (
        <li>
          <span>
            {metafieldToReadableField(
              key,
              t('productsSection.errors.imageMissingCOA')
            )}
          </span>
        </li>
      );
    }

    return <li>{metafieldToReadableField(key, errorMessage)}</li>;
  });

  return (
    <>
      {getReadyToSellModal && blockchain && (
        <GetReadyToSellModal
          isOpen={getReadyToSellModal}
          onClose={() => setGetReadyToSellModal(false)}
          blockchain={blockchain}
        />
      )}
      {toShow.length !== 0 && (
        <Layout.Section>
          <Banner
            title={t('productsSection.incorrectMetaFields.title')}
            status={'critical'}
            secondaryAction={{
              content: t('productsSection.incorrectMetaFields.action'),
              url: metafieldGuideUrl,
              external: true,
            }}
          >
            <p>{t('productsSection.incorrectMetaFields.message')}</p>
            <ul>{toShow}</ul>
          </Banner>
        </Layout.Section>
      )}
    </>
  );
};
