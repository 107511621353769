import React from 'react';
import { Spinner } from '@shopify/polaris';

const LoadingPage: React.FC = () => (
  <div
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <Spinner accessibilityLabel="Loading" size="large" />
  </div>
);

export default LoadingPage;
