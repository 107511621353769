import React from 'react';
import {
  Blockchain,
  BlockchainClass,
  blockchainToClass,
} from '../types/Blockchain';
import { BaseIcon, EthereumIcon, PolygonIcon } from './JsxIcons';

const BlockchainLogo: React.FC<{ blockchain: Blockchain }> = ({
  blockchain,
}) => {
  return blockchainToClass(blockchain) === BlockchainClass.POLYGON ? (
    <PolygonIcon />
  ) : blockchainToClass(blockchain) === BlockchainClass.ETHEREUM ? (
    <EthereumIcon />
  ) : blockchainToClass(blockchain) === BlockchainClass.BASE ? (
    <BaseIcon />
  ) : null;
};

export default BlockchainLogo;
