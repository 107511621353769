import React from 'react';
import { Button, Heading, Stack } from '@shopify/polaris';
import style from './HelpGuideCard.module.scss';

interface HelpGuideCardProps {
  image: React.ReactNode;
  title: string;
  subtitle: string;
  buttonText: string;
  linkTo: string;
}

export const HelpGuideCard: React.FC<HelpGuideCardProps> = ({
  image,
  title,
  subtitle,
  buttonText,
  linkTo,
}) => {
  return (
    <div className={`${style.stretchedBox}`}>
      <Stack vertical>
        <Stack distribution={'center'}>{image}</Stack>
        <Heading>{title}</Heading>
        <Stack.Item fill>
          <p>{subtitle}</p>
        </Stack.Item>
        <Button external url={linkTo}>
          {buttonText}
        </Button>
      </Stack>
    </div>
  );
};
