import API from 'utils/api';
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';

interface DeleteCollectorReward {
  productId: string;
  index: number;
}

export default function useDeleteCollectorReward(): UseMutationResult<
  AxiosResponse<unknown>,
  unknown,
  DeleteCollectorReward,
  unknown
> {
  return useMutation((request: DeleteCollectorReward) =>
    API().delete(
      `/shop/products/${request.productId}/collectorReward/${request.index}`
    )
  );
}
