import API from '../utils/api';
import { useQuery } from 'react-query';

export interface NeedsRefresh {
  url: string | null;
}

const fetchNeedPermissionRefresh = async () =>
  (await API().get('/shopify/authURL')).data;

const useNeedPermissionRefresh = () => {
  const { data, isLoading, isError } = useQuery<NeedsRefresh>(
    'appPermissions',
    () => fetchNeedPermissionRefresh()
  );

  if (isError) throw new Error('Could not fetch app permissions');

  return {
    data,
    isLoading,
  };
};

export default useNeedPermissionRefresh;
