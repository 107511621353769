import Footer from 'components/Footer';
import DropForm from 'components/DropForm';
import React from 'react';
import { useParams } from 'react-router';

const EditDropPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <DropForm editId={id} />
      <Footer />
    </>
  );
};

export default EditDropPage;
