import { useMutation } from 'react-query';
import API from 'utils/api';
import { AxiosResponse } from 'axios';

export type SetupShopProps = {
  shopDomain: string;
  sessionToken: string;
  selfCreatorDetails: SelfCreatorDetails;
  then: (data: SetupShopResponse) => void;
};

interface SetupShopResponse {
  accountId: string;
}

export type SelfCreatorDetails = {
  name: string;
  lastName: string | null;
  associatedYear: string;
  overrideAdminEmail: string | null;
};

export default function useSetupShop() {
  return useMutation(
    ({ shopDomain, sessionToken, selfCreatorDetails, then }: SetupShopProps) =>
      API()
        .post('/shopify/setupAccount', {
          shopDomain,
          sessionToken,
          selfCreatorDetails,
        })
        .then((res: AxiosResponse<SetupShopResponse>) => then(res.data))
  );
}
