import { useGetCustodialWalletBalance } from 'hooks/useGetCustodialWalletBalance';
import React, { useState } from 'react';
import {
  Card,
  ResourceItem,
  ResourceList,
  Spinner,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import t from 'lib/translation';
import { Blockchain } from 'types/Blockchain';
import RecoverWalletModal from '../../components/RecoverWalletModal/RecoverWalletModal';
import BlockchainAddress from 'components/BlockchainAddress';
import BlockchainLogo from '../../components/BlockchainLogo';

const CustodialWalletBalance: React.FC = () => {
  const [recoverWalletModal, setRecoverWalletModal] = useState(false);
  const { data } = useGetCustodialWalletBalance();

  return (
    <Card
      title={t('settingsSection.custodialWallets.title')}
      actions={[
        {
          content: t('settingsSection.custodialWallets.recoveryLink'),
          onAction() {
            setRecoverWalletModal(true);
          },
        },
      ]}
    >
      <RecoverWalletModal
        open={recoverWalletModal}
        hideModal={() => setRecoverWalletModal(false)}
      />
      {data === undefined ? (
        <Card.Section>
          <Stack distribution={'center'}>
            <Spinner />
          </Stack>
        </Card.Section>
      ) : (
        <>
          <Card.Section>
            <Stack vertical>
              <Stack vertical spacing={'tight'}>
                <TextStyle>
                  {t('settingsSection.custodialWallets.walletTitle')}
                </TextStyle>
                <BlockchainAddress
                  address={data.address}
                  blockchain={Blockchain.ETHEREUM}
                  fullLength
                />
              </Stack>
              <TextStyle>
                {t('settingsSection.custodialWallets.listTitle')}
              </TextStyle>
            </Stack>
          </Card.Section>
          <Card.Section fullWidth flush>
            <ResourceList
              items={[
                Blockchain.BASE_MAINNET,
                Blockchain.ETHEREUM,
                Blockchain.ETHEREUM_SEPOLIA,
                Blockchain.POLYGON,
              ].map((it) => ({
                blockchain: t(`blockchain.${it}`),
                icon: <BlockchainLogo blockchain={it} />,
                crypto: data.balance[it as Blockchain].crypto,
                cryptoName: t(`blockchainCurrency.${it}`),
                fiat: data.balance[it as Blockchain].fiat,
              }))}
              renderItem={(item) => {
                return (
                  <ResourceItem id={item.blockchain} onClick={() => {}}>
                    <Stack alignment={'center'}>
                      {item.icon && item.icon}
                      <Stack.Item fill>
                        <Stack vertical spacing={'extraTight'}>
                          <TextStyle>{item.blockchain}</TextStyle>
                          <TextStyle>${item.fiat}</TextStyle>
                        </Stack>
                      </Stack.Item>
                      <Stack spacing={'extraTight'}>
                        <TextStyle>{item.crypto}</TextStyle>
                        <TextStyle>{item.cryptoName}</TextStyle>
                      </Stack>
                    </Stack>
                  </ResourceItem>
                );
              }}
            />
          </Card.Section>
        </>
      )}
    </Card>
  );
};

export default CustodialWalletBalance;
