import t from '../lib/translation';

/**
 * Collector reward file extensions
 *
 * IMPORTANT: Don't forget to update `productForm.dropzoneCollectorRewardText`
 * in translation.ts
 */
export const collectorRewardExtensions = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.mp4',
  '.mov',
  '.wmv',
  '.pdf',
  '.csv',
  '.txt',
  '.docx',
  '.doc',
  '.mp4',
  '.xlsx',
  '.xls',
  '.mp3',
  '.zip',
  '.glb',
  '.gltf',
  '.usdz',
  '.json',
  '.vrm',
  '.fbx',
];

/**
 * Supported images types used in Verisart for things like email templates,
 * logos etc.
 */
export const simpleImageExtensions = ['.jpeg', '.jpg', '.png', '.gif'];

/**
 * Supported images for NFTs
 *
 * IMPORTANT: Don't forget to update `productForm.dropzoneNFTImagesText`
 * in translation.ts
 */
export const nftImageExtensions = ['.jpeg', '.jpg', '.png', '.gif'];

/**
 * Supported main assets for NFTs (if it's an image it will end up in the `image`
 * field, otherwise in the `animation_url` field).
 *
 * IMPORTANT: Don't forget to update `productForm.dropzoneNFTAssetsText`
 * in translation.ts
 */
export const nftAssetExtensions = nftImageExtensions.concat([
  '.mp4',
  '.glb',
  '.gltf',
]);

export const coaPresentationalExtensions = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.mp4',
  '.mov',
  '.wmv',
  '.webm',
  '.glb',
  '.gltf',
];

export const fileMatchesExtension = (file: File, extensions: string[]) => {
  return extensions.some((it) => file.name.toLowerCase().endsWith(it));
};

function matchesExtension(
  urlOrFilename: string,
  extensions: string[]
): boolean {
  let name: string;
  try {
    name = new URL(urlOrFilename).pathname.toLowerCase();
  } catch {
    name = urlOrFilename;
  }

  return extensions.some((it) => name.endsWith(it));
}

export function isImageFile(urlOrFilename: string) {
  return matchesExtension(urlOrFilename, [
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.tif',
    '.tiff',
    '.heic',
    '.webp',
  ]);
}

export function isModelFile(urlOrFilename: string) {
  return matchesExtension(urlOrFilename, ['.glb', '.vrm']);
}

export function isVideoFile(urlOrFilename: string) {
  return matchesExtension(urlOrFilename, [
    '.mp4',
    '.mov',
    '.avi',
    '.wmv',
    '.flv',
    '.mkv',
    '.webm',
  ]);
}

export const validLimitedTokenRuleValue = (input: string): string | boolean => {
  const redemptionLimit = Number(input);
  if (!Number.isInteger(redemptionLimit)) {
    return t('errors.tokenLimitedValidation.mustBeNumberOrString');
  }

  if (redemptionLimit <= 0 && redemptionLimit !== -1) {
    return t('errors.tokenLimitedValidation.mustBeNumberOrString');
  }
  return true;
};

export const validPositiveNumber = (projectId: string): string | boolean => {
  const projectIdNumber = Number(projectId);

  if (!Number.isInteger(projectIdNumber)) {
    return t('errors.artBlocksProjectIdValidation.mustBeNumber');
  }
  if (projectIdNumber < 0) {
    return t('errors.artBlocksProjectIdValidation.mustBePositive');
  }

  return true;
};

export const validBytes32 = (highlightId: string): string | boolean => {
  const regex = /^0x[0-9a-fA-F]{64}$/;
  if (!regex.test(highlightId)) {
    return t('errors.highlightIdValidation.mustBeByte32');
  }

  return true;
};
