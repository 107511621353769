import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DropsFormValues } from '../DropForm';
import CollectorRewards from '../../../pages/ProductForm/collectorRewards/CollectorRewards';
import t from '../../../lib/translation';
import { uploadDropFileToS3 } from '../../../utils/fileUpload';

interface DropCollectorRewardsProps {
  dropId: string;
}

const DropCollectorRewards: React.FC<DropCollectorRewardsProps> = ({
  dropId,
}) => {
  const { watch, setValue } = useFormContext<DropsFormValues>();
  const rewardFiles = watch('rewardFiles');

  return (
    <CollectorRewards
      id={dropId}
      type="DROP"
      assets={rewardFiles}
      setAsset={() => null}
      customDelete={(result, index) => {
        setValue(
          'rewardFiles',
          [...rewardFiles.filter((file, idx) => idx !== index)],
          { shouldDirty: true } // Not sure why this is required
        );
      }}
      fileUploadSuccessMessage={t(
        'dropForm.collectorRewardUploadSuccessMessage'
      )}
      uploadFile={async (file: File) => {
        const url = await uploadDropFileToS3(dropId, file);
        setValue(
          'rewardFiles',
          [
            ...rewardFiles,
            {
              fileURL: url,
              fileLabel: file.name,
            },
          ],
          { shouldDirty: true }
        );
      }}
    />
  );
};

export default DropCollectorRewards;
