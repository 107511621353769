import { Page } from '@shopify/polaris';
import React from 'react';
import PrintForm, { PrintFormProps } from './PrintForm';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';

type PrintFormPageType = Omit<PrintFormProps, 'setSaveCallback'>;

const PrintFormPageWrapper: React.FC<PrintFormPageType> = (props) => {
  const historyGoBack = useHistoryGoBack();
  return (
    <Page
      breadcrumbs={[
        {
          content: 'Prints',
          onAction: () => historyGoBack('/prints'),
        },
      ]}
      title={`${props.formType} print`}
    >
      <PrintForm {...props} />
    </Page>
  );
};

export default PrintFormPageWrapper;
