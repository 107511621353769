import React from 'react';
import { Page, Spinner } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import t from 'lib/translation';
import useGetPrints from 'hooks/useGetPrints';
import Footer from 'components/Footer';
import EmptyStatePage from 'components/EmptyStatePage';
import PrintList from 'components/PrintList/PrintList';

const PrintsPage = () => {
  const history = useHistory();
  const { data: prints, isLoading } = useGetPrints();

  const createPrint = () => {
    history.push('/prints/create');
  };

  return (
    <Page
      title={t('printPage.heading')}
      primaryAction={
        prints && prints.length === 0
          ? undefined
          : {
              content: t('printPage.button'),
              onAction: async () => createPrint(),
            }
      }
    >
      {prints && prints.length === 0 ? (
        <>
          <EmptyStatePage
            heading={t('printPage.empty.heading')}
            body={t('printPage.empty.body')}
            linkText={t('printPage.empty.link')}
            linkAction={createPrint}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <div>
              <Spinner accessibilityLabel="Spinner" size="large" />
            </div>
          ) : (
            <div>
              <PrintList prints={prints || []} />
              <Footer />
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default PrintsPage;
