import { Card, Tag, TextField } from '@shopify/polaris';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MetafieldFormValues } from '../ProductFormShow';
import t from 'lib/translation';

export const Tags: React.FC = () => {
  const {
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext<MetafieldFormValues>();

  const tagsValue = getValues('tags');
  const [tags, setTags] = useState<string[]>(
    tagsValue ? tagsValue.split(',') : []
  );
  const [currentTag, setCurrentTag] = useState<string>('');

  return (
    <Card>
      <Card.Header title="Tags" />
      <Card.Section>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField
            value={currentTag}
            onChange={(fieldValue) => {
              if (fieldValue[fieldValue.length - 1] === ' ') {
                const tagValue = fieldValue.substring(0, fieldValue.length - 1);
                const tagsAfterConcat =
                  tags.indexOf(tagValue) === -1 ? tags.concat(tagValue) : tags;
                setTags(tagsAfterConcat);
                setValue('tags', tagsAfterConcat.join(','), {
                  shouldDirty: true,
                });
                setCurrentTag('');
              } else {
                setCurrentTag(fieldValue);
              }
            }}
            autoComplete="off"
            label={t('productForm.tags.label')}
            error={errors.tags?.message}
            onFocus={() => clearErrors('tags')}
            helpText={t('productForm.tags.helpText')}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            marginTop: '1rem',
          }}
        >
          {tags.map((tag) => (
            <Tag
              key={tag}
              onRemove={() => {
                const tagsAfterRemoval = tags.filter((item) => item !== tag);
                setTags(tagsAfterRemoval);
                setValue('tags', tagsAfterRemoval.join(','), {
                  shouldDirty: true,
                });
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </Card.Section>
    </Card>
  );
};
