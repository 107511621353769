import React from 'react';
import { Toast } from '@shopify/polaris';
import useToast from 'hooks/useToast';

const ToastNotification = () => {
  const message = useToast((state) => state.message);
  const isError = useToast((state) => state.isError);
  const isVisible = useToast((state) => state.isVisible);
  const toggleToast = useToast((state) => state.toggleToast);

  const toastMarkup = isVisible ? (
    <Toast content={message} onDismiss={toggleToast} error={isError} />
  ) : null;

  return <div>{toastMarkup}</div>;
};

export default ToastNotification;
