import React, { useCallback, useState } from 'react';
import { LoadingState } from 'pages/LoadingState/LoadingState';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import { Modal } from '@shopify/polaris';
import t from '../../lib/translation';
import ContractsForm, {
  ContractsFormProps,
} from '../../components/ContractsForm/ContractsForm';

type CreateContractsFormDialogType = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
} & Pick<ContractsFormProps, 'lockBlockchain' | 'onSuccess'>;

const CreateContractsFormDialog: React.FC<CreateContractsFormDialogType> = ({
  showModal,
  setShowModal,
  onSuccess,
  lockBlockchain,
}) => {
  const [save, setSave] = useState<(() => void) | undefined>();

  const { isLoading: isMintingCapabilityLoading, mintingAvailable } =
    useMainnetMintingEnabled();

  // This is just some glue required due to the way ContractsForm works.
  // It is responsible for doing all the saving, but in this case, we're the one who renders the save button.
  // This state is required so RoyaltySplitForm can tell us what to call on save.
  const setSaveCallback = useCallback((callback: () => void) => {
    setSave(() => callback); // Storing a callback in state requires a double function call - see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  }, []);

  if (isMintingCapabilityLoading) {
    return <LoadingState />;
  }

  return (
    <Modal
      large
      open={showModal}
      onClose={() => setShowModal(false)}
      title={t('createContractForm.title')}
      primaryAction={{
        content: t('dropForm.toast.save'),
        onAction: save,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setShowModal(false),
        },
      ]}
    >
      <Modal.Section>
        <ContractsForm
          formType="Create"
          onSuccess={onSuccess}
          onTestMode={!mintingAvailable}
          setSaveCallback={setSaveCallback}
          lockBlockchain={lockBlockchain}
        />
      </Modal.Section>
    </Modal>
  );
};

export default CreateContractsFormDialog;
