import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import t from 'lib/translation';
import useRecoverWallet from '../../hooks/useRecoverWallet';
import useRecoverWalletEmail from 'hooks/useRecoverWalletEmail';

export type RecoverWalletModalProps = {
  hideModal: () => void;
  open: boolean;
};

const RecoverWalletModal: React.FC<RecoverWalletModalProps> = ({
  hideModal,
  open,
}) => {
  const { data: emailData, error: recoverEmailError } = useRecoverWalletEmail();
  const {
    mutate: recoverWalletEmail,
    isLoading,
    error: recoverError,
    reset,
    data: recoverResult,
  } = useRecoverWallet();

  const hide = () => {
    reset(); // Needed because the dialog doesn't get remounted/unmounted
    hideModal();
  };

  const error = recoverEmailError || recoverError;
  const showButtons = !error && !recoverResult;
  return (
    <Modal
      open={open}
      onClose={hide}
      title={t('recoverWalletModal.title')}
      primaryAction={
        showButtons
          ? {
              content: t('recoverWalletModal.actionButton'),
              onAction: recoverWalletEmail,
            }
          : undefined
      }
      secondaryActions={
        showButtons
          ? [
              {
                content: t('recoverWalletModal.helpButton'),
                url: 'https://help.verisart.com/en/articles/6694581',
              },
            ]
          : []
      }
      loading={!error && (!emailData || isLoading)}
    >
      <Modal.Section>
        <TextContainer>
          {error ? (
            getRecoveryErrors(error)
          ) : recoverResult ? (
            <>
              <p>
                {t('recoverWalletModal.completeMessage')}{' '}
                <strong>{emailData!.email}</strong>
              </p>
              <p>
                {t('recoverWalletModal.completeMessageCode')}
                <strong>{recoverResult.code}</strong>
              </p>
            </>
          ) : (
            emailData && (
              <>
                <p>{t('recoverWalletModal.description')}</p>
                <p>
                  {t('recoverWalletModal.descriptionEmail')}{' '}
                  <strong>{emailData!.email}</strong>
                </p>
              </>
            )
          )}
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRecoveryErrors(e: any): string {
  const msg = e?.response?.data?.message;
  if (msg?.includes('exceeded.recovery.attempts')) {
    return t('recoverWalletModal.errors.tooManyRequests');
  }
  if (msg?.includes('already.recovered')) {
    return t('recoverWalletModal.errors.alreadyRecovered');
  }
  return t('recoverWalletModal.errors.general');
}

export default RecoverWalletModal;
