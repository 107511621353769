import React, { useEffect, useState } from 'react';
import { Card, Checkbox, Heading, Stack, TextStyle } from '@shopify/polaris';
import {
  UseFormReturn,
  Controller,
  Control,
  useFormContext,
} from 'react-hook-form';
import { TokenGateFormValues } from './TokenGateForm';
import TextInput from 'components/forms/TextInput';
import { useProduct } from 'hooks/useProduct';

interface TokenGateDiscountProps {
  methods: UseFormReturn<TokenGateFormValues>;
  isPrint: Boolean;
}

//this is the component for adding discoutns to the token gate
const TokenGateDiscount: React.FC<TokenGateDiscountProps> = ({
  methods,
  isPrint,
}) => {
  const control = methods.control as Control<TokenGateFormValues>;
  const { setValue, watch } = useFormContext<TokenGateFormValues>();
  const discount = watch('discountPercentage');
  const [isDiscountVisible, setIsDiscountVisible] = useState(
    discount !== undefined
  );

  const selectedProduct = watch('selectedProduct');

  const { data: productData } = useProduct(selectedProduct?.[0]);

  const [productPrice, setProductPrice] = useState<number | null>(
    productData?.price ?? null
  );
  const [productPriceCurrency, setProductPriceCurrency] = useState<
    string | null
  >(productData?.currency ?? null);

  let priceShowing: Intl.NumberFormat | null = null;

  if (productPriceCurrency) {
    priceShowing = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: productPriceCurrency,
    });
  }

  useEffect(() => {
    if (productData) {
      setProductPrice(productData.price);
      setProductPriceCurrency(productData.currency);
    } else {
      setProductPrice(null);
    }
  }, [productData]);

  const handleDiscountCheckboxChange = (checked: boolean) => {
    setIsDiscountVisible(checked);
    if (!checked) {
      setValue('discountPercentage', undefined, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (!isDiscountVisible) {
      setValue('discountPercentage', undefined);
    }
  }, [isDiscountVisible, methods, setValue]);

  const calculateDiscountedPrice = () => {
    if (productPrice === undefined || productPrice === null) {
      return { discountAmount: 0, finalPrice: 0 };
    }
    if (discount === undefined || isNaN(Number(discount))) {
      return { discountAmount: 0, finalPrice: productPrice };
    }
    const discountAmount = (productPrice * Number(discount)) / 100;
    const finalPrice = productPrice - discountAmount;
    return { discountAmount, finalPrice };
  };

  const { discountAmount, finalPrice } = calculateDiscountedPrice();

  return (
    <Card
      title={
        <>
          <Heading>Discount</Heading>
          <TextStyle variation="subdued">
            Give exclusive discount access to token holders when they purchase
            your products.
          </TextStyle>
        </>
      }
    >
      <Card.Section>
        <Stack vertical>
          <Checkbox
            label={'Enable Discount'}
            checked={isDiscountVisible}
            onChange={handleDiscountCheckboxChange}
            disabled={isPrint === true}
          />
          {isDiscountVisible && (
            <Controller
              name="discountPercentage"
              control={control}
              render={({ field }) => (
                <>
                  <Stack distribution="fillEvenly">
                    <Stack vertical spacing="extraLoose">
                      <Stack vertical spacing="tight">
                        <TextStyle variation="strong">
                          Discount percentage
                        </TextStyle>
                        <div style={{ width: '80px' }}>
                          <TextInput
                            {...field}
                            prefix="%"
                            name="discountPercentage"
                            label={''}
                            error={
                              methods.formState.errors?.discountPercentage
                                ?.message
                            }
                            validator={(val: string) => {
                              const num = parseFloat(val);
                              if (isNaN(num))
                                return 'Please enter a valid number';
                              if (!Number.isInteger(num) || val.endsWith('.'))
                                return 'Please use integer value';
                              if (num < 1) return 'Cannot be less than 1%';
                              if (num > 100) return 'Cannot exceed 100%';
                              return true;
                            }}
                          />
                        </div>
                      </Stack>
                    </Stack>
                    {productPrice && (
                      <Stack vertical>
                        <Stack vertical spacing="tight">
                          <TextStyle variation="strong">Breakdown</TextStyle>
                          <Stack vertical spacing="tight">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>
                                <TextStyle variation="subdued">
                                  Original Price
                                </TextStyle>{' '}
                              </p>
                              <p style={{ color: 'gray', marginLeft: 'auto' }}>
                                {priceShowing?.format(productPrice)}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>
                                <TextStyle variation="subdued">
                                  Discounted Amount
                                </TextStyle>{' '}
                              </p>
                              <p style={{ color: 'gray', marginLeft: 'auto' }}>
                                {priceShowing?.format(discountAmount)}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <p>
                                <TextStyle variation="strong">
                                  Price After Discount:
                                </TextStyle>{' '}
                              </p>
                              <p style={{ color: 'black', marginLeft: 'auto' }}>
                                {priceShowing?.format(finalPrice)}
                              </p>
                            </div>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  <TextStyle variation="subdued">
                    <p style={{ paddingTop: '40px' }}>
                      Customers without the token can still purchase at full
                      price.
                    </p>
                  </TextStyle>
                </>
              )}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default TokenGateDiscount;
