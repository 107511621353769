import {
  AppBridgeContext,
  RedirectAdminAction,
  RedirectAdminResource,
  RedirectPassThroughAction,
} from 'containers/AppBridge';
import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { getShopDomain } from '../AppBridge';
import { Toast as ToastAppBridge } from '@shopify/app-bridge/actions';
import { Toast } from '@shopify/polaris';
import { sleep } from '../../utils/api';

let wooSecret: string | undefined = undefined;

/**
 * Request the Woo secret from the parent window. This is used for 2 purposes:
 *
 * 1. Exchanging for a longer lived session token
 * 2. Doing a single sign on request to verisart.com
 *
 */
const requestSecret = () => {
  window.parent.postMessage(
    { type: 'requestSecret' },
    `https://${getShopDomain()}`
  );
};

window.addEventListener('message', (event) => {
  if (event.origin !== `https://${getShopDomain()}`) {
    return;
  }

  if (event.data.type === 'secretResponse') {
    wooSecret = event.data.secret;
  }
});

const WooDriver = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const [toast, setToast] = useState<ToastAppBridge.Options | undefined>(
    undefined
  );

  return (
    <AppBridgeContext.Provider
      value={{
        host: '',
        apiKey: '',
        redirect: (
          action: RedirectPassThroughAction | RedirectAdminAction,
          resource: string | RedirectAdminResource
        ) => {
          if (action === 'App') {
            if (typeof resource !== 'string') {
              throw new Error(
                'Resource must be a string for an App or Remote redirect'
              );
            }
            history.push(resource);
          } else if (action === 'Remote') {
            if (typeof resource !== 'string') {
              throw new Error(
                'Resource must be a string for an App or Remote redirect'
              );
            }
            window.parent.postMessage(
              { type: 'redirect', resource },
              `https://${getShopDomain()}`
            );
          } else {
            throw new Error('Unsupported redirect action');
          }
        },
        showToast: (options: ToastAppBridge.Options) => {
          setToast(options);
        },
        getSessionToken: async () => {
          console.info('Refreshing woo secret');
          wooSecret = undefined;
          requestSecret();

          let attempts = 0;
          while (true) {
            await sleep(10);
            if (wooSecret) {
              console.info('Woo secret refreshed');
              return wooSecret;
            }
            attempts++;
            if (attempts > 1000) {
              throw new Error('Could not get the Woo secret');
            }
          }
        },
        platform: 'Woo',
        openInNewTab: () => {},
      }}
    >
      {toast && (
        <Toast
          content={toast.message}
          duration={toast.duration}
          onDismiss={() => setToast(undefined)}
        ></Toast>
      )}
      {children}
    </AppBridgeContext.Provider>
  );
};

export default WooDriver;
