import React, { useCallback, useState } from 'react';
import { Page } from '@shopify/polaris';
import {
  OnboardingModal,
  OnboardingModalFormValues,
  OnboardingStep,
} from './OnboardingModal';
import { FormProvider, useForm } from 'react-hook-form';
import useSetupSelfCreatorDetails from '../../hooks/useSetupSelfCreatorDetails';
import { useHistory } from 'react-router-dom';
import { useAttemptOnboarding } from '../../hooks/useAttemptOnboarding';
import LoadingPage from '../../auth/LoadingPage';
import { PLAN_PATH } from '../../AppRoutes';
import t from 'lib/translation';
import { useAppBridge } from 'containers/AppBridge';

const SelfCreatorDetailsPage: React.FC = () => {
  const appBridge = useAppBridge();
  const history = useHistory();
  const { data } = useAttemptOnboarding();

  const methods = useForm<OnboardingModalFormValues>({
    defaultValues: {
      creatorFirstName: '',
      creatorLastName: '',
      creatorYear: '',
      planType: null,
      userType: null,
    },
  });
  const { mutate: setupSelfCreatorDetails, isLoading } =
    useSetupSelfCreatorDetails(() => {
      appBridge.showToast({
        message: t('onboarding.onboardingToast'),
        duration: 5000,
      });
      history.push('/', { replace: true });
    });

  const onClose = useCallback(() => {
    history.push('/', { replace: true });
  }, [history]);

  const [steps, setSteps] = useState<OnboardingStep[]>(['selectplantype']);
  const pushStep = useCallback(
    (next: OnboardingStep) => {
      setSteps([next, ...steps]);
    },
    [steps]
  );
  const stepBack = useCallback(() => {
    const stepsAfter = steps.slice(1);
    if (stepsAfter.length === 0) {
      history.push('/', { replace: true });
    }
    setSteps(stepsAfter);
  }, [history, steps]);

  const navigateToDashboardAfterLinking = useCallback(() => {
    history.push('/?linked_account=true', { replace: true });
  }, [history]);

  if (data?.status === undefined) {
    return <LoadingPage />;
  }
  if (data.status === 'STARTED') {
    navigateToDashboardAfterLinking();
  }
  if (data.status === 'NEED_PLAN') {
    history.push(PLAN_PATH, { replace: true });
  }

  return (
    <Page>
      <FormProvider {...methods}>
        <OnboardingModal
          mode="onboardingVerisart"
          steps={steps}
          pushStep={pushStep}
          stepBack={stepBack}
          onClose={onClose}
          completeOnboarding={async (onboardingData) => {
            await setupSelfCreatorDetails({
              name: onboardingData.creatorFirstName,
              lastName:
                onboardingData.creatorLastName === ''
                  ? null
                  : onboardingData.creatorLastName,
              associatedYear: onboardingData.creatorYear,
              overrideAdminEmail: onboardingData.shopEmailAddress,
            });
            navigateToDashboardAfterLinking();
          }}
          completeOnboardingLoading={isLoading}
        />
      </FormProvider>
    </Page>
  );
};

export default SelfCreatorDetailsPage;
