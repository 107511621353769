import React from 'react';
import { Badge, Banner, Card, Link, Stack } from '@shopify/polaris';
import { FormProvider, useFormContext } from 'react-hook-form';
import { MetafieldFormValues } from '../ProductFormShow';
import SelectInput from 'components/forms/SelectInput';
import { Blockchain, isTestnet } from 'types/Blockchain';
import t from 'lib/translation';
import styles from './ProductInfo.module.scss';
import { RoyaltyAddressWithPayees } from '../../../hooks/useGetRoyaltyAddresses';
import {
  truncateBlockchainAddress,
  truncateString,
} from '../../../utils/stringFormatting';
import { useHistory } from 'react-router-dom';
import { Contract } from '../../../hooks/useGetContracts';
import { DropForProduct } from '../../../hooks/useGetDropProduct';
import { openSeaAssetsBaseUrl } from 'utils/blockchain';
import BlockchainAddress from 'components/BlockchainAddress';
import { copyToClipboard } from 'utils/copyTextToClipboard';
import CopyIcon from 'components/JsxIcons/Copy/Copy-Icon';
import useToast from 'hooks/useToast';

interface ProductInfoProps {
  isCertificate?: boolean;
  blockchain: Blockchain | undefined | null;
  contract: Contract | undefined | null;
  contractAddress: string | undefined;
  paymentSplitter: RoyaltyAddressWithPayees | undefined | null;
  tokenId: string | null;
  drop: DropForProduct | undefined | null;
  existingNFTStatus: 'ready' | 'already_sold' | 'cant_transfer' | null;
}

export function formatAddress(val: string | null | undefined): string {
  return val ? truncateBlockchainAddress(val) : 'Pending';
}

export const ProductInfo: React.FC<ProductInfoProps> = ({
  blockchain,
  contract,
  contractAddress,
  tokenId,
  paymentSplitter,
  drop,
  isCertificate,
  existingNFTStatus,
}) => {
  const showToast = useToast((state) => state.showToast);
  const history = useHistory();

  const methods = useFormContext<MetafieldFormValues>();
  return (
    <Card>
      <div className={`${styles.container}`}>
        <Card.Header title="Product information" />
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
          <Banner>{t('productForm.productInfoHelp')}</Banner>
        </div>
        <Card.Section>
          <Stack vertical>
            <FormProvider {...methods}>
              <SelectInput
                name={'status'}
                label={'Shop Product status'}
                options={[
                  { label: 'Active', value: 'ACTIVE' },
                  { label: 'Draft', value: 'DRAFT' },
                ]}
              />
            </FormProvider>
            {existingNFTStatus && (
              <Stack>
                <Stack.Item fill>
                  {t('productForm.importedNFT.status.title')}
                </Stack.Item>
                {existingNFTStatus === 'ready' ? (
                  <Badge status={'success'}>
                    {t('productForm.importedNFT.status.ready')}
                  </Badge>
                ) : existingNFTStatus === 'already_sold' ? (
                  <Badge>{t('productForm.importedNFT.status.sold')}</Badge>
                ) : existingNFTStatus === 'cant_transfer' ? (
                  <Badge status="critical">
                    {t('productForm.importedNFT.status.cantTransfer')}
                  </Badge>
                ) : undefined}
              </Stack>
            )}
            {isCertificate && (
              <Stack>
                <Stack.Item fill>Type</Stack.Item>
                <Stack.Item>
                  <Badge status={'warning'}>COA</Badge>
                </Stack.Item>
              </Stack>
            )}
            {blockchain && (
              <Stack>
                <Stack.Item fill>Type</Stack.Item>
                <Stack.Item>
                  <Badge status={'warning'}>
                    {isTestnet(blockchain) ? 'Test NFT' : 'NFT'}
                  </Badge>
                </Stack.Item>
              </Stack>
            )}
            {drop && (
              <Stack>
                <Stack.Item fill>{t('productForm.drop')}</Stack.Item>
                <Stack.Item>
                  <Link onClick={() => history.push(`/drops/edit/${drop.id}`)}>
                    {drop.name}
                  </Link>
                </Stack.Item>
              </Stack>
            )}
            {blockchain && (
              <Stack>
                <Stack.Item fill>Blockchain</Stack.Item>
                <Stack.Item>{t(`blockchain.${blockchain}`)}</Stack.Item>
              </Stack>
            )}
            {!contract && contractAddress && blockchain && (
              <Stack>
                <Stack.Item fill>{t('productForm.contractAddress')}</Stack.Item>
                <Stack.Item>
                  <BlockchainAddress
                    address={contractAddress}
                    blockchain={blockchain}
                  />
                </Stack.Item>
              </Stack>
            )}
            {contract && (
              <Stack>
                <Stack.Item fill>{t('productForm.contractAddress')}</Stack.Item>
                <Stack.Item>
                  <Link
                    onClick={() =>
                      history.push(`/contracts/edit/${contract.id}`)
                    }
                  >
                    {formatAddress(contract.address)}
                  </Link>
                </Stack.Item>
              </Stack>
            )}
            {tokenId && blockchain && (
              <Stack>
                <Stack.Item fill>{t('productForm.tokenId')}</Stack.Item>
                <Stack spacing="none">
                  <Link
                    url={`${openSeaAssetsBaseUrl(
                      blockchain
                    )}/${contractAddress}/${tokenId}`}
                  >
                    {truncateString(tokenId, 6, 4)}
                  </Link>
                  <div
                    className={`${styles['copy-icon']}`}
                    onClick={async (e) => {
                      e.currentTarget.focus();
                      const copied = await copyToClipboard(tokenId);
                      e.stopPropagation();
                      if (copied) showToast('Copied to clipboard', false);
                    }}
                  >
                    <CopyIcon />
                  </div>
                </Stack>
              </Stack>
            )}
            {paymentSplitter && (
              <Stack>
                <Stack.Item fill>{t('productForm.royaltyAddress')}</Stack.Item>
                <Stack.Item>
                  <Link
                    onClick={() =>
                      history.push(`/royalty-splits-edit/${paymentSplitter.id}`)
                    }
                  >
                    {paymentSplitter
                      ? paymentSplitter.contractAddress
                        ? formatAddress(paymentSplitter.contractAddress)
                        : paymentSplitter.payees.length === 1
                        ? formatAddress(paymentSplitter.payees[0].address)
                        : 'Pending'
                      : 'Pending'}
                  </Link>
                </Stack.Item>
              </Stack>
            )}
          </Stack>
        </Card.Section>
      </div>
    </Card>
  );
};
