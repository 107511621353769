import React from 'react';
import { useParams } from 'react-router';
import { useGetRoyaltyAddress } from '../../hooks/useGetRoyaltyAddress';
import LoadingState from 'pages/LoadingState';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import { useGetRecipients } from '../../hooks/useGetRecipients';
import { RoyaltySplitFormPage } from './RoyaltySplitFormPage';

const RoyaltySplitPageEditLoader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { mintingAvailable } = useMainnetMintingEnabled();
  const {
    data: paymentSplitterContract,
    error: royaltyAddressError,
    isLoading: royaltyAddressIsLoading,
  } = useGetRoyaltyAddress(id);
  const { data } = useGetRecipients();

  if (royaltyAddressError) {
    throw royaltyAddressError;
  }

  if (
    royaltyAddressIsLoading ||
    paymentSplitterContract === undefined ||
    mintingAvailable === undefined
  ) {
    return <LoadingState />;
  } else {
    return data ? (
      <RoyaltySplitFormPage
        formType={'Edit'}
        onTestMode={!mintingAvailable}
        paymentSplitterContract={paymentSplitterContract}
        recipients={data}
      />
    ) : (
      <LoadingState />
    );
  }
};

export default RoyaltySplitPageEditLoader;
