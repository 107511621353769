import { Banner, Button, Stack, TextStyle } from '@shopify/polaris';
import React from 'react';
import { getVerisartDotComHost } from 'lib/urls';
import styles from './PrintForm.module.scss';
import { Blockchain } from 'types/Blockchain';
import { ContractPreview } from 'hooks/useGetContractMetadata';
import { PrintFormValues } from './PrintForm';
import { UseFormReturn } from 'react-hook-form';

interface AuthorizeContractCardProps {
  contractAddress: string;
  blockchain: Blockchain | undefined;
  methods: UseFormReturn<PrintFormValues>;
  shopId: string;
  contractPreview: ContractPreview | undefined;
  onVerificationError: (error: string | null) => void;
}

const AuthorizeContractCard: React.FC<AuthorizeContractCardProps> = ({
  contractAddress,
  blockchain,
  methods,
  shopId,
  contractPreview,
  onVerificationError,
}) => {
  const openNewTabToAuthenticateOwnership = () => {
    const artBlocksProjectId = methods.watch('artBlocksProjectId');
    const baseUrl = getVerisartDotComHost();
    let url = `${baseUrl}/application/print?contractAddress=${contractAddress}&blockchain=${blockchain}&shopId=${shopId}`;

    if (contractPreview?.isArtBlocks) {
      if (!artBlocksProjectId) {
        onVerificationError(
          'ArtBlocks Project ID is required to verify ownership.'
        );
        return;
      } else {
        url += `&artBlocksProjectId=${artBlocksProjectId}`;
      }
    }

    onVerificationError(null);
    window.open(url, '_blank');
  };

  return (
    <>
      {contractAddress && blockchain && (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Banner status="critical">
            <div className={`${styles['card-content']}`}>
              <Stack vertical spacing="tight">
                <TextStyle>
                  You must verify you are the owner or the admin of this
                  contract.
                </TextStyle>
              </Stack>
              <div className={`${styles['button-container']}`}>
                <Button
                  onClick={() => {
                    openNewTabToAuthenticateOwnership();
                  }}
                >
                  Verify
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      )}
    </>
  );
};

export default AuthorizeContractCard;
