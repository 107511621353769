/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Icon, Stack, TextField, TextStyle } from '@shopify/polaris';
import React, { useState } from 'react';
import { SearchMinor, ProductsMajor } from '@shopify/polaris-icons';
import { parameters as p } from 'lib/translation';
import { useQueryClient } from 'react-query';
import styles from './ProductPicker.module.scss';
import { ProductPicker } from '../ProductPicker/ProductPicker';

interface ProductPickerWrapperProps {
  name: string;
  subtitle: string;
  helpText?: string;
  ListComponent: React.FC<{
    productId: string;
    onDelete?: () => void;
    disabled?: boolean;
  }>;
  selectMultiple?: boolean;
  minimumProductCount?: number;
  disabled?: boolean;
}

const ProductPickerWrapper: React.FC<ProductPickerWrapperProps> = ({
  name,
  helpText,
  ListComponent,
  selectMultiple = false,
  minimumProductCount,
  subtitle,
  disabled,
}) => {
  const { control, clearErrors, setValue } = useFormContext();

  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const [pickerQuery, setPickerQuery] = useState('');

  const onResourceAction = () => {
    setPickerQuery('');
    setProductPickerOpen(false);
  };

  const queryClient = useQueryClient();

  return (
    <Controller
      rules={
        minimumProductCount !== undefined
          ? {
              validate(value) {
                if (value === undefined || value.length < minimumProductCount) {
                  return p('productPicker.productError', minimumProductCount);
                }
              },
            }
          : {}
      }
      name={name}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { error: valueError },
      }) => {
        return (
          <Stack vertical>
            {productPickerOpen && (
              <ProductPicker
                selectMultiple={selectMultiple}
                initialQuery={pickerQuery}
                initialSelectionIds={value ?? []}
                onSelection={async (ids: number[]) => {
                  clearErrors(name);
                  await queryClient.invalidateQueries('products');
                  onChange(ids);
                  onResourceAction();
                }}
                onCancel={onResourceAction}
              />
            )}
            <Stack>
              <Stack.Item fill>
                <TextField
                  prefix={<Icon source={SearchMinor} color="base" />}
                  value={pickerQuery}
                  onChange={(fieldValue) => {
                    setPickerQuery(fieldValue);
                    setProductPickerOpen(true);
                  }}
                  label={null}
                  autoComplete="off"
                  helpText={helpText ?? ''}
                  error={valueError?.message}
                  disabled={disabled}
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  onClick={() => setProductPickerOpen(true)}
                  disabled={disabled}
                >
                  Browse
                </Button>
              </Stack.Item>
            </Stack>
            <Stack vertical>
              {typeof value === 'undefined' && (
                <div className={`${styles['my-8']} ${styles.center}`}>
                  <Icon source={ProductsMajor} color="base" />
                  <div className={styles['mt-4']}>
                    <TextStyle>{subtitle}</TextStyle>
                  </div>
                </div>
              )}
              {selectMultiple &&
                value?.map((item: string) => (
                  <ListComponent
                    key={item}
                    productId={item}
                    onDelete={
                      !disabled
                        ? () =>
                            setValue(
                              name,
                              value.filter((val: string) => val !== item),
                              { shouldDirty: true }
                            )
                        : undefined
                    }
                  />
                ))}

              {!selectMultiple && value?.[0] && (
                <ListComponent
                  productId={value[0]}
                  onDelete={
                    !disabled
                      ? () => setValue(name, [], { shouldDirty: true })
                      : undefined
                  }
                />
              )}
            </Stack>
          </Stack>
        );
      }}
    />
  );
};

export default ProductPickerWrapper;
