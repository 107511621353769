import React, { useCallback } from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import API from '../../utils/api';
import { useMutation, useQueryClient } from 'react-query';
import t from 'lib/translation';

function useDeleteEditionInfo() {
  const queryCache = useQueryClient();

  return useMutation(
    async (productId: string) => {
      await API().delete(`/shopify/products/${productId}/editionInfo`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('productMetadata');
      },
    }
  );
}

const ResetEditionModal: React.FC<{
  productId: string;
  show: boolean;
  setShow: (show: boolean) => void;
}> = ({ productId, show, setShow }) => {
  const { mutateAsync, isLoading } = useDeleteEditionInfo();

  const onReset = useCallback(async () => {
    await mutateAsync(productId);
    setShow(false);
  }, [mutateAsync, productId, setShow]);

  return (
    <Modal
      open={show}
      title={t('resetEditionModal.title')}
      primaryAction={{
        destructive: true,
        content: t('resetEditionModal.content'),
        onAction: onReset,
        loading: isLoading,
      }}
      secondaryActions={[
        {
          content: t('resetEditionModal.cancel'),
          onAction: () => setShow(false),
        },
      ]}
      onClose={() => setShow(false)}
    >
      <Modal.Section>
        <TextContainer>
          <p>{t('resetEditionModal.text')}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ResetEditionModal;
