import { useQuery, UseQueryResult } from 'react-query';
import { PaymentSplitterContractRenderable } from './useProductFormValues';
import { fetchRoyaltyAddress } from './useProductFormValues';

export const useGetRoyaltyAddress = (
  paymentSplitterContractId: string
): UseQueryResult<PaymentSplitterContractRenderable> => {
  return useQuery<PaymentSplitterContractRenderable>(
    ['royaltyAddress', paymentSplitterContractId],
    () => fetchRoyaltyAddress(paymentSplitterContractId)
  );
};
