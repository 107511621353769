import { Banner } from '@shopify/polaris';
import React from 'react';
import t, { parameters as p } from 'lib/translation';
import useSubscriptionDetails from 'hooks/useSubscriptionDetails';
import { PlanType } from 'utils/plans';

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const ConnectedLegacyPlanBanner: React.FC = () => {
  const { data: planData, isLoading } = useSubscriptionDetails();
  const showLegacyPlanBanner =
    planData?.subscriptionDetails.planType === PlanType.BUSINESS ||
    planData?.subscriptionDetails.planType === PlanType.PREMIUM;

  if (
    showLegacyPlanBanner &&
    isLoading === false &&
    planData?.subscriptionDetails.planType !== undefined
  ) {
    return (
      <div>
        <Banner
          status="warning"
          // TODO: fix this so that website is correct for environemnt and redirects correctly
          action={{
            content: t('banners.legacyBanner.actionContent'),
            url: 'verisart.com/profile',
          }}
          title={
            planData?.subscriptionDetails.planType === PlanType.BUSINESS
              ? t('banners.legacyBanner.business.title')
              : p(
                  'banners.legacyBanner.title',
                  toTitleCase(planData?.subscriptionDetails.planType)
                )
          }
        >
          {planData?.subscriptionDetails.planType === PlanType.BUSINESS
            ? t('banners.legacyBanner.business.content')
            : p(
                'banners.legacyBanner.content',
                toTitleCase(planData?.subscriptionDetails.planType)
              )}
        </Banner>
      </div>
    );
  }
  return null;
};

export default ConnectedLegacyPlanBanner;
