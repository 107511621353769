import { useEffect } from 'react';
import useChangePlan from 'hooks/useChangePlan';

const SetPlan = () => {
  const chargeId = new URLSearchParams(window.location.search).get('charge_id');

  const { mutate: changePlan } = useChangePlan();

  useEffect(() => {
    if (chargeId)
      changePlan({ chargeId, redirectPath: 'plan', planType: null });
  }, [changePlan, chargeId]);

  return null;
};

export default SetPlan;
