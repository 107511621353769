import {
  Button,
  ComplexAction,
  Modal,
  RadioButton,
  Scrollable,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris';
import React, { useCallback } from 'react';
import { useState } from 'react';
import t, { parameters as p } from 'lib/translation';
import BlockchainAddress from 'components/BlockchainAddress';
import { Blockchain } from 'types/Blockchain';
import { copyToClipboard } from 'utils/copyTextToClipboard';
import useToast from 'hooks/useToast';
import { useGetCustodialWalletBalance } from 'hooks/useGetCustodialWalletBalance';

export type Mode = 'owning' | 'representing';

type Step = 'select' | 'secondary';

export const GetReadyToSellModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  blockchain: Blockchain;
}> = ({ isOpen, onClose, blockchain }) => {
  const [step, setStep] = useState<Step>('select');
  const [mode, setMode] = useState<Mode>('owning');
  const showToast = useToast((state) => state.showToast);
  const { data } = useGetCustodialWalletBalance();
  const storeAddress = data?.address;

  const handleModeChange = useCallback(
    (_: boolean, newValue: Mode) => {
      setMode(newValue);
    },
    [setMode]
  );

  const primaryAction: ComplexAction =
    step === 'select'
      ? {
          content: t('productForm.importedNFT.modal.primaryAction'),
          onAction: () => setStep('secondary'),
        }
      : {
          content: t('productForm.importedNFT.secondaryModal.action'),
          onAction: onClose,
        };

  const secondaryActions: ComplexAction[] =
    step === 'select'
      ? [
          {
            content: t('productForm.importedNFT.modal.secondaryAction'),
            onAction: onClose,
          },
        ]
      : [];

  const secondaryBody = () =>
    mode === 'owning' ? (
      <Stack vertical spacing="loose">
        <Stack.Item>
          <TextContainer>
            <p>{t('productForm.importedNFT.secondaryModal.owningText')}</p>
          </TextContainer>
        </Stack.Item>
        <Stack.Item>
          <TextContainer>
            <p>{t('productForm.importedNFT.secondaryModal.wallet')}</p>
          </TextContainer>
          {storeAddress && blockchain && (
            <BlockchainAddress
              address={storeAddress}
              blockchain={blockchain}
              fullLength={true}
            />
          )}
        </Stack.Item>
        {/* We will revisit this later I am just commenting this out until we decide the next steps for this */}
        {/* <Stack spacing="tight">
          <TextContainer>
            <p>{t('productForm.importedNFT.secondaryModal.owningHelpText')}</p>
          </TextContainer>
          <Link>{t('productForm.importedNFT.secondaryModal.learnMore')}</Link>
        </Stack> */}
      </Stack>
    ) : (
      <Stack vertical spacing="loose">
        <Stack.Item>
          <TextContainer>
            <p>
              {t('productForm.importedNFT.secondaryModal.representingText')}
            </p>
          </TextContainer>
        </Stack.Item>
        <Stack vertical spacing="baseTight">
          <Stack alignment="center">
            <Stack.Item fill>
              <TextStyle variation="strong">
                {t('productForm.importedNFT.secondaryModal.email')}
              </TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Button
                onClick={() => {
                  copyToClipboard(
                    p(
                      'productForm.importedNFT.secondaryModal.emailContent',
                      storeAddress
                    )
                  );
                  showToast('Copied to clipboard', false);
                }}
              >
                {t('productForm.importedNFT.secondaryModal.copy')}
              </Button>
            </Stack.Item>
          </Stack>

          <Scrollable id="email" shadow style={{ height: '150px' }} focusable>
            <TextStyle variation="subdued">
              <pre>
                {p(
                  'productForm.importedNFT.secondaryModal.emailContent',
                  storeAddress
                )}
              </pre>
            </TextStyle>
          </Scrollable>
        </Stack>
        {/*This will be revisited later when we decide what to do with it but commenting out to get release out*/}
        {/* <Stack.Item>
          <Stack spacing="tight">
            <TextContainer>
              <p>{t('productForm.importedNFT.secondaryModal.emailHelp')}</p>
            </TextContainer>
            <Link>{t('productForm.importedNFT.secondaryModal.learnMore')}</Link>
          </Stack>
        </Stack.Item> */}
      </Stack>
    );

  const body = () =>
    step === 'select' ? (
      <Stack vertical spacing="tight">
        <TextStyle>
          <p>{t('productForm.importedNFT.modal.text')}</p>
        </TextStyle>
        <RadioButton
          label={t('productForm.importedNFT.modal.firstChoice')}
          helpText={t('productForm.importedNFT.modal.firstHelpText')}
          checked={mode === 'owning'}
          id="owning"
          onChange={handleModeChange}
        />
        <RadioButton
          label={t('productForm.importedNFT.modal.secondChoice')}
          helpText={t('productForm.importedNFT.modal.secondHelpText')}
          checked={mode === 'representing'}
          id="representing"
          onChange={handleModeChange}
        />
      </Stack>
    ) : (
      secondaryBody()
    );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('productForm.importedNFT.modal.title')}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>{body()}</Modal.Section>
    </Modal>
  );
};
