import { fail } from 'assert';

// Note that unlike core or website we don't need to track old dead blockchains
// as there will be no contracts/gates/etc on them
export enum Blockchain {
  POLYGON = 'POLYGON',
  POLYGON_AMOY = 'POLYGON_AMOY',
  ETHEREUM = 'ETHEREUM',
  ETHEREUM_SEPOLIA = 'ETHEREUM_SEPOLIA',
  FAKE_ETHEREUM_ON_SEPOLIA = 'FAKE_ETHEREUM_ON_SEPOLIA',
  FAKE_BASE_ON_BASE_SEPOLIA = 'FAKE_BASE_ON_BASE_SEPOLIA',
  BASE_MAINNET = 'BASE_MAINNET',
  BASE_SEPOLIA = 'BASE_SEPOLIA',
}

/**
 * The overall type of chain or ecosystem, regardless of which actual chain.
 * This is needed because we now support testnets in both Polygon and Ethereum, but we treat
 * them differently (e.g. different Icons, gasless minting etc).
 */
export enum BlockchainClass {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  BASE = 'BASE',
}

/** See BlockchainClass */
export function blockchainToClass(blockchain: Blockchain): BlockchainClass {
  return blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA ||
    blockchain === Blockchain.ETHEREUM
    ? BlockchainClass.ETHEREUM
    : blockchain === Blockchain.POLYGON ||
      blockchain === Blockchain.POLYGON_AMOY
    ? BlockchainClass.POLYGON
    : blockchain === Blockchain.BASE_MAINNET ||
      blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA ||
      blockchain === Blockchain.BASE_SEPOLIA
    ? BlockchainClass.BASE
    : fail(`Unknown blockchain ${blockchain}`);
}

export const isTestnet = (blockchain: Blockchain) => {
  return (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.POLYGON_AMOY ||
    blockchain === Blockchain.BASE_SEPOLIA
  );
};

export const isMainnet = (blockchain: Blockchain) => !isTestnet(blockchain);
