import { MouseEventHandler, useEffect, useState } from 'react';
import React from 'react';

// Note this code is copied and pasted from the website project under AssetView

export type ModelViewerMode = 'rotate' | 'camera' | 'off';

export type ModelViewerType = {
  src: string;
  mode: ModelViewerMode;
  className: string;
  style: React.CSSProperties;
  onClick?: MouseEventHandler;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      style?: React.CSSProperties;
      'auto-rotate'?: boolean;
      'camera-controls'?: boolean;
      class?: string;
      onClick?: MouseEventHandler;
    }
  }
}

export const MODEL_VIEWER_SCRIPT_URL =
  'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js';

const ModelViewer: React.FC<ModelViewerType> = ({
  mode,
  src,
  className,
  style,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (customElements.get('model-viewer')) {
      setLoading(false);
      return;
    }

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src='${MODEL_VIEWER_SCRIPT_URL}']`
    );
    if (!script) {
      script = document.createElement('script');
      script.src = MODEL_VIEWER_SCRIPT_URL;
      script.type = 'module';
      document.body.appendChild(script);
    }

    const listener = () => setLoading(false);
    script.addEventListener('load', listener);

    return () => script?.removeEventListener('load', listener);
  }, []);

  if (loading) return null;

  return (
    <model-viewer
      class={`${className}`}
      onClick={onClick}
      src={src}
      auto-rotate={mode === 'rotate' ? true : undefined}
      camera-controls={mode === 'camera' ? true : undefined}
      auto-rotate-delay={mode === 'rotate' ? 2000 : undefined}
      rotation-per-second={mode === 'rotate' ? '500%' : undefined}
      style={{ aspectRatio: '1', width: '100%', height: '100%', ...style }}
    />
  );
};

export default ModelViewer;
