import React, { useCallback } from 'react';
import { Card, Thumbnail, TextStyle, Link } from '@shopify/polaris';
import t from 'lib/translation';
import styles from './VariantTitleThumbnail.module.scss';
import { useHistory } from 'react-router-dom';

interface VariantTitleThumbnailProps {
  imgUrl: string;
  title: string;
  linkTo: string;
  numberOfVariants?: number;
}

const VariantTitleThumbnail: React.FunctionComponent<
  VariantTitleThumbnailProps
> = ({ imgUrl, title, linkTo, numberOfVariants }) => {
  let variantText = 'variant';
  if (numberOfVariants) {
    variantText = numberOfVariants > 1 ? 'variants' : 'variant';
  }
  const history = useHistory();
  const navigateToLink = useCallback(() => {
    history.push(linkTo);
  }, [history, linkTo]);

  return (
    <Card>
      <div className={styles['inner-wrapper']}>
        <Thumbnail
          source={imgUrl}
          size="large"
          alt={`${title} thumbnail image`}
        />
        <div className={styles['text-wrapper']}>
          <p>{title}</p>
          <TextStyle variation="subdued">
            {numberOfVariants
              ? `${numberOfVariants} ${variantText}`
              : 'variant'}
          </TextStyle>
          {linkTo && (
            <Link onClick={navigateToLink}>
              {t('variantTitleThumbnail.back')}
            </Link>
          )}
        </div>
      </div>
    </Card>
  );
};

export default VariantTitleThumbnail;
