import Footer from 'components/Footer';
import React from 'react';
import { useParams } from 'react-router';
import useGetContract from 'hooks/useGetContract';
import LoadingState from 'pages/LoadingState';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import ContractsFormPage from '../../components/ContractsForm/ContractsFormPage';

const EditContractsFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetContract(id);
  const { isLoading: isMintingCapabilityLoading, mintingAvailable } =
    useMainnetMintingEnabled();

  return (
    <>
      {isLoading || isMintingCapabilityLoading ? (
        <LoadingState />
      ) : (
        <ContractsFormPage
          data={data}
          formType={'Edit'}
          id={id}
          onTestMode={!mintingAvailable}
        />
      )}
      <Footer />
    </>
  );
};

export default EditContractsFormPage;
