import t from 'lib/translation';

export type RoyaltyType = 'NONE' | 'CONTRACT';

export interface RoyaltyOptions {
  id: RoyaltyType;
  label: string;
  helpText: string;
  default?: boolean;
}

const ordersPaid: RoyaltyOptions = {
  id: 'NONE',
  label: t('dropForm.royalties.noneTitle'),
  helpText: t('dropForm.royalties.noneSubtitle'),
  default: true,
};

const ordersFulfilled: RoyaltyOptions = {
  id: 'CONTRACT',
  label: t('dropForm.royalties.contractTitle'),
  helpText: t('dropForm.royalties.contractSubtitle'),
};

export const royaltyOptions = [ordersPaid, ordersFulfilled];
