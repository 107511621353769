export type ArtistName = {
  firstName: string;
  lastName?: string;
};

export type ArtistAttributes = {
  name: ArtistName;
  alternativeNames: ArtistName[];
  nationality?: string;
  yearOfBirth?: number;
  placeOfBirth?: string;
};

export type Artist = {
  id?: string;
  ulanId?: string;
} & ArtistAttributes;

export type RelatedArtist = {
  relationshipType?: ArtistRelationshipType;
  relationshipStatus?: ArtistRelationshipStatus;
} & Artist;

export enum ArtistRelationshipType {
  SELF = 'SELF',
  REPRESENTS = 'REPRESENTS',
  COLLECT = 'COLLECT',
}

export enum ArtistRelationshipStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  DISABLED = 'DISABLED',
  REJECTED = 'REJECTED',
}

// Managed Artist Types

export type NewAuthorizationRequest = {
  phone: string | null;
  email: string;
  message: string | null;
};

export type CreateAuthorizationRequest = {
  phone: string | null;
  email: string;
  message: string | null;
  artistId: string;
};

export type CreateSelfRelationshipRequest = {
  signatureImage: string | null;
  artistId: string;
};

export type ManagedRelationshipData = {
  certificateCount: number;
  transferredCount?: number;
  attributes: ArtistAttributes;
  signatureImage?: string;
  status: ArtistRelationshipStatus;
  createdAt: string;
  requestStatus?: AuthorizationRequestStatus;
  contactDetails?: {
    email?: string;
  };
  artistId: string;
  galleryDetails?: {
    galleryName: string;
    instagramHandle?: string;
    twitterHandle?: string;
  };
  authorizationId?: string;
  relationshipId: string;
  message?: string;
  requestPhone?: string;
  requestEmail?: string;
};

export enum AuthorizationRequestStatus {
  WRONG_OWNER = 'WRONG_OWNER',
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  PRE_APPROVED = 'PRE_APPROVED',
  APPROVED = 'APPROVED',
}

export type RelationshipDataDetails = {
  type: ArtistRelationshipType;
  status: ArtistRelationshipStatus;
  id: string;
  attributes: ArtistAttributes;
  signatureImage?: string;
};

export type RelationshipData = {
  relatedArtist: RelationshipDataDetails[];
};
