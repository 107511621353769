import { PlanType } from '../../utils/plans';

export enum Frequency {
  MONTH = 'MONTH',
}

export interface Plan {
  title: string;
  price: { [key in Frequency]: string };
  recommended: boolean;
  features: string[];
}

export type AllPlansIncludingLegacy =
  | PlanType.INDIVIDUAL_STARTER
  | PlanType.INDIVIDUAL_PREMIUM
  | PlanType.INDIVIDUAL_PRO
  | PlanType.BUSINESS_STARTER
  | PlanType.BUSINESS_PREMIUM
  | PlanType.BUSINESS_PRO
  | PlanType.GROW
  | PlanType.PRO;
export const NEW_PLANS = [PlanType.GROW, PlanType.PRO];

const plans: Record<AllPlansIncludingLegacy, Plan> = {
  // These only exist to show the plan card for legacy cards.
  // {{{
  INDIVIDUAL_STARTER: {
    title: 'Legacy Individual Starter',
    price: {
      MONTH: '$9.99/month',
    },
    recommended: false,
    features: [
      '20 NFTs and COAs/mo',
      '1 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Online support',
      '',
    ],
  },
  INDIVIDUAL_PREMIUM: {
    title: 'Legacy Individual Premium',
    price: {
      MONTH: '$19.99/month',
    },
    recommended: true,
    features: [
      '120 NFTs & certificates /mo',
      '2 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      '20-minute onboarding call',
    ],
  },
  INDIVIDUAL_PRO: {
    title: 'Legacy Individual Pro',
    price: {
      MONTH: '$49.99/month',
    },
    recommended: false,
    features: [
      '1000 NFTs and COAs/mo',
      '5 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      'Dedicated account manager',
    ],
  },
  BUSINESS_STARTER: {
    title: 'Legacy Business Starter',
    price: {
      MONTH: '$29.99/month',
    },
    recommended: false,
    features: [
      '5 represented creators',
      '100 NFTs and COAs/mo',
      '5 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Online support',
    ],
  },
  BUSINESS_PREMIUM: {
    title: 'Legacy Business Premium',
    price: {
      MONTH: '$59.99/month',
    },
    recommended: true,
    features: [
      '10 represented creators',
      '500 NFTs and COAs/mo',
      '10 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      '20-minute onboarding call',
    ],
  },
  BUSINESS_PRO: {
    title: 'Legacy Business Pro',
    price: {
      MONTH: '$199.99/month',
    },
    recommended: false,
    features: [
      '50 represented creators',
      '5000 NFTs and COAs/mo',
      '50 custom contracts',
      'Ethereum & Polygon',
      'Unlimited tokengates',
      'Supports multiple languages',
      'Add your branding',
      'Dedicated account manager',
    ],
  },
  // }}}

  GROW: {
    title: 'Growth',
    price: {
      MONTH: '$29.99/month',
    },
    recommended: true,
    features: [
      '1 verified Creator',
      '1,000 COAs per month',
      'Mint NFTs on Ethereum, Base, Polygon',
      'Unlimited smart contracts',
      'Sell prints of your NFTs',
      'Unlimited tokengates',
      'Add your branding',
      'Dedicated account manager',
    ],
  },
  PRO: {
    title: 'Pro',
    price: {
      MONTH: '$99.99/month',
    },
    recommended: false,
    features: [
      'Represent creators',
      'Unlimited COAs',
      'Mint NFTs on Ethereum, Base, Polygon',
      'Unlimited smart contracts',
      'Sell prints of your NFTs',
      'Unlimited tokengates',
      'Add your branding',
      'Dedicated account manager',
      '10% off QR and NFC packs',
      'Access to custom development',
    ],
  },
};

export default plans;
