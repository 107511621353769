import React from 'react';
import { FooterHelp, Icon, Link, Stack } from '@shopify/polaris';
import { ExternalMinor } from '@shopify/polaris-icons';
import t from 'lib/translation';

const Footer: React.FC = () => {
  return (
    <FooterHelp>
      <Stack spacing="extraTight">
        <span>{t('footer.infoText')} </span>
        <Link url="https://verisart.com">{t('footer.companyTitle')}</Link>
        <Icon source={ExternalMinor} />
      </Stack>
    </FooterHelp>
  );
};

export default Footer;
