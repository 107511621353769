import React from 'react';

const Spacer = () => (
  <div
    aria-hidden
    style={{
      height: 'var(--p-space-6, 1.5rem)',
    }}
  />
);

export default Spacer;
