import Footer from 'components/Footer';
import React, { useState } from 'react';
import TokenGateFormPageWrapper from '../../components/TokenGateForm/TokenGateFormPageWrapper';

const TokenGateFormPage = () => {
  const [formType, setFormType] = useState<'Create' | 'Edit'>('Create');

  return (
    <>
      <TokenGateFormPageWrapper
        formType={formType}
        onSuccess={() => setFormType('Edit')}
      />
      <Footer />
    </>
  );
};

export default TokenGateFormPage;
