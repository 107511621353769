import React, { useEffect, useState } from 'react';
import { Filters, ResourceList } from '@shopify/polaris';
import ContractListItem from 'components/ContractListItem';
import { LocalPagination, determinePageSize } from 'components/LocalPagination';
import styles from './ContractsList.module.scss';
import t from 'lib/translation';
import { Contract } from '../../hooks/useGetContracts';
import { SortOptions, SortType } from '../../types/Utils';
import { orderBy } from 'lodash';

const ContractsList: React.FC<{ contracts: Contract[] }> = ({ contracts }) => {
  const pageSize = 10;
  const numberOfPages = Math.ceil(contracts.length / pageSize);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [contractsAvailable, setContractsAvailable] =
    useState<Contract[]>(contracts);
  const [contractsAvailableAndSorted, setContractsAvailableAndSorted] =
    useState<Contract[]>(contracts);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, (c) => c.name.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setContractsAvailableAndSorted(
          orderBy(contractsAvailable, 'updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, contractsAvailable]);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setContractsAvailable(
        contracts.filter((contract) =>
          contract.name.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
    } else {
      setContractsAvailable(contracts);
    }
  }, [queryValue, contracts]);

  return (
    <>
      <ResourceList
        sortValue={sortValue}
        sortOptions={SortOptions}
        onSortChange={(selected: SortType) => {
          setSortValue(selected);
        }}
        filterControl={filterControl}
        items={determinePageSize(
          contractsAvailableAndSorted,
          currentPage,
          pageSize,
          numberOfPages
        )}
        renderItem={(item) => {
          return (
            <>
              {item && (
                <ContractListItem
                  resourceId={item.id}
                  name={item.name}
                  symbol={item.symbol}
                  address={item.address}
                  blockchain={item.blockchain}
                  ownerStatus={item.ownerStatus}
                  description={item.description}
                  ownerAddress={item.ownerAddress}
                  subtitle={t(`blockchain.${item.blockchain}`)}
                  mode="CONTRACTS"
                  openseaIndexed={item.openseaIndexed}
                />
              )}
            </>
          );
        }}
      />
      <div className={`${styles.pagination}`}>
        <LocalPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numberOfPages={numberOfPages}
        />
      </div>
    </>
  );
};

export default ContractsList;
