import { Blockchain } from 'types/Blockchain';

export function scannerBaseUrl(blockchain: Blockchain): string {
  if (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA
  )
    return 'https://sepolia.etherscan.io';
  if (blockchain === Blockchain.POLYGON_AMOY)
    return 'https://amoy.polygonscan.com';

  if (
    blockchain === Blockchain.BASE_SEPOLIA ||
    blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
  )
    return 'https://sepolia.basescan.org';

  if (blockchain === Blockchain.ETHEREUM) return 'https://etherscan.io';
  if (blockchain === Blockchain.POLYGON) return 'https://polygonscan.com';
  if (blockchain === Blockchain.BASE_MAINNET) return 'https://basescan.org';
  throw Error(`Unknown blockchain ${blockchain}`);
}

export function openSeaAssetsBaseUrl(blockchain: Blockchain): string {
  if (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA
  )
    return 'https://testnets.opensea.io/assets/sepolia';
  if (blockchain === Blockchain.POLYGON_AMOY)
    return 'https://testnets.opensea.io/assets/amoy';
  if (
    blockchain === Blockchain.BASE_SEPOLIA ||
    blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
  )
    return 'https://testnets.opensea.io/assets/base-sepolia';
  if (blockchain === Blockchain.ETHEREUM)
    return 'https://opensea.io/assets/ethereum';
  if (blockchain === Blockchain.POLYGON)
    return 'https://opensea.io/assets/matic';
  if (blockchain === Blockchain.BASE_MAINNET)
    return 'https://opensea.io/assets/base';
  throw Error(`Unknown blockchain ${blockchain}`);
}
