import t from 'lib/translation';
import { ObjectTypeOptions } from 'components/ObjectTypeSelector/objectTypeOptions';

const useObjectTypeList = () => {
  return [
    {
      label: t('productsSection.metaDataForms.objectTypes.none'),
      value: '',
    },
    ...ObjectTypeOptions.map((option) => {
      const { object_type: type } = option;
      return {
        label: t(
          `productsSection.metaDataForms.objectTypes.${type.toLowerCase()}`
        ),
        value: type,
      };
    }),
  ];
};

export default useObjectTypeList;
