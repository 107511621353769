import API from 'utils/api';
import { useQuery, UseQueryResult } from 'react-query';

export const hasAccountPurchasedSticker = async (): Promise<boolean> => {
  return API()
    .get(`/qr/sticker/me`)
    .then((res) => res.data);
};

const useHasAccountPurchasedSticker = (): UseQueryResult<boolean> => {
  return useQuery<boolean>(['accountHasPurchasedSticker'], () =>
    hasAccountPurchasedSticker()
  );
};

export default useHasAccountPurchasedSticker;
