import { Toast } from '@shopify/app-bridge/actions';
import t from 'lib/translation';

type WithSaveToastOptions = {
  errorMessage?: string;
  successMessage?: string;
};

type ErrorMessage = string;

const withSaveToast: (
  onSave: (() => Promise<ErrorMessage> | ErrorMessage) | null,
  showToast: (toastOptions: Toast.Options) => void,
  options?: WithSaveToastOptions
) => Promise<void> | void = async (onSave, showToast, options) => {
  const toastOptions: Toast.Options = {
    duration: 5000,
    message: '',
  };
  try {
    const errorMessage = onSave ? await onSave() : null;
    if (errorMessage) {
      toastOptions.message = errorMessage;
      toastOptions.isError = true;
    } else {
      toastOptions.message =
        options?.successMessage ?? t('settings.page.toastSettingsSaved');
    }
  } catch (e) {
    toastOptions.message =
      options?.errorMessage ?? t('settings.page.toastSettingsSaveError');
    toastOptions.isError = true;
    throw e;
  } finally {
    showToast(toastOptions);
  }
};

export default withSaveToast;
