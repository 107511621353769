import { Button, DropZone, Image, Stack, TextStyle } from '@shopify/polaris';
import React, { useRef, useState } from 'react';
import t from 'lib/translation';
import { uploadImage } from '../../hooks/useUploadCustomizeMedia';
import style from './ImageFileUpload.module.css';
import { simpleImageExtensions } from '../../utils/validation';

type ActionClickMethods = {
  clear: () => void;
};

type Action = {
  id?: string;
  content?: string | string[];
  onClick: (methods: ActionClickMethods) => void;
};

type FileUploadProps = {
  controller: ImageFileUploadController;
  disabled?: boolean;
  hint?: string;
  initialPreview?: string;
  label?: string;
  name: string;
  onFileSelected?: (url: string) => void;
  onFileUploaded?: (url: string) => void;
  secondaryActions?: Action[];
  reset?: boolean;
};

export class ImageFileUploadController {
  private files = new Map<string, File>();

  private uploadCompleteListeners = new Set<
    (name: string, url: string) => void
  >();

  addFile(
    name: string,
    file: File,
    onUploadCompleted: ((url: string) => void) | undefined = undefined
  ) {
    this.files.set(name, file);

    return onUploadCompleted
      ? this.onUploadCompleted((n, url) => {
          if (onUploadCompleted && n === name) {
            onUploadCompleted(url);
          }
        })
      : undefined;
  }

  removeFile(name: string) {
    this.files.delete(name);
  }

  async uploadAllFiles() {
    for (let [name, file] of this.files.entries()) {
      const url = await uploadImage(file);
      this.uploadCompleteListeners.forEach((listener) => listener(name, url));
    }
  }

  onUploadCompleted(callback: (name: string, url: string) => void) {
    this.uploadCompleteListeners.add(callback);
    return () => this.uploadCompleteListeners.delete(callback);
  }
}

/**
 * A component that shows a file selection or the currently selected file
 *
 * It works with a FileUploadController to manage the lifecycle and monitoring
 * or a file upload.
 *
 * A simple usage would look like the following:
 *
 *   const [controller] = useState(new FileController());
 *
 *   // Promise uploadAllFiles resolves once all pending uploads
 *   // have been completed
 *   const uploadFiles = async () => controller.uploadAllFiles()
 *
 *   useEffect(() => {
 *     return controller.onUploadCompleted((name: string, url: string) => {
 *       // Do something with an uploaded file
 *     });
 *   }, [controller]);
 *
 *   return (
 *     <>
 *       <FileUpload
 *         controller={controller}
 *         name="logo"
 *         onFileUploaded={(url) => {
 *           // Do something with the uploaded file
 *         }}
 *       />
 *       <button onClick={() => uploadFiles()}>Upload files</button>
 *     </>
 *   );
 */
const ImageFileUpload: React.FC<FileUploadProps> = ({
  controller,
  disabled,
  hint,
  initialPreview,
  label,
  name,
  onFileSelected,
  onFileUploaded,
  reset,
  secondaryActions,
}) => {
  const [currentPreview, setCurrentPreview] = useState(initialPreview ?? '');
  const [dialogOpen, setDialogOpen] = useState(false);
  const unsubscribe = useRef<() => void>();

  const handleDrop = (...files: File[][]) => {
    const [, acceptedFiles] = files;
    if (acceptedFiles.length === 0) {
      return;
    }
    const localPreview = window.URL.createObjectURL(acceptedFiles[0]);
    onFileSelected?.(localPreview);
    setCurrentPreview(localPreview);
    unsubscribe.current?.();
    unsubscribe.current = controller.addFile(name, acceptedFiles[0], (url) => {
      onFileUploaded?.(url);
      setCurrentPreview(url);
      window.URL.revokeObjectURL(localPreview);
    });
  };

  const clear = () => {
    unsubscribe.current?.();
    unsubscribe.current = undefined;
    controller.removeFile(name);
    setCurrentPreview('');
  };

  if (reset && currentPreview !== (initialPreview ?? '')) {
    setCurrentPreview(initialPreview ?? '');
  }

  return (
    <div className={style.FileUpload}>
      {currentPreview && (
        <>
          <TextStyle>{label}</TextStyle>
          <Image
            className={style.FileUploadPreview}
            source={currentPreview}
            alt=""
          />
        </>
      )}
      <div style={currentPreview ? { display: 'none' } : {}}>
        <DropZone
          disabled={disabled}
          label={label}
          variableHeight
          accept={simpleImageExtensions.join(',')}
          type="image"
          onDrop={handleDrop}
          allowMultiple={false}
          openFileDialog={dialogOpen}
          onFileDialogClose={() => setDialogOpen(false)}
        >
          <DropZone.FileUpload actionHint={hint} />
        </DropZone>
      </div>
      <Stack>
        <Button
          disabled={disabled}
          plain
          monochrome
          onClick={() => setDialogOpen(true)}
        >
          {currentPreview
            ? t('settings.upload.replaceImage')
            : t('settings.upload.selectImage')}
        </Button>
        {secondaryActions?.map(({ content, id, onClick }, idx) => (
          <Button
            key={id || idx}
            disabled={disabled}
            plain
            monochrome
            onClick={() => onClick({ clear })}
          >
            {content}
          </Button>
        ))}
      </Stack>
    </div>
  );
};

export default ImageFileUpload;
