import { getVerisartDotComHost } from '../lib/urls';
import useShop from '../hooks/useShop';
import { useCallback } from 'react';
import assert from 'assert';
import { getShopDomain, useAppBridge } from 'containers/AppBridge';

/**
 * Get a url on the main verisart.com website which end up at the specified path (e.g. `/artists`), after first doing a
 * login using the shopify credentials if possible.
 *
 * IMPORTANT: Do not store these URLs anywhere as the session token used expires very quickly. Call this at the
 * last possible moment before opening/redirecting.
 */
const getShortLivedUrlForWebsite = async (
  path: string,
  dummyCognito: boolean | undefined,
  getSessionToken: () => Promise<string>,
  verisartCognitoUsername?: string | null
): Promise<string> => {
  const sessionToken = await getSessionToken();
  const shopDomain = getShopDomain();
  const encodedPath = encodeURIComponent(path);
  if (dummyCognito) {
    return `${getVerisartDotComHost()}/application/shopify_login?sessionToken=${sessionToken}&shopDomain=${shopDomain}&next=${encodedPath}`;
  }
  assert(verisartCognitoUsername);
  // Else, the user has a linked account. In that case, they need to log in manually
  return `${getVerisartDotComHost()}/application/shopify_login?sessionToken=${sessionToken}&shopDomain=${shopDomain}&cognitoUsername=${encodeURIComponent(
    verisartCognitoUsername
  )}&next=${encodedPath}`;
};

/**
 * Redirects the user to verisart.com, performing single sign on if the Shop is using dummy cognito mode
 * (i.e. is not linked to an external verisart.com user)
 */
export const useOpenWindowOnVerisartDotCom = (): ((
  path: string,
  newWindow?: boolean
) => Promise<void>) => {
  const { data: shopData } = useShop();
  const app = useAppBridge();

  return useCallback(
    async (path: string, newWindow?: boolean) => {
      const url = await getShortLivedUrlForWebsite(
        path,
        shopData?.shop?.dummyCognito,
        app.getSessionToken,
        shopData?.shop?.verisartCognitoUsername
      );
      window.open(url, newWindow ? '_blank' : undefined);
    },
    [
      shopData?.shop?.dummyCognito,
      shopData?.shop?.verisartCognitoUsername,
      app.getSessionToken,
    ]
  );
};
