import React, { useState } from 'react';
import { Stack, Button } from '@shopify/polaris';

interface FormFieldProps {
  label: string;
  value: string;
  maxLength?: number;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  value,
  maxLength = Infinity,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isValueTruncated = value.length > maxLength;

  return (
    <Stack vertical>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <p
          style={{
            minWidth: '100px',
            fontWeight: 'bold',
            margin: 0,
          }}
        >
          {label}
        </p>
        <p style={{ margin: 0 }}>
          {value && (
            <>
              {isValueTruncated && !isExpanded
                ? `${value.substring(0, maxLength)}...`
                : value}
              {isValueTruncated && (
                <Button plain onClick={handleToggle}>
                  {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
              )}
            </>
          )}
        </p>
      </div>
    </Stack>
  );
};

export default FormField;
