import { Card, Layout } from '@shopify/polaris';
import React from 'react';
import styles from './ProductForm.module.scss';

interface CardElementProps {
  title?: string;
  helpText?: React.ReactNode;
  children: React.ReactNode;
}

const CardElement: React.FC<CardElementProps> = ({
  title,
  helpText,
  children,
}) => {
  return (
    <Card>
      {title !== undefined && <Card.Header title={title} />}
      {helpText !== undefined && (
        <div className={`${styles.helpText}`}>{helpText}</div>
      )}
      <Layout.Section>
        <div
          className={`${styles.container}`}
          style={{
            paddingTop: '1rem',
            display: 'flex',
            gap: '1rem',
            flexDirection: 'column',
          }}
        >
          {children}
        </div>
      </Layout.Section>
    </Card>
  );
};

export default CardElement;
