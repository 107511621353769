import useGetCapabilities from './useGetCapabilities';

export type MainnetMintingEnabled = {
  mintingAvailable?: boolean;

  // At least one of these will be true if enabled is true
  // {{{
  /** If shop can mint zero value products */
  giftingEnabled?: boolean;

  /** If shop can mint non-zero value products */
  paidEnabled?: boolean;
  // }}}

  isLoading: boolean;
};

export default function useMainnetMintingEnabled(): MainnetMintingEnabled {
  const { data, isLoading } = useGetCapabilities();
  const giftingEnabled = data?.capabilities?.includes('nft.shop.minting.free');
  const paidEnabled = data?.capabilities?.includes('nft.shop.minting');

  return {
    mintingAvailable: giftingEnabled || paidEnabled,
    giftingEnabled,
    paidEnabled,
    isLoading: isLoading,
  };
}
