import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import API from 'utils/api';

export enum BalanceName {
  Object = 'object',
  CertificateCustomization = 'certificate_customization',
  NFTCustomContract = 'nft_custom_contract',
  ArtistRelationships = 'artist_relationships',
}

interface QuotaData {
  used: number;

  /** null indicates "no limit" */
  limit: number | null;

  /** Will be set to some suitably high number in the case of "no limit" */
  remaining: number;
  renewable: boolean;
  renewDate?: string | null;
}

export function isAtLimit(quota: QuotaData): boolean {
  // Check against quota.limit is required because we don't care about limits that you wouldn't expect
  // to have allowance for.
  // null limit means unlimited so if non zero this is still correct
  return quota.limit !== 0 && quota.remaining === 0;
}

export const fetchQuota = async (
  balanceName: BalanceName
): Promise<QuotaData> => {
  const { data } = await API().get(`/quota/${balanceName}`);
  return data;
};

export const useQuota = (
  balanceName: BalanceName,
  options: UseQueryOptions<QuotaData> = {}
): UseQueryResult<
  { identifier: BalanceName; exceeded: boolean } & QuotaData
> => {
  return useQuery(['quota', balanceName], () => fetchQuota(balanceName), {
    ...options,
    select(data) {
      return {
        identifier: balanceName,
        exceeded:
          data.limit !== null && data.limit > 0 && data.used >= data.limit,
        ...data,
      };
    },
  });
};
