import { useMutation } from 'react-query';
import API from 'utils/api';

export type GetCoreTokenProps = {
  shopDomain: string;
  sessionToken: string;
};

export const fetchCoreToken = async ({
  shopDomain,
  sessionToken,
}: GetCoreTokenProps) => {
  const res = await API().post('/shopify/signedToken', {
    shopDomain,
    sessionToken,
  });
  return res.data;
};

export default function useGetCoreToken() {
  return useMutation(({ shopDomain, sessionToken }: GetCoreTokenProps) =>
    fetchCoreToken({ shopDomain, sessionToken })
  );
}
