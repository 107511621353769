/**
 * See also [useOpenWindowOnVerisartDotCom]
 */
export const getVerisartDotComHost = () => {
  switch (window.location.hostname) {
    case 'shopify-merchant-test.verisart.com': // test
      return 'https://website-test.verisart.com';
    case 'shopify-merchant-staging.verisart.com': // staging
      return 'https://website-staging.verisart.com';
    case 'shopify-merchant-production.verisart.com': // production
      return 'https://verisart.com';
    case 'shop.verisart.com':
      return 'https://verisart.com';
    default:
      return process.env.REACT_APP_APP_URL ?? 'http://localhost:3000';
  }
};

export const generateAppRedirectUrl = (
  storeName: string,
  appId: string,
  // path is of the shape "/path/to/something" - notice the leading slash
  path?: string
): string => `https://${storeName}/admin/apps/${appId}${path ? `${path}` : ''}`;
