export type BackgroundType = 'default' | 'color' | 'image';

export const backgroundTypeForValue = (value: string): BackgroundType => {
  if (!value) {
    return 'default';
  }

  try {
    new URL(value);
    return 'image';
  } catch (e) {
    return 'color';
  }
};
