import {
  Badge,
  Button,
  DisplayText,
  Heading,
  List,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import React, { useMemo, useState } from 'react';
import t, { parameters as p } from 'lib/translation';
import styles from './PricingCard.module.css';

interface PricingCardProps {
  actionTitle?: string;
  title: string;
  price: string;
  replacedPrice?: string;
  features: string[];
  recommended?: boolean;
  badge?: React.ReactElement;
  onClickGet?: () => Promise<void> | void;
  disabled?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
  actionTitle,
  title,
  price,
  replacedPrice,
  features,
  recommended,
  badge,
  onClickGet,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const currentBadge = useMemo(() => {
    const recommendedBadge = recommended && (
      <Badge status="success" statusAndProgressLabelOverride="Recommended">
        {t('pricing.badge.recommended')}
      </Badge>
    );
    return badge || recommendedBadge;
  }, [badge, recommended]);
  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await onClickGet?.();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${styles.pricingCard} ${recommended ? styles.featured : ''}`}
      style={{ minWidth: 280, maxWidth: 300 }}
    >
      <header>
        <hgroup>
          <DisplayText element="h2" size="medium">
            {title}
          </DisplayText>
          <Heading element="h3">
            <Stack spacing="extraTight">
              {replacedPrice && (
                <TextStyle variation="subdued">
                  <span style={{ textDecorationLine: 'line-through' }}>
                    {replacedPrice}
                  </span>
                </TextStyle>
              )}
              <TextStyle variation="subdued">{price}</TextStyle>
            </Stack>
          </Heading>
        </hgroup>
        <div className={styles.pricingCardBadge}>{currentBadge}</div>
      </header>
      <List type="bullet">
        {features.map((feature, index) => {
          if (feature === '') {
            return (
              <div className={styles.empty}>
                <List.Item key={index}></List.Item>
              </div>
            );
          } else {
            return <List.Item key={index}>{feature}</List.Item>;
          }
        })}
      </List>
      {onClickGet && (
        <div className={styles.pricingCardActions}>
          <Button
            primary
            disabled={disabled}
            loading={loading}
            onClick={handleButtonClick}
          >
            {actionTitle || p('pricing.plan.get', title)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
