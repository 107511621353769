import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import useSession from 'hooks/useSession';
import Header from '../components/Header';
import LoadingPage from '../auth/LoadingPage';
import { useAppBridge } from './AppBridge';

const AppContainer: React.FC<PropsWithChildren<{}>> = ({
  children,
}: PropsWithChildren<{}>) => {
  const embeddedInShopifyAdmin = window.parent !== window;
  const app = useAppBridge();
  const location = useLocation();
  const { authorizationUrl, refreshSessionToken } = useSession(
    (state) => state
  );

  useEffect(() => {
    if (authorizationUrl) {
      app.redirect(authorizationUrl, 'Remote');
    }
  }, [app, authorizationUrl]);

  useEffect(() => {
    refreshSessionToken(app.getSessionToken);
  }, [refreshSessionToken, app.getSessionToken]);

  return embeddedInShopifyAdmin ? (
    <>
      <Header pathname={location.pathname} />
      {children}
    </>
  ) : (
    <LoadingPage />
  );
};

export default AppContainer;
