import React, { useState } from 'react';
import styled from 'styled-components';
import {
  TextStyle,
  ColorPicker,
  TextField,
  HSBColor,
  rgbString,
  hsbToRgb,
  hsbToHex,
  rgbToHsb,
  Stack,
} from '@shopify/polaris';
import OutsideClickHandler from 'react-outside-click-handler';

const Color = styled.div`
  height: 100%;
  width: 37px;
  border-radius: 2px;
  border: 1px solid #c9c9c9;
  cursor: pointer;
  margin-right: 8px;
`;

const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 10000;
  background: #fff;
  padding: 10px;
  border: 1px solid #c0c0c0;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
`;

const TextContainer = styled.div`
  padding-top: 15px;
`;

const ColorPreviewBox = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

type ColorPickerProps = {
  background: HSBColor;
  label: string;
  hint?: string;
  handleColorChange: (color: HSBColor) => void;
  disabled?: boolean;
};

const BackgroundPicker = ({
  background,
  label,
  hint,
  handleColorChange,
  disabled,
}: ColorPickerProps) => {
  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState(hsbToHex(background));
  const [textError, setTextError] = useState('');

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result) {
      return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      };
    }

    return null;
  };

  const handleTextChange = (value: string) => {
    setTextValue(value);
    setTextError('');
    const color = hexToRgb(value);
    if (color) {
      handleColorChange(rgbToHsb(color));
    } else {
      setTextError('Incorrect hex value');
    }
  };

  if (background && textValue !== hsbToHex(background)) {
    setTextError('');
    setTextValue(hsbToHex(background));
  }

  if (disabled && open) {
    setOpen(false);
  }

  return (
    <>
      <Stack vertical spacing="extraTight">
        <Container
          onClick={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
        >
          <Color
            style={{
              backgroundColor: rgbString(hsbToRgb(background)),
            }}
          />
          <TextStyle variation={disabled ? 'subdued' : undefined}>
            <span style={{ cursor: 'pointer' }}>{label}</span>
          </TextStyle>
        </Container>
        <TextStyle variation="subdued">{hint}</TextStyle>
      </Stack>

      {open ? (
        <ColorPickerContainer>
          <OutsideClickHandler
            onOutsideClick={() => {
              setOpen(false);
            }}
          >
            <ColorPicker onChange={handleColorChange} color={background} />
            <TextContainer>
              <TextField
                label=""
                onChange={handleTextChange}
                value={textValue}
                error={textError}
                connectedLeft={
                  <ColorPreviewBox
                    style={{ backgroundColor: rgbString(hsbToRgb(background)) }}
                  />
                }
                autoComplete="off"
              />
            </TextContainer>
          </OutsideClickHandler>
        </ColorPickerContainer>
      ) : null}
    </>
  );
};

export default BackgroundPicker;
