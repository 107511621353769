import { Blockchain } from 'types/Blockchain';
import API from 'utils/api';
import { useQuery } from 'react-query';

export interface ContractPreview {
  name: string | null;
  symbol: string | null;
  type: 'ERC721' | 'ERC1155' | 'UNKNOWN';
  openSeaThumbnail: string | null;
  isArtBlocks: boolean;
}

const fetchSmartContractMetadata = async (
  contractAddress: string,
  blockchain: Blockchain
) => {
  const params = new URLSearchParams({
    contractAddress,
    blockchain,
  });
  const res = await API().get(`shop/tokenGate/contracts?${params.toString()}`);
  return res.data as ContractPreview;
};

const useGetContractMetadata = (
  contractAddress?: string,
  blockchain?: Blockchain
) => {
  return useQuery(
    ['contract', contractAddress, blockchain],
    () =>
      fetchSmartContractMetadata(
        contractAddress as string,
        blockchain as Blockchain
      ),
    {
      enabled: !!contractAddress && !!blockchain,
    }
  );
};

export default useGetContractMetadata;
