import { Card, Stack, TextStyle, Icon, Spinner } from '@shopify/polaris';
import styles from './CollectorlistItem.module.scss';
import { isImageFile, isModelFile, isVideoFile } from 'utils/validation';
import React, { useEffect } from 'react';
import { getFileExtension, removeFileExtension } from 'utils/stringFormatting';
import { TimelineAttachmentMajor, DeleteMinor } from '@shopify/polaris-icons';
import t from 'lib/translation';
import useDeleteCollectorReward from 'hooks/useDeleteCollectorReward';
import ModelViewer from '../../../components/ModelViewer';
import { CollectorRewardFile } from '../../../hooks/useCreateDrop';
import { useAppBridge } from 'containers/AppBridge';

interface CollectorListItemProps {
  productId: string;
  asset: CollectorRewardFile;
  setAsset: (rewards: Array<CollectorRewardFile>) => void;
  assets: Array<CollectorRewardFile>;
  index: number;
  customDelete?: (productId: string, index: number) => void;
}

const CollectorListItem: React.FC<CollectorListItemProps> = ({
  productId,
  asset,
  index,
  assets,
  setAsset,
  customDelete,
}) => {
  const app = useAppBridge();
  const clickHandler = () => window.open(asset.fileURL);
  const { mutateAsync: deleteCollectorReward, status } =
    useDeleteCollectorReward();

  const removeCollectorReward = async (metafieldKey: string, i: number) => {
    await deleteCollectorReward({ productId: metafieldKey, index: i });
    app.showToast({
      message: t('productForm.fileDeleted'),
      duration: 5000,
    });
  };

  useEffect(() => {
    if (status === 'success') {
      const withoutDeleted = [
        ...assets.slice(0, index),
        ...assets.slice(index + 1),
      ];
      setAsset(withoutDeleted);
    }
  }, [status, assets, index, setAsset]);

  return (
    <Stack.Item fill>
      <Card>
        <Card.Section>
          <Stack wrap={false}>
            <Stack.Item>
              <div className={`${styles['vertical-center']}`}>
                {/* This needs to accommodate the condition of having a file that is not img or vid */}

                {asset !== undefined && (
                  <>
                    {(() => {
                      if (isImageFile(asset.fileURL)) {
                        return (
                          <img
                            onClick={clickHandler}
                            className={`${styles.asset} ${styles.bordered}`}
                            src={asset.fileURL}
                            alt={t('productsSection.metaDataForms.titleImgAlt')}
                          />
                        );
                      } else if (isModelFile(asset.fileURL)) {
                        return (
                          <ModelViewer
                            src={asset.fileURL}
                            mode="off"
                            className=""
                            style={{}}
                            onClick={clickHandler}
                          />
                        );
                      } else if (isVideoFile(asset.fileURL)) {
                        return (
                          <video
                            onClick={clickHandler}
                            className={`${styles.asset} ${styles.bordered}`}
                            src={asset.fileURL}
                            loop
                            muted
                            autoPlay
                          />
                        );
                      } else {
                        return (
                          <div>
                            <div
                              className={`${styles.asset} ${styles.bordered}`}
                            >
                              <Icon
                                source={TimelineAttachmentMajor}
                                color="base"
                              />
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </>
                )}
              </div>
            </Stack.Item>
            <Stack.Item fill>
              <div className={`${styles['vertical-center']}`}>
                <TextStyle>{removeFileExtension(asset.fileLabel)}</TextStyle>
                <TextStyle variation="subdued">
                  {getFileExtension(asset.fileURL)!.replace(/\?.*/, '')}
                </TextStyle>
              </div>
            </Stack.Item>
            <Stack.Item>
              <div
                style={{ cursor: 'pointer' }}
                className={`${styles['vertical-center']}`}
                onClick={() =>
                  customDelete
                    ? customDelete(productId, index)
                    : removeCollectorReward(productId, index)
                }
              >
                {status === 'loading' ? (
                  <Spinner size="small" />
                ) : (
                  <Icon source={DeleteMinor} color="base" />
                )}
              </div>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </Stack.Item>
  );
};

export default CollectorListItem;
