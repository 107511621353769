import { useQuery, UseQueryResult } from 'react-query';
import API from 'utils/api';
import { PlanType } from '../utils/plans';
import { Frequency } from '../components/Pricing/plans';

export type UseSubscriptionDetailsResponse = {
  subscriptionDetails: {
    planType: PlanType;
    frequency?: Frequency;
    currentPeriodEnd: string;
    isStripeSubscriber: boolean;
    isShopifySubscriber: boolean;
    trialDaysRemaining: number;
  };
};

async function getSubscriptionDetails(): Promise<UseSubscriptionDetailsResponse | null> {
  try {
    const response = await API().get('/shop/me/subscription');
    return response.data;
  } catch (e) {
    if (e?.response?.status === 401) {
      return null;
    }
    throw e;
  }
}

export default function useSubscriptionDetails(): UseQueryResult<UseSubscriptionDetailsResponse | null> {
  return useQuery('subscriptionDetails', getSubscriptionDetails, {
    retry: false,
  });
}
