import { isValidEthereumAddress } from 'pages/RoyaltySplit/utils';
import { useEffect, useState } from 'react';
import { Blockchain } from 'types/Blockchain';
import API, { sleep } from 'utils/api';
import { isPrintJobReady } from './useGetPrintPreview';

interface GenerateGifAssetResponse {
  url: string;
  jobId: string | null;
}

const useGetGifForContract = (
  blockchain: Blockchain | undefined,
  contractAddress: string | null,
  artblocksContract: boolean | undefined,
  artBlocksProjectId?: string
) => {
  const [gifData, setGifData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      setGifData(null);
      setFetchError(null);

      if (
        blockchain &&
        contractAddress &&
        isValidEthereumAddress(contractAddress) &&
        (!artblocksContract || (artblocksContract && artBlocksProjectId))
      ) {
        try {
          setLoading(true);

          const response = await API().post<GenerateGifAssetResponse>(
            `/shop/print/printPreview/${blockchain}/${contractAddress}`,
            {},
            {
              params: {
                artBlocksProjectId: artBlocksProjectId,
              },
            }
          );

          console.log('Generated URL:', response.data.url);

          if (response.data.jobId) {
            // Not generated yet so wait for the job to complete
            while (!cancel) {
              if (await isPrintJobReady(response.data.jobId)) {
                break;
              }
              await sleep(1000);
            }
          }

          if (cancel) return;

          setGifData(response.data.url);
        } catch (error) {
          if (!cancel) {
            console.error(
              `Error generating GIF preview for token for ${blockchain}/${contractAddress}`,
              error
            );
            setFetchError(
              error.response?.data?.message ?? error.message ?? 'Unknown error'
            );
          }
        } finally {
          if (!cancel) setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      setLoading(false);
      cancel = true;
    };
  }, [blockchain, contractAddress, artBlocksProjectId, artblocksContract]);

  return { gifData, loading, fetchError };
};

export default useGetGifForContract;
