import {
  Button,
  Image,
  Link,
  Modal,
  Stack,
  TextContainer,
} from '@shopify/polaris';
import LessThan from 'assets/svgComponents/LessThan';
import React, { useCallback, useEffect, useState } from 'react';
import { translate as t } from 'lib/translation';
import { useAppBridge } from 'containers/AppBridge';
import { useLocation } from 'react-router-dom';

const ApplyForMintingModal = ({
  activator,
  open,
  onClose,
}: {
  activator?: React.RefObject<HTMLElement>;
  open?: boolean;
  onClose: () => void;
}) => {
  const app = useAppBridge();
  const apiKey = app.apiKey;
  const host = app.host;
  const location = useLocation();

  const startApplication = useCallback(() => {
    const callback = `https://${host}/apps/${apiKey}${location.pathname}`;
    app.redirect('Admin', {
      type: 'nft_application',
      callbackUrl: callback,
      apiKey: apiKey,
    });
  }, [apiKey, app, host, location.pathname]);

  // Track if we're below the "compact" width so that the component can be re-flowed with the icon above the next
  // to keep things legible in this modal.
  const [compact, setCompact] = useState(false);

  useEffect(() => {
    if (!window.matchMedia) return;

    const queryList = window.matchMedia('(max-width: 500px)');
    const mediaChange = () => {
      setCompact(queryList.matches);
    };
    queryList.addEventListener('change', mediaChange);
    setCompact(queryList.matches);
    return () => queryList.removeEventListener('change', mediaChange);
  }, []);

  return (
    <Modal
      activator={activator}
      open={open ?? false}
      title={t('applyForMinting.title')}
      onClose={() => onClose?.()}
    >
      <Modal.Section>
        <Stack wrap={compact}>
          {compact ? (
            <Stack.Item>
              <Image
                source="/images/shopify-payments-logo.svg"
                width="174"
                height="27"
                alt="Shopify Payments"
              />
            </Stack.Item>
          ) : null}
          <Stack.Item fill>
            <TextContainer>
              <p>{t('applyForMinting.firstSection')}</p>
              <p>{t('applyForMinting.secondSection')}</p>
              <p>
                <Link
                  url="https://help.verisart.com/en/articles/6616531-what-mode-am-i-in"
                  external
                  removeUnderline
                >
                  {t('applyForMinting.learnMore')}
                </Link>
              </p>
            </TextContainer>
          </Stack.Item>
          {compact ? null : (
            <Stack.Item>
              <Image
                source="/images/shopify-payments-logo.svg"
                width="174"
                height="27"
                alt="Shopify Payments"
              />
            </Stack.Item>
          )}
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            <Button
              icon={<LessThan />}
              accessibilityLabel={t('applyForMinting.cancel')}
              onClick={() => onClose?.()}
            />
          </Stack.Item>
          <Stack.Item>
            <Button primary onClick={() => startApplication()}>
              {t('applyForMinting.next')}
            </Button>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default ApplyForMintingModal;
