import React, { useCallback } from 'react';
import { Page, Card, DisplayText, Heading, Link } from '@shopify/polaris';
import { ErrorIcon } from 'components/JsxIcons';
import Footer from 'components/Footer';
import styles from './PageNotFound.module.css';
import { useHistory } from 'react-router-dom';

const PageNotFound = () => {
  const history = useHistory();
  const navigateToDrops = useCallback(() => {
    history.push('/drops');
  }, [history]);

  return (
    <>
      <Page>
        <Card>
          <div className={`${styles.container}`}>
            <div className={`${styles.icon}`}>
              <ErrorIcon color="black" />
            </div>
            <DisplayText element="h1" size="large">
              Page not found{' '}
            </DisplayText>
            <div className={`${styles.info}`}>
              <Heading>
                We could not find the page you were looking for, try the tabs
                above or go back to the{' '}
                <Link onClick={navigateToDrops}>drop page</Link>
              </Heading>
            </div>
          </div>
        </Card>
      </Page>
      <Footer />
    </>
  );
};

export default PageNotFound;
