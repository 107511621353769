import useCreatorMyself from 'hooks/useCreatorMyself';
import useCreatorsRepresented from 'hooks/useCreatorsRepresented';
import t from 'lib/translation';
import { SelectGroup, SelectOption } from '@shopify/polaris';
import { extractArtistOption } from '../ProductForm/ProductFormShow';

const useCreatorsList = (): {
  isLoading: boolean;
  data?: (SelectGroup | SelectOption)[];
} => {
  const creatorMyselfQuery = useCreatorMyself();
  const creatorsRepresentedQuery = useCreatorsRepresented();

  const isLoading =
    creatorMyselfQuery.isLoading || creatorsRepresentedQuery.isLoading;
  const data = isLoading
    ? undefined
    : [
        {
          label: t('settings.creators.defaultCreatorNoneLabel'),
          value: '',
        },
        {
          title: t('settings.creators.defaultCreatorMyselfLabel'),
          options:
            creatorMyselfQuery.data?.myselfData?.map((relationship) => ({
              label: extractArtistOption(relationship.attributes),
              value: relationship.artistId,
            })) ?? [],
        },
        {
          title: t('settings.creators.defaultCreatorRepresentedLabel'),
          options:
            creatorsRepresentedQuery.data?.representArtist?.map(
              (relationship) => ({
                label: extractArtistOption(relationship.attributes),
                value: relationship.artistId,
              })
            ) ?? [],
        },
      ].filter(
        (group) => group.options === undefined || group.options.length > 0
      );

  return {
    isLoading,
    data,
  };
};

export default useCreatorsList;
