import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getApiUrl } from '../constants';

export const UPSELL_QUERY_PARAM = 'vst_us';

const startShopSetup = async (
  shopDomain: string,
  anonId: string | null
): Promise<{
  authorizationUrl: string;
  apiKey: string;
  authorizationNeeded: boolean;
}> => {
  const anonIdQuery = anonId ? `&vst_anon_id=${anonId}` : '';
  const response = await fetch(
    `${getApiUrl()}/shopify/setup?shop=${shopDomain}${anonIdQuery}`,
    {
      method: 'GET',
    }
  );
  if (response.status !== 200) {
    throw Error(`Start shop setup failed with status code ${response.status}`);
  }
  return response.json();
};

/**
 * Handle passing in full shop URLs like https://foo.myshopify.com/123
 */
export const cleanupShopParam = (shopIn: string): string => {
  try {
    return new URL(shopIn).hostname;
  } catch {
    return shopIn;
  }
};

const useShopifyInstall = () => {
  const location = useLocation();
  const [state, setState] = useState<{
    isLoading: boolean;
    authorizationUrl: string | null;
    apiKey: string;
    authorizationNeeded: boolean;
  }>({
    isLoading: true,
    authorizationUrl: null,
    apiKey: '',
    authorizationNeeded: true,
  });

  const search = new URLSearchParams(location.search);
  const shopParam = search.get('shop');
  const anonId = search.get('vst_anon_id');
  const upsell = search.get(UPSELL_QUERY_PARAM);

  useEffect(() => {
    if (shopParam && !state.authorizationUrl) {
      startShopSetup(cleanupShopParam(shopParam), anonId).then(
        ({ authorizationUrl, apiKey, authorizationNeeded }) => {
          const upsellParamStr = upsell
            ? `?${UPSELL_QUERY_PARAM}=${upsell}`
            : '';
          const redirect = `https://${window.location.hostname}/redirect${upsellParamStr}`;
          const authWithRedirect = `${authorizationUrl}&redirect_uri=${encodeURIComponent(
            redirect
          )}`;
          setState({
            isLoading: false,
            authorizationUrl: authWithRedirect,
            apiKey,
            authorizationNeeded,
          });
        }
      );
    }
  }, [upsell, anonId, shopParam, location.search, state.authorizationUrl]);

  return state;
};

export default useShopifyInstall;
