import React, { Fragment } from 'react';
import {
  FormLayout,
  Stack,
  TextStyle,
  Icon,
  Tooltip,
  Button,
  Card,
  Layout,
} from '@shopify/polaris';
import t from 'lib/translation';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import HorizontalBoxLink from '../../components/HorizontalBoxLink';
import { useHistory } from 'react-router-dom';

const EmailSettingsButtons: React.FC = () => {
  const emailCustomizations = [
    {
      title: t('settings.emailCustomization.claimNftCard'),
      link: '/customizations/claimnft',
      help: t('settings.emailCustomization.nftBodyInformation'),
    },
    {
      title: t('settings.emailCustomization.viewNftCard'),
      link: '/customizations/viewnft',
      help: t('settings.emailCustomization.viewNFTBodyInformation'),
    },
    {
      title: t('settings.emailCustomization.claimCoaCard'),
      link: '/customizations/claimcoa',
      help: t('settings.emailCustomization.COABodyInformation'),
    },
  ];

  const history = useHistory();

  const sections = [
    {
      title: t('settings.emailCustomization.GlobalCustomization'),
      children: (
        <FormLayout>
          <TextStyle>
            {t('settings.emailCustomization.GlobalCustomizationsubheading')}
          </TextStyle>
          <Button onClick={() => history.push('/customizations/global')}>
            Customize emails
          </Button>
        </FormLayout>
      ),
    },
    {
      title: 'Emails',
      children: (
        <>
          <Stack vertical spacing="loose">
            <Stack vertical>
              {emailCustomizations.map((link, index) => (
                <HorizontalBoxLink
                  key={`${link.title}-${index}`}
                  title={link.title}
                  linkTo={link.link}
                  icon={
                    <Tooltip content={link.help}>
                      <Icon source={CircleInformationMajor} color="highlight" />
                    </Tooltip>
                  }
                />
              ))}
            </Stack>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      {sections.map((section) => (
        <Layout.Section fullWidth key={section.title}>
          <Card sectioned title={section.title}>
            {section.children}
          </Card>
        </Layout.Section>
      ))}
    </>
  );
};

export default EmailSettingsButtons;
