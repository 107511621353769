import React from 'react';
import { Card, Stack, Link, TextStyle } from '@shopify/polaris';
import style from './HowToPanel.module.scss';

interface HowToPanelProps {
  title: string;
  link: string;
  linkUrl: string;
  instructions: {
    title: string;
    subtitle: string;
    linkUrl?: string;
    linkText?: string;
  }[];
}

const HowToPanel: React.FC<HowToPanelProps> = ({
  title,
  link,
  linkUrl,
  instructions,
}) => {
  return (
    <Card>
      <Card.Header
        actions={[
          {
            content: link,
            url: linkUrl,
          },
        ]}
        title={title}
      ></Card.Header>
      <Stack alignment="fill">
        <Stack.Item fill>
          <TextStyle></TextStyle>
        </Stack.Item>
        <Stack.Item>
          <Link external url={linkUrl}></Link>
        </Stack.Item>
      </Stack>
      <div className={style.instructionSection}>
        {instructions.map((instruction, i) => (
          <div key={`${instruction}-${i}`} className={style.instruction}>
            <div className={style.numberBox}>
              <TextStyle variation="strong">{i + 1}</TextStyle>
            </div>
            <p className={style.intructionTitle}>
              {instruction.title.toUpperCase()}
            </p>
            <TextStyle variation="subdued">{instruction.subtitle}</TextStyle>
            {instruction.linkText && (
              <Link external url={instruction.linkUrl}>
                {instruction.linkText}
              </Link>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default HowToPanel;
