import React from 'react';
import {
  Control,
  Controller,
  UseFormReturn,
  useFormContext,
} from 'react-hook-form';
import {
  Card,
  Heading,
  TextStyle,
  Stack,
  RadioButton,
  Link,
} from '@shopify/polaris';
import TextInput from 'components/forms/TextInput';
import { DateTime } from 'luxon';
import { ControlledDateTimeInput } from 'components/forms/DateTimeInput/ControlledDateTimeInput';
import t from 'lib/translation';
import { TokenGateFormValues } from './TokenGateForm';
import { PrintFormValues } from 'components/PrintForm/PrintForm';
import { GatingMode } from 'hooks/useCreateTokenGate';
import { SnapshotState, validTokenRedeemption } from './TokenGateFormInputs';
import { validLimitedTokenRuleValue } from '../../utils/validation';

interface GateTypeSelectionProps {
  methods: UseFormReturn<TokenGateFormValues> | UseFormReturn<PrintFormValues>;
  snapshotState: SnapshotState | undefined;
  snapshotAt: string | undefined;
  maxPerWalletPerToken: number;
  disabled: boolean;
  contractType?: 'ERC721' | 'ERC1155' | 'UNKNOWN';
}

//this is the component for selecting the gate type. It is used in both the token gate form and the print form
const GateTypeSelection: React.FC<GateTypeSelectionProps> = ({
  methods,
  snapshotState,
  snapshotAt,
  maxPerWalletPerToken,
  disabled,
  contractType,
}) => {
  const { setValue } = useFormContext<TokenGateFormValues | PrintFormValues>();
  const control = methods.control as Control<TokenGateFormValues>;
  const locale = DateTime.local().zoneName.replace(/_/g, ' ');
  const abbreviatedTimeZone = DateTime.local().toFormat('ZZZZ');

  const isSnapshotPending =
    snapshotAt !== undefined && new Date(snapshotAt) > new Date();

  const now = DateTime.now()
    .set({ second: 0, millisecond: 0 })
    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone) // Add 1 hour
    .startOf('hour') // Round down to the nearest hour
    .plus({ hours: 1, minutes: 30 }); // Add 1 hour and 30 minutes
  const nextHalfHour = now.minute % 30 === 0 ? now : now.plus({ minutes: 30 });
  const result = nextHalfHour.toISO();

  return (
    <Card
      title={
        <>
          <Heading>{t(`tokenGateForm.gateType.title`)}</Heading>
          <TextStyle variation="subdued">
            {t(`tokenGateForm.gateType.subheading`)}
            <Link url="https://help.verisart.com/en/articles/5647641-what-is-an-nft#h_231907a349">
              {t('tokenGateForm.gateType.link')}
            </Link>
            {t('tokenGateForm.helpBannerFullStop')}
          </TextStyle>
        </>
      }
    >
      <Card.Section>
        <Controller
          name="gateOwnerType"
          control={control}
          render={({ field }) => (
            <Stack vertical>
              <Stack.Item>
                <TextInput
                  name="maxPerWalletPerToken"
                  label={'Redeemable items per wallet'}
                  error={
                    methods.formState.errors?.maxPerWalletPerToken?.message
                  }
                  validator={validLimitedTokenRuleValue}
                  maxLength={32}
                />
              </Stack.Item>
              <Stack.Item key={GatingMode.NO_FUTURE_OWNERS}>
                <Stack vertical>
                  <RadioButton
                    label={t(`tokenGateForm.gateType.noFutureOwnersGateType`)}
                    value={GatingMode.NO_FUTURE_OWNERS}
                    helpText={t(
                      `tokenGateForm.gateType.noFutureOwnersGateHelper`
                    )}
                    disabled={
                      snapshotState === SnapshotState.Completed ||
                      contractType === 'ERC1155'
                    }
                    onChange={(newValue: boolean) => {
                      if (newValue) {
                        field.onChange(GatingMode.NO_FUTURE_OWNERS);
                      }
                    }}
                    checked={field.value === GatingMode.NO_FUTURE_OWNERS}
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item key={GatingMode.FUTURE_OWNERS}>
                <Stack vertical>
                  <RadioButton
                    label={t(`tokenGateForm.gateType.futureOwnersGateType`)}
                    value={GatingMode.FUTURE_OWNERS}
                    helpText={t(
                      `tokenGateForm.gateType.futureOwnersGateHelper`
                    )}
                    disabled={snapshotState === SnapshotState.Completed}
                    onChange={(newValue: boolean) => {
                      if (newValue) {
                        field.onChange(GatingMode.FUTURE_OWNERS);
                      }
                    }}
                    checked={field.value === GatingMode.FUTURE_OWNERS}
                  />
                  {field.value === GatingMode.FUTURE_OWNERS && (
                    <TextInput
                      name="maxPerToken"
                      label={'Redeemable items per token'}
                      error={methods.formState.errors?.maxPerToken?.message}
                      required={t(
                        'errors.tokenLimitedValidation.mustBeNumberOrString'
                      )}
                      helpText={t('tokenGateForm.maxPerTokenHelpText')}
                      validator={(value) => {
                        const tokenRule = validLimitedTokenRuleValue(value);

                        if (tokenRule === true) {
                          return validTokenRedeemption(
                            maxPerWalletPerToken,
                            Number(value)
                          );
                        } else {
                          return tokenRule;
                        }
                      }}
                      maxLength={32}
                    />
                  )}
                </Stack>
              </Stack.Item>
              <Stack.Item key={GatingMode.SNAPSHOT_OF_OWNER_LIST}>
                <Stack vertical>
                  <RadioButton
                    label={t(`tokenGateForm.gateType.snapshotGateType`)}
                    value={GatingMode.SNAPSHOT_OF_OWNER_LIST}
                    helpText={t(`tokenGateForm.gateType.snapshotHelper`)}
                    disabled={snapshotState === SnapshotState.Completed}
                    onChange={(newValue: boolean) => {
                      if (newValue) {
                        field.onChange(GatingMode.SNAPSHOT_OF_OWNER_LIST);
                        if (snapshotAt === undefined) {
                          setValue('snapshotAt', result, {
                            shouldDirty: true,
                          });
                        }
                        methods.trigger(['snapshotAt', 'startedAt']);
                      }
                    }}
                    checked={field.value === GatingMode.SNAPSHOT_OF_OWNER_LIST}
                  />
                  {field.value === GatingMode.SNAPSHOT_OF_OWNER_LIST && (
                    <Stack vertical spacing="extraTight">
                      <TextStyle variation="subdued">
                        {locale}, {abbreviatedTimeZone}
                      </TextStyle>
                      <ControlledDateTimeInput
                        dateLabel={t('tokenGateForm.dateTime.snapshotDate')}
                        timeLabel={t('tokenGateForm.dateTime.snapshotTime')}
                        name={'snapshotAt'}
                        validate={
                          isSnapshotPending
                            ? (value) => {
                                if (
                                  value !== undefined &&
                                  DateTime.fromISO(value as string) <=
                                    DateTime.now()
                                ) {
                                  return t('tokenGateForm.error.snapshot');
                                }
                              }
                            : undefined
                        }
                        disabled={disabled}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack.Item>
            </Stack>
          )}
        />
      </Card.Section>
    </Card>
  );
};

export default GateTypeSelection;
