import React from 'react';
import { TableRecipient } from './RoyaltySplitForm';
import { Icon, ResourceItem, Stack, TextStyle } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import bigDecimal from 'js-big-decimal';
import { EditRecipient } from './RecipientModal';
import { truncateBlockchainAddress } from '../../utils/stringFormatting';

interface RoyaltySplitListItemProps {
  selectedRecipient: TableRecipient;
  disabled: boolean;
  totalPercentage: bigDecimal;
  deleteRow?: (uuid: string) => void;
  editRow?: (editRecipient: EditRecipient | undefined) => void;
  // Divide the shares by 100
  normalise?: boolean;
}

const RoyaltySplitListItem: React.FC<RoyaltySplitListItemProps> = ({
  selectedRecipient,
  disabled,
  totalPercentage,
  deleteRow,
  editRow,
  normalise,
}) => {
  if (selectedRecipient.total) {
    return (
      <ResourceItem onClick={() => {}} id={'totalShares'}>
        <Stack distribution={'trailing'}>
          <TextStyle variation={disabled ? 'subdued' : 'strong'}>
            Total royalties
          </TextStyle>
          <TextStyle variation={disabled ? 'subdued' : 'strong'}>
            {totalPercentage.getValue()}%
          </TextStyle>
        </Stack>
      </ResourceItem>
    );
  } else {
    return (
      <ResourceItem
        persistActions
        onClick={() => {
          if (!selectedRecipient.disabled) {
            if (!disabled) {
              if (editRow) {
                editRow({
                  id: selectedRecipient.artistOrRecipientId,
                  name: selectedRecipient.name,
                  wallet: selectedRecipient.wallet,
                  share: selectedRecipient.share,
                  uuid: selectedRecipient.uuid,
                });
              }
            }
          }
        }}
        id={selectedRecipient.uuid}
      >
        <Stack>
          <Stack.Item fill>
            <TextStyle
              variation={
                disabled || selectedRecipient.disabled ? 'subdued' : 'strong'
              }
            >
              {selectedRecipient.name}
            </TextStyle>
          </Stack.Item>
          <Stack.Item>
            {disabled || selectedRecipient.disabled ? (
              <TextStyle variation={'subdued'}>
                {truncateBlockchainAddress(
                  selectedRecipient.wallet.toLowerCase()
                )}
              </TextStyle>
            ) : (
              <p>
                {truncateBlockchainAddress(
                  selectedRecipient.wallet.toLowerCase()
                )}
              </p>
            )}
          </Stack.Item>
          <Stack.Item>
            {disabled || selectedRecipient.disabled ? (
              <TextStyle variation={'subdued'}>
                {normalise === true
                  ? selectedRecipient.share
                      .divide(new bigDecimal('100'), 2)
                      .getValue()
                  : selectedRecipient.share.getValue()}
                %
              </TextStyle>
            ) : (
              <p>
                {normalise === true
                  ? selectedRecipient.share
                      .divide(new bigDecimal('100'), 2)
                      .getValue()
                  : selectedRecipient.share.getValue()}
                %
              </p>
            )}
          </Stack.Item>
          {deleteRow && (
            <Stack vertical>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (deleteRow) {
                    deleteRow(selectedRecipient.uuid);
                  }
                }}
              >
                <Stack.Item>
                  <Icon
                    source={DeleteMinor}
                    color={
                      selectedRecipient.disabled || disabled
                        ? 'subdued'
                        : 'base'
                    }
                  />
                </Stack.Item>
              </div>
            </Stack>
          )}
        </Stack>
      </ResourceItem>
    );
  }
};

export default RoyaltySplitListItem;
