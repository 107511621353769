import { useQuery } from 'react-query';
import API from 'utils/api';

export const queryKey = 'shop.emailIdentityVerificationStatus';

export default function useEmailIdentitityVerificationStatus() {
  return useQuery(queryKey, () =>
    API()
      .get('/shop/me/emailIdentityVerification')
      .then((res) => res.data)
  );
}
