import * as React from 'react';

const PolygonIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity={0.1}
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      fill="#7514D7"
    />
    <path
      d="M26.62 15.72c-.48-.278-1.104-.278-1.631 0l-3.742 2.14-2.542 1.396-3.742 2.14c-.48.278-1.103.278-1.631 0l-2.974-1.675c-.48-.28-.816-.79-.816-1.349V15.07c0-.558.288-1.07.816-1.35l2.926-1.627c.48-.28 1.103-.28 1.63 0l2.927 1.628c.48.279.816.79.816 1.349v2.14l2.542-1.443v-2.14a1.5 1.5 0 0 0-.816-1.348l-5.42-3.07c-.48-.279-1.103-.279-1.631 0l-5.517 3.07A1.5 1.5 0 0 0 7 13.628v6.186c0 .558.288 1.07.815 1.349l5.517 3.07c.48.279 1.103.279 1.63 0l3.743-2.093 2.542-1.442 3.742-2.093c.48-.28 1.103-.28 1.63 0l2.927 1.628c.48.279.815.79.815 1.348v3.303c0 .558-.288 1.07-.815 1.349l-2.926 1.674c-.48.28-1.104.28-1.631 0l-2.927-1.628c-.48-.279-.815-.79-.815-1.349v-2.14l-2.542 1.443v2.14a1.5 1.5 0 0 0 .815 1.348l5.517 3.07c.48.279 1.103.279 1.63 0l5.517-3.07c.48-.28.816-.79.816-1.349v-6.186c0-.558-.288-1.07-.816-1.349l-5.564-3.116Z"
      fill="#8247E5"
    />
  </svg>
);

export default PolygonIcon;
