import { Card, Layout, RadioButton, Stack } from '@shopify/polaris';
import { Controller, useFormContext } from 'react-hook-form';
import { triggers } from './certificateTriggers';
import React from 'react';
import { SettingsFormInputs } from './SettingsForm';

export const EmailTrigger = () => {
  const form = useFormContext<SettingsFormInputs>();

  return (
    <Layout.Section fullWidth>
      <Card title="Set claim email trigger">
        <Card.Section title="When should claim emails be sent to customers?">
          <Controller
            name="certificateTrigger"
            control={form.control}
            render={({ field: { onChange, value, name } }) => (
              <Stack vertical spacing="extraTight">
                {triggers.map((trigger) => (
                  <RadioButton
                    key={trigger.id}
                    label={trigger.label}
                    helpText={trigger.helpText}
                    checked={value === trigger.id}
                    onChange={(checked) => {
                      if (checked) {
                        onChange(trigger.id);
                      }
                    }}
                    disabled={form.formState.isSubmitting}
                  />
                ))}
              </Stack>
            )}
          />
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};
