export const throwIfLengthNotEqualToOne = (array: unknown[]) => {
  if (array.length !== 1) {
    throw new Error('Expected size to be 1, but got ' + array.length);
  }
};

const ETHEREUM_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

export const isValidEthereumAddress = (address: string): boolean =>
  address.match(ETHEREUM_ADDRESS_REGEX) !== null;
