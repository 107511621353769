import {
  Banner,
  BannerStatus,
  Card,
  DisplayText,
  Heading,
  Layout,
  Link,
  SelectGroup,
  SelectOption,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Capability, CapabilityResponse } from 'hooks/useGetCapabilities';
import t, { parameters as p } from 'lib/translation';
import {
  CONTRACTS_PATH,
  CREATORS_PATH,
  ROYALTY_SPLITS_PATH,
  PLAN_PATH,
} from '../../AppRoutes';
import { CertificateTriggerType } from './certificateTriggers';
import SelectorCard from './SelectorCard';
import CustodialWalletBalance from './CustodialWalletBalance';
import UnlockButtonAndModal from './UnlockButtonAndModal';
import useContextualSaveBar from '../../hooks/useContextualSaveBar';
import withSaveToast from '../../hooks/withSaveToast';
import MintingStatus from './MintingStatus';
import UsageChargesPanel from '../../components/UsageChargesPanel';
import { UsageCharge } from '../../hooks/useGetUsageCharges';
import { PlanType } from '../../utils/plans';
import useSubscriptionDetails from '../../hooks/useSubscriptionDetails';
import DepaySetup from './DepaySetup';
import { useOpenWindowOnVerisartDotCom } from '../../utils/mainWebsiteLogin';
import useShop from '../../hooks/useShop';
import { getPlatform, useAppBridge } from 'containers/AppBridge';
import { EmailTrigger } from './EmailTrigger';
import CustomGweiSettings from './CustomGweiSettings';

export type SettingsFormInputs = {
  certificateTrigger: CertificateTriggerType;
  defaultCreator: string;
  defaultObjectType: string;
} & {
  [key in GweiLimits]: string;
};

export type GweiLimits =
  | 'gweiValue'
  | 'contractGweiValue'
  | 'paymentSplitGweiValue';

export const CapabilityRequiredSettings: React.FC<{
  description?: string;
  image?: React.ReactNode;
  requiredCapability: Capability;
  capabilities: CapabilityResponse;
  title?: string;
}> = ({
  capabilities,
  children,
  description,
  image,
  requiredCapability,
  title,
}) => {
  if (capabilities.capabilities.includes(requiredCapability)) {
    return <>{children}</>;
  }

  return (
    <Card title={title} sectioned>
      <Layout>
        <Layout.Section fullWidth>
          <p>{description}</p>
        </Layout.Section>
        {image && (
          <Layout.Section fullWidth>
            <Stack alignment="center" vertical>
              {image}
            </Stack>
          </Layout.Section>
        )}
        <Layout.Section fullWidth>
          <Stack alignment="center" vertical>
            <UnlockButtonAndModal
              capability={requiredCapability}
              supportedCapabilities={capabilities.supportedCapabilities}
            />
          </Stack>
        </Layout.Section>
      </Layout>
    </Card>
  );
};

const SettingsForm: React.FC<{
  usageCharges: UsageCharge | null;
  capabilities: CapabilityResponse;
  creatorsList: (SelectGroup | SelectOption)[];
  objectTypeList: (SelectGroup | SelectOption)[];
  defaultValues: SettingsFormInputs;
  onSubmit: (data: SettingsFormInputs) => Promise<void>;
  onComplete: () => Promise<void> | void;
  needsDepaySetup: boolean | undefined;
}> = ({
  usageCharges,
  capabilities,
  creatorsList,
  objectTypeList,
  defaultValues,
  onSubmit,
  onComplete,
  needsDepaySetup,
}) => {
  const form = useForm<SettingsFormInputs>({
    defaultValues,
  });

  const { data: shopData } = useShop();

  const { data } = useSubscriptionDetails();
  let currentPlanType;

  if (data) {
    const planExpired = data.subscriptionDetails.planType === PlanType.FREE;
    currentPlanType = planExpired
      ? undefined
      : data.subscriptionDetails.planType;
  }

  // Form submission

  const { handleSubmit } = form;
  const app = useAppBridge();
  const onSave = useCallback(
    async () =>
      withSaveToast(async () => {
        await handleSubmit(onSubmit)();

        if (!form.formState.isValid) {
          return 'Sorry, there was an error - please check your inputs';
        }

        return '';
      }, app.showToast),
    [app, handleSubmit, onSubmit, form.formState.isValid]
  );
  useContextualSaveBar(form, onSave);

  // Form reset

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = form;

  const { getValues } = form;
  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
      await onComplete();
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues, onComplete]);

  // Form navigation

  const history = useHistory();
  const location = useLocation();

  const [depayBanner, setDepayBanner] = useState<
    { title: string; message: string; status: BannerStatus } | undefined
  >(undefined);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    if (search.has('depay')) {
      if (search.get('depay') === 'success') {
        setDepayBanner({
          title: t('settings.depay.success.bannerTitle'),
          message: t('settings.depay.success.bannerDescription'),
          status: 'success',
        });
      } else if (search.get('depay') === 'wrong_shop') {
        setDepayBanner({
          title: t('settings.depay.wrong_shop.bannerTitle'),
          message: t('settings.depay.wrong_shop.bannerDescription'),
          status: 'critical',
        });
      }
      search.delete('depay');
      location.search = search.toString();
      history.replace(location);
    }
  }, [history, location, location.search]);

  const goToCreatorsSettings = () => history.push(CREATORS_PATH);
  const goToContractsSettings = () => history.push(CONTRACTS_PATH);
  const goToPlanPage = () => history.push(PLAN_PATH);
  const goToRoyaltySplitsSettings = () => history.push(ROYALTY_SPLITS_PATH);

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const {
    formState: { isSubmitting },
  } = form;

  return (
    <FormProvider {...form}>
      <Layout>
        <Layout.Section fullWidth>
          <div
            style={{
              paddingBottom: 'var(--p-space-5, 1.25rem)',
              paddingRight: 'var(--p-space-4, 1rem)',
            }}
          >
            <Heading>
              <DisplayText size="small">
                {t('settings.accountSettings.title')}
              </DisplayText>
            </Heading>
          </div>
          <Stack vertical>
            {depayBanner && (
              <Banner
                onDismiss={() => setDepayBanner(undefined)}
                title={depayBanner.title}
                status={depayBanner.status}
              >
                {depayBanner.message}
              </Banner>
            )}
            <Controller
              name="defaultCreator"
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SelectorCard
                  title={t('settings.creators.title')}
                  description={
                    <TextStyle>{t('settings.creators.description')}</TextStyle>
                  }
                  actions={{
                    content: t('settings.creators.manage'),
                    external: true,
                    onAction: goToCreatorsSettings,
                  }}
                  optionTitle={t('settings.creators.defaultCreatorLabel')}
                  optionValue={value}
                  options={creatorsList}
                  onOptionChanged={onChange}
                  disabled={isSubmitting}
                />
              )}
            />
          </Stack>
        </Layout.Section>
        {currentPlanType && (
          <Layout.Section fullWidth>
            <SelectorCard
              title={t('settings.plans.title')}
              description={
                <Stack vertical>
                  <TextStyle>
                    {' '}
                    {p(
                      'settings.plans.description',
                      t(`plans.${currentPlanType}`)
                    )}
                  </TextStyle>
                  <Stack spacing={'extraTight'}>
                    <TextStyle>
                      {shopData?.shop?.verisartEmail
                        ? p(
                            'settings.page.plan.cognitoAccountText',
                            shopData.shop.verisartEmail
                          )
                        : t('settings.page.plan.shopifyAccountText')}
                    </TextStyle>
                    <Link
                      onClick={() => openWindowOnVerisartDotCom('/dashboard')}
                      external
                    >
                      {t('settings.page.plan.linkText')}
                    </Link>
                  </Stack>
                </Stack>
              }
              actions={{
                content: t('settings.plans.manage'),
                onAction: goToPlanPage,
              }}
              disabled={isSubmitting}
            />
          </Layout.Section>
        )}
        <EmailTrigger />
        <Layout.Section fullWidth>
          <Controller
            name="defaultObjectType"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <SelectorCard
                title={t('settings.certificates.defaultObjectType')}
                description={
                  <TextStyle>
                    {' '}
                    {t('settings.certificates.defaultObjectTypeDescription')}
                  </TextStyle>
                }
                optionTitle={t('settings.certificates.defaultObjectType')}
                optionValue={value}
                options={objectTypeList}
                onOptionChanged={onChange}
                disabled={isSubmitting}
              />
            )}
          />
        </Layout.Section>
        <Layout.Section fullWidth>
          <div
            style={{
              paddingTop: 'var(--p-space-5, 1.25rem)',
              paddingRight: 'var(--p-space-4, 1rem)',
            }}
          >
            <Heading>
              <DisplayText size="small">
                {t('settings.minting.title')}
              </DisplayText>
            </Heading>
          </div>
        </Layout.Section>
        {getPlatform() !== 'Woo' && (
          <Layout.Section fullWidth>
            <MintingStatus />
          </Layout.Section>
        )}
        {usageCharges && (
          <Layout.Section fullWidth>
            <UsageChargesPanel usageCharges={usageCharges} />
          </Layout.Section>
        )}
        <Layout.Section fullWidth>
          <CustodialWalletBalance />
        </Layout.Section>
        <Layout.Section fullWidth>
          <CustomGweiSettings />
        </Layout.Section>
        <Layout.Section fullWidth>
          <SelectorCard
            title={t('settings.contracts.title')}
            description={
              <TextStyle> {t('settings.contracts.description')}</TextStyle>
            }
            actions={{
              content: t('settings.contracts.manage'),
              onAction: goToContractsSettings,
            }}
            disabled={isSubmitting}
          />
        </Layout.Section>

        <Layout.Section fullWidth>
          <SelectorCard
            title={t('settings.royaltySplits.title')}
            description={
              <TextStyle>{t('settings.royaltySplits.description')}</TextStyle>
            }
            actions={{
              content: t('settings.royaltySplits.manage'),
              onAction: goToRoyaltySplitsSettings,
            }}
            disabled={isSubmitting}
          />
        </Layout.Section>
        {needsDepaySetup !== undefined && (
          <DepaySetup needsSetup={needsDepaySetup} />
        )}
      </Layout>
    </FormProvider>
  );
};

export default SettingsForm;
