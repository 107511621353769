import React from 'react';
import {
  Card,
  Stack,
  RadioButton,
  Link,
  Select,
  Button,
  Checkbox,
} from '@shopify/polaris';
import {
  Control,
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { TraitFilterInput } from './TokenGateFormInputs';
import { filterTypes, FilterTypes, traitRuleTypes } from './types';
import t from 'lib/translation';
import { ContractPreview } from 'hooks/useGetContractMetadata';
import { TokenGateFormValues } from './TokenGateForm';
import { PrintFormValues } from 'components/PrintForm/PrintForm';

interface TokenGateFiltersProps {
  filters: FilterTypes;
  traits:
    | UseFieldArrayReturn<TokenGateFormValues, 'traits', 'id'>
    | UseFieldArrayReturn<PrintFormValues, 'traits', 'id'>;
  contractPreview?: ContractPreview;
  methods: UseFormReturn<TokenGateFormValues> | UseFormReturn<PrintFormValues>;
}

export const emptyTrait = { key: '', value: '' };

//created a new component for the traits section in the tokent gate because we are now using it in the print form as well
const TokenGateFilters: React.FC<TokenGateFiltersProps> = ({
  filters,
  traits,
  contractPreview,
  methods,
}) => {
  return (
    <Card title={t('tokenGateForm.filters.title')}>
      <Card.Section>
        <Stack vertical>
          <Stack.Item>
            <Stack vertical spacing="tight">
              {filterTypes.map((filterType) => (
                <Stack.Item key={filterType}>
                  <Controller
                    name="filters"
                    control={methods.control as Control<TokenGateFormValues>}
                    render={({ field }) => (
                      <RadioButton
                        label={t(
                          `tokenGateForm.filters.${filterType}FilterTypeLabel`
                        )}
                        value={filterType}
                        onChange={(newValue) => {
                          if (newValue) {
                            field.onChange(filterType);
                            if (
                              filterType === 'traits' &&
                              traits.fields.length === 0
                            ) {
                              traits.append(emptyTrait);
                            }
                          }
                        }}
                        onBlur={() => field.onBlur()}
                        checked={field.value === filterType}
                      />
                    )}
                  />
                </Stack.Item>
              ))}
            </Stack>
          </Stack.Item>
          {filters === 'traits' && (
            <Stack.Item>
              <Stack alignment="fill">
                <Stack.Item fill>
                  {/* leaving this empty so I can align the other stack item to the end */}
                </Stack.Item>
                <Stack.Item>
                  <Link
                    url="https://help.verisart.com/en/articles/6176930-configuring-nfts-in-shopify#h_cdde96d8fa"
                    removeUnderline
                  >
                    {t('productsSection.labelActionContent')}
                  </Link>
                </Stack.Item>
              </Stack>
              <Stack vertical>
                <Stack.Item>
                  <Controller
                    name="matchAll"
                    control={methods.control as Control<TokenGateFormValues>}
                    render={({ field }) => (
                      <Checkbox
                        label="Match all traits"
                        helpText={t(`tokenGateForm.filters.traitRuleHint`)}
                        checked={field.value ?? false}
                        onChange={(checked) => field.onChange(checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Controller
                    name="traitRule"
                    control={methods.control as Control<TokenGateFormValues>} // Updated line
                    render={({ field }) => (
                      <Select
                        label={null}
                        options={traitRuleTypes.map((value) => ({
                          label: t(
                            `tokenGateForm.filters.${value}TraitRuleOption`
                          ),
                          value,
                        }))}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack vertical>
                    {traits.fields.map((field, index) => (
                      <Stack.Item key={field.id}>
                        <TraitFilterInput
                          name={`traits.${index}`}
                          onDelete={
                            index === 0 && traits.fields.length === 1
                              ? undefined
                              : () => traits.remove(index)
                          }
                        />
                      </Stack.Item>
                    ))}
                    <Stack.Item>
                      <Button plain onClick={() => traits.append(emptyTrait)}>
                        {t('tokenGateForm.filters.addTrait')}
                      </Button>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default TokenGateFilters;
