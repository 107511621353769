import { Spinner } from '@shopify/polaris';
import Footer from 'components/Footer';
import useGetPrint from 'hooks/useGetPrint';
import React from 'react';
import { useParams } from 'react-router';
import styles from './EditPrintPage.module.scss';
import PrintFormPageWrapper from '../../components/PrintForm/PrintFormPageWrapper';

const EditPrintFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetPrint(id);

  return (
    <>
      {isLoading ? (
        <div className={`${styles.center}`}>
          <Spinner accessibilityLabel="Spinner" size="large" />
        </div>
      ) : (
        <PrintFormPageWrapper formType="Edit" data={data} id={id} />
      )}
      <Footer />
    </>
  );
};

export default EditPrintFormPage;
