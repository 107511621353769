import React from 'react';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { appPathForSearchParams } from './appPathForSearchParams';
import LoadingPage from 'auth/LoadingPage';
import EditSelectorModal from 'components/EditSelectorModal';
import useProductFormValues from 'hooks/useProductFormValues';

export type AdminLinkType = 'products' | 'variants';

const AdminLink: React.FC = () => {
  const history = useHistory();
  const { type } = useParams<{ type: AdminLinkType }>();
  const location = useLocation();
  const search = decodeURIComponent(location.search);
  const params = new URLSearchParams(search);
  const isBulkEdit = params.has('ids[]') && params.getAll('ids[]').length > 1;

  const productId = findSingleProductId(params);
  const { productFormValues, isLoading } = useProductFormValues(
    productId ?? '',
    false,
    !isBulkEdit && !!productId
  );

  const redirectToForm = (
    defaultType: 'nft' | 'certificate' | 'new_nft' | 'tokengate'
  ) => {
    history.replace(appPathForSearchParams({ defaultType, type, search }), {
      replace: true,
    });
  };

  if (isLoading || !productFormValues) {
    return <LoadingPage />;
  } else if (productFormValues?.data?.triggerLevel === 'CERTIFICATE') {
    // Handle this as though the user selected certificate from the edit selector modal
    redirectToForm('certificate');
    return <LoadingPage />;
  } else if (productFormValues?.data?.triggerLevel === 'NFT') {
    // Handle this as though the user selected nft from the edit selector modal
    redirectToForm('nft');
    return <LoadingPage />;
  } else {
    return (
      <EditSelectorModal
        cancelable
        open
        onCancel={() => {
          history.go(-1);
        }}
        onClose={() => {
          history.replace('/', { replace: true });
        }}
        onSelect={(defaultType) => {
          redirectToForm(defaultType);
        }}
      />
    );
  }
};

const findSingleProductId = (params: URLSearchParams) => {
  if (params.has('ids[]')) {
    return params.getAll('ids[]')[0];
  } else if (params.has('id')) {
    return params.get('id') ?? undefined;
  }
};

export default AdminLink;
