import React, { useCallback, useState } from 'react';
import { Modal } from '@shopify/polaris';
import t from '../../lib/translation';
import TokenGateForm, { TokenGateFormProps } from './TokenGateForm';

type TokenGateFormDialogType = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
} & Omit<TokenGateFormProps, 'setSaveCallback'>;

const TokenGateFormDialog: React.FC<TokenGateFormDialogType> = (props) => {
  const { showModal, setShowModal } = props;
  const [save, setSave] = useState<(() => void) | undefined>();

  // This is just some glue required due to the way ContractsForm works.
  // It is responsible for doing all the saving, but in this case, we're the one who renders the save button.
  // This state is required so RoyaltySplitForm can tell us what to call on save.
  const setSaveCallback = useCallback((callback: () => void) => {
    setSave(() => callback); // Storing a callback in state requires a double function call - see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  }, []);

  return (
    <Modal
      large
      open={showModal}
      onClose={() => setShowModal(false)}
      title={`${props.formType} Tokengate`}
      primaryAction={{
        content: t('dropForm.toast.save'),
        onAction: save,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setShowModal(false),
        },
      ]}
    >
      <Modal.Section>
        <TokenGateForm {...props} setSaveCallback={setSaveCallback} />
      </Modal.Section>
    </Modal>
  );
};

export default TokenGateFormDialog;
