import {
  FormLayout,
  RadioButton,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import t, { parameters as p } from 'lib/translation';
import ImageFileUpload, { ImageFileUploadController } from './ImageFileUpload';
import useContextualSaveBar from '../../hooks/useContextualSaveBar';
import { BackgroundType, backgroundTypeForValue } from './customization';
import withSaveToast from '../../hooks/withSaveToast';
import utilities from './utilities.module.css';
import { useAppBridge } from 'containers/AppBridge';
import TextInput from 'components/forms/TextInput';

const subheadingMaxLength = 40;

const backgroundChoices: {
  label: string;
  value: BackgroundType;
}[] = [
  {
    label: t('settings.certificates.backgroundDefaultLabel'),
    value: 'default',
  },
  { label: t('settings.certificates.backgroundImageLabel'), value: 'image' },
];

function isValidUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch {
    return false;
  }
}

type CertificateCustomizationSettingsFormInput = {
  background: {
    type: BackgroundType;
    value: string;
  };
  logo: string;
  subheading: string;
  templateName: string;
  link: string;
};

type CertificateCustomizationSettingsFormProps = {
  defaultValues: CertificateCustomizationSettingsFormInput;
  onSubmit: (
    data: CertificateCustomizationSettingsFormInput
  ) => Promise<void> | void;
  onComplete?: () => Promise<void> | void;
  templateId: string;
};

const CertificateCustomizationSettingsForm: React.FC<
  CertificateCustomizationSettingsFormProps
> = ({ defaultValues, onComplete, onSubmit, templateId }) => {
  const [controller] = useState(new ImageFileUploadController());
  const form = useForm({ defaultValues });
  // From submission

  const { handleSubmit } = form;
  const app = useAppBridge();
  const onSave = useCallback(
    async () =>
      withSaveToast(
        async () => {
          await controller.uploadAllFiles();
          await handleSubmit(onSubmit)();
          if (Object.keys(form.formState.errors).length > 0) {
            return t('settings.certificates.customizationSaveFailed');
          }
          return '';
        },
        app.showToast,
        {
          successMessage: t('settings.certificates.customizationSaved'),
        }
      ),
    [app.showToast, controller, handleSubmit, onSubmit, form.formState.errors]
  );

  useContextualSaveBar(form, onSave);

  // Form reset
  const {
    formState: { isSubmitSuccessful },
    reset,
    getValues,
  } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
      await onComplete?.();
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues, onComplete]);

  return (
    <FormProvider {...form}>
      <FormLayout>
        <Controller
          name="templateName"
          control={form.control}
          rules={{
            required: 'Template name is required',
            validate: () => {
              // Check that at least one field is filled out
              // This is a bit hacky doing this on the title but doing form validation in react-hook-form is a bit
              // tricky.
              if (
                !form.getValues('logo') &&
                !form.getValues('background')?.value &&
                !form.getValues('subheading')
              ) {
                return 'At least one customization field must be filled out';
              }
            },
          }}
          render={({
            field: { onChange, value },
            formState: { isSubmitting },
          }) => (
            <TextField
              label={t('settings.certificates.templateNameLabel')}
              value={value}
              onChange={onChange}
              autoComplete="off"
              disabled={isSubmitting || templateId === ''}
              error={form.formState.errors.templateName?.message}
            />
          )}
        />
        <Controller
          name="logo"
          control={form.control}
          render={({
            field: { name, onChange, value },
            formState: { isDirty, isSubmitting },
          }) => (
            <ImageFileUpload
              controller={controller}
              name={name}
              disabled={isSubmitting || templateId === ''}
              label={t('settings.certificates.logo')}
              hint={t('settings.certificates.logoHint')}
              initialPreview={value}
              onFileSelected={onChange}
              onFileUploaded={onChange}
              reset={!isDirty}
              secondaryActions={
                value
                  ? [
                      {
                        id: 'reset',
                        content: t('settings.certificates.logoClear'),
                        onClick({ clear }) {
                          onChange('');
                          form.setValue('link', '');
                          clear();
                        },
                      },
                    ]
                  : undefined
              }
            />
          )}
        />

        <TextInput
          name="link"
          label={t('settings.certificates.link')}
          disabled={templateId === ''}
          validator={(value) => {
            const logo = form.getValues('logo');

            if (value === '') {
              return true;
            }

            if (!logo) {
              return t('errors.customizationFormValidation.noLogo');
            }

            if (!isValidUrl(value)) {
              return t('errors.customizationFormValidation.urlFormat');
            }
            return true;
          }}
        />

        <Controller
          name="background"
          control={form.control}
          render={({
            field: {
              name,
              value: { type, value },
              onChange,
            },
            formState: { isDirty, isSubmitting },
          }) => {
            const valueBackgroundType = backgroundTypeForValue(value);
            const imageValue =
              valueBackgroundType === 'image' ? value : undefined;

            return (
              <div className={utilities.ExtraTightLayout}>
                <TextStyle>{t('settings.certificates.background')}</TextStyle>
                <Stack>
                  {backgroundChoices.map((choice) => (
                    <RadioButton
                      key={choice.value}
                      label={choice.label}
                      checked={choice.value === type}
                      onChange={() =>
                        onChange({
                          type: choice.value,
                          value: choice.value === 'default' ? '' : value,
                        })
                      }
                      disabled={isSubmitting || templateId === ''}
                    />
                  ))}
                </Stack>
                {type === 'image' && (
                  <ImageFileUpload
                    name={name}
                    controller={controller}
                    disabled={isSubmitting || templateId === ''}
                    initialPreview={imageValue}
                    onFileSelected={(url) => onChange({ type, value: url })}
                    onFileUploaded={(url) => {
                      // The `if` is a bug fix: If you choose a new image but then change your mind and choose `default`,
                      // we still dumbly upload the image and then fire this event. We need to go back to the
                      // form to check the background status to avoid this bug.
                      if (form.getValues('background').type === 'image') {
                        onChange({ type, value: url });
                      }
                    }}
                    reset={!isDirty}
                  />
                )}
              </div>
            );
          }}
        />
        <Controller
          name="subheading"
          control={form.control}
          rules={{
            maxLength: subheadingMaxLength,
          }}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState: { isSubmitting },
          }) => {
            const helpTextVariation = isSubmitting
              ? 'subdued'
              : subheadingMaxLength - value.length < 0 ||
                error?.type === 'maxLength'
              ? 'negative'
              : 'positive';
            return (
              <TextField
                label={t('settings.certificates.subheading')}
                autoComplete="off"
                value={value}
                onChange={onChange}
                disabled={isSubmitting || templateId === ''}
                helpText={
                  <TextStyle variation={helpTextVariation}>
                    {p(
                      'settings.certificates.subheadingLength',
                      Math.max(subheadingMaxLength - value.length, 0),
                      subheadingMaxLength
                    )}
                  </TextStyle>
                }
              />
            );
          }}
        />
      </FormLayout>
    </FormProvider>
  );
};

export default CertificateCustomizationSettingsForm;
