import { useRouteMatch } from 'react-router-dom';

const useParentPath = () => {
  const match = useRouteMatch();
  const parts = match.path.split('/');
  parts.pop();
  return parts.length === 0 ? '/' : `${parts.join('/')}`;
};

export default useParentPath;
