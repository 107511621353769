import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { PatchTokenGateProps } from './usePatchTokenGate';

export type PatchPrintDropProps = {
  printId: string;
  gate: PatchTokenGateProps;
  price: number | null;
  artistId: string | null | undefined;
  status: 'ACTIVE' | 'DRAFT' | null;
};

export default function usePatchPrintDrop() {
  const queryCache = useQueryClient();

  return useMutation(
    async ({ printId, gate, price, artistId, status }: PatchPrintDropProps) => {
      const response = await API().patch(`/shop/print/${printId}`, {
        gate,
        price,
        artistId,
        status,
      });
      return response.data;
    },
    {
      onSuccess: (_data, variables) => {
        queryCache.invalidateQueries('prints');
        queryCache.invalidateQueries(['print', variables.printId]);
        queryCache.invalidateQueries([
          'tokenGates',
          variables.gate.tokenGateId,
        ]);
      },
    }
  );
}
