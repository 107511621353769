import React from 'react';
import {
  Card,
  DisableableAction,
  Select,
  SelectGroup,
  SelectOption,
  SkeletonBodyText,
} from '@shopify/polaris';

interface SelectorCardProps {
  actions?: DisableableAction | DisableableAction[];
  title: string;
  description: React.ReactNode;
  disabled: boolean;
  optionTitle?: string;
  optionValue?: string;
  options?: (SelectOption | SelectGroup)[];
  onOptionChanged?: (value: string) => void;
}

const SelectorCard: React.FC<SelectorCardProps> = ({
  title,
  description,
  disabled,
  actions = [],
  optionTitle,
  optionValue,
  options,
  onOptionChanged,
}) => {
  const optionsHidden = optionTitle === undefined && options === undefined;

  const disabledActions = (Array.isArray(actions) ? actions : [actions]).map(
    (action) => ({
      ...action,
      disabled: action.disabled === undefined ? disabled : action.disabled,
    })
  );

  return (
    <Card title={title} actions={disabledActions}>
      <Card.Section>{description}</Card.Section>
      {!optionsHidden && (
        <Card.Section>
          {options ? (
            <Select
              label={optionTitle}
              options={options}
              onChange={onOptionChanged}
              value={optionValue}
              disabled={disabled}
            />
          ) : (
            <SkeletonBodyText lines={2} />
          )}
        </Card.Section>
      )}
    </Card>
  );
};

export default SelectorCard;
