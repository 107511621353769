import { useQuery } from 'react-query';
import API from 'utils/api';
import { Print } from './useCreatePrintProduct';
import { TokenGate } from './useGetTokenGate';

export type PrintDrop = {
  id: string;
  shopId: string;
  productId: string;
  productTitle?: string;
  productDescription?: string;
  tokenGate: TokenGate;
  updatedAt: string;
  artistId: string;
  status: 'ACTIVE' | 'DRAFT';
};

export default function useGetPrint(id: string) {
  return useQuery<Print>(['print', id], async () => {
    const response = await API().get(`shop/print/${id}`);

    return response.data;
  });
}
