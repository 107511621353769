import { UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { useContextualSaveBar } from 'components/ContextualSaveBar/ContextualSaveBar';

const useContextualSaveBarWithForm: <FieldValues>(
  form: UseFormReturn<FieldValues>,
  onSave: () => Promise<void> | void,
  disabled?: boolean
) => void = (form, onSave, disabled) => {
  const { trigger, set, subscribe } = useContextualSaveBar();

  const { reset, formState } = form;

  const { isDirty, errors, submitCount } = formState;

  useEffect(() => {
    if (disabled) return;
    if (isDirty || (submitCount > 0 && Object.keys(errors).length > 0)) {
      trigger('show');
    } else {
      trigger('hide');
    }
  }, [submitCount, errors, isDirty, trigger, disabled]);

  useEffect(() => {
    const unsubscribeBag = new Set<() => void>();

    unsubscribeBag.add(
      subscribe('discard', async () => {
        set({ save: 'loading', discard: 'disabled' });
        reset();
        set({ save: 'default', discard: 'default' });
      })
    );

    unsubscribeBag.add(
      subscribe('save', async () => {
        set({ save: 'loading', discard: 'disabled' });
        try {
          await onSave();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          set({ save: 'default', discard: 'default' });
        } finally {
          set({ save: 'default', discard: 'default' });
        }
      })
    );

    return () => {
      unsubscribeBag.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [subscribe, set, reset, onSave]);

  // Return a hook which runs at unmount to hide the save bar
  useEffect(() => {
    return () => trigger('hide');
  }, [trigger]);
};

/**
 * Wrapper around `useContextualSaveBar()` which allows optionally allowing the
 * save function to be handled externally instead of using the contextual bar.
 *
 * If `saveOverride` is set then the contextual save bar is disabled and it is called
 * with the `onSave` function passed in to allow the parent component to handle saves.
 */
export const useContextualSaveBarOrExternal: <FieldValues>(
  form: UseFormReturn<FieldValues>,
  onSave: () => Promise<void> | void,
  saveOverride?: (saveCallback: () => void) => void
) => void = (form, onSave, saveOverride) => {
  useEffect(() => {
    if (saveOverride) {
      saveOverride(onSave);
    }
  }, [saveOverride, onSave]);

  return useContextualSaveBarWithForm(form, onSave, !!saveOverride);
};

export default useContextualSaveBarWithForm;
