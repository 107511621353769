import { useQuery } from 'react-query';
import API from 'utils/api';
import { ContractListItemProps } from 'components/ContractListItem';

export default function useGetContract(contractId: string) {
  return useQuery<ContractListItemProps>(['contract', contractId], async () => {
    const response = await API().get(`nft/contract/${contractId}`);
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  });
}
