import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './BoxLink.module.scss';
import {
  Heading,
  Icon,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Stack,
} from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';

interface HorizontalBoxLinkProps {
  title: string;
  primary?: boolean;
  loading?: boolean;
  subtitle?: string;
  icon: React.ReactNode;
  linkTo: string;
}

export const HorizontalBoxLink: React.FC<HorizontalBoxLinkProps> = ({
  title,
  icon,
  linkTo,
  subtitle,
  primary,
  loading,
}) => {
  const history = useHistory();

  return loading ? (
    <div className={`${styles['box-link']} ${styles['box-link-surface']}`}>
      <Stack alignment={'center'}>
        <Stack.Item fill>
          <Stack>
            <SkeletonThumbnail />
            <Stack spacing={'extraTight'}>
              <SkeletonDisplayText />
              <SkeletonBodyText />
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack alignment={'center'}>
          <Icon source={ChevronRightMinor} />
        </Stack>
      </Stack>
    </div>
  ) : (
    <div
      onClick={() => history.push(linkTo)}
      className={`${styles['box-link']} ${
        primary ? styles['box-link-primary'] : styles['box-link-surface']
      }`}
    >
      <div className={styles['box-link-contents']}>
        <Stack.Item fill>
          <Stack>
            <Stack spacing={'extraTight'}>
              <Heading>{title}</Heading>
              {subtitle && <p>{subtitle}</p>}
            </Stack>
            {icon}
          </Stack>
        </Stack.Item>
        <Stack alignment={'center'}>
          <Icon source={ChevronRightMinor} />
        </Stack>
      </div>
    </div>
  );
};
