import {
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import React, { Dispatch, SetStateAction } from 'react';
import { Blockchain } from 'types/Blockchain';
import useGetContractMetadata from 'hooks/useGetContractMetadata';

const SmartContractPreview = ({
  contractAddress,
  blockchain,
  missingBlockchainMessage,
  errorMessage,
  setContractType,
}: {
  contractAddress?: string;
  blockchain?: Blockchain;
  missingBlockchainMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  setContractType: Dispatch<
    SetStateAction<'ERC721' | 'ERC1155' | 'UNKNOWN' | undefined>
  >;
}) => {
  const { data, isError, isLoading } = useGetContractMetadata(
    contractAddress,
    blockchain
  );

  if (isError) {
    return <>{errorMessage}</>;
  }

  if (data?.type === 'UNKNOWN') {
    return <>{errorMessage}</>;
  }

  if (!blockchain) {
    return <>{missingBlockchainMessage}</>;
  }

  if (isLoading) {
    return (
      <Stack>
        <Stack.Item>
          <SkeletonThumbnail />
        </Stack.Item>
        <Stack.Item fill>
          <Stack vertical>
            <SkeletonBodyText lines={3} />
          </Stack>
        </Stack.Item>
      </Stack>
    );
  }

  if (data) {
    setContractType(data?.type);
    return (
      <Stack>
        <Stack.Item>
          {data.openSeaThumbnail ? (
            <img
              className="Polaris-SkeletonThumbnail Polaris-SkeletonThumbnail--sizeMedium"
              src={data.openSeaThumbnail ?? undefined}
              alt={data.name ?? ''}
            />
          ) : (
            <SkeletonThumbnail />
          )}
        </Stack.Item>
        <Stack.Item fill>
          <Stack vertical alignment="leading" spacing="none">
            <TextStyle variation="strong">{data.name}</TextStyle>
            <TextStyle variation="subdued">{data.symbol}</TextStyle>
            <TextStyle variation="subdued">{data.type}</TextStyle>
          </Stack>
        </Stack.Item>
      </Stack>
    );
  }

  return null;
};

export default SmartContractPreview;
