import { ReactNode, useState } from 'react';
import { Redirect, Toast as ToastAppBridge } from '@shopify/app-bridge/actions';
import { Toast } from '@shopify/polaris';
import React from 'react';
import {
  AppBridgeContext,
  RedirectAdminAction,
  RedirectAdminResource,
  RedirectPassThroughAction,
} from 'containers/AppBridge';
import { useHistory } from 'react-router';
import { useAppBridge as useShopifyAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken as getShopifySessionToken } from '@shopify/app-bridge-utils';

const ShopifyDriver: React.FC<{
  apiKey: string;
  host: string;
  children: ReactNode;
}> = ({ apiKey, host, children }) => {
  const shopifyAppBridge = useShopifyAppBridge();

  const history = useHistory();
  const redirectObject = Redirect.create(shopifyAppBridge);

  const redirect = (
    action: RedirectPassThroughAction | RedirectAdminAction,
    resource: string | RedirectAdminResource
  ) => {
    if (action === 'App') {
      if (typeof resource !== 'string') {
        throw new Error(
          'Resource must be a string for an App or Remote redirect'
        );
      }
      history.push(resource);
    } else if (action === 'Remote') {
      if (typeof resource !== 'string') {
        throw new Error(
          'Resource must be a string for an App or Remote redirect'
        );
      }
      redirectObject.dispatch(Redirect.Action.REMOTE, resource);
    } else {
      if (typeof resource === 'string') {
        throw new Error(`Resource must be of type RedirectAdminResource`);
      }
      switch (resource.type) {
        case 'checkout':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/checkout'
          );
          break;
        case 'nft_application':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            `/nft-application?apiKey=${encodeURIComponent(
              resource.apiKey
            )}&callback=${encodeURIComponent(resource.callbackUrl)}`
          );
          break;
        case 'product':
          redirectObject.dispatch(Redirect.Action.ADMIN_SECTION, {
            name: Redirect.ResourceType.Product,
            newContext: resource.newContext,
            resource: {
              id: resource.id,
            },
          });
          break;
        case 'subscription':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/billing/subscriptions'
          );
          break;
        case 'shipping':
          redirectObject.dispatch(
            Redirect.Action.ADMIN_PATH,
            '/settings/shipping'
          );
          break;
        default:
          throw new Error('Not implemented yet');
      }
    }
  };

  const showToast = (options: ToastAppBridge.Options) => {
    setToast(options);
  };

  const getSessionToken = async () => {
    return getShopifySessionToken(shopifyAppBridge);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const [toast, setToast] = useState<ToastAppBridge.Options | undefined>(
    undefined
  );

  return (
    <AppBridgeContext.Provider
      value={{
        host: host,
        apiKey: apiKey,
        redirect,
        showToast,
        getSessionToken,
        platform: 'Shopify',
        openInNewTab, // Add this line
      }}
    >
      {toast && (
        <Toast
          content={toast.message}
          duration={toast.duration}
          onDismiss={() => setToast(undefined)}
        ></Toast>
      )}
      {children}
    </AppBridgeContext.Provider>
  );
};

export default ShopifyDriver;
