import useProductFormValues, {
  ManagedArtists,
  MetafieldsType,
  MetafieldToArrayValue,
  MetafieldValue,
  ProductFormValues,
  SubmittableMetaFields,
  TriggerLevel,
} from 'hooks/useProductFormValues';
import t from 'lib/translation';
import React from 'react';
import ProductFormShow, {
  EditionType,
  MetafieldFormValues,
} from './ProductFormShow';
import VariantFormShow from './VariantFormShow';
import ProductFormLoading from './ProductFormLoading';
import { retrieveErrorMessage } from 'utils/validationMessaging';
import { AxiosResponse } from 'axios';
import { Toast } from '@shopify/polaris';
import { RelationshipDataDetails } from 'types/Artist';
import useNeedPermissionRefresh from 'hooks/useNeedPermissionRefresh';
import { useHistory, useParams } from 'react-router-dom';
import useTurnOffProducts from 'hooks/useTurnOffProducts';
import { useQueryClient } from 'react-query';

interface MetafieldFormLoaderProps {
  isVariant: boolean;
}

enum EditionTypeValidation {
  single_edition = 'single_edition',
  single_ap = 'single_ap',
  multiple_unnumbered_edition = 'multiple_unnumbered_edition',
  multiple_unnumbered_ap = 'multiple_unnumbered_ap',
  multiple_auto = 'multiple_auto',
}

export function isEditionType(val: string): boolean {
  return val in EditionTypeValidation;
}

export const NFTMetafields: Array<MetafieldsType> = [
  'blockchain',
  'contract_address',
  'nft_royalty_address',
  'traits',
];

export const COAMetafields: Array<MetafieldsType> = [
  'object_type',
  'width',
  'height',
  'depth',
  'dimension_unit',
  'production_location',
  'current_location',
  'inventory_number',
];

export const EditionMetafields: Array<MetafieldsType> = [
  'edition_type',
  'edition_note',
  'edition_number',
  'edition_volume',
  'auto_number_editions',
  'ap_number',
  'ap_volume',
];

export const EditionMetafieldsPerEditionType: {
  [editionMetafield in EditionType]: Array<MetafieldsType>;
} = {
  single_edition: [
    'edition_number',
    'edition_volume',
    'ap_volume',
    'edition_note',
    'edition_type',
  ],
  single_ap: [
    'ap_number',
    'ap_volume',
    'edition_volume',
    'edition_note',
    'edition_type',
  ],
  multiple_unnumbered_edition: [
    'edition_volume',
    'edition_note',
    'edition_type',
  ],
  multiple_unnumbered_ap: ['ap_volume', 'edition_note', 'edition_type'],
  multiple_auto: [
    'auto_number_editions',
    'edition_volume',
    'edition_note',
    'edition_type',
  ],
};

export const SuccessfulToast = (
  showing: boolean,
  setIsSuccessfulToastShowing: (state: boolean) => void
) =>
  showing ? (
    <Toast
      content="Product updated"
      onDismiss={() => setIsSuccessfulToastShowing(false)}
    />
  ) : null;

export const FailedToast = (
  showing: boolean,
  setIsFailedToastShowing: (state: boolean) => void
) =>
  showing ? (
    <Toast
      content="Product not updated"
      onDismiss={() => setIsFailedToastShowing(false)}
    />
  ) : null;

export function extractArtistOption(artist: RelationshipDataDetails): string {
  const firstName: string = artist.attributes.name.firstName;
  const lastName: string | undefined = artist.attributes.name.lastName;
  const yearOfBirth: number | undefined = artist.attributes.yearOfBirth;
  return `${firstName}${lastName !== undefined ? ' ' + lastName : ''}${
    yearOfBirth !== undefined ? ' (' + yearOfBirth + ')' : ''
  }`;
}

export function artistOptions(
  relatedArtistsData: ManagedArtists
): { title?: string; options: { value: string; label: string }[] }[] {
  const artistSelectOptionsAuthorized = relatedArtistsData.relatedArtist
    .filter((item) => item.status === 'AUTHORIZED')
    .map(function (artist) {
      return { label: extractArtistOption(artist), value: artist.id };
    });

  const artistSelectOptionsPending = relatedArtistsData.relatedArtist
    .filter((item) => item.status === 'PENDING')
    .map(function (artist) {
      return { label: extractArtistOption(artist), value: artist.id };
    });

  return [
    {
      options: [{ value: '', label: 'Select' }],
    },
    {
      title: t('creatorStatus.authorized'),
      options: artistSelectOptionsAuthorized,
    },
    {
      title: t('creatorStatus.pending'),
      options: artistSelectOptionsPending,
    },
  ];
}

const MetafieldFormLoader: React.FC<MetafieldFormLoaderProps> = ({
  isVariant,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const {
    productFormValues: { data: productFormValues },
    relatedArtists: { data: relatedArtistsData },
    capabilities: { data: capabilities },
    isLoading: isDataLoading,
    patch,
  } = useProductFormValues(id, isVariant);

  const {
    data: needsPermissionRefresh,
    isLoading: isPermissionRefreshLoading,
  } = useNeedPermissionRefresh();

  const { turnOffProducts } = useTurnOffProducts();

  if (
    isDataLoading ||
    isPermissionRefreshLoading ||
    !needsPermissionRefresh ||
    !productFormValues ||
    !relatedArtistsData ||
    !capabilities
  ) {
    return <ProductFormLoading />;
  }

  const onSubmit =
    (
      setIsSubmitting: (isSubmitting: boolean) => void,
      onSuccess: (submittedData?: MetafieldFormValues) => void,
      onFail: () => void,
      setError: (
        metafield: MetafieldsType,
        errorObject: { type: string; message: string }
      ) => void
    ) =>
    async (dirtyData: MetafieldFormValues) => {
      setIsSubmitting(true);

      const data: MetafieldFormValues = {} as MetafieldFormValues;

      for (const property in dirtyData) {
        if (SubmittableMetaFields.some((val) => val === property)) {
          // @ts-ignore
          data[property] = dirtyData[property];
        }
      }

      const fail = () => {
        onFail();
        throw new Error('Submission failed');
      };
      let dataToSend: Partial<MetafieldFormValues> = data;
      try {
        switch (data.product_trigger_level as TriggerLevel) {
          case 'CERTIFICATE':
            NFTMetafields.forEach((item) => (dataToSend[item] = null));
            break;
          case 'NFT':
            COAMetafields.forEach((item) => (dataToSend[item] = null));
            break;
          default:
            console.error('Unknown product trigger level');
            return;
        }

        /**
         * Handle Editions
         * If the edition is set to 'no' clear all the edition fields
         * If the edition is set to 'yes' clear all the fields that are not relevant to the edition type
         */
        if (data.is_this_edition === 'yes' && data.edition_type === '') {
          setError('edition_type', {
            type: 'custom',
            message: t('metaDataForms.errors.editionType'),
          });
          fail();
          return;
        }
        if (data.is_this_edition === 'no') {
          EditionMetafields.forEach((editionMetafield) => {
            dataToSend[editionMetafield] = null;
          });
        } else if (data.is_this_edition === 'yes') {
          EditionMetafields.forEach((editionMetafield) => {
            dataToSend[editionMetafield] = EditionMetafieldsPerEditionType[
              data.edition_type as EditionType
            ].includes(editionMetafield)
              ? dataToSend[editionMetafield]
              : null;
          });
        }

        const asList: MetafieldToArrayValue = {} as MetafieldToArrayValue;
        SubmittableMetaFields.forEach((key) => {
          if (!(key === 'display_image_url' || key === 'image_url')) {
            asList[key as MetafieldsType] = {
              value:
                dataToSend[key as MetafieldsType] !== null &&
                dataToSend[key as MetafieldsType] !== ''
                  ? [dataToSend[key as MetafieldsType] as string]
                  : [],
            };
          }
        });

        const patchData: {
          metafields: MetafieldToArrayValue;
          productTriggerLevel: string;
          status: 'ACTIVE' | 'DRAFT';
        } = {
          metafields: {
            ...asList,
          },
          productTriggerLevel: data.product_trigger_level as string,
          status: data.status,
        };

        const response: AxiosResponse<ProductFormValues> = await patch(
          patchData
        );

        if (response.status !== 200) {
          fail();
          return;
        }

        let errorsEncountered = false;
        Object.keys(response.data.metafields).forEach((key) => {
          const metafieldValue: MetafieldValue =
            response.data.metafields[key as MetafieldsType];
          if (metafieldValue.issue !== null) {
            errorsEncountered = true;
            setError(key as MetafieldsType, {
              // We're overloading the `type` field with the error code. The docs of react-hook-form are a bit unclear
              // if this is OK or not, but it seems to work fine. (They let you set an arbitrary string)
              type: metafieldValue.issue[0],
              message: retrieveErrorMessage(metafieldValue.issue[0]),
            });
          }
        });

        if (!errorsEncountered) {
          onSuccess(dataToSend as MetafieldFormValues);
          queryClient.invalidateQueries(['print', productFormValues.print]);
        } else {
          fail();
        }
      } catch (e) {
        console.error('Caught exception while submitting form', e);
        fail();
      } finally {
        setIsSubmitting(false);
      }
      setIsSubmitting(false);
    };

  return isVariant ? (
    <VariantFormShow
      needsPermissionRefresh={needsPermissionRefresh}
      productFormValues={productFormValues}
      relatedArtistsData={relatedArtistsData}
      capabilities={capabilities}
      onSubmit={onSubmit}
    />
  ) : (
    <ProductFormShow
      needsPermissionRefresh={needsPermissionRefresh}
      productFormValues={productFormValues}
      relatedArtistsData={relatedArtistsData}
      capabilities={capabilities}
      onSubmit={onSubmit}
      onDeactivateProduct={async () => {
        await turnOffProducts([parseInt(id)]);
        queryClient.invalidateQueries('productFormValues');
        history.push(`/certificate`);
      }}
    />
  );
};

export default MetafieldFormLoader;
