import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { queryKey as emailIdentityVerificationStatusQueryKey } from 'hooks/useEmailIdentityVerificationStatus';
import { Shop } from './useShop';

export interface DefaultMetafields {
  [key: string]: string;
}

export type PatchShopProps = {
  emailCustomization?: { [key: string]: string };
  defaultMetafields?: DefaultMetafields;
};

type PatchShopRequest = {
  emailCustomization?: {
    fromEmail?: string;
    logo?: string;
    buttonColor?: string;
    body?: string;
    buttonTextColor?: string;
    nftBody?: string;
    viewNftBody?: string;
  };
  defaultMetafields?: Record<string, string>;
};

export default function usePatchShop() {
  const queryCache = useQueryClient();

  return useMutation<unknown, unknown, PatchShopRequest>(
    ({ emailCustomization, defaultMetafields }: PatchShopProps) =>
      API()
        .patch('/shop/me', {
          emailCustomization,
          defaultMetafields,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('shop');
        queryCache.invalidateQueries(emailIdentityVerificationStatusQueryKey);
      },
      onMutate: (data) => {
        queryCache.cancelQueries('shop');
        const previousShop = queryCache.getQueryData('shop') as Shop;
        const updatedShop = {
          ...previousShop,
          shop: {
            ...previousShop.shop,
            emailCustomization:
              data.emailCustomization === undefined
                ? previousShop.shop.emailCustomization
                : data.emailCustomization,
          },
        };
        queryCache.setQueryData('shop', updatedShop);
        return () => queryCache.setQueryData('shop', previousShop);
      },
    }
  );
}
