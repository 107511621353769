import React, { useEffect, useState } from 'react';
import { Banner, Card, Filters, ResourceList } from '@shopify/polaris';
import PrintListItem from 'components/PrintListItem';
import { LocalPagination, determinePageSize } from 'components/LocalPagination';
import styles from './PrintList.module.scss';
import { orderBy } from 'lodash';
import { SortOptions, SortType } from 'types/Utils';
import { useLocation } from 'react-router-dom';
import { PrintMinimal } from '../../hooks/useGetPrints';

const PrintList: React.FC<{ prints: PrintMinimal[] }> = ({ prints }) => {
  const location = useLocation();
  const pageSize = 10;
  const numberOfPages = Math.ceil(prints.length / pageSize);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);

  const [currentPage, setCurrentPage] = useState(1);
  const [printsAvailable, setPrintsAvailable] =
    useState<PrintMinimal[]>(prints);
  const [printsAvailableAndSorted, setprintsAvailableAndSorted] =
    useState<PrintMinimal[]>(prints);

  const params = new URLSearchParams(location.search);

  const printCreated = params.get('printCreated') === 'true';

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setPrintsAvailable(
        prints.filter(
          (print) =>
            print.name &&
            print.name.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
    } else {
      setPrintsAvailable(prints);
    }
  }, [queryValue, prints]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setprintsAvailableAndSorted(
          orderBy(printsAvailable, (t) => t.name, 'asc')
        );
        break;
      case 'CREATED_AT':
        setprintsAvailableAndSorted(
          orderBy(printsAvailable, 'createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setprintsAvailableAndSorted(
          orderBy(printsAvailable, 'updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, printsAvailable]);

  return (
    <>
      {printCreated && (
        <div style={{ marginBottom: '1rem' }}>
          <Banner status="success" title="Print created">
            Congratulations! Your print has been created successfully.
          </Banner>
        </div>
      )}
      <Card>
        <ResourceList
          sortValue={sortValue}
          sortOptions={SortOptions}
          filterControl={filterControl}
          onSortChange={(selected: SortType) => {
            setSortValue(selected);
          }}
          items={determinePageSize(
            printsAvailableAndSorted,
            currentPage,
            pageSize,
            numberOfPages
          )}
          renderItem={(item) => <PrintListItem {...item} />}
        />
      </Card>
      <div className={`${styles.pagination}`}>
        <LocalPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          numberOfPages={numberOfPages}
        />
      </div>
    </>
  );
};

export default PrintList;
