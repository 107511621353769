/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CancelSmallMinor, ImageMajor } from '@shopify/polaris-icons';
import { useProduct } from 'hooks/useProduct';
import {
  Button,
  Icon,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Thumbnail,
} from '@shopify/polaris';

// TODO: Refactor to accept data as a prop, useProductData can now accept a list

const SimpleListItem: React.FC<{
  productId: string;
  onDelete?: () => void;
}> = ({ productId, onDelete }) => {
  const { data } = useProduct(productId);

  return (
    <Stack.Item>
      {data ? (
        <Stack alignment="center">
          <Stack.Item>
            <Thumbnail
              alt={data.title}
              source={
                data.thumbnailUrl
                  ? data.thumbnailUrl
                  : // Fixme this cast is needed to work around some TS confusion over SVG types
                    (ImageMajor as any)
              }
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextStyle>{data.title}</TextStyle>
          </Stack.Item>
          {onDelete && (
            <Stack.Item>
              <Button
                plain
                icon={<Icon source={CancelSmallMinor} />}
                onClick={() =>
                  // This doesnt work for multiple products at the moment
                  onDelete()
                }
              />
            </Stack.Item>
          )}
        </Stack>
      ) : (
        <Stack alignment="center">
          <Stack.Item>
            <SkeletonThumbnail />
          </Stack.Item>
          <Stack.Item fill>
            <SkeletonBodyText lines={1} />
          </Stack.Item>
          {onDelete && (
            <Stack.Item>
              <Button
                plain
                disabled
                icon={<Icon source={CancelSmallMinor} />}
                onClick={() =>
                  // This doesnt work for multiple products at the moment
                  onDelete()
                }
              />
            </Stack.Item>
          )}
        </Stack>
      )}
    </Stack.Item>
  );
};

export default SimpleListItem;
