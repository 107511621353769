import React, { ReactNode, useCallback } from 'react';
import { DeleteMajor, ImageMajor } from '@shopify/polaris-icons';
import {
  Button,
  Icon,
  Link,
  SkeletonBodyText,
  SkeletonThumbnail,
  Stack,
  TextStyle,
  Thumbnail,
} from '@shopify/polaris';
import t from '../../lib/translation';
import { useHistory } from 'react-router-dom';
import style from './ProductListItem.module.scss';

type ProductItem = {
  id: number;
  title: string;
  thumbnailUrl: string | null;
  printId: string | null;
};

const ProductListItem: React.FC<{
  product: ProductItem;
  onDelete?: () => void;

  statusBadge: ReactNode | null;

  /**
   * If true an edit link is shown
   */
  editLink: boolean;
}> = ({ product, onDelete, statusBadge, editLink }) => {
  const numericalId = product.id;

  const history = useHistory();
  const navigateToProductForm = useCallback(() => {
    history.push(`/product-metadata/${numericalId}/nft`);
  }, [history, numericalId]);

  return (
    <Stack.Item>
      {product ? (
        <Stack alignment="center">
          <Stack.Item>
            <Thumbnail
              alt={product.title}
              source={
                product.thumbnailUrl
                  ? product.thumbnailUrl
                  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (ImageMajor as any)
              }
            />
          </Stack.Item>
          <Stack.Item fill>
            <TextStyle>{product.title}</TextStyle>
          </Stack.Item>
          {statusBadge && <Stack.Item>{statusBadge}</Stack.Item>}
          {editLink && (
            <Stack.Item>
              <Link onClick={navigateToProductForm}>
                {t('productListItem.edit')}
              </Link>
            </Stack.Item>
          )}
          {onDelete && (
            <Stack.Item>
              <Button
                plain
                icon={<Icon source={DeleteMajor} />}
                onClick={onDelete}
                disabled={!!product.printId}
              />
            </Stack.Item>
          )}
        </Stack>
      ) : (
        <Stack alignment="center">
          <Stack.Item>
            <SkeletonThumbnail />
          </Stack.Item>
          <Stack.Item fill>
            <SkeletonBodyText lines={1} />
          </Stack.Item>
          {onDelete && (
            <Stack.Item>
              <Button
                plain
                disabled
                icon={<Icon source={DeleteMajor} />}
                onClick={onDelete}
              />
            </Stack.Item>
          )}
        </Stack>
      )}
      <hr className={style.divider} />
    </Stack.Item>
  );
};

export default ProductListItem;
