/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useProduct } from 'hooks/useProduct';
import { Spinner } from '@shopify/polaris';
import FormField from 'components/forms/FormField';
import t from 'lib/translation';

const PrintFormProductPreview: React.FC<{
  productId: string;
  formType: 'Create' | 'Edit';
  title: string;
  gifData: string | null;
  gifLoading: boolean;
}> = ({ productId, formType, title, gifData, gifLoading }) => {
  const { data } = useProduct(productId);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const loadingMessages = [
    'Fetching token images',
    'Verifying token images',
    'Generating product image...',
  ];

  useEffect(() => {
    if (gifLoading) {
      const interval = setInterval(() => {
        setLoadingMessageIndex((prevIndex) => {
          if (prevIndex < loadingMessages.length - 1) {
            return prevIndex + 1;
          } else {
            return prevIndex; // Stop at the last message
          }
        });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [gifLoading, loadingMessages.length]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {formType === 'Create' ? (
        gifLoading ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              minHeight: '150px',
            }}
          >
            <Spinner />
            <div style={{ marginTop: '10px' }}>
              {loadingMessages[loadingMessageIndex]}
            </div>
          </div>
        ) : gifData ? (
          <img
            src={gifData}
            alt="Product preview"
            style={{
              maxHeight: '200px',
              display: 'block',
              border: '1px solid #ccc',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        ) : null
      ) : data?.thumbnailUrl ? (
        <img
          src={data.thumbnailUrl}
          alt="Product preview"
          style={{
            width: '100%',
            maxHeight: '400px',
            maxWidth: '45%',
            display: 'block',
            border: '1px solid #ccc',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
            marginRight: '15px',
            objectFit: 'contain',
          }}
        />
      ) : null}
      <div
        style={{
          width:
            (data?.thumbnailUrl || gifData) && formType === 'Create'
              ? '60%'
              : '100%',
        }}
      >
        <FormField label="Title" value={title} />
        <FormField
          label="Description"
          value={
            formType === 'Create'
              ? t('printDropForm.productDescriptionDefault')
              : data?.description ?? ''
          }
        />
      </div>
    </div>
  );
};

export default PrintFormProductPreview;
