import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import API from 'utils/api';
import { trackChangePlanConfirm, trackChangePlanStart } from 'lib/tracking';
import { useAppBridge } from 'containers/AppBridge';
import { useState } from 'react';

export type ChangePlanProps = {
  /**
   * Leave as null to indicate you're adding just the usage charge
   *
   */
  planType: string | null;

  /**
   * Set this value when the user has completed the approval to
   * force a resync using the new subscription.
   *
   * Mutually exclusive with planType
   */
  chargeId?: string;
  redirectPath: string;
};

export default function useChangePlan(): UseMutationResult<
  { confirmationUrl: string | null; chargeId: string | null },
  unknown,
  ChangePlanProps
> {
  const [overrideIsLoading, setOverrideIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const app = useAppBridge();
  const mutation = useMutation(
    ({ planType, chargeId, redirectPath }: ChangePlanProps) =>
      API()
        .post('/shop/me/changePlan', {
          planType,
          chargeId,
          redirectPath,
        })
        .then((res) => res.data),
    {
      onMutate: ({ planType }) => {
        if (planType) {
          trackChangePlanStart(planType);
        }
      },
      onSuccess: (data, { planType, chargeId }) => {
        if (data.confirmationUrl) {
          setOverrideIsLoading(true);
          app.redirect('Remote', data.confirmationUrl);
        } else {
          if (chargeId && data.shop.planType) {
            trackChangePlanConfirm(data.shop.planType);
          }
          queryClient.invalidateQueries(['subscriptionDetails']);
        }
      },
    }
  );

  // This is just to make the UI behave a bit more prettily when we have a redirect.
  // The problem was that spinners etc will stop after the mutation has happened while the `app.redirect` is still
  // in progress, which can be pretty slow. This keeps the spinner going basically forever until the browser
  // is redirected.
  mutation.isLoading = mutation.isLoading || overrideIsLoading;

  return mutation;
}
