import type { AdminLinkType } from './AdminLink';

export const appPathForSearchParams = ({
  defaultType,
  type,
  search,
}: {
  type: AdminLinkType;
  defaultType?: 'nft' | 'certificate' | 'new_nft' | 'tokengate';
  search: string;
}): string => {
  if (defaultType === 'new_nft') {
    return '/drops';
  }
  if (defaultType === 'tokengate') {
    return '/tokengates';
  }

  const searchParams = new URLSearchParams(search);

  let ids = searchParams.getAll('ids[]');

  if (ids?.length === 0) {
    ids = searchParams.getAll('id');
  }

  let path = '/';

  switch (type) {
    case 'products':
      path += 'product-metadata';
      break;
    case 'variants':
      path += 'variant-metadata';
      break;
    default:
      throw new Error(
        `appPathForSearchParams: unsupported type for app path: ${type}`
      );
  }

  if (ids?.length === 1) {
    return `${path}/${ids.join()}` + (defaultType ? `/${defaultType}` : '');
  }

  return `/${defaultType}`;
};
