import { Banner, Card, Icon, Link, Spinner, Stack } from '@shopify/polaris';
import FormField from 'components/forms/FormField';
import { TokenImageMetadataResponse } from 'hooks/useGetPrintPreview';
import React, { useEffect } from 'react';
import { PrintFormValues } from './PrintForm';
import { UseFormReturn } from 'react-hook-form';
import { ArrowLeftMinor, ArrowRightMinor } from '@shopify/polaris-icons';
import TextInput from 'components/forms/TextInput';
import { ContractPreview } from 'hooks/useGetContractMetadata';

interface TokenPreviewCardProps {
  methods: UseFormReturn<PrintFormValues>;
  printMetadata: TokenImageMetadataResponse | null;
  printPreviewLoading: boolean;
  error: string | null;
  contractPreview: ContractPreview | undefined;
}

const TokenPreviewCard: React.FC<TokenPreviewCardProps> = ({
  methods,
  printMetadata,
  printPreviewLoading,
  error,
  contractPreview,
}) => {
  const printPreviewDPI = printMetadata?.dpi;
  const printPreview = printMetadata?.preview?.cloudinaryUrl;
  const printPreviewWidth = printMetadata?.rotated
    ? printMetadata?.paper?.height
    : printMetadata?.paper?.width;
  const printPreviewHeight = printMetadata?.rotated
    ? printMetadata?.paper?.width
    : printMetadata?.paper?.height;

  const isArtBlocksContract = contractPreview?.isArtBlocks;
  const artBlocksProjectId = isArtBlocksContract
    ? methods.watch('artBlocksProjectId')
    : undefined;

  const getInitialTokenId = () => {
    if (isArtBlocksContract && artBlocksProjectId) {
      return parseInt(`${artBlocksProjectId}000000`);
    }
    return 1; // default to 1 for normal contracts
  };

  const currentTokenId =
    parseInt(methods.watch('tokenId')) || getInitialTokenId();

  useEffect(() => {
    if (isArtBlocksContract && artBlocksProjectId) {
      const newTokenId = parseInt(`${artBlocksProjectId}000000`);
      methods.setValue('tokenId', newTokenId.toString());
    } else {
      methods.setValue('tokenId', '1');
    }
  }, [isArtBlocksContract, artBlocksProjectId, methods]);
  const handleTokenIdInputChange = (value?: string) => {
    if (value !== undefined) {
      methods.setValue('tokenId', value);
    }
  };

  const handleDecrement = () => {
    const newValue = currentTokenId - 1;
    if (
      newValue >=
      (isArtBlocksContract ? parseInt(`${artBlocksProjectId}000000`) : 1)
    ) {
      methods.setValue('tokenId', newValue.toString());
    }
  };

  const handleIncrement = () => {
    const newValue = currentTokenId + 1;
    methods.setValue('tokenId', newValue.toString());
  };

  return (
    <>
      <Card title="Print preview">
        {error && (
          <div style={{ margin: '10px' }}>
            <Banner status="critical">{error}</Banner>
          </div>
        )}
        {printMetadata?.assetType === 'GENERATIVE_HTML' && (
          <div style={{ margin: '10px' }}>
            <Banner status="success">
              Congratulations! Your image has been re-rendered for optimum
              printing
            </Banner>
          </div>
        )}
        {printPreviewDPI && printPreviewDPI < 150 && (
          <div style={{ margin: '10px' }}>
            <Banner status="critical">
              Warning! You need a minimum DPI of 150 for optimal printing.
            </Banner>
          </div>
        )}
        <Stack distribution="fillEvenly">
          <Stack vertical spacing="extraTight">
            <div style={{ marginBottom: '0px' }}>
              {printPreviewLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    minHeight: '150px',
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                printPreview && (
                  <Card.Section>
                    <Stack>
                      <div
                        style={{
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        <img
                          src={printPreview}
                          alt="Print preview"
                          style={{
                            maxHeight: '200px',
                            display: 'block',
                            border: '1px solid #ccc',
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                          }}
                        />

                        {/* Horizontal line */}
                        <div
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '2px',
                            backgroundColor: 'black',
                          }}
                        />

                        {/* Vertical line */}
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            height: '100%',
                            width: '2px',
                            backgroundColor: 'black',
                          }}
                        />

                        {/* Label for width */}
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '-25px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: 'white',
                            padding: '2px 5px',
                            borderRadius: '3px',
                          }}
                        >
                          {printPreviewWidth?.toString()}"
                        </div>

                        {/* Label for height */}
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            right: '-35px',
                            backgroundColor: 'white',
                            padding: '2px 5px',
                            borderRadius: '3px',
                          }}
                        >
                          {printPreviewHeight?.toString()}"
                        </div>
                      </div>
                    </Stack>
                  </Card.Section>
                )
              )}
            </div>
          </Stack>
          <Card.Section>
            <Stack vertical spacing="extraLoose">
              <Stack vertical spacing="tight">
                <Stack distribution="fillEvenly" alignment="center">
                  <p
                    style={{
                      minWidth: '100px',
                      fontWeight: 'bold',
                      margin: 0,
                    }}
                  >
                    Token ID
                  </p>
                </Stack>
                <div style={{ marginLeft: '6.1rem' }}>
                  <Stack>
                    <div
                      style={{ cursor: 'pointer', marginTop: '8px' }}
                      onClick={handleDecrement}
                    >
                      <Icon source={ArrowLeftMinor} />
                    </div>
                    <div style={{ width: '70px' }}>
                      <TextInput
                        name="tokenId"
                        label="tokenId"
                        labelHidden
                        error={
                          methods.formState.errors?.contractAddress?.message
                        }
                        placeholder={methods.watch('tokenId')}
                        customOnChange={handleTokenIdInputChange}
                        center
                      />
                    </div>
                    <div
                      style={{ cursor: 'pointer', marginTop: '8px' }}
                      onClick={handleIncrement}
                    >
                      <Icon source={ArrowRightMinor} />
                    </div>
                  </Stack>
                </div>
              </Stack>
              <div style={{ marginTop: '35px' }}>
                <Stack vertical spacing="tight">
                  {printPreviewHeight && printPreviewWidth && (
                    <FormField
                      label="Dimensions"
                      value={`${printPreviewHeight?.toString()} x ${printPreviewWidth?.toString()} inches`}
                    />
                  )}
                  {printPreviewDPI && (
                    <div>
                      <FormField
                        label="DPI"
                        value={Math.round(Number(printPreviewDPI)).toString()}
                      />
                      <div style={{ paddingLeft: '100px' }}>
                        <p style={{ margin: 0, fontSize: '0.7rem' }}>
                          <Link url="https://help.verisart.com/en/articles/9497684-launch-your-print-store-on-shopify#h_ce265c259d">
                            Learn more about DPI
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                </Stack>
              </div>
            </Stack>
          </Card.Section>
        </Stack>
      </Card>
    </>
  );
};

export default TokenPreviewCard;
