import React from 'react';

const ErrorPage: React.FC = () => (
  <div
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <p>Sorry, something went wrong.</p>
  </div>
);

export default ErrorPage;
