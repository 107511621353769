import { isValidEthereumAddress } from 'pages/RoyaltySplit/utils';
import { useEffect, useState } from 'react';
import { Blockchain } from 'types/Blockchain';
import API, { sleep } from 'utils/api';
import useShop from './useShop';

export interface TokenImageMetadataResponse {
  blockchain: Blockchain;
  contractAddress: string;
  tokenId: string;
  assetType: 'IMAGE' | 'GENERATIVE_HTML';
  assetDimensions: {
    width: number;
    height: number;
  };
  paper: {
    width: number;
    height: number;
    sku: string;
  };
  preview: {
    s3path: string;
    s3Url: string;
    cloudinaryUrl: string;
  };
  rotated: boolean;
  dpi: number;
}

interface TokenImageMetadataGetResponse {
  thumbnail: string;
  metadata: string;
  jobId: string | null;
}

export interface JobStatusResponse {
  ready: boolean;
}

export const isValidTokenId = (tokenId: string): boolean =>
  /^[0-9]+$/.test(tokenId);

/**
 * Check if a print job is ready (or throw an Axios error if failed)
 */
export const isPrintJobReady = async (jobId: string): Promise<boolean> => {
  const status = (
    await API().get<JobStatusResponse>(
      `/shop/print/printPreview/jobStatus/${jobId}`
    )
  ).data;

  return status.ready;
};

const useGetPrintPreview = (
  blockchain: Blockchain | null | undefined,
  contractAddress: string | undefined,
  tokenId: string | undefined,
  productId: string | undefined
) => {
  const { data: shop } = useShop();

  const [printPreviewData, setPrintPreviewData] =
    useState<TokenImageMetadataResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      setFetchError(null);
      setPrintPreviewData(null);

      if (
        tokenId &&
        isValidTokenId(tokenId) &&
        blockchain &&
        contractAddress &&
        isValidEthereumAddress(contractAddress)
      ) {
        try {
          setLoading(true);

          // Request the preview
          const productParam = productId
            ? `?productId=${productId}&shopId=${shop?.shop?.id}`
            : '';
          const response = await API().post<TokenImageMetadataGetResponse>(
            `shop/print/printPreview/${blockchain}/${contractAddress}/${tokenId}${productParam}`
          );

          if (response.data.jobId) {
            // Not generated yet so wait for the job to complete
            while (!cancel) {
              if (await isPrintJobReady(response.data.jobId)) {
                break;
              }
              await sleep(1000);
            }
          }

          if (cancel) return;

          // Fetch the metadata
          const metadataResponse = (
            await API().get<TokenImageMetadataResponse>(response.data.metadata)
          ).data;

          if (cancel) return;

          setPrintPreviewData(metadataResponse);
        } catch (error) {
          if (!cancel) {
            console.error(
              `Error generating print preview for token for ${blockchain}/${contractAddress}/${tokenId}`,
              error
            );
            setFetchError(
              error.response?.data?.message ?? error.message ?? 'Unknown error'
            );
          }
        } finally {
          if (!cancel) setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      setLoading(false);
      cancel = true;
    };
  }, [blockchain, contractAddress, productId, shop?.shop?.id, tokenId]);

  return { printPreviewData, loading, fetchError };
};

export default useGetPrintPreview;
