import { Contract } from '../hooks/useGetContracts';

export const formatServerString = (str: string) => {
  if (str) {
    return str
      .replace(/_/g, ' ')
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
  }
  return '';
};

export const extractId = (str: string) => {
  const extractedIdArray = str.split('/');
  return extractedIdArray[extractedIdArray.length - 1];
};

export const properCase = (value: string) =>
  value && value.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

export function truncateString(
  str: string,
  firstCharCount: number,
  endCharCount: number
): string {
  if (str.length < 10) {
    return str;
  } else {
    let convertedStr = '';
    convertedStr += str.substring(0, firstCharCount);
    convertedStr += '…';
    convertedStr += str.substring(str.length - endCharCount, str.length);
    return convertedStr;
  }
}

export function truncateBlockchainAddress(str: string): string {
  return truncateString(str, 6, 4);
}

export const getFileExtension = (filename: string): string | undefined => {
  const a = filename.split('.');
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return '';
  }
  return a.pop();
};

export const removeFileExtension = (fileName: string): string => {
  return fileName.replace(/\.[^/.]+$/, '');
};

export function formatContractNameFromObject(contract: Contract) {
  return formatContractName(contract.name, contract.address);
}

export function formatContractName(
  contractName: string,
  contractAddress: string | null | undefined
): string {
  if (!contractAddress) return contractName;
  return `${contractName} (${truncateBlockchainAddress(contractAddress)})`;
}
