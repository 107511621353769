import { Card, Stack, TextField, TextStyle, Link } from '@shopify/polaris';
import useCustomization from 'hooks/useCustomization';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useGetCapabilities from 'hooks/useGetCapabilities';
import { CapabilityResponse } from 'hooks/useGetCapabilities';

export const CustomizationInfo: React.FC = () => {
  const history = useHistory();
  const customizationQuery = useCustomization();
  const capabilitiesQuery = useGetCapabilities();
  let capabilities: CapabilityResponse | undefined = capabilitiesQuery.data;
  const selectedTemplateId = customizationQuery.data?.shopTemplateId;

  const selectedCustomization = customizationQuery.data?.customizations?.find(
    (customization) => customization.templateId === selectedTemplateId
  );

  const hasCustomizationCapability = capabilities?.capabilities.includes(
    'certificates.customize'
  );

  return (
    <Card>
      <Card.Header title="Certificate Customization" />
      <Card.Section>
        <Stack vertical>
          <TextField
            label=""
            value={
              !customizationQuery.data
                ? ''
                : hasCustomizationCapability
                ? selectedCustomization?.templateName ?? 'No customization'
                : 'No customization'
            }
            disabled
            autoComplete="off"
          ></TextField>
          <div style={{ textAlign: 'center' }}>
            <Link onClick={() => history.push('/customizations')}>
              <TextStyle>Change default template</TextStyle>
            </Link>
          </div>
        </Stack>
      </Card.Section>
    </Card>
  );
};
