import React, { useState } from 'react';
import { Button, Heading, Modal, Stack, TextStyle } from '@shopify/polaris';
import LessThan from 'assets/svgComponents/LessThan';
import { translate as t } from 'lib/translation';
import styles from './EditSelectorModal.module.css';
import classNames from 'classnames';

const EDIT_SELECTOR_OPTIONS = ['new_nft', 'certificate', 'tokengate'] as const;

type EditSelectorOption = typeof EDIT_SELECTOR_OPTIONS[number];

const EditSelectorModal = ({
  cancelable,
  open,
  onCancel,
  onClose,
  onSelect,
}: {
  cancelable?: boolean;
  open?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onSelect?: (selected: EditSelectorOption) => void;
}) => {
  const [selected, setSelected] = useState<EditSelectorOption | ''>('');

  return (
    <Modal
      open={open ?? false}
      title={t('editSelectorModal.title')}
      onClose={() => onClose?.()}
      large
    >
      <Modal.Section>
        <Stack vertical>
          <p>{t('editSelectorModal.subtitle')}</p>
          <div className={styles.row}>
            {EDIT_SELECTOR_OPTIONS.map((option) => (
              <button
                key={option}
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setSelected(option);
                }}
                className={classNames(
                  styles.button,
                  selected === option && styles['button--selected']
                )}
              >
                <img
                  src={`/images/edit-selector-${option}.png`}
                  alt=""
                  width="120px"
                  height="86px"
                  className={styles.buttonImage}
                />
                <Stack vertical>
                  <Heading element="h3">
                    {t(`editSelectorModal.${option}ButtonLabel.title`)}
                  </Heading>
                  <TextStyle variation="subdued">
                    {t(`editSelectorModal.${option}ButtonLabel.subtitle`)}
                  </TextStyle>
                </Stack>
              </button>
            ))}
          </div>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack>
          <Stack.Item fill>
            {cancelable && (
              <Button
                icon={<LessThan />}
                accessibilityLabel={t('editSelectorModal.cancel')}
                onClick={() => onCancel?.()}
              />
            )}
          </Stack.Item>
          <Stack.Item>
            <Button
              primary
              onClick={() => {
                if (selected) {
                  onSelect?.(selected);
                }
              }}
              disabled={!selected}
            >
              {t('editSelectorModal.next')}
            </Button>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default EditSelectorModal;
