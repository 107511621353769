import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Layout,
  Card,
  TextStyle,
  Heading,
  Button,
  ResourceList,
  Stack,
  Banner,
  BannerStatus,
  Badge,
  List,
  TextContainer,
} from '@shopify/polaris';
import { PaymentSplitterContractRenderable } from '../../hooks/useProductFormValues';
import { Blockchain } from '../../types/Blockchain';
import { EditRecipient, RecipientModal } from './RecipientModal';
import { FetchRecipientsResponse } from '../../hooks/useGetRecipients';
import { throwIfLengthNotEqualToOne } from './utils';
import { v4 as uuidv4 } from 'uuid';
import bigDecimal from 'js-big-decimal';
import style from './RoyaltySplit.module.scss';
import t from 'lib/translation';
import RoyaltySplitListItem from './RoyaltySplitListItem';
import { AxiosError } from 'axios';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import TextInput from '../../components/forms/TextInput';
import SelectInput from '../../components/forms/SelectInput';
import RoyaltySplitTableEdit from './RoyaltySplitTableEdit';
import LoadingState from 'pages/LoadingState';
import useUpdateRoyaltySplit from './useUpdateRoyaltySplit';
import withSaveToast from '../../hooks/withSaveToast';
import { useContextualSaveBarOrExternal } from '../../hooks/useContextualSaveBar';
import { CustomRecipient } from './RecipientModal';
import { useIntercom } from 'react-use-intercom';
import { IntercomEvents } from '../../interfaces/IntercomEvents';
import useCreateRoyaltySplit from './useCreateRoyaltySplit';
import assert from 'assert';
import { useHistory } from 'react-router';
import { useAppBridge } from 'containers/AppBridge';
import { isEnvironmentTest } from '../../constants';

/*
 * Format for adding a new recipient with a new wallet
 * */
export interface RecipientToAdd {
  uuid: string;
  name: string;
  wallet: string;
}

/*
 * Format for adding a new wallet to an existing artist/recipient
 * */
export interface AddNewWallet {
  uuid: string;
  id: string;
  wallet: string;
  isArtist?: boolean;
}

/*
 * Format for adding an existing recipient with an existing wallet
 * */
export interface AddRecipient {
  id: string;
  wallet: string;
}

/*
 * Format for displaying the current payment splitter input
 * */
export interface TableRecipient {
  uuid: string;
  artistOrRecipientId?: string;
  name: string;
  wallet: string;
  // This should be the % value that this recipient is entitled to in this current split
  share: bigDecimal;
  /*
   * To customize the Resource List we add these special.
   * The verisart flag will be used to render the special 'disabled' state row for the Verisart share
   * The total flag will be used to display a row summing the shares
   * When these flags are set the rest of the elements are ignored
   * */
  disabled?: boolean;
  total?: boolean;
  action?: string;
}

const ONE_HUNDRED_BIG_DECIMAL = new bigDecimal('100');

export interface RoyaltySplitFormValues {
  splitName: string | null;
  blockchain: Blockchain | null;
  tableRecipients?: TableRecipient[];
}

export interface RoyaltySplitFormProps {
  formType: 'Create' | 'Edit';
  onTestMode: boolean;
  paymentSplitterContract?: PaymentSplitterContractRenderable;
  recipients?: FetchRecipientsResponse;
  /**
   * If set will preset the chain to this and mark it as disabled (create mode only)
   */
  lockBlockchain?: Blockchain;

  /**
   * Used to allow having the save buttons exist outside of this form. If set then this will be invoked with a function
   * which can be called when you're ready to save.
   *
   * If undefined then this form will use the `useContextualSaveBar()` save bar
   */
  setSaveCallback?: (saveCallback: () => void) => void;

  onRoyaltySplitCreated?: (id: string) => void;
}
export const RoyaltySplitForm: React.FC<RoyaltySplitFormProps> = ({
  formType,
  onTestMode,
  paymentSplitterContract,
  recipients,
  lockBlockchain,
  setSaveCallback,
  onRoyaltySplitCreated,
}) => {
  const { trackEvent } = useIntercom();
  const history = useHistory();
  const methods = useForm<RoyaltySplitFormValues>({
    defaultValues: {
      splitName: paymentSplitterContract?.name,
      blockchain: paymentSplitterContract?.blockchain ?? lockBlockchain,
      tableRecipients: [],
    },
  });

  useEffect(() => {
    if (paymentSplitterContract?.name) {
      methods.setValue('splitName', paymentSplitterContract?.name);
    }
  }, [paymentSplitterContract?.name, methods]);

  const { updateRoyaltySplit } = useUpdateRoyaltySplit();
  const { createRoyaltySplit } = useCreateRoyaltySplit();
  const splitName = methods.watch('splitName');
  const blockchain = methods.watch('blockchain');
  const { fields: tableRecipients, replace: replaceTableRecipients } =
    useFieldArray({
      control: methods.control,
      name: 'tableRecipients',
      rules: {
        required:
          formType === 'Create'
            ? t('royaltySplitPage.errors.atLeastOneRecipient')
            : undefined,
      },
    });
  const [walletsToAdd, setWalletsToAdd] = useState<AddNewWallet[]>([]);
  const [recipientsToAdd, setRecipientsToAdd] = useState<RecipientToAdd[]>([]);

  const setTableRecipients = useCallback(
    (tableRecipientsInput: TableRecipient[]) => {
      replaceTableRecipients(tableRecipientsInput);
    },
    [replaceTableRecipients]
  );

  // Stored as a state as it's used as a conditional to render the add recipient modal
  const [addRecipientFunction, setAddRecipientFunction] = useState<
    | ((
        share: bigDecimal,
        addNewRecipient?: RecipientToAdd,
        addNewWallet?: AddNewWallet,
        addRecipient?: AddRecipient
      ) => void)
    | undefined
  >(undefined);

  const [confirmTransactionModalActive, setConfirmTransactionModalActive] =
    useState<boolean>(false);

  let mergedRecipients: CustomRecipient[] = [];

  if (recipients) {
    mergedRecipients = [
      ...recipients.recipients.map((it) => ({
        id: it.recipientId,
        walletAddresses: it.walletAddress.map((walletAddress) => ({
          address: walletAddress,
          hardLinked: false,
        })),
        name: it.name,
        isArtist: false,
      })),
      ...recipients.authArtists.map((it) => ({
        id: it.artistId,
        name: it.name,
        walletAddresses: it.walletAddress.map((walletAddress) => ({
          address: walletAddress.address,
          hardLinked: walletAddress.hardLinked,
        })),
        isArtist: true,
      })),
    ];
  }

  const [totalPercentage, setTotalPercentage] = useState<bigDecimal>(
    new bigDecimal('0')
  );
  const [deployed, setDeployed] = useState(false);
  const [editRecipient, setEditRecipient] = useState<EditRecipient | undefined>(
    undefined
  );

  const [bannerInfo, setBannerInfo] = useState<{
    title?: string;
    message: string;
    status?: BannerStatus;
    action?: { text: string; url: string };
  } | null>(null);

  const deleteRow = useCallback(
    (uuid: string) => {
      setBannerInfo(null);
      setTableRecipients(tableRecipients.filter((it) => it.uuid !== uuid));
      setRecipientsToAdd(recipientsToAdd.filter((it) => it.uuid !== uuid));
      setWalletsToAdd(walletsToAdd.filter((it) => it.uuid !== uuid));
    },
    [
      recipientsToAdd,
      setRecipientsToAdd,
      setTableRecipients,
      setWalletsToAdd,
      tableRecipients,
      walletsToAdd,
    ]
  );

  useEffect(() => {
    // We add the 1 for the Verisart split
    setTotalPercentage(
      tableRecipients
        .map((it) => it.share)
        .reduce((partialSum, elem) => partialSum.add(elem), new bigDecimal('0'))
    );
  }, [tableRecipients]);

  useEffect(() => {
    if (totalPercentage.compareTo(ONE_HUNDRED_BIG_DECIMAL) === 1) {
      setBannerInfo({
        message: t('royaltySplitPage.errors.splitsAbove100'),
        status: 'critical',
      });
    }
  }, [totalPercentage, setBannerInfo]);

  const addRow =
    (deleteRowAfterAdding?: string) =>
    () =>
    (
      share: bigDecimal,
      addNewRecipient?: RecipientToAdd,
      addNewWallet?: AddNewWallet,
      addRecipient?: AddRecipient
    ) => {
      if (!addNewRecipient && !addNewWallet && !addRecipient) {
        throw new Error(
          'Expected to add a new recipient, a wallet or an existing recipient but doing none of these.'
        );
      }
      if (addNewRecipient) {
        setTableRecipients([
          ...(tableRecipients !== undefined
            ? tableRecipients.filter(
                (tableRecipient) => tableRecipient.uuid !== deleteRowAfterAdding
              )
            : []),
          {
            share,
            name: addNewRecipient.name,
            wallet: addNewRecipient.wallet,
            uuid: addNewRecipient.uuid,
          },
        ]);
        setRecipientsToAdd([
          ...(recipientsToAdd !== undefined
            ? recipientsToAdd.filter(
                (recipientToAdd) => recipientToAdd.uuid !== deleteRowAfterAdding
              )
            : []),
          {
            name: addNewRecipient.name,
            wallet: addNewRecipient.wallet,
            uuid: addNewRecipient.uuid,
          },
        ]);
      }
      if (addNewWallet) {
        const matching = mergedRecipients.filter(
          (it) => it.id === addNewWallet.id
        );
        throwIfLengthNotEqualToOne(matching);
        const name = matching[0].name;
        const isArtist = matching[0].isArtist;
        setTableRecipients([
          ...(tableRecipients !== undefined
            ? tableRecipients.filter(
                (tableRecipient) => tableRecipient.uuid !== deleteRowAfterAdding
              )
            : []),
          {
            artistOrRecipientId: addNewWallet.id,
            share,
            name,
            wallet: addNewWallet.wallet,
            uuid: addNewWallet.uuid,
          },
        ]);
        setWalletsToAdd([
          ...(walletsToAdd !== undefined
            ? walletsToAdd.filter(
                (walletToAdd) => walletToAdd.uuid !== deleteRowAfterAdding
              )
            : []),
          {
            id: addNewWallet.id,
            wallet: addNewWallet.wallet,
            uuid: addNewWallet.uuid,
            isArtist: isArtist,
          },
        ]);
      }
      if (addRecipient) {
        const matching = mergedRecipients.filter(
          (it) => it.id === addRecipient.id
        );
        throwIfLengthNotEqualToOne(matching);
        const name = matching[0].name;
        const currentUUID = uuidv4();
        setTableRecipients([
          ...(tableRecipients !== undefined
            ? tableRecipients.filter(
                (tableRecipient) => tableRecipient.uuid !== deleteRowAfterAdding
              )
            : []),
          {
            share,
            name,
            wallet: addRecipient.wallet,
            uuid: currentUUID,
            artistOrRecipientId: addRecipient.id,
          },
        ]);
      }
    };

  const getBackendErrorMessage = (error: AxiosError) => {
    if (error?.response?.status === 500) {
      return t('createRoyaltySplitForm.errors.generalError');
    } else {
      const rawErrorMessage = error?.response?.data?.message;
      if (rawErrorMessage.includes('not.enough.gas')) {
        return t('createRoyaltySplitForm.errors.notEnoughGas');
      }

      return t('createRoyaltySplitForm.errors.generalError');
    }
  };
  const app = useAppBridge();

  const createRoyaltySplitSubmit = useCallback(async () => {
    try {
      assert(blockchain);
      assert(splitName);
      const { id } = await createRoyaltySplit({
        name: splitName,
        blockchain,
        tableRecipients,
        walletsToAdd,
        recipientsToAdd,
      });

      onRoyaltySplitCreated?.(id);

      trackEvent(IntercomEvents.royaltySplitCreated);
      if (history.location.pathname === '/royalty-splits-create') {
        history.replace('royalty-splits');
      }
    } catch (e) {
      setBannerInfo({
        title: t('royaltySplitPage.generalError.title'),
        message: getBackendErrorMessage(e as AxiosError),
        status: 'critical',
      });
      throw e;
    }
    setDeployed(true);
    setConfirmTransactionModalActive(false);
  }, [
    blockchain,
    createRoyaltySplit,
    history,
    onRoyaltySplitCreated,
    recipientsToAdd,
    splitName,
    tableRecipients,
    trackEvent,
    walletsToAdd,
  ]);

  const submitForm = useCallback(async () => {
    setBannerInfo(null);

    if (formType === 'Create') {
      if (await methods.trigger()) {
        if (tableRecipients.length === 1) {
          withSaveToast(
            async () => {
              await methods.handleSubmit(createRoyaltySplitSubmit)();
              return '';
            },
            app.showToast,
            {
              successMessage: t('royaltySplitForm.toast.createSuccess'),
              errorMessage: t('royaltySplitForm.toast.createError'),
            }
          );
        } else {
          setConfirmTransactionModalActive(true);
        }
      }
    } else {
      try {
        if (paymentSplitterContract) {
          await updateRoyaltySplit({
            name: splitName!!,
            id: paymentSplitterContract.id,
          });
        }
      } catch (e) {
        console.log('e : ', e);
        setBannerInfo({
          title: t('royaltySplitPage.editPage.banner.fail.title'),
          message: t('royaltySplitPage.editPage.banner.fail.message'),
          status: 'warning',
        });
        throw e;
      }
    }
  }, [
    formType,
    methods,
    tableRecipients.length,
    app,
    createRoyaltySplitSubmit,
    paymentSplitterContract,
    updateRoyaltySplit,
    splitName,
  ]);

  // Form submission

  const { handleSubmit } = methods;

  const onSave = useCallback(async () => {
    const formValid = await methods.trigger();
    if (!formValid) return;

    if (formType === 'Create') {
      if (tableRecipients.length === 1) {
        withSaveToast(
          async () => {
            await methods.handleSubmit(createRoyaltySplitSubmit)();
            return '';
          },
          app.showToast,
          {
            successMessage: t('royaltySplitForm.toast.createSuccess'),
            errorMessage: t('royaltySplitForm.toast.createError'),
          }
        );
      } else {
        setConfirmTransactionModalActive(true);
      }
    } else {
      withSaveToast(
        async () => {
          await handleSubmit(submitForm)();
          return '';
        },
        app.showToast,
        {
          successMessage: t('royaltySplitForm.toast.editSuccess'),
          errorMessage: t('royaltySplitForm.toast.editError'),
        }
      );
    }
  }, [
    methods,
    formType,
    tableRecipients,
    app,
    createRoyaltySplitSubmit,
    handleSubmit,
    submitForm,
  ]);

  useContextualSaveBarOrExternal(methods, onSave, setSaveCallback);

  // Form reset

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = methods;

  const { getValues } = methods;
  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues]);

  return (
    <>
      {bannerInfo && (
        <Layout>
          <Layout.Section>
            <div className={style['margin-bottom-1-rem']}>
              <Banner
                title={bannerInfo.title}
                status={bannerInfo.status}
                action={
                  bannerInfo.action !== undefined
                    ? {
                        content: bannerInfo.action.text,
                        url: bannerInfo.action.url,
                      }
                    : undefined
                }
                onDismiss={() => setBannerInfo(null)}
              >
                {bannerInfo.message}
              </Banner>
            </div>
          </Layout.Section>
        </Layout>
      )}
      {confirmTransactionModalActive && (
        <Modal
          open={confirmTransactionModalActive}
          onClose={() => setConfirmTransactionModalActive(false)}
          title={t('royaltySplitPage.confirmationModal.title')}
          primaryAction={{
            content: 'Save',
            onAction: () =>
              withSaveToast(
                async () => {
                  await methods.handleSubmit(createRoyaltySplitSubmit)();
                  return '';
                },
                app.showToast,
                {
                  successMessage: t('royaltySplitForm.toast.createSuccess'),
                  errorMessage: t('royaltySplitForm.toast.createError'),
                }
              ),
          }}
          secondaryActions={[
            {
              content: t('royaltySplitPage.confirmationModal.secondaryAction'),
              onAction() {
                setConfirmTransactionModalActive(false);
              },
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <Banner
                title={t('royaltySplitPage.confirmationModal.banner.title')}
                status={'warning'}
              >
                <List>
                  <List.Item>
                    {t('royaltySplitPage.confirmationModal.banner.firstLine')}
                  </List.Item>
                  <List.Item>
                    {t('royaltySplitPage.confirmationModal.banner.secondLine')}
                  </List.Item>
                  <List.Item>
                    {t('royaltySplitPage.confirmationModal.banner.thirdLine')}
                  </List.Item>
                </List>
              </Banner>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
      {addRecipientFunction !== undefined && (
        <RecipientModal
          walletsAlreadyIncluded={tableRecipients
            .map((it) => it.wallet.toLowerCase())
            .filter((wallet) => wallet !== editRecipient?.wallet)}
          editRecipient={editRecipient}
          totalPercentage={totalPercentage}
          recipients={mergedRecipients}
          close={() => {
            setEditRecipient(undefined);
            setAddRecipientFunction(undefined);
          }}
          addRecipient={addRecipientFunction}
        />
      )}
      <Layout>
        <FormProvider {...methods}>
          <Layout.Section>
            <Card>
              <Card.Section>
                <TextInput
                  customOnChange={() => methods.clearErrors('splitName')}
                  required={t('royaltySplitPage.errors.nameRequired')}
                  disabled={deployed}
                  name={'splitName'}
                  maxLength={50}
                  label={t('royaltySplitPage.labels.name.label')}
                  helpText={t('royaltySplitPage.labels.name.helpText')}
                  error={methods.formState.errors.splitName?.message}
                  // Clear error on focus
                />
              </Card.Section>
            </Card>

            {formType === 'Create' ? (
              <>
                {/* Create Table */}
                <div className={style['padding-around-1-rem']}>
                  <Stack>
                    <Stack.Item fill>
                      <Heading>Recipients</Heading>
                    </Stack.Item>
                    <Stack.Item>
                      {tableRecipients.length !== 0 && !deployed && (
                        <Button
                          plain
                          removeUnderline
                          onClick={() => {
                            setAddRecipientFunction(addRow());
                          }}
                        >
                          Add Recipient
                        </Button>
                      )}
                      {tableRecipients.length !== 0 && deployed && (
                        <TextStyle variation={'subdued'}>
                          Add Recipient
                        </TextStyle>
                      )}
                    </Stack.Item>
                  </Stack>
                </div>
                <Card>
                  <Card.Section>
                    {tableRecipients.length === 0 ? (
                      <div
                        onMouseOver={() =>
                          methods.clearErrors('tableRecipients')
                        }
                        className={style['padding-around-2-rem']}
                      >
                        <Stack
                          vertical
                          distribution={'center'}
                          alignment={'center'}
                        >
                          <TextStyle variation="strong">
                            {t('royaltySplitPage.recipientsEmptyState.title')}
                          </TextStyle>
                          <TextStyle variation="subdued">
                            {t('royaltySplitPage.recipientsEmptyState.content')}
                          </TextStyle>
                          <Button
                            primary
                            onClick={() => setAddRecipientFunction(addRow())}
                          >
                            {t('royaltySplitPage.recipientsEmptyState.ctaText')}
                          </Button>
                          {methods.formState.errors.tableRecipients?.root
                            ?.message && (
                            <TextStyle variation={'negative'}>
                              {
                                methods.formState.errors.tableRecipients?.root
                                  ?.message
                              }
                            </TextStyle>
                          )}
                        </Stack>
                      </div>
                    ) : (
                      <>
                        {recipients && (
                          <>
                            <ResourceList
                              items={[
                                // Sort by share value and name
                                ...tableRecipients.sort((t1, t2) =>
                                  t1.share.compareTo(t2.share) === 0
                                    ? t1.name.localeCompare(t2.name)
                                    : t1.share.compareTo(t2.share)
                                ),
                                {
                                  uuid: '',
                                  wallet: '',
                                  share: new bigDecimal(''),
                                  name: '',
                                  total: true,
                                },
                              ]}
                              renderItem={(
                                selectedRecipient: TableRecipient
                              ) => {
                                return (
                                  <RoyaltySplitListItem
                                    selectedRecipient={selectedRecipient}
                                    disabled={deployed}
                                    totalPercentage={totalPercentage}
                                    deleteRow={deleteRow}
                                    editRow={(
                                      recipientRow: EditRecipient | undefined
                                    ) => {
                                      if (recipientRow) {
                                        setEditRecipient(recipientRow);
                                        setAddRecipientFunction(
                                          addRow(recipientRow.uuid)
                                        );
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Card.Section>
                </Card>
              </>
            ) : (
              <>
                {/* Edit Table */}
                {paymentSplitterContract ? (
                  <RoyaltySplitTableEdit
                    paymentSplitterContract={paymentSplitterContract}
                  />
                ) : (
                  <LoadingState />
                )}
              </>
            )}
          </Layout.Section>
          <Layout.Section secondary>
            <Card
              title={
                <Stack>
                  <Heading element="h2">
                    {t('royaltySplitPage.chainSection.title')}
                  </Heading>
                  {onTestMode && (
                    <Badge status="warning">{t('info.testMode')}</Badge>
                  )}
                </Stack>
              }
            >
              <Card.Section>
                <Stack vertical spacing={'tight'}>
                  <SelectInput
                    customOnChange={() => methods.clearErrors('blockchain')}
                    required={
                      formType === 'Create'
                        ? t('royaltySplitPage.errors.blockchainRequired')
                        : undefined
                    }
                    disabled={
                      formType === 'Edit' || deployed || !!lockBlockchain
                    }
                    name={'blockchain'}
                    error={methods.formState.errors.blockchain?.message}
                    label={t('royaltySplitPage.chainSection.selectInput.label')}
                    options={chainOptions(onTestMode)}
                  />
                </Stack>
              </Card.Section>
            </Card>
            <Card title={t('royaltySplitPage.bestPractice.title')}>
              <Card.Section>
                <TextStyle>
                  {t('royaltySplitPage.bestPractice.content')}
                </TextStyle>
              </Card.Section>
            </Card>
          </Layout.Section>
        </FormProvider>
      </Layout>
    </>
  );
};

const chainOptions = (onTestMode: boolean) => [
  {
    value: Blockchain.BASE_MAINNET,
    label: t(`blockchain.${Blockchain.BASE_MAINNET}`),
    disabled: onTestMode,
  },
  {
    value: Blockchain.ETHEREUM,
    label: t(`blockchain.${Blockchain.ETHEREUM}`),
    disabled: onTestMode,
  },
  {
    value: Blockchain.ETHEREUM_SEPOLIA,
    label: t(`blockchain.${Blockchain.ETHEREUM_SEPOLIA}`),
  },
  {
    value: Blockchain.POLYGON,
    label: t(`blockchain.${Blockchain.POLYGON}`),
    disabled: onTestMode,
  },
  ...(isEnvironmentTest()
    ? [
        {
          value: Blockchain.FAKE_ETHEREUM_ON_SEPOLIA,
          label: t(`blockchain.${Blockchain.FAKE_ETHEREUM_ON_SEPOLIA}`),
        },
        {
          value: Blockchain.FAKE_BASE_ON_BASE_SEPOLIA,
          label: t(`blockchain.${Blockchain.FAKE_BASE_ON_BASE_SEPOLIA}`),
        },
      ]
    : []),
];
