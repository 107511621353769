import { useMutation } from 'react-query';
import axios from 'axios';

import API from 'utils/api';

export type UploadImageProps = {
  file: File;
};

export const uploadImage = (file: File): Promise<string> => {
  return API()
    .post(
      'customize/media/upload',
      {},
      {
        headers: {
          'Content-Type': file.type,
        },
      }
    )
    .then((res) => {
      const options = {
        headers: {
          'Content-Type': file.type,
          'X-Amz-Acl': 'public-read',
        },
      };

      return axios.put(res.data, file, options);
    })
    .then((res) => {
      const splitUrl = res.config.url?.split('?');
      const url =
        splitUrl !== undefined && splitUrl.length > 1 ? splitUrl[0] : undefined;
      if (url === undefined) {
        throw new Error('failed to get images public url');
      }

      return url;
    });
};

export default function useUploadImage() {
  return useMutation(({ file }: UploadImageProps) => uploadImage(file), {
    onError: () => {
      throw new Error('failed to upload image');
    },
  });
}
