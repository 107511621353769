import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';
import { Blockchain } from '../types/Blockchain';

export interface PayeeWithName {
  address: string;
  name: string | null;
}

export type PaymentSplitterType = 'SINGLE' | 'SPLITTER';

export interface RoyaltyAddress {
  id: string;
  accountId: string;
  blockchain: Blockchain;
  walletId: string;
  payeesRaw: string[];
  ratios: number[];
  name: string | undefined;
  deployingTx: string | null;
  createdAt: string;
  updatedAt: string;
  contractAddress: string | null;
  type: PaymentSplitterType;
}

export type RoyaltyAddressWithPayees = {
  payees: PayeeWithName[];
} & RoyaltyAddress;

export interface GetRoyaltyAddressesResponse {
  royaltyAddresses: RoyaltyAddressWithPayees[];
  verisartWallet: string | null;
  shopWallet: string | null;
}

const fetchRoyaltyAddresses =
  async (): Promise<GetRoyaltyAddressesResponse> => {
    return (await API().get('/shopify/royaltyAddresses')).data;
  };

export const useGetRoyaltyAddresses = (
  refetchOnReconnect?: boolean,
  refetchOnWindowFocus?: boolean
): UseQueryResult<GetRoyaltyAddressesResponse> => {
  return useQuery<GetRoyaltyAddressesResponse>(
    'royaltyAddress',
    fetchRoyaltyAddresses,
    {
      refetchOnReconnect: refetchOnReconnect ?? true,
      refetchOnWindowFocus: refetchOnWindowFocus ?? true,
    }
  );
};
