import React, { Dispatch, SetStateAction } from 'react';
import { Pagination } from '@shopify/polaris';

export function determinePageSize<T>(
  items: T[],
  currentPage: number,
  pageSize: number,
  numberOfPages: number
): T[] {
  let itemsToReturn;

  if (!items) return [];

  if (currentPage < numberOfPages) {
    itemsToReturn = items.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  } else {
    itemsToReturn = items.slice((currentPage - 1) * pageSize, items.length);
  }
  return [...itemsToReturn];
}

export type ContractsListProps = {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  numberOfPages: number;
};

const LocalPagination: React.FC<ContractsListProps> = ({
  currentPage,
  setCurrentPage,
  numberOfPages,
}) => {
  return (
    <Pagination
      hasPrevious={currentPage > 1}
      onPrevious={() => {
        setCurrentPage(currentPage - 1);
      }}
      hasNext={currentPage < numberOfPages}
      onNext={() => {
        setCurrentPage(currentPage + 1);
      }}
    />
  );
};

export default LocalPagination;
