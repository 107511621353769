import React, { useEffect, useState } from 'react';
import t from 'lib/translation';
import DropZoneWithLoader, { DropZoneFileTypes } from './DropZoneWithLoader';
import { BannerStatus, Stack } from '@shopify/polaris';
import { isModelFile, isVideoFile } from 'utils/validation';
import ModelViewer from '../../../components/ModelViewer';

export interface ChangeAsset {
  uploadFile: (file: File) => Promise<void>;
  fileTypes: DropZoneFileTypes;
  setError: (
    message: string,
    title?: string,
    action?: { text: string; url: string },
    status?: BannerStatus
  ) => void;
}

export interface Asset {
  title?: string;
  asset?: string;
  changeAsset?: ChangeAsset;
  disabled?: boolean;
  /*
  this was put here because we are not showing the video assets if they are IPFS files because we do not see the extension of the file
  */
  forceVideo?: Boolean;
  inherited?: boolean;
}

export const AssetRenderer: React.FC<Asset> = ({
  asset,
  title,
  changeAsset,
  disabled,
  forceVideo,
  inherited,
}) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (asset) {
      const img = new Image();
      img.onload = function () {
        setWidth(img.width);
        setHeight(img.height);
      };
      img.src = asset;
    }
  }, [asset]);

  if (asset === undefined && changeAsset === undefined) {
    throw new Error(
      'Asset being undefined means that changeAsset must NOT be undefined'
    );
  }
  return (
    <>
      {asset !== undefined ? (
        <>
          <div key={title}>
            <div>
              <div>
                <p>{title}</p>
                <div
                  style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}
                >
                  <div>
                    {isVideoFile(asset) || forceVideo ? (
                      <>
                        <video
                          onClick={() => window.open(asset)}
                          style={{ width: '15rem', cursor: 'pointer' }}
                          src={asset}
                          loop
                          muted
                          autoPlay
                        />
                      </>
                    ) : isModelFile(asset) ? (
                      <ModelViewer
                        src={asset}
                        mode="off"
                        className=""
                        style={{ width: '15rem', cursor: 'pointer' }}
                        onClick={() => window.open(asset)}
                      />
                    ) : (
                      <>
                        <img
                          onClick={() => window.open(asset)}
                          style={{ width: '15rem', cursor: 'pointer' }}
                          src={asset}
                          alt={t('productsSection.metaDataForms.titleImgAlt')}
                        />
                      </>
                    )}
                    <span>
                      {width && height ? (
                        <>
                          {width} x {height} px{' '}
                        </>
                      ) : null}
                      {inherited && <i>(From product)</i>}
                    </span>
                  </div>
                  {changeAsset !== undefined && (
                    <DropZoneWithLoader
                      fileTypes={changeAsset.fileTypes}
                      uploadFile={changeAsset.uploadFile}
                      setError={changeAsset.setError}
                      disabled={disabled}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {changeAsset !== undefined ? (
            <>
              <Stack vertical>
                <Stack.Item>
                  {title}
                  <DropZoneWithLoader
                    fileTypes={changeAsset.fileTypes}
                    uploadFile={changeAsset.uploadFile}
                    setError={changeAsset.setError}
                  />
                </Stack.Item>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
