import React from 'react';

const LessThan = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9651_75343)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9998 16.0002C11.7438 16.0002 11.4878 15.9022 11.2928 15.7072L6.29276 10.7073C5.90176 10.3163 5.90176 9.68425 6.29276 9.29325L11.2928 4.29325C11.6838 3.90225 12.3158 3.90225 12.7068 4.29325C13.0978 4.68425 13.0978 5.31625 12.7068 5.70725L8.41376 10.0002L12.7068 14.2933C13.0978 14.6842 13.0978 15.3162 12.7068 15.7072C12.5118 15.9022 12.2558 16.0002 11.9998 16.0002Z"
        fill="#5C5F62"
      />
    </g>
    <defs>
      <clipPath id="clip0_9651_75343">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LessThan;
