import { useHistory, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

/**
 * Goes back in history if there is something on the stack otherwise goes to the fallback path
 */
export const useHistoryGoBack = (): ((fallbackPath: string) => void) => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(
    (fallbackPath: string) => {
      // See https://stackoverflow.com/a/70532858/19602673 and https://github.com/remix-run/history/blob/main/docs/api-reference.md#location
      if (location.key && location.key !== 'default') {
        history.goBack();
      } else {
        history.push(fallbackPath);
      }
    },
    [history, location.key]
  );
};
