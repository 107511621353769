import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import API from 'utils/api';

export type UpdateCertificationPreferenceProps = {
  topic: string;
};

export default function useUpdateCertificationPreference() {
  return useMutation(
    ({ topic }: UpdateCertificationPreferenceProps) =>
      API()
        .patch('/shopify/certificationPreference', {
          topic,
        })
        .then((res) => res.data),
    {
      onError: (err: AxiosError) => {
        if (err.response && err.response.status === 400) {
          throw new Error('Error: Open orders found');
        }
      },
    }
  );
}
