import { Badge, FooterHelp, Stack } from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';
import PricingCard from './PricingCard';
import type { Plan, AllPlansIncludingLegacy } from './plans';
import plans, { Frequency, NEW_PLANS } from './plans';
import styles from './PricingCard.module.css';
import { getPlatform } from 'containers/AppBridge';
import { PlanType } from '../../utils/plans';

interface PricingProps {
  currentPlan?: PlanType;
  onManagePlan?: () => Promise<void> | void;
  onPlanSelected?: (plan: PlanType) => Promise<void> | void;
  selectionDisabled?: boolean;
}

const Pricing: React.FC<PricingProps> = ({
  currentPlan,
  onManagePlan,
  onPlanSelected,
  selectionDisabled,
}) => {
  const frequency = Frequency.MONTH;

  // To support legacy plans, we actually include the old plan as a card (it won't be selectable though).
  // It will disappear once the user selects a new plan.
  const showPlans =
    currentPlan && !NEW_PLANS.includes(currentPlan)
      ? [currentPlan, ...NEW_PLANS]
      : NEW_PLANS;

  const planSections = showPlans.map((tier) => {
    const plan = plans[tier as AllPlansIncludingLegacy] as Plan;
    const isCurrentPlan = currentPlan === tier;

    const badge = isCurrentPlan ? (
      <Badge
        status="warning"
        statusAndProgressLabelOverride={t('pricing.badge.current')}
      >
        {t('pricing.badge.current')}
      </Badge>
    ) : undefined;
    let actionTitle: string | undefined;
    let onClickGet: (() => Promise<void> | void) | undefined;

    if (isCurrentPlan && onManagePlan) {
      actionTitle = t('pricing.plan.manage');
      onClickGet = onManagePlan;
    } else if (!isCurrentPlan) {
      onClickGet = async () => {
        await onPlanSelected?.(tier);
      };
    }

    return (
      <PricingCard
        key={tier}
        title={plan.title}
        price={plan.price[frequency]}
        features={plan.features}
        recommended={plan.recommended && currentPlan === undefined}
        badge={badge}
        actionTitle={actionTitle}
        onClickGet={onClickGet}
        disabled={selectionDisabled}
      />
    );
  });

  return (
    <Stack vertical>
      <Stack distribution="center" alignment="leading">
        {planSections}
      </Stack>
      <div className={styles.planFooter}>
        <FooterHelp>
          {getPlatform() === 'Shopify' ? t('footer.plan') : t('footer.wooPlan')}
        </FooterHelp>
      </div>
    </Stack>
  );
};

export default Pricing;
