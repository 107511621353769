import { ClientApplication } from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';

export const GUIDE_URL =
  'https://www.notion.so/verisart/Guide-to-Verisart-certification-on-Shopify-dde395b17f874336afd247ef0cd2816a';

export const openGuide = (app: ClientApplication) => {
  const redirect = Redirect.create(app);
  redirect.dispatch(Redirect.Action.REMOTE, {
    url: GUIDE_URL,
    newContext: true,
  });
};
