import { RGBAColor } from '@shopify/polaris';

export default function rgbToObject(color: string): RGBAColor {
  const colorMatch = color.match(/\(([^)]+)\)/);

  if (!colorMatch) {
    return { red: 0, green: 0, blue: 0, alpha: 0 };
  }

  const [red, green, blue, alpha] = colorMatch[1].split(',');
  const objColor = {
    red: parseInt(red, 10),
    green: parseInt(green, 10),
    blue: parseInt(blue, 10),
    alpha: parseInt(alpha, 10) || 1,
  };
  return objColor;
}
