import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useShopifyInstall, {
  cleanupShopParam,
} from '../hooks/useShopifyInstall';
import ErrorPage from '../auth/ErrorPage';
import LoadingPage from '../auth/LoadingPage';

/**
 * Landing page to allow starting the install process. Requires the `?shop=xxx.myshopify.com` parameter set.
 *
 * You can also optionally set the `vst_campaign` query param. If this is present we'll update the
 * `Latest Campaign` property of the user in Mixpanel.
 *
 * Normally installation is done from the app store so this is mainly used for campaign style links.
 */
const Install: React.FC = () => {
  const [error, setError] = useState(false);
  const location = useLocation();
  const search = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const { authorizationUrl } = useShopifyInstall();

  useEffect(() => {
    const go = async () => {
      const thisShop = search.get('shop');
      if (!thisShop) {
        setError(true);
        return;
      }

      if (!authorizationUrl) {
        return;
      }

      try {
        await mixpanel.identify(cleanupShopParam(thisShop));
        await mixpanel.track('Install Landing Page');
      } catch (e) {
        console.error('Caught error tracking', e);
      }

      window.location.assign(authorizationUrl);
    };
    go();
  }, [search, authorizationUrl]);

  if (error) {
    return <ErrorPage />;
  }

  return <LoadingPage />;
};

export default Install;
