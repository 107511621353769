import { useMutation, useQueryClient } from 'react-query';
import API from '../utils/api';

export default function useDeleteCustomization() {
  const queryCache = useQueryClient();

  return useMutation(
    async (customization: string) => {
      await API().delete(`/customize/me/${customization}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('customization');
      },
    }
  );
}
