import React, { useCallback } from 'react';
import { UsageCharge } from '../../hooks/useGetUsageCharges';
import { Banner, Card, Icon, Link, Stack, TextStyle } from '@shopify/polaris';
import { CircleInformationMajor } from '@shopify/polaris-icons';
import t, { parameters as p } from 'lib/translation';
import { DateTime } from 'luxon';
import { useAppBridge } from 'containers/AppBridge';
import { isPanelDisabled } from '../../platform/platform';

interface UsageChargesPanelProps {
  usageCharges?: UsageCharge;
}

export const UsageChargesPanel: React.FC<UsageChargesPanelProps> = ({
  usageCharges,
}) => {
  const appBridge = useAppBridge();

  const navigateToBilling = useCallback(() => {
    appBridge.redirect('Admin', { type: 'subscription' });
  }, [appBridge]);
  if (isPanelDisabled('usage_charges', appBridge.platform)) return null;

  return (
    <>
      {usageCharges && (
        <Card
          title={t('settings.usageCharges.title')}
          actions={[
            {
              onAction: navigateToBilling,
              content: t('settings.usageCharges.action'),
            },
          ]}
        >
          <Card.Section>
            <Stack vertical>
              {usageCharges.inWarning && (
                <Banner
                  status="warning"
                  title={t('settings.usageCharges.banner.title')}
                >
                  <Stack spacing={'extraTight'}>
                    <p>{t('settings.usageCharges.banner.description')}</p>
                    <Link onClick={navigateToBilling}>
                      {t('settings.usageCharges.banner.cta')}
                    </Link>
                  </Stack>
                </Banner>
              )}
              <Stack vertical spacing={'tight'}>
                <Stack spacing={'extraTight'} wrap>
                  <TextStyle variation={'strong'}>
                    <TextStyle
                      variation={usageCharges.inWarning ? 'warning' : undefined}
                    >
                      ${usageCharges.balanceUsed}{' '}
                      {t('settings.usageCharges.currencyPost')}
                    </TextStyle>
                  </TextStyle>
                  <p>{t('settings.usageCharges.currentUsage')}</p>
                </Stack>
                <Stack wrap spacing={'extraTight'}>
                  <p>{t('settings.usageCharges.capCharges.pre')}</p>
                  <TextStyle variation="strong">
                    ${usageCharges.cappedAmount}{' '}
                    {t('settings.usageCharges.currencyPost')}
                  </TextStyle>
                  <p>
                    {p(
                      'settings.usageCharges.capCharges.post',
                      formatDateFromBackend(usageCharges.resetDate)
                    )}
                  </p>
                </Stack>
              </Stack>
              <Stack spacing={'tight'}>
                <Icon source={CircleInformationMajor} />
                <p>{t('settings.usageCharges.verisartCharges')}</p>
              </Stack>
            </Stack>
          </Card.Section>
        </Card>
      )}
    </>
  );
};

export const formatDateFromBackend = (date: string) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
};
