import React from 'react';
import { Button, DisplayText, TextStyle } from '@shopify/polaris';
import t from 'lib/translation';
import useGetStartedBanner from 'store/useGetStartedBanner';

import styles from './GetStarted.module.scss';
import Tick from '../../assets/svgComponents/Tick';

interface GetStartedProps {
  loading?: boolean;
  onStartClicked?: () => void;
}

const GetStarted: React.FC<GetStartedProps> = ({ loading, onStartClicked }) => {
  const { updateBannerStatus } = useGetStartedBanner();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.info}>
          <DisplayText element="h1" size="large">
            {t('dashboard.getStarted.title')}
          </DisplayText>
          <div className={styles.spacer}>
            <TextStyle variation="strong">
              <Tick />
              {t('dashboard.getStarted.guidanceText')}
            </TextStyle>
            <TextStyle variation="strong">
              <Tick />
              {t('dashboard.getStarted.guidanceText2')}
            </TextStyle>
            <TextStyle variation="strong">
              <Tick />
              {t('dashboard.getStarted.guidanceText3')}
            </TextStyle>
          </div>
          <Button
            primary
            size={'large'}
            disabled={loading}
            loading={loading}
            onClick={() => {
              updateBannerStatus(true);
              onStartClicked?.();
            }}
          >
            {t('dashboard.getStarted.buttonGetStarted')}
          </Button>
        </div>
        <img
          height="480px"
          width="auto"
          src={`${window.location.origin}/images/shopify-dashboard.png`}
          alt="Onboarding Illustration"
        />
      </div>
    </div>
  );
};

export default GetStarted;
