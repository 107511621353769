import React, { useCallback, useState } from 'react';
import { Button, Popover, Subheading, TextContainer } from '@shopify/polaris';
import UnlockIcon from 'assets/svgComponents/UnlockIcon';
import { Capability } from 'hooks/useGetCapabilities';
import t from 'lib/translation';
import { PlanType } from 'utils/plans';
import styles from './SettingsPage.module.css';

type CapabilityDetails = {
  capability: Capability;
  supportedCapabilities: Record<Capability, PlanType>;
};

const UnlockButtonAndModal: React.FC<CapabilityDetails> = ({
  capability,
  supportedCapabilities,
}) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const planNeeded: PlanType = supportedCapabilities[capability];

  const planName =
    planNeeded === 'BUSINESS' ||
    planNeeded === 'BUSINESS_STARTER' ||
    planNeeded === 'BUSINESS_PREMIUM' ||
    planNeeded === 'BUSINESS_PRO'
      ? 'Business'
      : 'Premium';

  const togglePopoverActive = useCallback(
    () => setPopoverActive((thisActive) => !thisActive),
    []
  );

  const activator = (
    <div className={`${styles.unlockButtonColour}`}>
      <Button icon={UnlockIcon} plain onClick={togglePopoverActive}>
        Unlock
      </Button>
    </div>
  );

  return (
    <div>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={togglePopoverActive}
      >
        <Popover.Pane fixed>
          <Popover.Section>
            <TextContainer>
              <Subheading>{`${planName} Plan Feature`}</Subheading>
              <p>
                {`${t('settingsSection.onlyAvailablePtOne')} ${planName} ${t(
                  'settingsSection.onlyAvailablePtTwo'
                )}`}
              </p>
              <div className={`${styles.unlockButtonColour}`}>
                <Button plain url="/plan">
                  {`Upgrade ${planName}`}
                </Button>
              </div>
            </TextContainer>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default UnlockButtonAndModal;
