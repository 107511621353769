import { useMutation, useQueryClient } from 'react-query';
import API from '../utils/api';

export interface EthereumGasLimitGwei {
  [key: string]: number | null;
}

const setGweiLimits = async (gasLimitGwei: EthereumGasLimitGwei) => {
  await API().put('/account/me/gasLimit', { gasLimitGwei });
};

const useGweiLimits = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    async (newEthereumGasLimit: EthereumGasLimitGwei) => {
      await setGweiLimits(newEthereumGasLimit);
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries('newEthereumGasLimit');
      },
    }
  );
  return {
    putGweiState: (newEthereumGasLimit: EthereumGasLimitGwei) =>
      mutateAsync(newEthereumGasLimit),
  };
};

export default useGweiLimits;
