import React from 'react';
import { PaymentSplitterContractRenderable } from '../../hooks/useProductFormValues';
import style from './RoyaltySplit.module.scss';
import {
  Layout,
  Card,
  TextStyle,
  Stack,
  Heading,
  ResourceList,
} from '@shopify/polaris';
import { TableRecipient } from './RoyaltySplitForm';
import generateUuid from '@shopify/app-bridge/actions/uuid';
import bigDecimal from 'js-big-decimal';
import RoyaltySplitListItem from './RoyaltySplitListItem';

interface RoyaltySplitTableEditProps {
  paymentSplitterContract: PaymentSplitterContractRenderable;
}

const RoyaltySplitTableEdit: React.FC<RoyaltySplitTableEditProps> = ({
  paymentSplitterContract,
}) => {
  const recipients: TableRecipient[] = paymentSplitterContract.payees.map(
    (payee, idx) => ({
      uuid: generateUuid(),
      name: payee.name ?? '<Unknown>',
      wallet: payee.address,
      share: new bigDecimal(paymentSplitterContract.ratios[idx]),
      disabled: true,
    })
  );

  return (
    <Layout>
      <Layout.Section>
        <div className={style['padding-around-1-rem']}>
          <Stack>
            <Stack.Item fill>
              <Heading>Recipients</Heading>
            </Stack.Item>
            <Stack.Item>
              <TextStyle variation={'subdued'}>Add Recipient</TextStyle>
            </Stack.Item>
          </Stack>
        </div>
        <Card>
          <Card.Section>
            <ResourceList
              items={[
                ...recipients,
                {
                  uuid: '',
                  wallet: '',
                  share: new bigDecimal(''),
                  name: '',
                  total: true,
                },
              ]}
              renderItem={(selectedRecipient: TableRecipient) => {
                return (
                  <RoyaltySplitListItem
                    normalise
                    selectedRecipient={selectedRecipient}
                    disabled={true}
                    totalPercentage={recipients
                      .map((it) => it.share)
                      .reduce(
                        (soFar, current) => soFar.add(current),
                        new bigDecimal('0')
                      )
                      .divide(new bigDecimal('100'), 2)}
                  />
                );
              }}
            />
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default RoyaltySplitTableEdit;
