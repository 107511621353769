import { useMutation, UseMutationResult } from 'react-query';
import API from '../utils/api';

export type RecoverWalletResponse = {
  code: string;
};

const useRecoverWallet = (): UseMutationResult<
  RecoverWalletResponse,
  unknown,
  void
> => {
  return useMutation(async () => {
    const response = await API().post('/custodialWallet/default/recover');
    return response.data;
  });
};

export default useRecoverWallet;
