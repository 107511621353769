import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Link, RadioButton } from '@shopify/polaris';

interface RadioButtonProps {
  name: string;
  label: ReactNode;
  helpText?: string;
  value: string;
  linktext?: string;
  linkUrl?: string;
}
const RadioButtonInput: React.FC<RadioButtonProps> = ({
  name,
  label,
  value,
  helpText,
  linktext,
  linkUrl,
}) => {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <RadioButton
          helpText={
            linktext && helpText ? (
              <>
                {helpText}{' '}
                <Link url={linkUrl} removeUnderline>
                  {linktext}
                </Link>
              </>
            ) : (
              helpText
            )
          }
          label={label}
          onChange={() => {
            field.onChange(value);
          }}
          checked={field.value === value}
        />
      )}
    />
  );
};

export default RadioButtonInput;
