import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PolarisLink = ({ children, url = '', external, ...rest }: any) => {
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    return (
      <a
        href={url}
        {...{ ...rest, target: '_blank', rel: 'noopener noreferrer' }}
      >
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
};

export default PolarisLink;
