import * as React from 'react';

function MinusIcon() {
  return (
    <svg
      width={16}
      height={2}
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0H1a1 1 0 000 2h14a1 1 0 100-2z"
        fill="#637381"
      />
    </svg>
  );
}

export default MinusIcon;
