import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';

interface Range {
  low: string;
  high: string;
}

interface ChainWithRange {
  ethereum: Range;
  polygon: Range;
  ethereumFiat: Range;
  polygonFiat: Range;
}

export type Action = 'deployingContract' | 'minting' | 'paymentSplitter';

type FeeRange = {
  [key in Action]: ChainWithRange;
};

async function fetchFeeRange(forGwei?: number): Promise<FeeRange> {
  const res = await API().get('/nft/costEstimates', {
    params: {
      forGwei: forGwei,
    },
  });
  return res.data;
}

export const useGetFeeRange = (forGwei?: number): UseQueryResult<FeeRange> => {
  return useQuery(['feeRange', forGwei], () => fetchFeeRange(forGwei), {
    enabled: forGwei === undefined || !isNaN(forGwei),
  });
};
