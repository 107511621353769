import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';

type AttemptedOnboardingStatus =
  | 'ALREADY_ONBOARDED'
  | 'STARTED'
  | 'NEED_ARTIST'
  | 'NEED_PLAN';
interface AttemptOnboardingResponse {
  status: AttemptedOnboardingStatus;
}

const fetchAttemptOnboarding = async (): Promise<AttemptOnboardingResponse> => {
  return (await API().post('/shopify/attemptOnboard')).data;
};

export const useAttemptOnboarding =
  (): UseQueryResult<AttemptOnboardingResponse> => {
    return useQuery<AttemptOnboardingResponse>('attemptOnboarding', () =>
      fetchAttemptOnboarding()
    );
  };
