import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

export default function useCancelSubscription() {
  const queryCache = useQueryClient();

  return useMutation(() => API().delete('/shop/me/subscription'), {
    onSuccess: () => {
      queryCache.invalidateQueries('subscriptionDetails');
    },
    onMutate: () => {
      queryCache.cancelQueries('subscriptionDetails');
      const previousDetails = queryCache.getQueryData('subscriptionDetails');

      const updatedDetails = {
        subscriptionDetails: {
          planType: 'FREE',
          currentPeriodEnd: null,
        },
      };
      queryCache.setQueryData('subscriptionDetails', updatedDetails);
      return () =>
        queryCache.setQueryData('subscriptionDetails', previousDetails);
    },
  });
}
