import {
  Badge,
  Banner,
  Button,
  Card,
  Heading,
  Layout,
  Link,
  Stack,
} from '@shopify/polaris';
import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import ControllerSelectInput from 'components/forms/SelectInput';
import DeployContractModal from 'components/DeployContractModal';
import useCreateContract from 'hooks/useCreateContract';
import { Blockchain } from 'types/Blockchain';
import t from 'lib/translation';
import usePatchContract from 'hooks/usePatchContract';
import { ContractListItemProps } from 'components/ContractListItem';
import { useContextualSaveBarOrExternal } from '../../hooks/useContextualSaveBar';
import withSaveToast from '../../hooks/withSaveToast';
import { useIntercom } from 'react-use-intercom';
import { IntercomEvents } from '../../interfaces/IntercomEvents';
import ContractsFormInputs from './ContractsFormInputs';
import { useHistory } from 'react-router';
import { ExternalMinor } from '@shopify/polaris-icons';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';
import { getPlatform, useAppBridge } from 'containers/AppBridge';
import mixpanel from 'mixpanel-browser';
import { isEnvironmentTest } from '../../constants';

export type ContractsFormValues = {
  contractName?: string;
  contractSymbol?: string;
  description: string;
  chain: Blockchain;
};

export type ContractsFormProps = {
  formType: 'Create' | 'Edit';
  id?: string;
  onSuccess?: (contractId: string) => void;
  data?: ContractListItemProps;
  onTestMode: boolean;

  /**
   * If set will preset the chain to this and mark it as disabled (create mode only)
   */
  lockBlockchain?: Blockchain;

  /**
   * Used to allow having the save buttons exist outside of this form. If set then this will be invoked with a function
   * which can be called when you're ready to save.
   *
   * If undefined then this form will use the `useContextualSaveBar()` save bar
   */
  setSaveCallback?: (saveCallback: () => void) => void;
};

const ContractsForm: React.FC<ContractsFormProps> = ({
  formType,
  id,
  onSuccess,
  data,
  onTestMode,
  lockBlockchain,
  setSaveCallback,
}) => {
  const { trackEvent } = useIntercom();
  const methods = useForm<ContractsFormValues>({
    defaultValues: {
      contractName: data?.name,
      contractSymbol: data?.symbol,
      description: data?.description,
      chain: data?.blockchain ?? lockBlockchain,
    },
  });

  const { mutateAsync: createContract, isLoading } = useCreateContract();
  const { mutateAsync: editContract } = usePatchContract();

  const [apiError, setApiError] = useState<string | ReactNode | null>(null);
  const [contractId, setContractId] = useState(id);

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const history = useHistory();

  const getBackendErrorMessage = (error: AxiosError) => {
    if (error?.response?.status === 500) {
      return t('createContractForm.errors.generalError');
    } else {
      const rawErrorMessage = error?.response?.data?.message;
      const errorMap = {
        'bad.contract.name': t('createContractForm.errors.badContractName'),
        'bad.contract.symbol': t('createContractForm.errors.badContractSymbol'),
        'quota.limit.exceeded': t('createContractForm.errors.quotaExceeded'),
        'bad.contract.description.too.long': t(
          'createContractForm.errors.descriptionTooLong'
        ),
        'bad.token.symbol.too.long': t(
          'createContractForm.errors.symbolTooLong'
        ),
        'bad.contract.name.too.long': t(
          'createContractForm.errors.nameTooLong'
        ),
        'not.enough.gas': (
          <>
            {t('createContractForm.errors.notEnoughGas')}
            <Link url="https://help.verisart.com/en/articles/6694581-merchant-minting-wallet#h_0061643ca5">
              {t('createContractForm.errors.helpCenter')}
            </Link>
          </>
        ),
      };
      let errorMessage: ReactNode | string = t(
        'createContractForm.errors.generalError'
      );
      for (const [key, value] of Object.entries(errorMap)) {
        if (rawErrorMessage.includes(key)) {
          errorMessage = value;
        }
      }
      return errorMessage;
    }
  };

  const submitEditContract = useCallback(
    async (values: ContractsFormValues) => {
      if (!contractId) {
        throw new Error('Id cannot be undefined');
      }
      try {
        await editContract({ id: contractId, description: values.description });
      } catch (e) {
        setApiError('Could not update your contract');
        throw e;
      }
    },
    [contractId, editContract]
  );

  const submitForm = async (values: ContractsFormValues) => {
    try {
      const response = await createContract({
        contractName: values.contractName,
        contractSymbol: values.contractSymbol,
        description: values.description,
        chain: values.chain,
      });
      mixpanel.track('Contract Created', {
        platform: getPlatform(),
      });
      trackEvent(IntercomEvents.contractCreated);
      onSuccess?.(response.id);
      setContractId(response.id);
      if (history.location.pathname === '/contracts/create') {
        history.replace('/contracts');
      }
    } catch (err) {
      setApiError(getBackendErrorMessage(err as AxiosError));
    } finally {
      closeModal();
    }
  };

  // Form submission

  const { handleSubmit } = methods;
  const app = useAppBridge();
  const onSave = useCallback(async () => {
    const formValid = await methods.trigger();
    if (!formValid) return;
    if (formType !== 'Create') {
      withSaveToast(
        async () => {
          await handleSubmit(submitEditContract)();
          return '';
        },
        app.showToast,
        {
          successMessage: t('createContractForm.toast.editSuccess'),
          errorMessage: t('createContractForm.toast.editError'),
        }
      );
    } else {
      openModal();
    }
  }, [app, formType, handleSubmit, methods, submitEditContract]);

  useContextualSaveBarOrExternal(methods, onSave, setSaveCallback);

  // Form reset

  const {
    formState: { isSubmitSuccessful },
    reset,
  } = methods;

  const { getValues } = methods;
  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues]);

  return (
    <>
      {isModalOpen && (
        <DeployContractModal
          hideModal={() => {
            closeModal();
          }}
          open={isModalOpen}
          primaryAction={() =>
            withSaveToast(
              async () => {
                closeModal();
                await methods.handleSubmit(submitForm)();
                return '';
              },
              app.showToast,
              {
                successMessage: t('createContractForm.toast.createSuccess'),
                errorMessage: t('createContractForm.toast.createError'),
              }
            )
          }
          gasCost={''}
          loading={isLoading}
        />
      )}

      <FormProvider {...methods}>
        <form>
          <Layout>
            {apiError && (
              <Layout.Section>
                <Banner
                  title={t('createContractForm.errors.errorTitle')}
                  status={'critical'}
                >
                  {apiError ? (
                    <p>{apiError}</p>
                  ) : (
                    <div>
                      <ul>
                        {methods.formState.errors.contractName && (
                          <li>{t('createContractForm.contractName.title')}</li>
                        )}
                        {methods.formState.errors.contractSymbol && (
                          <li>
                            {t('createContractForm.contractSymbol.title')}
                          </li>
                        )}
                        {methods.formState.errors.description && (
                          <li>{t('createContractForm.description.title')}</li>
                        )}
                      </ul>
                    </div>
                  )}
                </Banner>
              </Layout.Section>
            )}
            <Layout.Section>
              <Card>
                <Card.Section>
                  <ContractsFormInputs methods={methods} formType={formType} />
                </Card.Section>
              </Card>
              {/* <Card>
              <Card.Section>
                <FormLayout>
                  <TextInput
                    name="contractName"
                    label={t('createContractForm.contractName.title')}
                    placeholder={t('createContractForm.placeHolders.name')}
                    helpText={t('createContractForm.helpText.name')}
                    required={
                      formType === 'Create'
                        ? t('createContractForm.contractName.errorMessage')
                        : undefined
                    }
                    maxLength={32}
                    error={methods.formState.errors?.contractName?.message}
                    disabled={formType === 'Edit'}
                  />
                  <TextInput
                    name="contractSymbol"
                    label={t('createContractForm.contractSymbol.title')}
                    placeholder={t('createContractForm.placeHolders.symbol')}
                    helpText={t('createContractForm.helpText.symbol')}
                    required={
                      formType === 'Create'
                        ? t('createContractForm.contractSymbol.errorMessage')
                        : undefined
                    }
                    maxLength={6}
                    error={methods.formState.errors?.contractSymbol?.message}
                    disabled={formType === 'Edit'}
                  />
                  <TextInput
                    name="description"
                    label={t('createContractForm.description.title')}
                    helpText={t('createContractForm.helpText.description')}
                    required={t(
                      'createContractForm.description.errorMessage'
                    )}
                    maxLength={1000}
                    error={methods.formState.errors?.description?.message}
                    textbox={3}
                  />
                </FormLayout>
              </Card.Section>
            </Card> */}
            </Layout.Section>
            <Layout.Section secondary>
              <Card
                title={
                  <Stack>
                    <Heading element="h2">
                      {t('royaltySplitPage.chainSection.title')}
                    </Heading>
                    {onTestMode && (
                      <Badge status="warning">{t('info.testMode')}</Badge>
                    )}
                  </Stack>
                }
              >
                <Card.Section>
                  <Stack vertical spacing={'tight'}>
                    <ControllerSelectInput
                      label={t(
                        'royaltySplitPage.chainSection.selectInput.label'
                      )}
                      name="chain"
                      options={[
                        {
                          value: Blockchain.BASE_MAINNET,
                          label: t(`blockchain.${Blockchain.BASE_MAINNET}`),
                          disabled: onTestMode,
                        },
                        {
                          value: Blockchain.ETHEREUM,
                          label: t(`blockchain.${Blockchain.ETHEREUM}`),
                          disabled: onTestMode,
                        },
                        {
                          value: Blockchain.ETHEREUM_SEPOLIA,
                          label: t(`blockchain.${Blockchain.ETHEREUM_SEPOLIA}`),
                        },
                        {
                          value: Blockchain.POLYGON,
                          label: t(`blockchain.${Blockchain.POLYGON}`),
                          disabled: onTestMode,
                        },
                        ...(isEnvironmentTest()
                          ? [
                              {
                                value: Blockchain.FAKE_ETHEREUM_ON_SEPOLIA,
                                label: t(
                                  `blockchain.${Blockchain.FAKE_ETHEREUM_ON_SEPOLIA}`
                                ),
                              },
                              {
                                value: Blockchain.FAKE_BASE_ON_BASE_SEPOLIA,
                                label: t(
                                  `blockchain.${Blockchain.FAKE_BASE_ON_BASE_SEPOLIA}`
                                ),
                              },
                            ]
                          : []),
                      ]}
                      required={t('createContractForm.chain.errorMessage')}
                      error={methods.formState.errors?.chain?.message}
                      disabled={formType === 'Edit' || !!lockBlockchain}
                    />
                  </Stack>
                </Card.Section>
              </Card>
              <Card>
                <Card.Header title={t('createContractForm.manifold.title')} />
                <Card.Section>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <img
                        style={{ paddingTop: '1rem' }}
                        height="48"
                        width="100"
                        alt="Manifold Logo"
                        src="/images/manifold.png"
                      />
                      <p style={{ paddingLeft: '1rem' }}>
                        {t('createContractForm.manifold.body')}
                      </p>
                    </div>
                    <Button
                      icon={ExternalMinor}
                      external
                      onClick={() => {
                        mixpanel.track('Manifold Contract Import Started', {
                          Platform: getPlatform(),
                        });
                        openWindowOnVerisartDotCom(
                          '/settings/wallets-and-contracts'
                        );
                      }}
                    >
                      {t('createContractForm.manifold.button')}
                    </Button>
                    <div style={{ textAlign: 'center' }}>
                      <Link
                        url="https://help.verisart.com/en/articles/6930817-mint-on-manifold-contracts-via-verisart-and-shopify"
                        removeUnderline
                      >
                        {t('createContractForm.manifold.link')}
                      </Link>
                    </div>
                  </div>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </form>
      </FormProvider>
    </>
  );
};

export default ContractsForm;
