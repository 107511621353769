import { useQuery } from 'react-query';
import API from '../utils/api';

export interface Certificate {
  // Sometimes the thumbnail is null as the product has no image set on Shopify
  thumbnail: string | null;
  title: string;
  status: 'READY' | 'NEEDS_REVIEW';
  productId: number;
  printId: string | null;
}
interface CertificateResponse {
  certificates: Certificate[];
}
export default function useGetCertificates() {
  return useQuery<CertificateResponse>(['certificates'], async () => {
    const response = await API().get('/shopify/certificates');
    if (response.status !== 200) {
      return null;
    }
    return response.data;
  });
}
