interface FormatArtistNameOptions {
  lastNameBeforeFirst: boolean;
}

export const formatArtistName = (
  record: { firstName: string; lastName: string },
  opts?: Partial<FormatArtistNameOptions>
) => {
  const parts = [record.firstName, record.lastName].filter(
    (part) => part.length > 0
  );
  return opts?.lastNameBeforeFirst ?? true
    ? parts.reverse().join(', ')
    : parts.join(' ');
};
