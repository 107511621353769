import t from 'lib/translation';
import { getPlatform } from '../../containers/AppBridge';

export type CertificateTriggerType = 'ORDERS_PAID' | 'ORDERS_FULFILLED';

export interface CertificateTrigger {
  id: CertificateTriggerType;
  label: string;
  helpText: string;
  default?: boolean;
}

const ordersPaid: CertificateTrigger = {
  id: 'ORDERS_PAID',
  label: t('settings.certificationTrigger.ordersPaidLabel'),
  helpText: t('settings.certificationTrigger.ordersPaidHelpText'),
  default: true,
};

const ordersFulfilled: CertificateTrigger = {
  id: 'ORDERS_FULFILLED',
  label: t('settings.certificationTrigger.ordersFulfilledLabel'),
  helpText:
    getPlatform() === 'Woo'
      ? t('settings.certificationTrigger.ordersFulfilledHelpTextWooCommerce')
      : t('settings.certificationTrigger.ordersFulfilledHelpText'),
};

export const triggers = [ordersPaid, ordersFulfilled];
