import styles from '../ProductForm.module.scss';
import { Spinner, Thumbnail } from '@shopify/polaris';
import React from 'react';
import { isImageFile } from 'utils/validation';

function trim(s: string, max: number) {
  const len = s.length;
  if (len <= max) {
    return s;
  }

  return s.substring(0, max);
}

export function FileLoader(props: { files: Array<File> }) {
  return (
    <div className={styles.thumbnail}>
      <>
        {props.files.length > 0 && (
          <div className={styles.inner}>
            <span className={styles.thumbnail}>
              {isImageFile(props.files[0].name) && (
                <Thumbnail
                  size="medium"
                  alt={props.files[0].name}
                  source={URL.createObjectURL(props.files[0])}
                />
              )}
              {/*else if not image file then no need to show a thumbnail*/}
            </span>
            <span className={styles.uploadInfo}>
              {trim(props.files[0].name, 10)} <br></br> {props.files[0].size}{' '}
              bytes
            </span>
          </div>
        )}
      </>
      <div className={styles.spinner}>
        <Spinner size="small" />
      </div>
    </div>
  );
}
