import * as React from 'react';
import { Page, Card, DisplayText, Heading } from '@shopify/polaris';
import { ErrorIcon } from 'components/JsxIcons';
import Footer from 'components/Footer';
import t from 'lib/translation';
import styles from './ErrorComponent.module.css';
import { FallbackProps } from 'react-error-boundary';

const ErrorComponent: React.FC<FallbackProps> = ({ error }) => {
  return (
    <>
      <Page>
        <Card>
          <div className={`${styles.container}`}>
            <div className={`${styles.icon}`}>
              <ErrorIcon color="black" />
            </div>
            <DisplayText element="h1" size="large">
              {t('components.error.title')}{' '}
            </DisplayText>
            <div className={`${styles.info}`}>
              <Heading>{t('components.error.message')}</Heading>
            </div>
            <div className={`${styles.info}`}>
              <Heading>{t('components.error.technicalDetails')}</Heading>
            </div>
            <pre>{error?.message}</pre>
          </div>
        </Card>
      </Page>
      <Footer />
    </>
  );
};

export default ErrorComponent;
