import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import useSession from 'hooks/useSession';
import { getApiUrl } from '../constants';

const API = (authorized: boolean = true) => {
  const config: AxiosRequestConfig = {
    baseURL: getApiUrl(),
    timeout: 25000,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const HTTP: AxiosInstance = axios.create(config);

  if (authorized) {
    HTTP.interceptors.request.use((request) => {
      const { sessionToken } = useSession.getState();
      request.headers.Authorization = sessionToken
        ? `Bearer ${sessionToken}`
        : '';
      return request;
    });
  }

  return HTTP;
};

export default API;

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
