import { useQuery } from 'react-query';
import API from 'utils/api';

export type PrintMinimal = {
  id: string;
  shopId: string;
  productId: number;
  updatedAt: string;
  tokenGateId: string;
  templateApplied: boolean;
  name: string;
  createdAt: string;
  contractVerified: boolean;
};

export default function useGetPrints() {
  return useQuery<PrintMinimal[]>('print', async () => {
    const response = await API().get('shop/print');

    return response.data;
  });
}
