import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Layout,
  Page,
  PageActions,
  Select,
  SkeletonPage,
  Heading,
  DisplayText,
} from '@shopify/polaris';
import { useMutation, useQueryClient } from 'react-query';
import t from 'lib/translation';
import Spacer from './Spacer';
import CertificateCustomizationSettingsForm from './CertificateCustomizationSettingsForm';
import useCustomization from 'hooks/useCustomization';
import useDeleteCustomization from 'hooks/useDeleteCustomization';
import API from 'utils/api';
import { backgroundTypeForValue } from './customization';
import utilities from './utilities.module.css';
import withSaveToast from 'hooks/withSaveToast';
import assert from 'assert';
import { Toast } from '@shopify/app-bridge/actions';
import { CapabilityRequiredSettings } from './SettingsForm';
import useGetCapabilities from 'hooks/useGetCapabilities';
import { CapabilityResponse } from 'hooks/useGetCapabilities';
import mixpanel from 'mixpanel-browser';
import { getPlatform, useAppBridge } from 'containers/AppBridge';
import EmailSettingsButtons from './EmailSettingsButtons';

type CreateCustomizationInput = {
  background: string;
  logo: string;
  subheading: string;
  templateId?: string;
  templateName: string;
  link?: string;
};

const useCreateCustomization = () => {
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation(
    async (data: CreateCustomizationInput) => {
      const customization = await API().post('/customize/me', {
        ...data,
        background: data.background.length > 0 ? data.background : null,
      });
      await API().patch('/shop/me', {
        templateId: customization.data.templateId,
      });
      return customization.data as {
        templateId: string;
        customizationVersion: number;
      };
    },
    {
      mutationKey: 'customize',
      async onSuccess() {
        await queryClient.invalidateQueries('customize');
        queryClient.invalidateQueries('shop');
      },
    }
  );
  return { isLoading, createCustomization: mutateAsync };
};

const CustomizationSettingsPage = () => {
  const customizationQuery = useCustomization();
  const { mutateAsync: deleteCustomization } = useDeleteCustomization();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const { isLoading, createCustomization } = useCreateCustomization();
  const capabilitiesQuery = useGetCapabilities();
  const app = useAppBridge();

  let capabilities: CapabilityResponse | undefined = capabilitiesQuery.data;

  useEffect(() => {
    if (customizationQuery.data?.shopTemplateId) {
      setSelectedTemplateId(customizationQuery.data.shopTemplateId);
    }
  }, [customizationQuery.data?.shopTemplateId]);

  const handleTemplateSelection = useCallback(
    async (value: string) => {
      setSelectedTemplateId(value);
      let clearTemplateId: boolean;
      if (value === '') {
        clearTemplateId = true;
      } else {
        clearTemplateId = false;
      }
      if ((value && value !== 'New template') || value === '') {
        withSaveToast(
          async () => {
            await API().patch('/shop/me', {
              templateId: value,
              clearTemplateId: clearTemplateId,
            });
            return '';
          },
          app.showToast,
          {
            successMessage: t('settings.certificates.customizationSaved'),
            errorMessage: t('settings.certificates.customizationSaveFailed'),
          }
        );
      }
    },
    [app]
  );

  const deleteCertificateCustomization = async function () {
    assert(selectedTemplateId);
    try {
      await deleteCustomization(selectedTemplateId);
      setSelectedTemplateId('New template');
      const options: Toast.Options = {
        duration: 5000,
        message: '',
      };
      options.message = t('settings.certificates.deletedCustomization');
      app.showToast(options);
    } catch (e) {
      console.log('There is an error here');
    }
  };

  const templates =
    customizationQuery.data?.customizations
      .map((customization) => ({
        label: customization.templateName ?? '',
        value: customization.templateId ?? '',
      }))
      .filter((template) => !!template.value || !!template.label) ?? [];
  const selectedCustomization = customizationQuery.data?.customizations?.find(
    (customization) =>
      customization.templateId === (selectedTemplateId ?? templates[0]?.value)
  );

  const defaultValues = useMemo(
    () => ({
      templateName: selectedCustomization?.templateName ?? '',
      logo: selectedCustomization?.logo ?? '',
      link: selectedCustomization?.link ?? '',
      background: {
        type: backgroundTypeForValue(selectedCustomization?.background ?? ''),
        value: selectedCustomization?.background ?? '',
      },
      subheading: selectedCustomization?.subheading ?? '',
    }),
    [
      selectedCustomization?.templateName,
      selectedCustomization?.logo,
      selectedCustomization?.background,
      selectedCustomization?.subheading,
      selectedCustomization?.link,
    ]
  );

  if (customizationQuery.isLoading) {
    return <SkeletonPage />;
  }

  if (capabilities === undefined) {
    return null;
  }

  return (
    <Page
      title={t('settings.certificates.settingsPageTitle')}
      // Not sure why this is needed. After running `unimported` and removing a bunch of unused files this
      // started breaking.
      // @ts-ignore
      css={{
        marginBottom: 24,
      }}
    >
      <Layout>
        <Layout.Section fullWidth>
          <Heading>
            <DisplayText size="small">Certificate customization</DisplayText>
          </Heading>
          <Spacer />
          <CapabilityRequiredSettings
            title={t('settings.certificates.customizationsTitle')}
            description={t('settings.certificates.customizationsDescription')}
            image={
              <img
                width="416"
                height="272"
                src={`${window.location.origin}/images/unlock-customisation.png`}
                alt="Unlock customization preview"
              />
            }
            requiredCapability="certificates.customize"
            capabilities={capabilities}
          >
            <Card sectioned>
              <div className={utilities.ExtraTightLayout}>
                <Select
                  label={t('settings.certificates.templateLabel')}
                  value={selectedTemplateId ?? templates[0]?.value}
                  options={[
                    {
                      label: 'No customization',
                      value: '',
                    },
                    ...templates,
                    {
                      label: t('settings.certificates.newTemplateLabel'),
                      value: 'New template',
                    },
                  ]}
                  onChange={handleTemplateSelection}
                  disabled={isLoading}
                />
                <CertificateCustomizationSettingsForm
                  templateId={selectedTemplateId}
                  defaultValues={defaultValues}
                  onSubmit={async ({
                    background,
                    logo,
                    subheading,
                    templateName,
                    link,
                  }) => {
                    const { templateId } = await createCustomization({
                      background: background.value,
                      logo,
                      subheading,
                      templateId: selectedCustomization?.templateId,
                      templateName,
                      link,
                    });
                    setSelectedTemplateId(templateId);
                    mixpanel.track('Customization Added', {
                      Platform: getPlatform(),
                    });
                  }}
                />
              </div>

              {capabilities.capabilities.includes('certificates.customize') && (
                <PageActions
                  secondaryActions={[
                    {
                      content: t('tokenGateForm.deleteButton'),
                      destructive: true,
                      outline: true,
                      onAction: () => {
                        deleteCertificateCustomization();
                      },
                      disabled:
                        templates.length === 0 ||
                        selectedTemplateId === 'New template' ||
                        selectedTemplateId === '',
                    },
                  ]}
                />
              )}
            </Card>
          </CapabilityRequiredSettings>
          <Spacer />
        </Layout.Section>

        <Layout.Section fullWidth>
          <Heading>
            <DisplayText size="small">
              {t('settings.emailCustomization.settingsPageTitle')}
            </DisplayText>
          </Heading>
        </Layout.Section>
        <EmailSettingsButtons />
      </Layout>

      <Spacer />
    </Page>
  );
};

export default CustomizationSettingsPage;
