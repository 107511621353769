import React, { useEffect, useRef, useState } from 'react';
import { Banner, Button } from '@shopify/polaris';
import t from 'lib/translation';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import ApplyForMintingModal from '../ApplyForMintingModal';

interface ActivateNftBannerProps {
  alwaysActive?: boolean;
}

const NFT_ELIGIBILITY_LEVEL_KEY = 'nftEligibilityLevel';

const ActivateNftBanner: React.FC<ActivateNftBannerProps> = ({
  alwaysActive,
}) => {
  const applyButton = useRef<HTMLButtonElement>(null);
  const [showApply, setShowApply] = useState(false);
  const [newLevel, setNewLevel] = useState(false);
  const { isLoading, mintingAvailable, giftingEnabled, paidEnabled } =
    useMainnetMintingEnabled();
  let currentLevel: 'selling' | 'gifting' | 'none' | undefined;
  if (paidEnabled) {
    currentLevel = 'selling';
  } else if (giftingEnabled) {
    currentLevel = 'gifting';
  } else if (paidEnabled === false || giftingEnabled === false) {
    currentLevel = 'none';
  }

  useEffect(() => {
    let lastSeenLevel: string | undefined;
    try {
      lastSeenLevel =
        window.sessionStorage.getItem(NFT_ELIGIBILITY_LEVEL_KEY) ?? undefined;
    } catch (e) {
      console.error(
        `Failed reading ${NFT_ELIGIBILITY_LEVEL_KEY} from sessionStorage`,
        e
      );
    }
    setNewLevel(
      lastSeenLevel !== undefined &&
        currentLevel !== undefined &&
        lastSeenLevel !== currentLevel
    );
    if (currentLevel !== undefined) {
      try {
        window.sessionStorage.setItem(NFT_ELIGIBILITY_LEVEL_KEY, currentLevel);
      } catch (e) {
        console.error(
          `Failed writing ${NFT_ELIGIBILITY_LEVEL_KEY} to sessionStorage`,
          e
        );
      }
    }
  }, [currentLevel]);

  if (newLevel && currentLevel && currentLevel !== 'none') {
    const currentLevelKey =
      currentLevel.substring(0, 1).toUpperCase() + currentLevel.substring(1);

    return (
      <Banner
        title={t(`banners.nftBanner.newLevel${currentLevelKey}Header`)}
        status="success"
      >
        <p>{t(`banners.nftBanner.newLevel${currentLevelKey}Body`)}</p>
      </Banner>
    );
  } else if ((!mintingAvailable && !isLoading) || alwaysActive) {
    return (
      <Banner title={t('banners.nftBanner.header')} status="warning">
        <p>
          {t('banners.nftBanner.body')}{' '}
          <span ref={applyButton}>
            <Button plain monochrome onClick={() => setShowApply(true)}>
              {t('banners.nftBanner.linkText')}
            </Button>
          </span>
        </p>
        <ApplyForMintingModal
          activator={applyButton}
          open={showApply}
          onClose={() => setShowApply(false)}
        />
      </Banner>
    );
  }
  return null;
};

export default ActivateNftBanner;
