import * as React from 'react';

const OpenseaIcon = () => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm5.223-7.126v.515c0 .03-.018.056-.044.068-.161.069-.713.322-.942.64-.128.178-.249.372-.37.568-.437.7-.88 1.412-1.662 1.412h-4.17A2.68 2.68 0 0 1 3.36 9.392v-.047c0-.04.032-.072.072-.072h2.324c.046 0 .08.043.076.088a.794.794 0 0 0 .083.446c.138.28.424.456.733.456h1.15v-.899H6.661a.073.073 0 0 1-.06-.115l.023-.032.019-.028a8.29 8.29 0 0 0 .414-.661c.104-.183.206-.378.287-.573.015-.033.027-.066.04-.098l.003-.009.017-.048c.016-.045.032-.088.044-.131.014-.04.025-.082.036-.123l.007-.027a2.315 2.315 0 0 0 .045-.742 2.826 2.826 0 0 0-.023-.234 2.423 2.423 0 0 0-.032-.209 3.516 3.516 0 0 0-.066-.312l-.009-.04-.017-.063a7.973 7.973 0 0 0-.26-.796 8.71 8.71 0 0 0-.208-.497l-.03-.066a4.51 4.51 0 0 1-.062-.13 4.606 4.606 0 0 0-.069-.143l-.02-.041a.765.765 0 0 1-.028-.06l-.14-.26a.046.046 0 0 1 .051-.067l.88.239h.005l.116.032.128.037.046.013v-.523c0-.252.203-.457.453-.457.124 0 .238.051.32.134a.46.46 0 0 1 .132.323v.776l.093.026a.075.075 0 0 1 .022.01l.03.025c.02.014.042.03.067.05.014.01.027.022.042.034.021.018.044.038.07.057a6.704 6.704 0 0 1 .38.335 6.776 6.776 0 0 1 .584.612l.046.056.082.1.029.039c.043.057.087.115.127.176l.031.047a3.698 3.698 0 0 1 .21.35c.023.046.046.095.066.143a2.09 2.09 0 0 1 .152.485v.007a.76.76 0 0 1 .017.124 1.326 1.326 0 0 1-.072.582l-.006.019c-.018.05-.036.101-.059.15-.049.115-.107.229-.176.336a1.929 1.929 0 0 1-.117.18 2.548 2.548 0 0 1-.157.207 5.365 5.365 0 0 1-.378.439 2.26 2.26 0 0 1-.09.094l-.015.014a4.15 4.15 0 0 1-.14.133l-.09.083a.074.074 0 0 1-.048.018h-.7v.899h.88c.198 0 .385-.07.537-.198l.004-.004c.058-.051.28-.244.54-.532a.068.068 0 0 1 .034-.02l2.434-.704a.072.072 0 0 1 .091.069Zm-9.276-.605.035-.054 2.08-3.256a.071.071 0 0 1 .126.009c.348.78.648 1.748.507 2.352-.06.248-.224.584-.41.895-.023.045-.05.09-.077.132a.07.07 0 0 1-.06.031h-2.14a.071.071 0 0 1-.06-.11Z"
      fill="currentColor"
    />
  </svg>
);

export default OpenseaIcon;
