import create, { State, UseStore } from 'zustand';
import { getApiUrl } from '../constants';
import { getShopDomain } from '../containers/AppBridge';

/**
 * Returned by useSession() as the sessionToken if no account is linked yet
 */
export const ACCOUNT_NOT_CREATED = 'ACCOUNT_NOT_CREATED';

const fetchSession = async (
  sessionToken: string,
  shop: string
): Promise<string> => {
  const response = await fetch(`${getApiUrl()}/shopify/session`, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ sessionToken, shop }),
  });
  if (response.status !== 200) {
    throw Error(`Fetch session failed with status ${response.status}`);
  }
  const { session } = await response.json();
  return session;
};

export const isAccountCreated = (state: StateWithActions) =>
  !!state.sessionToken && state.sessionToken !== ACCOUNT_NOT_CREATED;
export const isAccountSessionLoaded = (state: StateWithActions) =>
  !!state.sessionToken;

interface StateWithActions extends State {
  sessionToken: string | null;
  refreshSessionToken(getSessionToken: () => Promise<string>): Promise<void>;
}

const useSession: UseStore<StateWithActions> = create((set) => {
  return {
    sessionToken: null,
    async refreshSessionToken(getSessionToken: () => Promise<string>) {
      const shopifySessionToken = await getSessionToken();
      const sessionToken = await fetchSession(
        shopifySessionToken,
        getShopDomain()
      );
      set({ sessionToken });
    },
  };
});

export default useSession;
