import {
  Banner,
  Button,
  Card,
  FormLayout,
  Layout,
  Link,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import TextInput from 'components/forms/TextInput';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import SimpleListItem from 'components/forms/SimpleListItem';
import ProductPickerWrapper from 'components/forms/ProductPickerWrapper';
import t from 'lib/translation';
import { CircleAlertMajor, DeleteMajor } from '@shopify/polaris-icons';
import { TokenGateFormValues } from './TokenGateForm';
import SmartContractPreview from 'components/SmartContractPreview';
import useGetContractMetadata from '../../hooks/useGetContractMetadata';
import TokenGateFilters from './TokenGateFilters';
import TokenGateSchedule from './TokenGateSchedule';
import GateTypeSelection from './GateTypeSelection';
import { validPositiveNumber } from 'utils/validation';
import TokenGateDiscount from './TokenGateDiscount';
import { useFeature } from '@optimizely/react-sdk';

type TokenGateFormInputsProps = {
  methods: UseFormReturn<TokenGateFormValues>;
  traits: UseFieldArrayReturn<TokenGateFormValues, 'traits', 'id'>;
  formSuccess: boolean;
  filters: 'none' | 'traits';
  disabledAddress?: boolean;
  disabledSnapshot?: boolean;
  isPrint: boolean;
};

export enum SnapshotState {
  Off = 'off',
  Pending = 'pending',
  Completed = 'completed',
}

export const snapshotStateChecker = (snapshot?: Date) => {
  if (snapshot && isNaN(snapshot.getTime())) {
    throw new Error('Invalid date passed for snapshot');
  }

  if (!snapshot) {
    return SnapshotState.Off;
  } else if (snapshot > new Date()) {
    return SnapshotState.Pending;
  } else if (snapshot < new Date()) {
    return SnapshotState.Completed;
  }
};

export const validTokenRedeemption = (
  maxPerWalletPerToken: number,
  maxPerToken: number
) => {
  if (maxPerToken === -1) {
    // maxPerWalletPerToken can be -1 or any positive value
    return true;
  } else if (maxPerWalletPerToken > 0 && maxPerToken >= maxPerWalletPerToken) {
    // maxPerWalletPerToken has to be less than or equal to maxPerToken
    return true;
  } else {
    return t('tokenGateForm.maxPerWalletAndPerTokenError');
  }
};

export const emptyTrait = { key: '', value: '' };

const TokenGateFormInputs: React.FC<TokenGateFormInputsProps> = ({
  methods,
  traits,
  filters,
  disabledAddress,
  disabledSnapshot,
  isPrint,
}) => {
  const [tokengateDiscountFlag] = useFeature('tokengate_discount_ui');
  const snapshotAt = methods.watch('snapshotAt');
  const maxPerWalletPerToken = methods.watch('maxPerWalletPerToken');
  const gateOwnerType = methods.watch('gateOwnerType');
  let snapshotState = snapshotStateChecker(
    snapshotAt ? new Date(snapshotAt) : undefined
  );
  const [contractType, setContractType] = useState<
    'ERC721' | 'ERC1155' | 'UNKNOWN' | undefined
  >(undefined);

  useEffect(() => {
    methods.trigger('startedAt');
  }, [gateOwnerType, methods]);

  const [blockchain, contractAddress] = methods.watch([
    'blockchain',
    'contractAddress',
  ]);

  const { data: contractPreview } = useGetContractMetadata(
    contractAddress,
    blockchain
  );

  return (
    <>
      <Layout.Section>
        <div style={{ paddingBottom: '1rem' }}>
          <Banner>
            {t('tokenGateForm.helpBanner')}
            <Link url="https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store">
              {t('tokenGateForm.helpBannerLink')}
            </Link>
            {t('tokenGateForm.helpBannerFullStop')}
          </Banner>
        </div>
        <Card>
          <Card.Section>
            <FormLayout>
              <TextInput
                required={t('tokenGateForm.error.nameRequired')}
                name="name"
                label="Name"
                error={methods.formState.errors?.name?.message}
                disabled={isPrint}
              />
              <TextInput
                required={t('tokenGateForm.error.contractAddress')}
                name="contractAddress"
                label={t('tokenGateForm.fields.contractAddress')}
                error={methods.formState.errors?.contractAddress?.message}
                disabled={
                  disabledAddress ||
                  snapshotState === SnapshotState.Completed ||
                  isPrint
                }
              />
              {contractPreview?.isArtBlocks && (
                <TextInput
                  name="artBlocksProjectId"
                  label={t('tokenGateForm.fields.artBlocksProjectId')}
                  error={methods.formState.errors?.artBlocksProjectId?.message}
                  validator={validPositiveNumber}
                  maxLength={32}
                  disabled={isPrint}
                  required={t('tokenGateForm.error.artBlocksProjectId')}
                />
              )}
            </FormLayout>
          </Card.Section>
          {contractAddress && (
            <Card.Section>
              <SmartContractPreview
                blockchain={blockchain}
                contractAddress={contractAddress}
                missingBlockchainMessage={
                  <TextStyle variation="subdued">
                    {t('tokenGateForm.contractPreview.missingBlockchain')}
                  </TextStyle>
                }
                errorMessage={
                  <Banner
                    status="warning"
                    icon={CircleAlertMajor}
                    title={t('tokenGateForm.contractPreview.errorTitle')}
                  >
                    {t('tokenGateForm.contractPreview.errorMessage')}
                  </Banner>
                }
                setContractType={setContractType}
              />
            </Card.Section>
          )}
        </Card>
        <Card title={t('tokenGateForm.gatedProduct.label')}>
          <Card.Section>
            <ProductPickerWrapper
              selectMultiple={false}
              subtitle={t('tokenGateForm.gatedProduct.subtitle')}
              name="selectedProduct"
              ListComponent={SimpleListItem}
              minimumProductCount={1}
              disabled={isPrint}
            />
          </Card.Section>
        </Card>
        <GateTypeSelection
          methods={methods}
          snapshotState={snapshotState}
          snapshotAt={snapshotAt}
          maxPerWalletPerToken={maxPerWalletPerToken}
          disabled={
            disabledSnapshot || methods.watch('snapshotAt') === undefined
          }
          contractType={contractType}
        />
        {tokengateDiscountFlag && (
          <TokenGateDiscount methods={methods} isPrint={isPrint} />
        )}

        <TokenGateSchedule methods={methods} />
        <TokenGateFilters
          filters={filters}
          traits={traits}
          contractPreview={contractPreview}
          methods={methods}
        />
      </Layout.Section>
    </>
  );
};

export const TraitFilterInput: React.FC<{
  name: string;
  onDelete?: () => void;
}> = ({ name, onDelete }) => {
  return (
    <Stack alignment="center">
      <Stack.Item fill>
        <Controller
          name={`${name}.key`}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              label={t('tokenGateForm.filters.traitNameLabel')}
              labelHidden
              placeholder={t('tokenGateForm.filters.traitNamePlaceholder')}
              autoComplete={'off'}
              value={field.value}
              onChange={field.onChange}
              error={
                fieldState.error &&
                t(
                  `tokenGateForm.filters.traitNameValidation${
                    fieldState.error.type.charAt(0).toUpperCase() +
                    fieldState.error.type.substring(1)
                  }`
                )
              }
            />
          )}
        />
      </Stack.Item>
      <Stack.Item fill>
        <Controller
          name={`${name}.value`}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              label={t('tokenGateForm.filters.traitValueLabel')}
              labelHidden
              placeholder={t('tokenGateForm.filters.traitValuePlaceholder')}
              autoComplete={'off'}
              value={field.value}
              onChange={field.onChange}
              error={
                fieldState.error &&
                t(
                  `tokenGateForm.filters.traitValueValidation${
                    fieldState.error.type.charAt(0).toUpperCase() +
                    fieldState.error.type.substring(1)
                  }`
                )
              }
            />
          )}
        />
      </Stack.Item>
      <Stack.Item>
        <Button
          accessibilityLabel={t('tokenGateForm.filters.traitDeleteLabel')}
          onClick={onDelete}
          icon={DeleteMajor}
          plain
          disabled={!onDelete}
        />
      </Stack.Item>
    </Stack>
  );
};

export default TokenGateFormInputs;
