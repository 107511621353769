function formatDate(date: string | null, includeTime?: boolean): string | null {
  if (date === null) return null;
  return new Date(date).toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: includeTime ? '2-digit' : undefined,
    minute: includeTime ? '2-digit' : undefined,
  });
}

export default formatDate;
