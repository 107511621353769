import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getApiUrl } from '../constants';
import LoadingPage from './LoadingPage';
import ErrorPage from './ErrorPage';
import { UPSELL_QUERY_PARAM } from '../hooks/useShopifyInstall';

const setupShop = async (params: { [key: string]: string }) => {
  const response = await fetch(`${getApiUrl()}/shopify/setup`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  if (response.status !== 200) {
    throw Error(`Setup shop failed with status code ${response.status}`);
  }
};

const createRedirectParameters = () => {
  const search = new URLSearchParams(window.location.search);
  const shop = search.get('shop');
  const host = search.get('host');
  const upsell = search.get(UPSELL_QUERY_PARAM);

  return new URLSearchParams({
    shop: shop ?? '',
    host: host ?? '',
    upsell: upsell ?? '',
  }).toString();
};

const OAuthRedirect: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    const go = async () => {
      let params: { [key: string]: string } = {};
      for (const [key, value] of search) {
        params = { ...params, [key]: value };
      }

      try {
        await setupShop(params);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    go();
  }, [search]);

  if (error) {
    return <ErrorPage />;
  }

  return loading ? (
    <LoadingPage />
  ) : (
    <Redirect to={`/?${createRedirectParameters()}`} />
  );
};

export default OAuthRedirect;
