const hostname = {
  test: 'shopify-merchant-test.verisart.com',
  staging: 'shopify-merchant-staging.verisart.com',
  production: 'shop.verisart.com',
};

// Selects api url for each environment
const getApiUrl = () => {
  switch (window.location.hostname) {
    case hostname.test:
      return 'https://verisart-core-test.herokuapp.com/v3';
    case hostname.staging:
      return 'https://verisart-core-staging.herokuapp.com/v3';
    case hostname.production:
      return 'https://api.verisart.com/v3';
    default:
      return (
        process.env.REACT_APP_CORE_API_BASE_URL ?? 'http://localhost:8080/v3'
      );
  }
};

const getIntercomAppId = () => {
  switch (window.location.hostname) {
    case 'shopify-merchant-staging.verisart.com':
      return 'npgdaet5';
    case 'shopify-merchant-production.verisart.com':
      return 'npgdaet5';
    case 'shop.verisart.com':
      return 'npgdaet5';
    default:
      return 'jt2gn9r9';
  }
};

const getMixPanelID = () => {
  switch (window.location.hostname) {
    case hostname.staging:
      return '77c3a27f03939011999e39e95432d5c0';
    case hostname.production:
      return 'f409d63813518f137d630dbfb6cfe88b';
    default:
      return '14ba74481674349227ade23079559a49';
  }
};

type Environment = 'development' | 'test' | 'staging' | 'production';

const getEnvironment = (): Environment => {
  switch (window.location.hostname) {
    case hostname.test:
      return 'test';
    case hostname.staging:
      return 'staging';
    case hostname.production:
      return 'production';
    default:
      return 'development';
  }
};

export const isEnvironmentTest = () => {
  const env = getEnvironment();
  return env === 'test' || env === 'development';
};

const metafieldGuideUrl =
  'https://help.verisart.com/en/articles/7946109-troubleshooting-guide-for-product-form-errors';

const defaultMetafields = `metafields.verisart.title,metafields.verisart.artist_id_1,metafields.verisart.width,metafields.verisart.height,metafields.verisart.depth,metafields.verisart.dimension_unit,metafields.verisart.production_year,metafields.verisart.object_type,metafields.verisart.medium,metafields.verisart.edition_type,metafields.verisart.edition_number,metafields.verisart.edition_volume,metafields.verisart.edition_note,metafields.verisart.ap_number,metafields.verisart.ap_volume,metafields.verisart.qr_linkable`;

const gtmContainerId = () => {
  if (window.location.hostname === hostname.production) {
    return 'GTM-T9ZL259';
  }
  return process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID;
};

// eslint-disable-next-line import/prefer-default-export
export {
  getApiUrl,
  getEnvironment,
  metafieldGuideUrl,
  getMixPanelID,
  defaultMetafields,
  gtmContainerId,
  getIntercomAppId,
};
