export type ObjectType =
  | 'PAINTING'
  | 'SCULPTURE'
  | 'PRINT'
  | 'DESIGN'
  | 'COLLECTIBLE'
  | 'PHOTO'
  | 'DRAWING'
  | 'MIXED_MEDIA'
  | 'FURNITURE'
  | 'BOOK'
  | 'EPHEMERA'
  | 'DIGITAL_ARTWORK'
  | 'NFT' // Not supported in the UI as this is a reserved flow
  | 'VIDEO'
  | 'OTHER'; // Not yet supported in the UI as this requires an additional free text field

export const NonPhysicalObjects: ObjectType[] = ['DIGITAL_ARTWORK', 'NFT']; // NFT is not actually selectable, it is included for completeness

export const ObjectTypeOptions: { object_type: ObjectType; label: string }[] = [
  {
    object_type: 'BOOK',
    label: 'Book',
  },
  {
    object_type: 'COLLECTIBLE',
    label: 'Collectible',
  },
  {
    object_type: 'DESIGN',
    label: 'Design',
  },
  {
    object_type: 'DIGITAL_ARTWORK',
    label: 'Digital artwork',
  },
  {
    object_type: 'DRAWING',
    label: 'Drawing',
  },
  {
    object_type: 'EPHEMERA',
    label: 'Ephemera',
  },
  {
    object_type: 'VIDEO',
    label: 'Video',
  },
  {
    object_type: 'FURNITURE',
    label: 'Furniture',
  },
  {
    object_type: 'MIXED_MEDIA',
    label: 'Mixed Media',
  },
  {
    object_type: 'PAINTING',
    label: 'Painting',
  },
  {
    object_type: 'PHOTO',
    label: 'Photo',
  },
  {
    object_type: 'PRINT',
    label: 'Print',
  },
  {
    object_type: 'SCULPTURE',
    label: 'Sculpture',
  },
];
