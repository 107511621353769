import React, { useEffect, useState } from 'react';
import { Card, Heading, TextStyle, Stack, Checkbox } from '@shopify/polaris';
import { DateTime } from 'luxon'; // Assuming you're using Luxon for date/time manipulation
import { ControlledDateTimeInput } from 'components/forms/DateTimeInput/ControlledDateTimeInput';
import t from 'lib/translation';
import {
  UseFormReturn,
  Controller,
  Control,
  useFormContext,
} from 'react-hook-form';
import { TokenGateFormValues } from './TokenGateForm';
import { PrintFormValues } from 'components/PrintForm/PrintForm';

interface TokenGateScheduleProps {
  methods: UseFormReturn<TokenGateFormValues> | UseFormReturn<PrintFormValues>;
}

//this is the component for scheduling the token gate. It is used in both the token gate form and the print form
const TokenGateSchedule: React.FC<TokenGateScheduleProps> = ({ methods }) => {
  const { setValue, getValues, watch } = useFormContext<
    TokenGateFormValues | PrintFormValues
  >();
  const control = methods.control as Control<TokenGateFormValues>;
  const endedAt = watch('endedAt');
  const locale = DateTime.local().zoneName.replace(/_/g, ' ');
  const abbreviatedTimeZone = DateTime.local().toFormat('ZZZZ');
  const [isEndedAtVisible, setIsEndedAtVisible] = useState(
    endedAt !== undefined
  );

  const handleEndedAtCheckboxChange = (checked: boolean) => {
    setIsEndedAtVisible(checked);
    if (checked && getValues('endedAt') === undefined) {
      const now = DateTime.now()
        .set({ second: 0, millisecond: 0 })
        .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .toISO();
      setValue('endedAt', now, { shouldDirty: true });
    }
    if (!checked) {
      setValue('endedAt', undefined, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (!isEndedAtVisible) {
      setValue('endedAt', undefined);
    }
  }, [isEndedAtVisible, methods, setValue]);

  return (
    <Card
      title={
        <>
          <Heading>Scheduled</Heading>
          <TextStyle variation="subdued">
            {locale}, {abbreviatedTimeZone}
          </TextStyle>
        </>
      }
    >
      <Card.Section>
        <Stack vertical>
          <Controller
            name="startedAt"
            control={control}
            render={({ field, fieldState }) => (
              <ControlledDateTimeInput
                {...field}
                dateLabel={t('tokenGateForm.dateTime.startDate')}
                timeLabel={t('tokenGateForm.dateTime.startTime')}
                hintLabel={t('tokenGateForm.dateTime.startDateHint')}
                validate={(value, values) => {
                  const snapshotAtlocal = values.snapshotAt;
                  if (snapshotAtlocal !== undefined && snapshotAtlocal !== '') {
                    if (
                      DateTime.fromISO(value).diff(
                        DateTime.fromISO(snapshotAtlocal),
                        'hours'
                      ).hours < 1
                    ) {
                      return t('tokenGateForm.error.startTime');
                    }
                  }
                  if (endedAt !== undefined && endedAt !== '') {
                    if (DateTime.fromISO(value) > DateTime.fromISO(endedAt)) {
                      return t('tokenGateForm.error.startTimeAfterEnd');
                    }
                  }
                }}
              />
            )}
          />
          <Checkbox
            label={t('tokenGateForm.fields.checkbox')}
            checked={isEndedAtVisible}
            onChange={handleEndedAtCheckboxChange}
          />
          {isEndedAtVisible && (
            <Controller
              name="endedAt"
              control={control}
              render={({ field }) => (
                <ControlledDateTimeInput
                  {...field}
                  dateLabel={t('tokenGateForm.dateTime.endDate')}
                  timeLabel={t('tokenGateForm.dateTime.endTime')}
                  hintLabel={t('tokenGateForm.dateTime.endDateHint')}
                  validate={(value, values) => {
                    const startedAt = values.startedAt;
                    if (startedAt !== undefined && startedAt !== '') {
                      if (
                        DateTime.fromISO(value) < DateTime.fromISO(startedAt)
                      ) {
                        return t('tokenGateForm.error.endTimeBeforeStart');
                      }
                    }
                  }}
                />
              )}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default TokenGateSchedule;
