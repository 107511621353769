import { Controller, useFormContext } from 'react-hook-form';
import {
  Button,
  Icon,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import React, { ReactNode, useState } from 'react';
import { SearchMinor, ProductsMajor } from '@shopify/polaris-icons';
import { ProductResource } from 'hooks/useProducts';
import styles from './ProductPickerMulti.module.scss';
import ProductListItem from 'components/ProductListItem';
import { determinePageSize, LocalPagination } from '../LocalPagination';
import { useProducts } from '../../hooks/useProducts';
import ProductPicker from '../ProductPicker';

interface ProductPickerMultiProps {
  name: string;
  subtitle: string;
  helpText?: string;
  emptyStateText?: string;
  disabled?: boolean;
  // See ProductListItem.editLink
  editLink: (resource: ProductResource) => boolean;
  statusBadge: (resource: ProductResource) => ReactNode | null;
}

function preserveOrdering(previousIds: number[], newIds: number[]): number[] {
  const newSet = new Set<number>(newIds);

  const existing: number[] = [];

  // Find existing in original order
  previousIds.forEach((i) => {
    if (newSet.delete(i)) {
      existing.push(i);
    }
  });

  // Find the new ones (note Sets preserve insertion order)
  const prefix = Array.from(newSet);

  // The list is most recent first so put them at the start
  return prefix.reverse().concat(existing);
}

const ProductPickerMulti: React.FC<ProductPickerMultiProps> = ({
  name,
  subtitle,
  helpText,
  emptyStateText,
  editLink,
  statusBadge,
  disabled,
}) => {
  const { control, clearErrors, setValue, watch } = useFormContext();

  const [productPickerOpen, setProductPickerOpen] = useState(false);
  const [pickerQuery, setPickerQuery] = useState('');

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfPages = Math.ceil(watch(name).length / pageSize);

  const onResourceAction = () => {
    setPickerQuery('');
    setProductPickerOpen(false);
  };

  const formValues = watch(name) as number[];

  const paginatedData = determinePageSize(
    formValues,
    currentPage,
    pageSize,
    numberOfPages
  );

  const { data: productData, isLoading: productDataLoading } = useProducts(
    paginatedData.map((p) => p.toString()),
    formValues.length > 0
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { error: valueError },
      }) => {
        return (
          <Stack vertical>
            {productPickerOpen && (
              <ProductPicker
                initialQuery={pickerQuery}
                onCancel={onResourceAction}
                initialSelectionIds={value?.map((p: string) => {
                  return Number(p);
                })}
                onSelection={(ids: number[]) => {
                  clearErrors(name);
                  onChange(preserveOrdering(value, ids));
                  onResourceAction();
                }}
              />
            )}
            <Stack>
              <Stack.Item fill>
                <TextField
                  prefix={<Icon source={SearchMinor} color="base" />}
                  value={pickerQuery}
                  onChange={(fieldValue) => {
                    setPickerQuery(fieldValue);
                    setProductPickerOpen(true);
                  }}
                  label={null}
                  autoComplete="off"
                  helpText={helpText ?? ''}
                  error={valueError?.message}
                  disabled={disabled}
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  onClick={() => setProductPickerOpen(true)}
                  disabled={disabled}
                >
                  Browse
                </Button>
              </Stack.Item>
            </Stack>
            <Stack vertical>
              {typeof value === 'undefined' && (
                <div className={`${styles['my-8']} ${styles.center}`}>
                  <Icon source={ProductsMajor} color="base" />
                  <div className={styles['mt-4']}>
                    <TextStyle>{subtitle}</TextStyle>
                  </div>
                </div>
              )}

              {productDataLoading && (
                <div className={styles['align-middle']}>
                  <Spinner />
                </div>
              )}
              <div className={styles.resourceProduct}>
                {(value as unknown[]).length !== 0 && !productDataLoading && (
                  <Stack.Item>
                    <hr className={styles.divider} />
                  </Stack.Item>
                )}
                {productData?.products?.map((item: ProductResource) => (
                  <ProductListItem
                    key={item.id}
                    product={item}
                    onDelete={() => {
                      const filteredVals = formValues?.filter(
                        (val: number) => val !== item.id
                      );
                      return setValue(name, filteredVals, {
                        shouldDirty: true,
                      });
                    }}
                    editLink={editLink(item)}
                    statusBadge={statusBadge(item)}
                  />
                ))}
              </div>
              {!productDataLoading && formValues.length === 0 && (
                <div className={`${styles['my-8']} ${styles.center}`}>
                  <Icon source={ProductsMajor} color="base" />
                  <div className={styles['mt-4']}>
                    <TextStyle>{emptyStateText}</TextStyle>
                  </div>
                </div>
              )}
              {!productDataLoading && formValues.length > 0 && (
                <Stack distribution={'center'}>
                  <LocalPagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numberOfPages={numberOfPages}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        );
      }}
    />
  );
};

export default ProductPickerMulti;
