import api from './api';

export const downloadFile = async (
  url: string,
  opts: { name?: string; extension?: string }
) => {
  const { name, extension } = opts;

  if (extension && !name) {
    throw new Error('name must be specified when supplying an extension');
  }

  const filename = name
    ? `${name}-${asSimpleISO(new Date())}${extension ? `.${extension}` : ''}`
    : undefined;

  const res = await api().get(url, { responseType: 'blob' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(res.data);
  if (filename) {
    link.download = filename;
  }
  link.click();
};

const pad = (n: number) => {
  if (n < 10) {
    return '0' + n;
  } else {
    return n;
  }
};

const asSimpleISO = (d: Date) =>
  d.getUTCFullYear() +
  '-' +
  pad(d.getUTCMonth()) +
  '-' +
  pad(d.getUTCDate()) +
  'T' +
  pad(d.getUTCHours()) +
  ':' +
  pad(d.getUTCMinutes()) +
  ':' +
  pad(d.getUTCSeconds()) +
  'Z';
