import { useMutation, useQueryClient } from 'react-query';
import API from '../utils/api';

export default function useDeleteDrop() {
  const queryCache = useQueryClient();

  return useMutation(
    async (dropId: string) => {
      await API().delete(`/shop/drop/${dropId}`);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('drop');
      },
    }
  );
}
