import React from 'react';
import {
  Card,
  Stack,
  TextStyle,
  DisplayText,
  Icon,
  Link,
} from '@shopify/polaris';
import t from 'lib/translation';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import style from './printUiAdvertiseBanner.module.scss';
interface PrintUiAdvertiseBannerProps {
  dismiss: (dismissValue: string) => void;
}

export const PrintUiAdvertiseBanner: React.FC<PrintUiAdvertiseBannerProps> = ({
  dismiss,
}) => {
  return (
    <div className={style.getting_started_card}>
      <Card>
        <Card.Section>
          <Stack wrap={false}>
            <Stack.Item fill>
              <Stack alignment={'center'} wrap={false}>
                <Stack.Item fill>
                  <Stack vertical>
                    <DisplayText size={'small'}>
                      {t('dashboard.printUiBanner.title')}
                    </DisplayText>
                    <Stack spacing="extraTight">
                      <TextStyle variation="subdued">
                        {t('dashboard.printUiBanner.message')}
                      </TextStyle>
                      <Link url="https://help.verisart.com/en/articles/9497684-launch-your-print-store-on-shopify">
                        {t('dashboard.printUiBanner.link')}
                      </Link>
                    </Stack>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dismiss(new Date().toISOString());
                }}
              >
                <Icon source={CancelSmallMinor} />
              </div>
            </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
};
