import { useQuery } from 'react-query';
import API from '../utils/api';
import { PlanType } from '../utils/plans';

// Not the exhaustive list. See `Capability` on core for details
export type Capability =
  | 'certificates.customize'
  | 'emails.customize'
  | 'emails.customize.html'
  | 'artists.represent'
  | 'certificates.editions.bulkCreate'
  | 'nft.shop.minting'
  | 'nft.shop.minting.free';

export type CapabilityResponse = {
  capabilities: Capability[];
  supportedCapabilities: Record<Capability, PlanType>;
};

export default function useGetCapabilities() {
  return useQuery<CapabilityResponse>('plan', async () => {
    const response = await API().get('/plan');
    if (response.status !== 200) {
      return [];
    }
    return response.data;
  });
}
