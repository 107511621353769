import { Page } from '@shopify/polaris';
import { RoyaltySplitForm, RoyaltySplitFormProps } from './RoyaltySplitForm';
import { parameters as p } from 'lib/translation';
import React from 'react';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';

export const RoyaltySplitFormPage: React.FC<RoyaltySplitFormProps> = (
  props
) => {
  const historyGoBack = useHistoryGoBack();

  return (
    <Page
      breadcrumbs={[
        {
          content: 'Royalty Splits',
          onAction: () => historyGoBack('/royalty-splits'),
        },
      ]}
      title={p('royaltySplitPage.form.title', props.formType)}
    >
      <RoyaltySplitForm {...props} />
    </Page>
  );
};
