import { Badge, Card, Stack } from '@shopify/polaris';
import FormField from 'components/forms/FormField';
import { Print } from 'hooks/useCreatePrintProduct';
import { ManagedArtists } from 'hooks/useProductFormValues';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';
import { ArtistDetails, PrintFormValues } from './PrintForm';
import { ContractPreview } from 'hooks/useGetContractMetadata';
import { UseFormReturn } from 'react-hook-form';
import { formatArtistName } from 'utils/formatArtistName';

interface CertificatePreviewCardProps {
  methods: UseFormReturn<PrintFormValues>;
  formType: 'Create' | 'Edit';
  data: Print | undefined;
  relatedArtists: UseQueryResult<ManagedArtists, unknown>;
  selectedArtist: ArtistDetails | null;
  contractPreview: ContractPreview | undefined;
  tokenIdInput: string;
  printPreviewWidth: number | undefined;
  printPreviewHeight: number | undefined;
  certificateDescription: string | undefined;
}

const CertificatePreviewCard: React.FC<CertificatePreviewCardProps> = ({
  methods,
  formType,
  data,
  relatedArtists,
  selectedArtist,
  contractPreview,
  tokenIdInput,
  printPreviewWidth,
  printPreviewHeight,
  certificateDescription,
}) => {
  const history = useHistory();
  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  return (
    <Card
      title="Certificate"
      actions={
        formType === 'Edit'
          ? [
              {
                content: 'Edit',
                onAction: () => {
                  history.push(`/product-metadata/${data?.productId}`);
                },
              },
            ]
          : []
      }
    >
      <Card.Section>
        <Stack vertical>
          {data?.artistId && relatedArtists && selectedArtist?.firstName && (
            <FormField
              label="Artist"
              value={formatArtistName({
                firstName: selectedArtist.firstName,
                lastName: selectedArtist?.lastName ?? '',
              })}
            />
          )}
          {data?.artistId && relatedArtists && (
            <Stack vertical>
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <p
                  style={{
                    minWidth: '100px',
                    fontWeight: 'bold',
                    margin: 0,
                  }}
                >
                  Signature
                </p>
                {selectedArtist?.signatureImage ? (
                  <img
                    src={selectedArtist.signatureImage}
                    alt="signature"
                    style={{ maxWidth: '100px' }}
                  />
                ) : (
                  selectedArtist?.type === 'SELF' && (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        openWindowOnVerisartDotCom('/settings/account')
                      }
                    >
                      <Badge status={'critical'}>
                        No signature uploaded. Click here to upload
                      </Badge>
                    </div>
                  )
                )}
              </div>
            </Stack>
          )}
          <FormField
            label="Title"
            value={
              formType === 'Create' && contractPreview
                ? `${contractPreview.name} Print with token ${tokenIdInput}`
                : data?.productCertificateTitle
                ? data.productCertificateTitle.replace(
                    '{tokenId}',
                    tokenIdInput
                  )
                : methods.watch('name') || ''
            }
          />
          <FormField label="Year" value={currentYear.toString()} />
          <FormField
            label="Medium"
            value="Giclée print on Hahnemühle German etching paper"
          />
          {printPreviewWidth && printPreviewHeight && (
            <FormField
              label="Dimensions"
              value={`${printPreviewWidth?.toString()} x ${printPreviewHeight?.toString()} inches`}
            />
          )}
          {certificateDescription && (
            <FormField
              label="Description"
              value={certificateDescription}
              maxLength={100}
            />
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default CertificatePreviewCard;
