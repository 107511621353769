import create from 'zustand';

const useToast = create((set) => ({
  message: '',
  isError: false,
  isVisible: false,
  showToast: (message: string, isError: boolean) =>
    set((state) => ({ isVisible: true, message, isError })),
  toggleToast: () => set((state) => ({ isVisible: !state.isVisible })),
}));

export default useToast;
