import React from 'react';
import { Page, PageProps } from '@shopify/polaris';
import GlobalBanners from 'components/GlobalBanners';
import { Banners } from 'types/Banners';

interface PageLayoutProps {
  children: React.ReactNode;
  pageProps?: PageProps;
  displayedBanners?: Banners[];
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  pageProps,
  displayedBanners,
}) => {
  return (
    <Page {...pageProps}>
      <GlobalBanners displayedBanners={displayedBanners} />
      <div>{children}</div>
    </Page>
  );
};

export default PageLayout;
