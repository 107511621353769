import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';
import { CollectorRewardFile } from './useCreateDrop';
import { Blockchain } from '../types/Blockchain';

export interface DropForProduct {
  id: string;
  name: string;
  blockchain: Blockchain;
  collectorRewardFiles: Array<CollectorRewardFile>;
}

export default function useGetDropProduct(
  productId: string,
  enabled: boolean = true
): UseQueryResult<DropForProduct> {
  return useQuery(
    ['drop', 'product', productId],
    async () => {
      return (await API().get(`shop/drop/product/${productId}`)).data;
    },
    {
      enabled,
    }
  );
}
