import t from '../../lib/translation';
import { Modal } from '@shopify/polaris';
import { RoyaltySplitForm, RoyaltySplitFormProps } from './RoyaltySplitForm';
import React, { useCallback, useState } from 'react';
import { useGetRecipients } from '../../hooks/useGetRecipients';
import { parameters as p } from 'lib/translation';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import LoadingState from '../LoadingState';

type RoyaltySplitFormModalType = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
} & Pick<RoyaltySplitFormProps, 'lockBlockchain' | 'onRoyaltySplitCreated'>;

const RoyaltySplitModalCreateLoader: React.FC<RoyaltySplitFormModalType> = ({
  showModal,
  setShowModal,
  lockBlockchain,
  onRoyaltySplitCreated,
}) => {
  const [save, setSave] = useState<(() => void) | undefined>();

  const { data, error } = useGetRecipients();
  const { isLoading: isMintingCapabilityLoading, mintingAvailable } =
    useMainnetMintingEnabled();

  if (error) {
    throw error;
  }

  // This is just some glue required due to the way RoyaltySplitForm works.
  // It is responsible for doing all the saving, but in this case, we're the one who renders the save button.
  // This state is required so RoyaltySplitForm can tell us what to call on save.
  const setSaveCallback = useCallback((callback: () => void) => {
    setSave(() => callback); // Storing a callback in state requires a double function call - see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  }, []);

  if (!data || isMintingCapabilityLoading) {
    return <LoadingState />;
  }

  return (
    <Modal
      large
      open={showModal}
      onClose={() => setShowModal(false)}
      title={p('royaltySplitPage.form.title', 'Create')}
      primaryAction={{
        content: t('dropForm.toast.save'),
        onAction: save,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setShowModal(false),
        },
      ]}
    >
      <Modal.Section>
        <RoyaltySplitForm
          formType={'Create'}
          recipients={data}
          onTestMode={!mintingAvailable}
          setSaveCallback={setSaveCallback}
          lockBlockchain={lockBlockchain}
          onRoyaltySplitCreated={onRoyaltySplitCreated}
        />
      </Modal.Section>
    </Modal>
  );
};
export default RoyaltySplitModalCreateLoader;
