import classnames from 'classnames';
import React from 'react';
import { RadioButton as PolarisRadioButton, Stack } from '@shopify/polaris';
import styles from './styles.module.css';

type RadioButtonProps = {
  fullWidth?: boolean;
  label: React.ReactNode;
  helpText?: React.ReactNode;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  accessory?: React.ReactNode;
  disabled?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  fullWidth,
  label,
  helpText,
  checked,
  onChange,
  accessory,
  disabled,
}) => (
  <div
    className={classnames(styles.container, {
      [styles['w-full']]: fullWidth,
      [styles['bg-surface-selected']]: checked,
      [styles['disabled-surface']]: disabled,
    })}
  >
    <Stack spacing="extraLoose" alignment="center">
      <Stack.Item fill>
        <label className={`${styles.hover}`}>
          <PolarisRadioButton
            label={label}
            helpText={helpText}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        </label>
      </Stack.Item>
      <Stack.Item>
        <div className={styles.accessory}>{accessory}</div>
      </Stack.Item>
    </Stack>
  </div>
);

export default RadioButton;
