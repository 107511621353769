import React, { useCallback } from 'react';
import { Badge, Card } from '@shopify/polaris';
import ProductPickerMulti from 'components/forms/ProductPickerMulti';
import t, { parameters as p } from 'lib/translation';
import { ProductResource } from '../../../hooks/useProducts';
import useGetDrops from '../../../hooks/useGetDrops';

export type NFTProductsProps = {
  dropId: string | null | undefined;
  disabled?: boolean;
};

const DropNFTProducts: React.FC<NFTProductsProps> = ({ dropId, disabled }) => {
  const { data: drops } = useGetDrops();

  const editLink = useCallback(
    (item: ProductResource) => {
      // Note we're checking against the drop ID because if a user tries to add an item to our drop which belongs to
      // another drop, we don't want to allow clicking a link
      return dropId ? item.dropId === dropId : false;
    },
    [dropId]
  );

  const statusBadge = useCallback(
    (item: ProductResource) => {
      if (dropId && item.dropId) {
        if (item.dropId !== dropId) {
          const drop = drops?.find((it) => it.drop.id === item.dropId);
          return (
            <Badge status="critical">
              {p('dropForm.nftProducts.alreadyInDrop', drop?.drop?.name)}
            </Badge>
          );
        } else if (item.isSoldNft) {
          return <Badge>{t('dropForm.nftProducts.sold')}</Badge>;
        } else if (item.hasIssue || item.variantHasIssue) {
          return <Badge status="critical">{t('productActionRequired')}</Badge>;
        }
      }
      return null;
    },
    [drops, dropId]
  );

  return (
    <Card>
      <Card.Section>
        <ProductPickerMulti
          name="products"
          subtitle={t('dropForm.nftProducts.subtitle')}
          emptyStateText={t('dropForm.nftProducts.emptyState')}
          editLink={editLink}
          statusBadge={statusBadge}
          disabled={disabled}
        />
      </Card.Section>
    </Card>
  );
};

export default DropNFTProducts;
