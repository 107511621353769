import { useQuery } from 'react-query';
import API from 'utils/api';
import { ManagedArtists } from './useProductFormValues';

const fetchManagedArtists = async () => {
  return (await API().get('/managedArtist/artist')).data;
};

const useRelatedArtists = (enabled: boolean = true) => {
  const relatedArtists = useQuery<ManagedArtists>(
    'relatedArtists',
    fetchManagedArtists,
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      enabled,
    }
  );

  return {
    relatedArtists,
    isLoading: relatedArtists.isLoading,
    error: relatedArtists.error,
    data: relatedArtists.data,
  };
};

export default useRelatedArtists;
