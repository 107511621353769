import { useQuery, UseQueryResult } from 'react-query';
import API from '../utils/api';
import { Blockchain } from '../types/Blockchain';

type WalletBalance = {
  [key in keyof typeof Blockchain]: { crypto: string; fiat: string };
};

export interface WalletWithBalance {
  address: string;
  balance: WalletBalance;
}

async function fetchCustodialWalletBalance(): Promise<WalletWithBalance> {
  const res = await API().get('/shopify/getCustodialWallet');
  return res.data;
}

export const useGetCustodialWalletBalance =
  (): UseQueryResult<WalletWithBalance> => {
    return useQuery('custodialWalletBalance', fetchCustodialWalletBalance);
  };
