import * as React from 'react';

const CopyIcon = () => (
  <svg width={11} height={12} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.351 0v4.318h.935V12H3.738v-.96H0V0h9.351ZM.935 10.08h2.803V4.319h4.673V.96H.935v9.12Zm3.738.96h4.673V5.278H4.673v5.762Z"
      fill="#0F0F11"
    />
  </svg>
);

export default CopyIcon;
