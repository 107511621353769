import { Banner, Link, Stack } from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';

const EditionLockedBanner: React.FC<{
  autoNumberEditionNext: number | null;
  isNFT: boolean;
  setResetEditionModal: (show: boolean) => void;
}> = ({ autoNumberEditionNext, isNFT, setResetEditionModal }) => {
  return (
    <Banner title={t('editionLockedBanner.title')}>
      <Stack vertical>
        <Stack.Item>
          {isNFT
            ? t('editionLockedBanner.paragraph1NFT')
            : t('editionLockedBanner.paragraph1Cert')}
        </Stack.Item>
        <Stack.Item>{t('editionLockedBanner.paragraph2')}</Stack.Item>
        {autoNumberEditionNext && (
          <Stack.Item>
            {t('editionLockedBanner.nextEdition')} {autoNumberEditionNext}
          </Stack.Item>
        )}
        <Stack.Item>
          <Link onClick={() => setResetEditionModal(true)}>
            {t('editionLockedBanner.resetEditionLink')}
          </Link>
        </Stack.Item>
      </Stack>
    </Banner>
  );
};

export default EditionLockedBanner;
