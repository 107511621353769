import { useQuery } from 'react-query';
import API from 'utils/api';
import { ManagedRelationshipData } from 'types/Artist';

interface CreatorMyselfResponse {
  myselfData: ManagedRelationshipData[];
}

const useCreatorMyself = () =>
  useQuery<CreatorMyselfResponse>('creatorsMyself', async () => {
    const response = await API().get('/managedArtist/myself');
    return response.data;
  });

export default useCreatorMyself;
