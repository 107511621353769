import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function UnlockIcon(props: any) {
  return (
    <svg
      width="13"
      height="22"
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.61181 12.5579H4.45143L1.83075 20.4136C1.79796 20.5121 1.80103 20.6186 1.83943 20.7151C1.87784 20.8116 1.9492 20.8921 2.04135 20.9428C2.1335 20.9934 2.24073 21.0112 2.34475 20.9931C2.44878 20.9749 2.54316 20.9219 2.61181 20.8432L11.8905 10.1804C11.9472 10.1154 11.9837 10.0359 11.9957 9.95118C12.0077 9.86647 11.9946 9.78018 11.9581 9.70251C11.9216 9.62485 11.8632 9.55906 11.7897 9.51295C11.7162 9.46684 11.6308 9.44234 11.5436 9.44235H8.54866L11.1692 1.58667C11.2021 1.48819 11.1992 1.38157 11.1608 1.28501C11.1224 1.18845 11.0511 1.10794 10.9589 1.05723C10.8667 1.00652 10.7594 0.988753 10.6553 1.00696C10.5513 1.02517 10.4569 1.07823 10.3883 1.15707L1.10903 11.8204C1.05248 11.8854 1.01613 11.965 1.00425 12.0496C0.99238 12.1343 1.00549 12.2205 1.04203 12.2981C1.07856 12.3757 1.137 12.4414 1.21043 12.4874C1.28386 12.5335 1.36921 12.5579 1.45638 12.5579L2.61181 12.5579ZM9.82784 3.03388L7.59802 9.71797C7.57799 9.77803 7.57276 9.84188 7.58276 9.90431C7.59276 9.96673 7.6177 10.0259 7.65554 10.0771C7.69338 10.1282 7.74305 10.1699 7.80046 10.1986C7.85788 10.2273 7.92142 10.2423 7.98588 10.2423H10.7639L3.17213 18.9666L5.40207 12.2823C5.4221 12.2222 5.42733 12.1584 5.41733 12.096C5.40733 12.0335 5.38239 11.9743 5.34455 11.9232C5.30671 11.872 5.25704 11.8304 5.19963 11.8017C5.14221 11.773 5.07867 11.758 5.01421 11.758H2.23613L9.82784 3.03388Z"
        fill="#008060"
        stroke="#008060"
      />
    </svg>
  );
}

export default UnlockIcon;
