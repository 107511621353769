import API from '../../utils/api';
import { useMutation, useQueryClient } from 'react-query';

const api = API();

interface UpdateRoyaltyAddress {
  id: string;
  name: string;
}

const useUpdateRoyaltySplit = () => {
  const queryCache = useQueryClient();
  const { mutateAsync } = useMutation(
    async (updateRoyaltyAddress: UpdateRoyaltyAddress) => {
      return api.patch(
        '/shopify/royaltyAddress/' + updateRoyaltyAddress.id,
        { name: updateRoyaltyAddress.name },
        {
          timeout: 20000,
        }
      );
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('royaltyAddress');
      },
    }
  );

  return {
    updateRoyaltySplit: mutateAsync,
  };
};

export default useUpdateRoyaltySplit;
