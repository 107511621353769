import { useCallback, useEffect, useState } from 'react';
import API from '../utils/api';
import { ProductMinimal } from './useProduct';

interface GetProductsResponse {
  products: ProductMinimal[];
  cursor: string;
}

const getProducts = (cursor: string | null, query: string) => {
  return API().get<GetProductsResponse>(
    `/shopify/products?${
      query && query !== '' ? `query=${encodeURIComponent(query)}&` : ''
    }size=${PAGE_SIZE}&fetchMode=${FETCH_MODE}${
      cursor ? `&cursor=${cursor}` : ''
    }`
  );
};

export const PAGE_SIZE = 15;
const FETCH_MODE = 'MINIMAL';
export const useGetProducts = (query: string) => {
  const [products, setProducts] = useState<ProductMinimal[]>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await getProducts(null, query);
        setProducts(response.data.products);
        setCursor(response.data.cursor);
      } finally {
        setLoading(false);
      }
    })();
  }, [query]);

  const getMoreProducts = useCallback(async () => {
    if (cursor) {
      setLoading(true);
      try {
        const response = await getProducts(cursor, query);
        const nextProducts = response.data.products;
        setProducts([...products, ...nextProducts]);
        setCursor(response.data.cursor);
      } finally {
        setLoading(false);
      }
    }
  }, [cursor, products, query]);

  return {
    products,
    getMoreProducts,
    moreAvailable: !!cursor,
    loading,
  };
};
