import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

type PatchContractProps = { description: string; id: string };

export default function usePatchContract() {
  const queryCache = useQueryClient();

  return useMutation(
    async ({ description, id }: PatchContractProps) =>
      API()
        .patch(`/nft/contract/${id}`, {
          description: description,
        })
        .then((res) => {
          return res.data;
        }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('contract');
      },
    }
  );
}
