import { Page } from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';
import Footer from 'components/Footer';
import useCreatorsList from './useCreatorsList';
import useShop from 'hooks/useShop';
import { CertificateTriggerType } from './certificateTriggers';
import useObjectTypeList from './useObjectTypeList';
import SettingsForm, { SettingsFormInputs } from './SettingsForm';
import useGetCapabilities from 'hooks/useGetCapabilities';
import usePatchShop from 'hooks/usePatchShop';
import useUpdateCertificationPreference from 'hooks/useUpdateCertificationPreference';
import { useQueryClient } from 'react-query';
import useGetUsageCharges from '../../hooks/useGetUsageCharges';
import LoadingPage from '../../auth/LoadingPage';
import useGetGweiFeeLimits from 'hooks/useGetGweiLimits';
import useGweiLimits from '../../hooks/useSetGweiLimits';
import { Blockchain } from 'types/Blockchain';
import { useAppBridge } from '../../containers/AppBridge';
import { isPanelDisabled } from '../../platform/platform';

export { default as CustomizationSettingsPage } from './CustomizationSettingsPage';

const SettingsPage = () => {
  const { platform } = useAppBridge();
  const usageCharges = useGetUsageCharges(
    !isPanelDisabled('usage_charges', platform)
  );
  const queryClient = useQueryClient();
  const capabilitiesQuery = useGetCapabilities();
  const gweiLimits = useGetGweiFeeLimits();
  const shopQuery = useShop();
  const { mutateAsync: patchShop } = usePatchShop();
  const { mutateAsync: updateCertificationPreference } =
    useUpdateCertificationPreference();
  const creatorsList = useCreatorsList();
  const objectTypeList = useObjectTypeList();
  const { putGweiState } = useGweiLimits();

  if (
    usageCharges.isLoading ||
    capabilitiesQuery.isLoading ||
    shopQuery.isLoading ||
    creatorsList.isLoading ||
    gweiLimits.isLoading
  ) {
    return <LoadingPage />;
  }

  const formDefaults = {
    certificateTrigger: (shopQuery.data?.shop?.orderPreference ??
      'ORDERS_PAID') as CertificateTriggerType,
    defaultCreator: shopQuery.data?.shop?.defaultMetafields?.artist_id ?? '',
    defaultObjectType:
      shopQuery.data?.shop?.defaultMetafields?.object_type ?? '',
    gweiValue: gweiLimits.data?.mintingGwei ?? '50',
    contractGweiValue: gweiLimits.data?.contractGwei ?? '50',
    paymentSplitGweiValue: gweiLimits.data?.paymentSplitGwei ?? '50',
  };

  if (!capabilitiesQuery.data) {
    throw new Error('Capabilities not loaded');
  }

  return (
    <Page title={t('settings.page.title')}>
      <SettingsForm
        usageCharges={usageCharges.data?.usageCharge ?? null}
        capabilities={capabilitiesQuery.data}
        creatorsList={creatorsList.data ?? []}
        objectTypeList={objectTypeList}
        defaultValues={formDefaults}
        onSubmit={async (data: SettingsFormInputs) => {
          const metafieldsUpdated =
            data.defaultCreator !==
              shopQuery.data?.shop?.defaultMetafields?.artist_id ||
            data.defaultObjectType !==
              shopQuery.data?.shop?.defaultMetafields?.object_type;

          if (metafieldsUpdated) {
            await patchShop({
              defaultMetafields: {
                artist_id: data.defaultCreator,
                object_type: data.defaultObjectType,
              },
            });
          }

          if (
            data.certificateTrigger !== shopQuery.data?.shop?.orderPreference
          ) {
            await updateCertificationPreference({
              topic: data.certificateTrigger,
            });
          }

          if (data.contractGweiValue !== gweiLimits.data?.contractGwei) {
            await putGweiState({
              [Blockchain.ETHEREUM + '|CONTRACTS']: parseInt(
                data.contractGweiValue
              ),
            });
          }
          if (
            data.paymentSplitGweiValue !== gweiLimits.data?.paymentSplitGwei
          ) {
            await putGweiState({
              [Blockchain.ETHEREUM + '|PAYMENT_SPLIT']: parseInt(
                data.paymentSplitGweiValue
              ),
            });
          }
          if (
            data.gweiValue !== gweiLimits.data?.mintingGwei ||
            data.contractGweiValue !== gweiLimits.data?.contractGwei
          ) {
            await putGweiState({
              [Blockchain.ETHEREUM]: parseInt(data.gweiValue),
            });
          }
        }}
        onComplete={async () => {
          await queryClient.invalidateQueries('shop');
        }}
        needsDepaySetup={shopQuery.data?.shop?.needsDepaySetup}
      />
      <Footer />
    </Page>
  );
};

export default SettingsPage;
