import * as React from 'react';

const EtherscanIcon = () => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.326 7.618a.678.678 0 0 1 .682-.679l1.13.004a.68.68 0 0 1 .679.68v4.271c.127-.037.29-.078.47-.12a.567.567 0 0 0 .436-.55v-5.3a.68.68 0 0 1 .679-.68h1.132a.68.68 0 0 1 .679.68v4.919s.283-.115.56-.232a.567.567 0 0 0 .346-.521V4.226a.68.68 0 0 1 .679-.68h1.132a.68.68 0 0 1 .68.68v4.828c.98-.71 1.975-1.566 2.765-2.595a1.14 1.14 0 0 0 .173-1.064A7.987 7.987 0 0 0 8.103 0C3.667-.06 0 3.563 0 8a7.975 7.975 0 0 0 1.062 4.002 1.01 1.01 0 0 0 .965.5c.214-.02.481-.046.798-.083a.566.566 0 0 0 .502-.561v-4.24M3.302 14.47a7.998 7.998 0 0 0 12.682-7.017c-2.922 4.358-8.318 6.396-12.682 7.017"
      fill="#2C6ECB"
    />
  </svg>
);

export default EtherscanIcon;
