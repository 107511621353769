import { getShopDomain } from '../containers/AppBridge';

declare global {
  interface Window {
    dataLayer?: object[];
  }
}

type DataLayerEventName =
  | 'change_plan_start'
  | 'change_plan_confirm'
  | 'shopify_store_identified'
  | 'shopify_store_plan_updated'
  | 'page_view';

interface UserData {
  shopify_domain?: string;
  plan?: string;
}

interface EventData {
  new_plan?: string;
}

interface PageData {
  path: string;
}

interface DataLayerObject {
  event: DataLayerEventName;
  user_data?: UserData;
  event_data?: EventData;
  page_data?: PageData;
}

const pushToDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

// Keep track of the last pushed shopify domain and plan to determine if
// shopify_store event should be fired.
let currentShopifyDomain: string | undefined;
let currentPlan: string | undefined;

export const setShopifyDomain = (shopifyDomain: string, plan?: string) => {
  if (currentShopifyDomain === shopifyDomain && currentPlan === plan) {
    return;
  }

  let event: DataLayerEventName = 'shopify_store_identified';

  if (shopifyDomain === currentShopifyDomain && plan !== currentPlan) {
    event = 'shopify_store_plan_updated';
  }

  currentShopifyDomain = shopifyDomain;
  currentPlan = plan;

  pushToDataLayer({
    event,
    user_data: {
      shopify_domain: shopifyDomain,
      plan,
    },
  });
};

export const trackChangePlanStart = (newPlan: string) =>
  pushToDataLayer({
    event: 'change_plan_start',
    event_data: {
      new_plan: newPlan,
    },
    page_data: {
      path: window.location.pathname,
    },
  });

export const trackChangePlanConfirm = (newPlan: string) =>
  pushToDataLayer({
    event: 'change_plan_confirm',
    event_data: {
      new_plan: newPlan,
    },
    user_data: {
      plan: newPlan,
    },
    page_data: {
      path: window.location.pathname,
    },
  });

export const trackPageView = (path: string) =>
  pushToDataLayer({
    event: 'page_view',
    user_data: {
      shopify_domain: getShopDomain(),
    },
    page_data: {
      path,
    },
  });
