import React from 'react';
import useGetDrops from '../../hooks/useGetDrops';
import DropPageList from './DropPageList';
import LoadingPage from '../../auth/LoadingPage';
export const DropPageLoader: React.FC = () => {
  const { data } = useGetDrops();

  if (data === undefined) return <LoadingPage />;

  return <DropPageList drops={data} />;
};
