import * as React from 'react';

const EthereumIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      fill="#F2F2F2"
    />
    <path d="m20.156 5.04-9.238 15.33 9.238-4.199V5.039Z" fill="#8A92B2" />
    <path d="m20.157 15.852-9.239 4.2 9.239 5.462v-9.662Z" fill="#62688F" />
    <path d="m29.397 20.37-9.24-15.33V16.17l9.24 4.199Z" fill="#62688F" />
    <path d="m20.157 25.514 9.24-5.462-9.24-4.2v9.662Z" fill="#454A75" />
    <path d="m10.918 20.996 9.239 13.02v-7.561l-9.239-5.459Z" fill="#8A92B2" />
    <path d="M20.157 26.455v7.56l9.245-13.019-9.245 5.459Z" fill="#62688F" />
  </svg>
);

export default EthereumIcon;
