import API from '../../utils/api';
import { useMutation, useQueryClient } from 'react-query';
import {
  AddNewWallet,
  RecipientToAdd,
  TableRecipient,
} from './RoyaltySplitForm';
import bigDecimal from 'js-big-decimal';
import { Blockchain } from '../../types/Blockchain';

const createPaymentSplitter = async ({
  name,
  blockchain,
  tableRecipients,
  walletsToAdd,
  recipientsToAdd,
}: UpdateRoyaltySplitRequest): Promise<{ id: string }> => {
  const result = await API().post('/shopify/deployPaymentSplitter', {
    name,
    blockchain,
    recipients: tableRecipients.map((it) => ({
      wallet: it.wallet.toLowerCase(),
      // We are multiplying by 100 to ensure that we turn the % into BPS
      share: it.share.multiply(new bigDecimal('100')).floor().getValue(),
    })),
    walletsToAdd: walletsToAdd.map((it) => ({
      artistId: it.isArtist ? it.id : null,
      royaltyRecipientId: it.isArtist ? null : it.id,
      address: it.wallet.toLowerCase(),
    })),
    recipientsToCreate: recipientsToAdd.map((it) => ({
      fullName: it.name,
      address: it.wallet.toLowerCase(),
    })),
  });
  return result.data;
};

export type UpdateRoyaltySplitResponse = {
  id: string;

  // There are other fields returned - check core for details
};

export type UpdateRoyaltySplitRequest = {
  name: string;
  blockchain: Blockchain;
  tableRecipients: TableRecipient[];
  walletsToAdd: AddNewWallet[];
  recipientsToAdd: RecipientToAdd[];
};

const useCreateRoyaltySplit = () => {
  const queryCache = useQueryClient();
  const { mutateAsync } = useMutation<
    UpdateRoyaltySplitResponse,
    unknown,
    UpdateRoyaltySplitRequest
  >(createPaymentSplitter, {
    onSuccess: () => {
      queryCache.invalidateQueries('royaltyAddress');
    },
  });

  return {
    createRoyaltySplit: mutateAsync,
  };
};

export default useCreateRoyaltySplit;
