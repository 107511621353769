import {
  TextStyle,
  Page,
  Spinner,
  Link,
  Stack,
  Banner,
  Icon,
} from '@shopify/polaris';
import EmptyStatePage from 'components/EmptyStatePage';
import Footer from 'components/Footer';
import TokenGateList from 'components/TokenGateList/TokenGateList';
import useGetTokenGates from 'hooks/useGetTokenGates';
import React, { useCallback } from 'react';
import styles from './TokenGateListPage.module.scss';
import t from 'lib/translation';
import { useHistory } from 'react-router-dom';
import useGetFunctionApplied from 'hooks/useGetFunctionApplied';
import { SettingsMinor } from '@shopify/polaris-icons';
import { useAppBridge } from 'containers/AppBridge';

const TokenGatePage = () => {
  const history = useHistory();
  const { data: tokenGateData, isLoading } = useGetTokenGates();
  const checkoutFunctionApplied = useGetFunctionApplied();
  const createTokenGate = () => {
    history.push(`/tokengates/create`);
  };

  const { redirect } = useAppBridge();

  const navigateToCheckout = useCallback(() => {
    redirect('Admin', { type: 'checkout' });
  }, [redirect]);

  return (
    <Page
      title={t('tokenGatePage.heading')}
      primaryAction={
        tokenGateData && tokenGateData?.length === 0
          ? undefined
          : {
              content: t('tokenGatePage.button'),
              onAction: async () => createTokenGate(),
            }
      }
    >
      {checkoutFunctionApplied.data?.status === false && (
        <div style={{ marginBottom: '5px' }}>
          <Banner
            title={t('tokenGatePage.checkoutFunctionBanner.title')}
            status="warning"
          >
            <TextStyle>
              {t('tokenGatePage.checkoutFunctionBanner.line1')}
            </TextStyle>
            <ul className={styles.bulletList}>
              <li>
                <Stack spacing="extraTight">
                  <TextStyle>
                    {t('tokenGatePage.checkoutFunctionBanner.step1Part1')}
                  </TextStyle>
                  <Link onClick={navigateToCheckout}>
                    <Stack spacing="extraTight">
                      <Icon source={SettingsMinor} color="base" />
                      <TextStyle>Checkout settings,</TextStyle>
                    </Stack>
                  </Link>
                  <TextStyle>
                    {t('tokenGatePage.checkoutFunctionBanner.step1Part2')}
                  </TextStyle>
                  <TextStyle variation="strong">
                    {t('tokenGatePage.checkoutFunctionBanner.step1Part3')}
                  </TextStyle>
                </Stack>
              </li>
              <li>
                <Stack spacing="extraTight">
                  <TextStyle>
                    {t('tokenGatePage.checkoutFunctionBanner.step2Part1')}
                  </TextStyle>
                  <TextStyle variation="strong">
                    {t('tokenGatePage.checkoutFunctionBanner.step2Part2')}
                  </TextStyle>
                </Stack>
              </li>
              <li>
                <Stack spacing="extraTight">
                  <TextStyle>
                    {t('tokenGatePage.checkoutFunctionBanner.step3Part1')}
                  </TextStyle>
                  <TextStyle variation="strong">
                    {t('tokenGatePage.checkoutFunctionBanner.step3Part2')}
                  </TextStyle>
                  <TextStyle>
                    {t('tokenGatePage.checkoutFunctionBanner.step3Part3')}
                  </TextStyle>
                </Stack>
              </li>
            </ul>
            <Link url="https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store#h_5e78dd6a62">
              {t('tokenGatePage.learnMore')}
            </Link>
          </Banner>
        </div>
      )}
      {tokenGateData && tokenGateData?.length === 0 ? (
        <>
          <EmptyStatePage
            heading={t('tokenGatePage.empty.heading')}
            body={t('tokenGatePage.empty.body')}
            linkText={t('tokenGatePage.empty.link')}
            linkAction={createTokenGate}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <div className={`${styles.center}`}>
              <Spinner accessibilityLabel="Spinner" size="large" />
            </div>
          ) : (
            <div>
              <div className={`${styles['subheader-wrapper']}`}>
                <Stack alignment="center" distribution="equalSpacing">
                  <TextStyle variation="subdued">
                    {t('tokenGatePage.subtitle')}{' '}
                    <Link url="https://help.verisart.com/en/articles/6480830-add-tokengating-to-your-shopify-store#h_5e78dd6a62">
                      {t('tokenGatePage.learnMore')}
                    </Link>
                  </TextStyle>
                </Stack>
              </div>
              <TokenGateList tokenGates={tokenGateData || []} />
              <Footer />
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default TokenGatePage;
