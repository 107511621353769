import React, { useState } from 'react';
import { PaymentSplitterContractRenderable } from '../../hooks/useProductFormValues';
import { ResourceItem, Card, TextStyle, Stack, Button } from '@shopify/polaris';
import {
  BasescanIcon,
  EtherscanIcon,
  MinusIcon,
  PlusIcon,
} from '../../components/JsxIcons';
import BlockchainAddress from '../../components/BlockchainAddress';
import styles from './RoyaltySplit.module.scss';
import PolygonscanIcon from '../../components/JsxIcons/Polygonscan/Polygonscan-Icon';
import { scannerBaseUrl } from '../../utils/blockchain';
import bigDecimal from 'js-big-decimal';
import t from 'lib/translation';
import { useHistory } from 'react-router-dom';
import BlockchainLogo from '../../components/BlockchainLogo';
import { BlockchainClass, blockchainToClass } from '../../types/Blockchain';

type RoyaltySplitItemProps = PaymentSplitterContractRenderable & {
  verisartWallet: string | null;
  shopWallet: string | null;
};

const BPS_CONVERSION_FACTOR = new bigDecimal('100');
const ZERO_BIG_DECIMAL = new bigDecimal('0');

export const RoyaltySplitItem: React.FC<RoyaltySplitItemProps> = ({
  id,
  contractAddress,
  blockchain,
  name,
  ratios,
  payees,
  verisartWallet,
  shopWallet,
}) => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const verisartPayee = payees
    .map((payee, idx) => ({ payee, idx }))
    .filter(({ payee }) => payee.address === verisartWallet)[0];
  const shopPayee = payees
    .map((payee, idx) => ({ payee, idx }))
    .filter(({ payee }) => payee.address === shopWallet)[0];
  const otherPayees = payees
    .map((payee, idx) => ({ payee, idx }))
    .filter(
      ({ payee }) =>
        payee.address !== verisartWallet && payee.address !== shopWallet
    );

  return (
    <>
      <ResourceItem
        onClick={() => {
          setExpanded(!expanded);
        }}
        media={<BlockchainLogo blockchain={blockchain} />}
        id={contractAddress}
      >
        <div className={styles.flex}>
          <div className={styles['royalty-split-row-name']}>
            <TextStyle variation="strong">{name}</TextStyle>
            {contractAddress ? (
              <BlockchainAddress
                address={contractAddress}
                blockchain={blockchain}
              />
            ) : payees.length === 1 ? (
              <TextStyle>
                <BlockchainAddress
                  address={payees[0].address}
                  blockchain={blockchain}
                />
              </TextStyle>
            ) : (
              <TextStyle variation="strong">
                {t('royaltySplitPage.royaltySplitItem.noAddress')}
              </TextStyle>
            )}
          </div>
          <Stack alignment={'center'}>
            <Stack.Item>
              <TextStyle>
                {ratios
                  .map((it) =>
                    new bigDecimal(it).divide(BPS_CONVERSION_FACTOR, 2)
                  )
                  .reduce((soFar, item) => soFar.add(item), ZERO_BIG_DECIMAL)
                  .getValue()}
                %
              </TextStyle>
            </Stack.Item>
            <Stack.Item>
              <Button
                onClick={function () {
                  const e = arguments[0];
                  e.stopPropagation();
                  history.push('/royalty-splits-edit/' + id);
                }}
              >
                Edit
              </Button>
            </Stack.Item>
            <Stack.Item>{expanded ? <MinusIcon /> : <PlusIcon />}</Stack.Item>
          </Stack>
        </div>
      </ResourceItem>
      {expanded && (
        <Card sectioned subdued>
          <div className={styles['expanded-card']}>
            {/* This is just a way to ensure that we correctly align the title elements with the card elements. It is the size of the chain icon*/}
            <div className={styles['blockchain-width']} />
            <div className={styles['width-100']}>
              <div className={styles['royalty-split-expanded-card-items']}>
                <div className={styles['flex-column']}>
                  <TextStyle variation="subdued">
                    {t('royaltySplitPage.royaltySplitItem.recipients')}
                  </TextStyle>
                  <div className={styles.recipients}>
                    {otherPayees.map((payee, index) => (
                      <div
                        key={payee.payee.address}
                        className={styles['flex-column']}
                      >
                        <TextStyle>
                          {payee.payee.name ? payee.payee.name : 'Unnamed'}
                        </TextStyle>
                        <TextStyle variation="subdued">
                          {new bigDecimal(ratios[payee.idx])
                            .divide(BPS_CONVERSION_FACTOR, 2)
                            .getValue()}
                          %
                        </TextStyle>
                        <BlockchainAddress
                          address={payee.payee.address}
                          blockchain={blockchain}
                        />
                      </div>
                    ))}
                    {shopPayee && (
                      <div className={styles['flex-column']}>
                        <TextStyle>Me</TextStyle>
                        <TextStyle variation="subdued">
                          {new bigDecimal(ratios[shopPayee.idx])
                            .divide(BPS_CONVERSION_FACTOR, 2)
                            .getValue()}
                          %
                        </TextStyle>
                        <BlockchainAddress
                          address={shopPayee.payee.address}
                          blockchain={blockchain}
                        />
                      </div>
                    )}
                    {verisartPayee && (
                      <div className={styles['flex-column']}>
                        <TextStyle>Verisart</TextStyle>
                        <TextStyle variation="subdued">
                          {new bigDecimal(ratios[verisartPayee.idx])
                            .divide(BPS_CONVERSION_FACTOR, 2)
                            .getValue()}
                          %
                        </TextStyle>
                        <BlockchainAddress
                          address={verisartPayee.payee.address}
                          blockchain={blockchain}
                        />
                      </div>
                    )}
                    <div className={styles['flex-column']}>
                      <TextStyle variation="subdued">
                        {t('royaltySplitPage.royaltySplitItem.totalRoyalties')}
                      </TextStyle>
                      <TextStyle>
                        {ratios
                          .map((it) => new bigDecimal(it))
                          .reduce(
                            (soFar, item) => soFar.add(item),
                            ZERO_BIG_DECIMAL
                          )
                          .divide(BPS_CONVERSION_FACTOR, 2)
                          .getValue()}
                        %
                      </TextStyle>
                    </div>
                  </div>
                </div>
                <div className={styles['chain-info']}>
                  <TextStyle variation="subdued">Chain</TextStyle>
                  <TextStyle>{t(`blockchain.${blockchain}`)}</TextStyle>
                </div>
                <div className={styles['flex-grow']}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${scannerBaseUrl(blockchain)}/address/${
                      contractAddress ? contractAddress : payees[0].address
                    }`}
                    className={styles['scan-link']}
                  >
                    <div className={styles['icon-scan-link']}>
                      <div>
                        {blockchainToClass(blockchain) ===
                        BlockchainClass.POLYGON ? (
                          <PolygonscanIcon />
                        ) : blockchainToClass(blockchain) ===
                          BlockchainClass.ETHEREUM ? (
                          <EtherscanIcon />
                        ) : blockchainToClass(blockchain) ===
                          BlockchainClass.BASE ? (
                          <BasescanIcon />
                        ) : null}
                      </div>
                      <div>
                        <span>
                          View on{' '}
                          {blockchainToClass(blockchain) ===
                          BlockchainClass.POLYGON
                            ? 'Polygonscan'
                            : blockchainToClass(blockchain) ===
                              BlockchainClass.ETHEREUM
                            ? 'Etherscan'
                            : blockchainToClass(blockchain) ===
                              BlockchainClass.BASE
                            ? 'Basescan'
                            : null}
                        </span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
