import { RadioButton, Stack, TextStyle } from '@shopify/polaris';
import React, { useCallback } from 'react';
import { MintMode } from 'hooks/useCreateDrop';
import { useFormContext } from 'react-hook-form';
import { DropsFormValues } from '../DropForm';
import t from 'lib/translation';

export type DropPayGasFeeProps = {
  disabled: boolean;
};

const DropPayGasFee: React.FC<DropPayGasFeeProps> = ({ disabled }) => {
  const { watch, setValue } = useFormContext<DropsFormValues>();

  const mintMode = watch('mintMode');

  const handleChange = useCallback(
    (_: boolean, newValue: MintMode) => {
      setValue('mintMode', newValue, { shouldDirty: true });
    },
    [setValue]
  );
  return (
    <Stack vertical spacing="baseTight">
      <TextStyle>{t('dropForm.gasFeeCustomization.title')}</TextStyle>
      <Stack vertical spacing="extraTight">
        <RadioButton
          label={t('dropForm.gasFeeCustomization.merchantPays')}
          helpText={t('dropForm.gasFeeCustomization.merchantHelpText')}
          checked={mintMode === MintMode.CUSTODIAL_MINTING}
          id={MintMode.CUSTODIAL_MINTING}
          onChange={handleChange}
          disabled={disabled}
        />
        <RadioButton
          label={t('dropForm.gasFeeCustomization.customerPays')}
          helpText={t('dropForm.gasFeeCustomization.customerHelpText')}
          id={MintMode.SIGNABLE_MINTING}
          checked={mintMode === MintMode.SIGNABLE_MINTING}
          onChange={handleChange}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
};

export default DropPayGasFee;
