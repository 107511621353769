import { Page } from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';
import ContractsForm, { ContractsFormProps } from './ContractsForm';
import { useHistoryGoBack } from '../../hooks/useHistoryGoBack';

type ContractsFormPageType = Omit<ContractsFormProps, 'setSaveCallback'>;

const ContractsFormPage: React.FC<ContractsFormPageType> = (props) => {
  const historyGoBack = useHistoryGoBack();

  return (
    <Page
      breadcrumbs={[
        {
          content: t('createContractForm.title'),
          onAction: () => historyGoBack('/contracts'),
        },
      ]}
      title={`${props.formType} contract`}
    >
      <ContractsForm {...props} />
    </Page>
  );
};

export default ContractsFormPage;
