import { Badge, Card, Spinner, Stack, TextStyle, Link } from '@shopify/polaris';
import React, { useState } from 'react';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import useShop from '../../hooks/useShop';
import t, { parameters as p } from '../../lib/translation';
import formatDate from '../../utils/formatDate';
import ApplyForMintingModal from '../../components/ApplyForMintingModal';

const MintingStatus: React.FC = () => {
  const {
    paidEnabled,
    giftingEnabled,
    isLoading: capIsLoading,
  } = useMainnetMintingEnabled();
  const [showApply, setShowApply] = useState(false);
  const { data: shopData, isLoading: useShopIsLoading } = useShop();
  const isLoading = capIsLoading || useShopIsLoading;
  const missingFullMinting = !paidEnabled || !giftingEnabled;

  // We may actually allow minting ourselves regardless of Shopify returns so don't show the date
  // if *we* say they can mint
  const reapplyDate =
    missingFullMinting && shopData?.shop?.nftSalesEligibilityReapplyDate
      ? formatDate(shopData?.shop?.nftSalesEligibilityReapplyDate)
      : null;

  return (
    <Card
      title={t('settingsSection.mintingStatus.title')}
      actions={
        missingFullMinting
          ? [
              {
                content: t('settingsSection.mintingStatus.reapplyLink'),
                onAction() {
                  setShowApply(true);
                },
              },
            ]
          : []
      }
    >
      {isLoading ? (
        <Card.Section>
          <Stack distribution={'center'}>
            <Spinner />
          </Stack>
        </Card.Section>
      ) : (
        <>
          <Card.Section>
            <Stack vertical>
              <Stack vertical spacing={'tight'}>
                {!paidEnabled && !giftingEnabled && (
                  <Badge progress="incomplete" status="attention">
                    {t('settingsSection.mintingStatus.testModeBadge')}
                  </Badge>
                )}

                {!paidEnabled && giftingEnabled && (
                  <Badge progress="partiallyComplete" status="warning">
                    {t('settingsSection.mintingStatus.approvedForGiftingBadge')}
                  </Badge>
                )}

                {paidEnabled && giftingEnabled && (
                  <Badge progress="complete">
                    {t('settingsSection.mintingStatus.approvedForSellingBadge')}
                  </Badge>
                )}

                {reapplyDate && (
                  <Stack spacing={'extraTight'}>
                    <TextStyle variation={'subdued'}>
                      {p(
                        'settingsSection.mintingStatus.reapplyText',
                        reapplyDate
                      )}
                    </TextStyle>
                    <Link
                      external
                      url={
                        'https://help.verisart.com/en/articles/6616531-what-does-test-mode-mean'
                      }
                    >
                      {t('settingsSection.mintingStatus.learnMore')}
                    </Link>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Card.Section>
        </>
      )}
      <ApplyForMintingModal
        open={showApply}
        onClose={() => setShowApply(false)}
      />
    </Card>
  );
};

export default MintingStatus;
