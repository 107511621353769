import React, { useRef } from 'react';
import { truncateBlockchainAddress } from 'utils/stringFormatting';
import styles from './BlockchainAddress.module.scss';
import { scannerBaseUrl } from 'utils/blockchain';
import { Blockchain } from 'types/Blockchain';
import { copyToClipboard } from 'utils/copyTextToClipboard';
import useToast from 'hooks/useToast';
import CopyIcon from 'components/JsxIcons/Copy/Copy-Icon';

type BlockchainAddressProps = {
  address: string;
  blockchain: Blockchain;
  fullLength?: Boolean;
};

const BlockchainAddress: React.FC<BlockchainAddressProps> = ({
  address,
  blockchain,
  fullLength,
}) => {
  const showToast = useToast((state) => state.showToast);
  const blockchainRef = useRef(null);

  return (
    <div className={`${styles.wrapper}`}>
      {address && (
        <a
          target="_blank"
          className={`${styles['blockchain-link']}`}
          href={`${scannerBaseUrl(blockchain)}/address/${address}`}
          rel="noreferrer"
        >
          {!fullLength ? truncateBlockchainAddress(address) : address}
        </a>
      )}
      <div>
        <input
          className={`${styles['hidden-input']}`}
          ref={blockchainRef}
          value={address}
          readOnly
        />
        <div
          className={`${styles['copy-icon']}`}
          onClick={async (e) => {
            e.currentTarget.focus();
            const copied = await copyToClipboard(address);
            e.stopPropagation();
            if (copied) showToast('Copied to clipboard', false);
          }}
        >
          <CopyIcon />
        </div>
      </div>
    </div>
  );
};

export default BlockchainAddress;
