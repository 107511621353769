import { useQuery } from 'react-query';
import API from '../utils/api';
import { Contract } from './useGetContracts';
import { CollectorRewardFile, MintMode } from './useCreateDrop';
import { Blockchain } from 'types/Blockchain';
import { RoyaltyAddress } from './useGetRoyaltyAddresses';

export interface Drop {
  id: string;
  name: string;
  contractId: string | null;
  externalAddress?: string;
  externalBlockchain?: Blockchain;
  collectorRewardFiles: CollectorRewardFile[];
  productsIds: number[];
  paymentSplitterContractId: string | null | undefined;
  createdAt: string;
  updatedAt: string;
  mintMode: MintMode;
  hasIssue: boolean;
  artBlocksProjectId: number | null;
  highlightId: string | null;
}

export interface GetDropsResponse {
  drop: Drop;
  contract?: Contract;
  paymentSplitter?: RoyaltyAddress;
}

export default function useGetDrops() {
  return useQuery<GetDropsResponse[]>('drop', async () => {
    return (await API().get('shop/drop?sort=updatedAt&order=DESC')).data;
  });
}
