import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useParentPath from 'utils/useParentPath';
import {
  Banner,
  Button,
  Card,
  Form,
  Layout,
  Link,
  Page,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import t from 'lib/translation';
import { Toast } from '@shopify/app-bridge/actions';
import { Controller, useForm } from 'react-hook-form';
import API from 'utils/api';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { AxiosError } from 'axios';
import style from './AdvancedEmailPage.module.css';
import RadioButton from 'components/RadioButton';
import useGetCapabilities from 'hooks/useGetCapabilities';
import UnlockButtonAndModal from './UnlockButtonAndModal';
import useShop from '../../hooks/useShop';
import usePatchShop from 'hooks/usePatchShop';
import EmailCustomizationForm from './EmailCustomizationForm';
import { useAppBridge } from 'containers/AppBridge';

export enum TestEmailType {
  CERTIFICATE = 'shopify_transfer_certificate',
  NFT_CLAIM = 'shopify_claim_your_nft',
  NFT_MINTED = 'shopify_nft_minted',
}

type TemplateContent = { subject: string; body: string };

const api = API();

export const isAxiosError = (e: unknown): e is AxiosError => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (e as any).isAxiosError === true;
};

const EmailSettingModes = ['basic', 'advanced'] as const;

export type EmailSettingMode = typeof EmailSettingModes[number];

const onSendTestEmail = async (
  email: string,
  type: TestEmailType,
  template?: TemplateContent
) => {
  await api.post(
    'shop/me/testEmail',
    { email, type, template },
    { timeout: 5000 }
  );
  console.info('Sending test email', { email, type, template });
};

const deleteTemplate = async (
  type: TestEmailType,
  queryClient: QueryClient
) => {
  try {
    const res = await api.delete(`/shopify/templates/${type}`, {
      timeout: 5000,
    });
    if (res.status !== 200) {
      return false;
    }
    queryClient.setQueryData(['email_template', type], {
      body: '',
      subject: '',
    });
    await queryClient.refetchQueries(['email_template', type]);
    return true;
  } catch {
    return false;
  }
};

const useGetEmailTemplate = (type: TestEmailType) =>
  useQuery(['email_template', type], async (): Promise<TemplateContent> => {
    const res = await api.get(`/shopify/templates/${type}`);
    return res.data;
  });

type ConnectedAdvancedEmailPageProps = {
  type: TestEmailType;
};

const ConnectedAdvancedEmailPage: React.FC<ConnectedAdvancedEmailPageProps> = ({
  type,
}) => {
  const shopQuery = useShop();
  const { data } = useGetEmailTemplate(type);

  if (!data || !shopQuery.data) {
    return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Spinner accessibilityLabel="Loading" size="large" />
      </div>
    );
  }

  return <AdvancedEmailPage initialTemplate={data} type={type} />;
};

export type BasicEmailBody = {
  body: string;
};

type AdvancedEmailPageProps = {
  initialTemplate: TemplateContent;
  type: TestEmailType;
};

const strings: Record<TestEmailType, { title: string; subheading: string }> = {
  [TestEmailType.CERTIFICATE]: {
    title: t(`settings.emailCustomization.claimCoaEmail`),
    subheading: t(`settings.emailCustomization.COABodyInformationLong`),
  },
  [TestEmailType.NFT_CLAIM]: {
    title: t(`settings.emailCustomization.claimNFTEmail`),
    subheading: t(`settings.emailCustomization.nftBodyInformationLong`),
  },
  [TestEmailType.NFT_MINTED]: {
    title: t(`settings.emailCustomization.viewNFTEmail`),
    subheading: t(`settings.emailCustomization.viewNFTBodyInformationLong`),
  },
};

const SendTestEmailForm: React.FC<{
  type: TestEmailType;
  template?: TemplateContent;
  onError?: (errorMessage: string) => void;
  isDirty: boolean;
}> = ({ onError, type, template, isDirty }) => {
  const app = useAppBridge();
  const { data: shop } = useShop();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: shop?.shop.contactEmail!!,
    },
  });
  const onSubmit = handleSubmit(async (data) => {
    const options: Toast.Options = {
      duration: 5000,
      message: '',
    };
    try {
      await onSendTestEmail(data.email, type, template);
      reset();
      options.message = t('settings.emailCustomization.sendTestEmailSuccess');
    } catch (e) {
      options.message = t('settings.emailCustomization.sendTestEmailError');
      options.isError = true;
      if (isAxiosError(e) && e.response?.status === 400) {
        onError?.(e.response?.data?.message);
      }
    } finally {
      app.showToast(options);
    }
  });

  useEffect(() => {
    try {
      setValue('email', shop?.shop.contactEmail!!);
    } catch (error) {
      console.error('Failed to fetch email', error);
    }
  }, [shop?.shop.contactEmail, setValue]);

  return (
    <Card title={t('settings.emailCustomization.testEmailCardTitle')} sectioned>
      <Stack vertical>
        <Stack.Item>
          <Form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: t(
                  'settings.emailCustomization.sendTestEmailAddressRequired'
                ),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t(
                    'settings.emailCustomization.sendTestEmailAddressFormatIncorrect'
                  ),
                },
              }}
              render={({
                field: { name, onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name={name}
                  label={t('settings.emailCustomization.sendTestEmailAddress')}
                  disabled={true}
                  error={error?.message}
                  autoComplete="off"
                  type="email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  connectedRight={
                    <Button
                      disabled={isDirty}
                      submit
                      primary
                      loading={isSubmitting}
                    >
                      {t('settings.emailCustomization.sendEmail')}
                    </Button>
                  }
                />
              )}
            />
          </Form>
        </Stack.Item>
      </Stack>
    </Card>
  );
};

const emailCustomizationKeyForType = (
  type: TestEmailType
): 'body' | 'nftBody' | 'viewNftBody' => {
  switch (type) {
    case TestEmailType.CERTIFICATE:
      return 'body';
    case TestEmailType.NFT_CLAIM:
      return 'nftBody';
    case TestEmailType.NFT_MINTED:
      return 'viewNftBody';
  }
};

const AdvancedEmailPage = ({
  initialTemplate,
  type,
}: AdvancedEmailPageProps) => {
  const history = useHistory();
  const parentPath = useParentPath();
  const back = () => history.replace(parentPath);
  const [errorMessage, setErrorMessage] = useState('');
  const [dirtyFields, setDirtyFields] = useState(false);
  const queryClient = useQueryClient();
  const { data: capabilities } = useGetCapabilities();
  const [emailSettingMode, setEmailSettingMode] = useState(
    !initialTemplate.subject && !initialTemplate.body ? 'basic' : 'advanced'
  );

  const isAdvancedTemplateSet = initialTemplate.subject && initialTemplate.body;
  const [currentTemplate, setCurrentTemplate] =
    useState<{ body: string; subject?: string }>(initialTemplate);
  const { title, subheading } = strings[type];

  const { mutateAsync: updateShop } = usePatchShop();

  const { mutateAsync: updateEmailTemplate } = useMutation(
    async (content: TemplateContent) => {
      const res = await api.patch(`/shopify/templates/${type}`, content);
      return res.data;
    },
    {
      onMutate(content: TemplateContent) {
        queryClient.setQueryData(['email_template', type], content);
      },
    }
  );

  const app = useAppBridge();
  const shopQuery = useShop();

  const basicBodyValue =
    shopQuery.data?.shop?.emailCustomization?.[
      emailCustomizationKeyForType(type)
    ] ?? '';

  const submitAdvanced = async (d: { body: string; subject: string }) => {
    const options: Toast.Options = {
      duration: 5000,
      message: '',
    };
    try {
      await updateEmailTemplate(d);
      options.message = t(
        'settings.emailCustomization.submitCustomHTMLSuccess'
      );
    } catch (e) {
      options.isError = true;
    } finally {
      if (!options.isError) {
        app.showToast(options);
      }
    }
  };

  const submitBasic = async ({ body }: BasicEmailBody) => {
    const key = emailCustomizationKeyForType(type);

    const options: Toast.Options = {
      duration: 5000,
      message: '',
    };

    try {
      await updateShop({
        emailCustomization: {
          [key]: body,
        },
      });
      options.message = t('settings.emailCustomization.submitBasicBodySuccess');
    } catch (e) {
      options.message = t('settings.emailCustomization.submitBasicBodyError');
      options.isError = true;
    } finally {
      app.showToast(options);
    }
  };

  return (
    <>
      <Page
        title={title}
        breadcrumbs={[{ content: t('settings.page.title'), onAction: back }]}
      >
        <Layout>
          <Layout.Section fullWidth>
            <Stack vertical spacing="loose">
              {emailSettingMode === 'basic' && isAdvancedTemplateSet && (
                <Banner status="warning">
                  <p>
                    {t('settings.emailCustomization.advancedEmailSetWarning')}
                  </p>
                </Banner>
              )}
              {errorMessage && (
                <Banner
                  title={t(
                    'settings.emailCustomization.testEmailSendErrorTitle'
                  )}
                  status="critical"
                >
                  <p>{t('settings.emailCustomization.testEmailSyntaxError')}</p>
                  <p className={`${style.codeBlock}`}>
                    <span>{errorMessage}</span>
                  </p>
                </Banner>
              )}

              <TextStyle variation="subdued">{subheading}</TextStyle>
              <Card sectioned>
                <Stack vertical spacing="loose">
                  <Stack distribution="fillEvenly">
                    {EmailSettingModes.map((mode) => {
                      const showUnlockBadge =
                        mode === 'advanced' &&
                        capabilities !== undefined &&
                        !capabilities.capabilities.includes(
                          'emails.customize.html'
                        );
                      return (
                        <RadioButton
                          key={mode}
                          label={t(
                            `settings.emailCustomization.${mode}RadioLabel`
                          )}
                          helpText={t(
                            `settings.emailCustomization.${mode}RadioHelp`
                          )}
                          checked={emailSettingMode === mode}
                          onChange={() => setEmailSettingMode?.(mode)}
                          accessory={
                            showUnlockBadge &&
                            capabilities !== undefined && (
                              <UnlockButtonAndModal
                                capability={'emails.customize.html'}
                                supportedCapabilities={
                                  capabilities.supportedCapabilities
                                }
                              />
                            )
                          }
                          disabled={showUnlockBadge}
                        />
                      );
                    })}
                  </Stack>
                  {emailSettingMode === 'basic' && (
                    <EmailCustomizationForm
                      body={basicBodyValue}
                      onSubmit={(data) => submitBasic(data)}
                      onDirtyChange={setDirtyFields}
                    />
                  )}

                  {emailSettingMode === 'advanced' && (
                    <>
                      <Stack vertical spacing="loose">
                        <TextStyle>
                          {t('settings.emailCustomization.docsCopy')}{' '}
                          <Link url="https://docs.verisart.com/reference/shopify-custom-email-templates">
                            {t('settings.emailCustomization.emailLearnMore')}
                          </Link>
                        </TextStyle>
                        <EmailCustomizationForm
                          subject={initialTemplate.subject}
                          body={initialTemplate.body}
                          onSubmit={(data) =>
                            submitAdvanced({
                              ...data,
                              subject: data.subject ?? '',
                            })
                          }
                          onModify={setCurrentTemplate}
                          onClear={async () => {
                            const options: Toast.Options = {
                              duration: 5000,
                              message: '',
                            };
                            if (await deleteTemplate(type, queryClient)) {
                              options.message = t(
                                'settings.emailCustomization.deleteCustomHTMLSuccess'
                              );
                              app.showToast(options);
                              return true;
                            } else {
                              options.message = 'failed';
                              app.showToast(options);
                              return false;
                            }
                          }}
                          onDirtyChange={setDirtyFields}
                        />
                      </Stack>
                    </>
                  )}
                </Stack>
              </Card>
            </Stack>
          </Layout.Section>
          <Layout.Section>
            <SendTestEmailForm
              type={type}
              template={
                emailSettingMode === 'advanced'
                  ? {
                      body: currentTemplate?.body ?? '',
                      subject: currentTemplate?.subject ?? '',
                    }
                  : undefined
              }
              onError={setErrorMessage}
              isDirty={dirtyFields}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default ConnectedAdvancedEmailPage;
