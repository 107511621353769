import API from '../utils/api';
import { useQuery } from 'react-query';

const fetchRecipients = async () => {
  return (await API().get('/shopify/recipients')).data;
};

export interface FetchRecipientsResponse {
  authArtists: ArtistRecipient[];
  recipients: Recipient[];
}

export interface ArtistRecipient {
  artistId: string;
  name: string;
  walletAddress: WalletAddress[];
}

export interface WalletAddress {
  address: string;
  hardLinked: boolean;
}

export interface Recipient {
  name: string;
  walletAddress: string[];
  recipientId: string;
}

export const useGetRecipients = () => {
  const { data, error, isLoading } = useQuery<FetchRecipientsResponse>(
    'recipients',
    () => fetchRecipients()
  );

  return {
    data,
    error,
    isLoading,
  };
};
