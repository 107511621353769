import t from 'lib/translation';
import { CreatorType } from 'pages/OnboardingPage/OnboardingModal';

export const OLDEST_ALLOWED_YEAR_BUSINESS = 1900;
export const OLDEST_ALLOWED_YEAR_PERSON = 1920;

/**
 * Empty string is no error, else error message is returned
 *
 * @param creatorType
 */
export const validYearOfBirth =
  (creatorType: CreatorType) =>
  (value: string): string | boolean => {
    const parsed = parseInt(value);

    if (isNaN(parsed) || value.length !== 4 || value.substring(0, 1) === '0') {
      return creatorType === 'individual'
        ? t('onboarding.creatorDetails.validation.individual.invalidYear')
        : t('onboarding.creatorDetails.validation.business.invalidYear');
    }

    if (parsed > new Date().getFullYear()) {
      return creatorType === 'individual'
        ? t('onboarding.creatorDetails.validation.individual.maxYear')
        : t('onboarding.creatorDetails.validation.business.maxYear');
    }

    if (creatorType === 'business' && parsed < OLDEST_ALLOWED_YEAR_BUSINESS) {
      return t('onboarding.creatorDetails.validation.business.minYear');
    }

    if (creatorType === 'individual' && parsed < OLDEST_ALLOWED_YEAR_PERSON) {
      return t('onboarding.creatorDetails.validation.individual.minYear');
    }

    return true;
  };
