import React from 'react';
import {
  Page,
  Subheading,
  Card,
  Stack,
  Icon,
  TextStyle,
  Button,
} from '@shopify/polaris';
import Footer from 'components/Footer';
import CreatorsTable from 'components/CreatorsTable';
import { CustomersMajor, ExternalMinor } from '@shopify/polaris-icons';
import SimpleEmptyState from 'components/SimpleEmptyState';

import t from 'lib/translation';
import useShop from 'hooks/useShop';
import useCreatorMyself from 'hooks/useCreatorMyself';
import useCreatorsRepresented from 'hooks/useCreatorsRepresented';
import { useOpenWindowOnVerisartDotCom } from 'utils/mainWebsiteLogin';
import styles from './CreatorsPage.module.scss';
import useGetCapabilities from '../../hooks/useGetCapabilities';

const CreatorsPage = () => {
  const { data: shopData } = useShop();
  const { data: myData } = useCreatorMyself();
  const { data: representedData } = useCreatorsRepresented();

  const { data: capabilities } = useGetCapabilities();

  const openWindowOnVerisartDotCom = useOpenWindowOnVerisartDotCom();

  const hasRepresentsCapability =
    capabilities?.capabilities?.includes('artists.represent');

  return (
    <>
      <Page title={t('creatorsSection.title')}>
        {myData?.myselfData.length === 0 &&
        representedData?.representArtist.length === 0 ? (
          <SimpleEmptyState
            title={t('creatorsSection.emptyState.title')}
            subtitle={t('creatorsSection.emptyState.subTitle')}
            buttonText={t('creatorsSection.emptyState.buttonText')}
            buttonAction={() =>
              openWindowOnVerisartDotCom('/settings/authorizations')
            }
          />
        ) : (
          <>
            <Card>
              <Card.Section>
                <Stack alignment={'center'}>
                  <Icon source={CustomersMajor} />
                  <Stack.Item fill>
                    <TextStyle variation="strong">
                      {t('creatorsSection.ctaManage')}
                    </TextStyle>
                  </Stack.Item>
                  <Button
                    icon={ExternalMinor}
                    external
                    onClick={() =>
                      openWindowOnVerisartDotCom('/settings/account')
                    }
                  >
                    {t('creatorsSection.ctaManageButton')}
                  </Button>
                </Stack>
              </Card.Section>
            </Card>
            <div className={`${styles['subheader-wrapper']}`}>
              <Subheading>
                {t('creatorsSection.personalTable.heading')}
              </Subheading>
            </div>
            <div />
            <Card>
              <CreatorsTable
                rows={myData?.myselfData ?? []}
                emptyTableText={t('creatorsSection.personalTable.emptyTitle')}
              />
            </Card>
            <div className={`${styles.spacer}`} />
            {hasRepresentsCapability && (
              <div className={`${styles['subheader-wrapper']}`}>
                <Subheading>
                  {t('creatorsSection.representsTable.heading')}
                </Subheading>
              </div>
            )}
            {representedData?.representArtist &&
              representedData?.representArtist.length > 0 &&
              hasRepresentsCapability && (
                <Card>
                  <CreatorsTable
                    rows={representedData?.representArtist ?? []}
                    overrideStatus={
                      shopData?.shop.businessVerified ? null : 'On hold'
                    }
                  />
                </Card>
              )}
          </>
        )}
      </Page>
      <Footer />
    </>
  );
};

export default CreatorsPage;
