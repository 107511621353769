import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';

export default function useRefreshSubscription() {
  const queryCache = useQueryClient();

  return useMutation(() => API().post('/shop/me/subscription/refresh'), {
    onSuccess: () => {
      queryCache.invalidateQueries('subscriptionDetails');
    },
  });
}
