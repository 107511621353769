import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { SelfCreatorDetails } from './useSetupShop';

async function setupSelfCreatorDetails(
  firstName: string,
  lastName: string | null,
  year: string
) {
  return API().post('/shopify/setupCreatorDetails', {
    selfCreatorDetails: {
      name: firstName,
      lastName: lastName,
      associatedYear: year,
    },
  });
}

export default function useSetupSelfCreatorDetails(onSuccess: () => void) {
  const queryCache = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (data: SelfCreatorDetails) => {
      await setupSelfCreatorDetails(
        data.name,
        data.lastName,
        data.associatedYear
      );
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries('attemptOnboarding');
        onSuccess();
      },
    }
  );

  return { mutate, isLoading };
}
