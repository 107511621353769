import React, { useState } from 'react';
import { Badge, Card, Layout, Link, Stack, TextStyle } from '@shopify/polaris';
import t from '../../lib/translation';
import { AxiosResponse } from 'axios';
import API from '../../utils/api';
import mixpanel from 'mixpanel-browser';
import { getPlatform, useAppBridge } from '../../containers/AppBridge';
import { isPanelDisabled } from '../../platform/platform';

interface DepaySetupProps {
  needsSetup: boolean;
}
const DepaySetup: React.FC<DepaySetupProps> = ({ needsSetup }) => {
  const { platform } = useAppBridge();
  const [depayCta, setDepayCta] = useState(t('settings.depay.ctaAction'));

  if (isPanelDisabled('depay', platform)) return null;

  return (
    <Layout.Section fullWidth>
      <Card
        title={t('settings.depay.cardTitle')}
        actions={
          !needsSetup
            ? [
                {
                  content: t('settings.depay.activatedAction'),
                  external: true,
                  url: 'https://help.verisart.com/en/articles/6891605-add-a-web3-purchase-option-to-your-shopify-store',
                },
              ]
            : [
                {
                  content: depayCta,
                  disabled: depayCta === t('settings.depay.ctaActionDisabled'),
                  external: true,
                  onAction: async () => {
                    setDepayCta(t('settings.depay.ctaActionDisabled'));
                    const authUrl: AxiosResponse<{ url: string }> =
                      await API().get('shopify/depay/generateAuthURL');
                    mixpanel.track('Depay Configured', {
                      Platform: getPlatform(),
                    });
                    window.open(authUrl.data.url, '_blank');
                    setDepayCta(t('settings.depay.ctaAction'));
                  },
                },
              ]
        }
      >
        <Card.Section>
          {!needsSetup ? (
            <Badge>Ready</Badge>
          ) : (
            <Stack vertical>
              <TextStyle>
                {t('settings.depay.bulletPoints.first.text')}{' '}
                <Link url={'https://apps.shopify.com/depay'}>
                  {t('settings.depay.bulletPoints.first.link')}
                </Link>
              </TextStyle>
              <TextStyle>
                {t('settings.depay.bulletPoints.second.text')}{' '}
                <Link
                  url={
                    'https://help.verisart.com/en/articles/6891605-add-a-web3-purchase-option-to-your-shopify-store'
                  }
                >
                  {t('settings.depay.bulletPoints.second.link')}
                </Link>
              </TextStyle>
              <TextStyle>
                {t('settings.depay.bulletPoints.third.text')}
              </TextStyle>
            </Stack>
          )}
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};

export default DepaySetup;
