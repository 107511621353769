import Footer from 'components/Footer';
import DropForm from 'components/DropForm';
import React from 'react';

const CreateDropPage = () => {
  return (
    <>
      <DropForm />
      <Footer />
    </>
  );
};

export default CreateDropPage;
