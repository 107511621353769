import { useQuery } from 'react-query';
import API from 'utils/api';
import { ManagedRelationshipData } from 'types/Artist';

interface CreatorsRepresentResponse {
  representArtist: ManagedRelationshipData[];
}

const useCreatorsRepresent = () =>
  useQuery<CreatorsRepresentResponse>('creatorsRepresented', async () => {
    const response = await API().get('/managedArtist/represent');
    return response.data;
  });

export default useCreatorsRepresent;
