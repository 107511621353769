import { TextField } from '@shopify/polaris';
import React from 'react';
import { Controller } from 'react-hook-form';

interface ControllerTextInputProps {
  name: string;
  label: string;
  labelHidden?: boolean;
  placeholder?: string;
  helpText?: string;
  textbox?: number;
  labelAction?: { content: string; url: string };
  customOnChange?: (val?: string) => void;
  noDefault?: boolean;
  error?: string;
  prefix?: React.ReactNode;
  required?: string;
  maxLength?: number;
  disabled?: boolean;
  validator?: (val: string) => string | boolean;
  type?: 'number' | 'text';
  center?: boolean;
  valueOverride?: string;
  errorOverride?: boolean;
}

const ControllerTextInput: React.FC<ControllerTextInputProps> = ({
  name,
  label,
  labelHidden,
  customOnChange,
  placeholder,
  helpText,
  textbox,
  labelAction,
  error,
  prefix,
  required,
  maxLength,
  disabled,
  validator,
  type,
  center,
  valueOverride,
  errorOverride,
}) => {
  return (
    <Controller
      name={name}
      rules={{
        required,
        ...(maxLength && {
          maxLength: {
            value: maxLength || 0,
            message: `Maximum of ${maxLength} characters`,
          },
        }),
        validate: validator,
      }}
      render={({ field, fieldState: { error: fieldError } }) => (
        <TextField
          prefix={prefix}
          value={
            valueOverride ??
            (field.value === null ? '' : field.value?.toString())
          } // This makes data that is in number format work
          onChange={(fieldValue) => {
            if (customOnChange) {
              customOnChange(fieldValue);
            }
            field.onChange(fieldValue);
          }}
          onBlur={field.onBlur}
          label={label}
          labelHidden={labelHidden}
          autoComplete="off"
          placeholder={placeholder ?? ''}
          helpText={helpText ?? ''}
          error={error ? (errorOverride ? error : fieldError?.message) : ''}
          multiline={textbox ?? false}
          labelAction={labelAction}
          disabled={disabled}
          type={type}
          align={center ? 'center' : undefined}
        />
      )}
    />
  );
};

export default ControllerTextInput;
