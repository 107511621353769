import React from 'react';
import {
  Card,
  Banner,
  IndexTable,
  Stack,
  TextStyle,
  Link,
} from '@shopify/polaris';
import t from 'lib/translation';
import { GweiSettingsRow } from './GweiSettingsRow';

export const CustomGweiSettings: React.FC = () => {
  const rowMarkup = [
    <GweiSettingsRow
      key="minting"
      action="minting"
      rowNum={0}
      formName="gweiValue"
    />,
    <GweiSettingsRow
      key="deployingContract"
      action="deployingContract"
      rowNum={1}
      formName="contractGweiValue"
    />,
    <GweiSettingsRow
      key="paymentSplitter"
      action="paymentSplitter"
      rowNum={2}
      formName="paymentSplitGweiValue"
    />,
  ];

  return (
    <Card title={t('settingsSection.customGwei.title')}>
      <Card.Section>
        <Banner>
          <p>{t('settingsSection.customGwei.tooltip')}</p>
        </Banner>
      </Card.Section>
      <Card.Section>
        <Stack spacing={'loose'}>
          <Stack spacing="extraTight">
            <TextStyle>
              {t('settingsSection.customGwei.maximumCost.text')}
            </TextStyle>
            <Link url="https://help.verisart.com/en/articles/7979948-set-gas-fee-limits">
              {t('settingsSection.customGwei.maximumCost.linkText')}
            </Link>
          </Stack>
          <TextStyle>
            {t('settingsSection.customGwei.normalCost.text')}
          </TextStyle>
        </Stack>
      </Card.Section>
      <Card.Section>
        <IndexTable
          headings={[
            { title: 'Action' },
            { title: 'GWEI limit' },
            { title: 'Maximum cost' },
            { title: 'Current cost' },
          ]}
          selectable={false}
          itemCount={3}
        >
          {rowMarkup}
        </IndexTable>
      </Card.Section>
    </Card>
  );
};
