import React, { useState, useEffect, useMemo } from 'react';
import { Card, Stack, RadioButton, Button } from '@shopify/polaris';
import { royaltyOptions } from './RoyaltyOptions';
import { useGetRoyaltyAddresses } from 'hooks/useGetRoyaltyAddresses';
import { useFormContext } from 'react-hook-form';
import ControllerSelectInput from 'components/forms/SelectInput';
import useToast from 'hooks/useToast';
import style from './DropRoyalties.module.scss';
import t from 'lib/translation';
import { DropsFormValues } from '../DropForm';

export type DropRoyaltiessProps = {
  disabled: boolean;
  createNewSplitter: () => void;
};

/**
 * The form which lets you pick existing drop royalties. May also call `createNewSplitter()` to indicate that the
 * user wants to create a new splitter (that is handled outside of this component).
 */
const DropRoyalties: React.FC<DropRoyaltiessProps> = ({
  disabled,
  createNewSplitter,
}) => {
  const { data: royaltySplitsData } = useGetRoyaltyAddresses();

  const { watch, setValue, formState } = useFormContext<DropsFormValues>();

  const royalties = watch('royalties');

  const [type, setType] = useState<'NONE' | 'CONTRACT'>(
    royalties ? 'CONTRACT' : 'NONE'
  );
  const selectedChain = watch('chain');

  const showToast = useToast((state) => state.showToast);
  const splits = useMemo(
    () =>
      royaltySplitsData?.royaltyAddresses
        .filter((contract) => contract.blockchain === selectedChain)
        .map((contract, i) => ({
          value: contract.id,
          label: contract.name || '',
        })) ?? [],
    [royaltySplitsData?.royaltyAddresses, selectedChain]
  );

  useEffect(() => {
    type === 'NONE' && setValue('royalties', null, { shouldDirty: true });
  }, [type, setValue]);

  return (
    <Card>
      <Card.Section>
        <Stack vertical>
          <Stack>
            <Stack vertical spacing="extraTight">
              {royaltyOptions.map((royaltyOption) => (
                <RadioButton
                  key={`${royaltyOption.id}`}
                  disabled={disabled}
                  label={royaltyOption.label}
                  helpText={royaltyOption.helpText}
                  checked={royaltyOption.id === type}
                  onChange={(checked) => {
                    if (checked) {
                      setType(royaltyOption.id);
                    }
                  }}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>

        {type === 'CONTRACT' && (
          <div className={style['mt-4']}>
            <Stack vertical>
              <ControllerSelectInput
                label={''}
                name="royalties"
                options={splits}
                error={formState.errors?.royalties?.message}
              />
              <Stack alignment="fill">
                <Button
                  plain
                  onClick={() => {
                    if (!selectedChain) {
                      showToast(t('dropForm.toast.missingChain'));
                    } else {
                      createNewSplitter();
                    }
                  }}
                >
                  {t('dropForm.createRoyaltysplit')}
                </Button>
              </Stack>
            </Stack>
          </div>
        )}
      </Card.Section>
    </Card>
  );
};

export default DropRoyalties;
