import { IndexTable, TextStyle } from '@shopify/polaris';
import TextInput from 'components/forms/TextInput';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Action, useGetFeeRange } from '../../../hooks/useGetFeeRange';
import { validPositiveNumber } from '../../../utils/validation';
import { GweiLimits, SettingsFormInputs } from '../SettingsForm';

interface GweiSettingsRowProps {
  action: Action;
  formName: GweiLimits;
  rowNum: number;
}

export const GweiSettingsRow: React.FC<GweiSettingsRowProps> = ({
  action,
  formName,
  rowNum,
}) => {
  const form = useFormContext<SettingsFormInputs>();
  const gweiValue = form.watch(formName);
  const { data: configuredData } = useGetFeeRange(parseInt(gweiValue));
  const { data: marketData } = useGetFeeRange();

  return (
    <IndexTable.Row id={formName} key={formName} position={rowNum}>
      <IndexTable.Cell>
        <TextStyle variation="strong">{renderAction(action)}</TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextInput
          error={form.formState.errors?.[formName]?.message}
          name={formName}
          label={''}
          validator={validPositiveNumber}
        ></TextInput>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {configuredData?.[action]?.ethereum?.high} ETH ($
        {configuredData?.[action]?.ethereumFiat?.high})
      </IndexTable.Cell>
      <IndexTable.Cell>
        {marketData?.[action]?.ethereum?.high} ETH ($
        {marketData?.[action]?.ethereumFiat?.high})
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};

const renderAction = (action: Action) => {
  switch (action) {
    case 'minting':
      return 'Minting';
    case 'deployingContract':
      return 'Deploying contract';
    case 'paymentSplitter':
      return 'Deploying payment splitter';
    default:
      return 'Unknown';
  }
};
