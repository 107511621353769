import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import t, { parameters as p } from 'lib/translation';
import {
  Button,
  Modal,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import useContextualSaveBar from 'hooks/useContextualSaveBar';

type OnSubmit = (data: {
  body: string;
  subject?: string;
}) => Promise<void> | void;
type OnClear = () => Promise<boolean> | boolean;
const EmailSettingModes = ['basic', 'advanced'] as const;
type OnChange = (data: { body: string; subject?: string }) => void;

export type EmailSettingMode = typeof EmailSettingModes[number];

const bodyTextMaxLength = 400;
const nftBodyTextMaxLength = 400;

const EmailCustomizationForm = ({
  subject,
  body,
  onSubmit,
  onClear,
  onModify,
  onDirtyChange,
}: {
  subject?: string;
  body: string;
  onSubmit: OnSubmit;
  onClear?: OnClear;
  onModify?: OnChange;
  onDirtyChange?: (isDirty: boolean) => void;
}) => {
  const form = useForm({
    defaultValues: {
      subject,
      body,
    },
  });

  const [isClearing, setClearing] = useState(false);

  useEffect(() => {
    if (onModify) {
      const { unsubscribe } = form.watch((data) =>
        onModify({ body: data.body ?? '', subject: data.subject })
      );
      return unsubscribe;
    }
  }, [onModify, form.watch, form]);

  useContextualSaveBar(form, form.handleSubmit(onSubmit));
  const { isSubmitSuccessful, isDirty } = useFormState({
    control: form.control,
  });
  const { reset, getValues } = form;

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [form.watch, form.formState, onDirtyChange, form, isDirty]);

  useEffect(() => {
    const resetForm = async () => {
      reset(getValues());
    };

    if (isSubmitSuccessful) {
      resetForm();
    }
  }, [reset, isSubmitSuccessful, getValues, isDirty]);

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Stack vertical spacing="loose">
        {subject !== undefined && (
          <Controller
            name="subject"
            control={form.control}
            rules={{
              required: t('settings.emailCustomization.subjectRequired'),
            }}
            render={({
              field: { value, onChange },
              fieldState: { error },
              formState: { isSubmitting },
            }) => (
              <TextField
                label="Subject"
                autoComplete="off"
                value={value}
                onChange={onChange}
                disabled={isSubmitting || isClearing}
                error={error?.message}
              />
            )}
          />
        )}
        <Stack vertical spacing="loose">
          <Controller
            name="body"
            control={form.control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { error },
              formState: { isSubmitting },
            }) => {
              const helpTextVariation = isSubmitting
                ? 'subdued'
                : nftBodyTextMaxLength - value.length < 0 ||
                  error?.type === 'maxLength'
                ? 'negative'
                : 'positive';
              return subject === undefined ? (
                <TextField
                  label={t('settings.emailCustomization.bodyText')}
                  autoComplete="off"
                  multiline={4}
                  value={value}
                  onChange={onChange}
                  disabled={isSubmitting}
                  helpText={
                    <TextStyle variation={helpTextVariation}>
                      {p(
                        'settings.emailCustomization.bodyTextLength',
                        Math.max(bodyTextMaxLength - value.length, 0),
                        bodyTextMaxLength
                      )}
                    </TextStyle>
                  }
                />
              ) : (
                <TextField
                  label={t('settings.emailCustomization.customHTMLBodyLabel')}
                  disabled={isSubmitting || isClearing}
                  error={error?.message}
                  autoComplete="off"
                  type="email"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline={20}
                  maxHeight={400}
                />
              );
            }}
          />
        </Stack>
        {onClear !== undefined && (
          <Stack vertical alignment="trailing">
            <Button destructive outline onClick={() => setModalOpen(true)}>
              {t('settings.emailCustomization.ClearAllButton')}
            </Button>
          </Stack>
        )}
        {onClear && isModalOpen && (
          <Modal
            open={isModalOpen}
            title={t('settings.emailCustomization.deleteModalTitle')}
            primaryAction={{
              destructive: true,
              content: 'Delete',
              onAction: async () => {
                setClearing(true);
                if (await onClear()) {
                  reset({ body: '', subject: '' });
                }
                setClearing(false);
                setModalOpen(false);
              },
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setModalOpen(false),
              },
            ]}
            onClose={() => setModalOpen(false)}
          >
            <Modal.Section>
              <TextContainer>
                <p>
                  {t('settings.emailCustomization.deleteEmailTemplateModal')}
                </p>
              </TextContainer>
            </Modal.Section>
          </Modal>
        )}
      </Stack>
    </>
  );
};

export default EmailCustomizationForm;
