import { useQuery } from 'react-query';
import API from 'utils/api';
import { TokenGate } from './useGetTokenGate';

export default function useGetTokenGates() {
  return useQuery<TokenGate[]>('tokenGates', async () => {
    const response = await API().get('shop/tokenGate');

    return response.data;
  });
}
