import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Filters, Page, ResourceList } from '@shopify/polaris';
import Footer from 'components/Footer';
import t from 'lib/translation';
import SimpleEmptyState from 'components/SimpleEmptyState';
import {
  determinePageSize,
  LocalPagination,
} from '../../components/LocalPagination';
import { GetDropsResponse } from '../../hooks/useGetDrops';
import ContractListItem from '../../components/ContractListItem';
import styles from './DropPage.module.scss';
import { SortOptions, SortType } from '../../types/Utils';
import { orderBy } from 'lodash';
import PremintedNFTImport from './PremintedNFTImport';
import { Contract } from '../../hooks/useGetContracts';
import { DateTime } from 'luxon';
import { RoyaltyAddress } from '../../hooks/useGetRoyaltyAddresses';
interface DropPageProps {
  drops: GetDropsResponse[];
}

const DropPageList: React.FC<DropPageProps> = ({ drops }) => {
  const pageSize = 10;
  const numberOfPages = Math.ceil(drops.length / pageSize);
  const isEmptyState = drops.length === 0;

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortValue, setSortValue] = useState<SortType>('UPDATED_AT');
  const [queryValue, setQueryValue] = useState<string | undefined>(undefined);
  const [dropsAvailable, setDropsAvailable] =
    useState<GetDropsResponse[]>(drops);
  const [dropsAvailableAndSorted, setDropsAvailableAndSorted] =
    useState<GetDropsResponse[]>(drops);
  const [importModal, setImportModal] = useState(false);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={[]}
      onQueryChange={(value) => setQueryValue(value)}
      onQueryClear={() => setQueryValue(undefined)}
      onClearAll={() => setQueryValue(undefined)}
    />
  );

  useEffect(() => {
    if (queryValue) {
      setDropsAvailable(
        drops.filter((drop) =>
          drop.drop.name.toLowerCase().includes(queryValue.toLowerCase())
        )
      );
      setCurrentPage(1);
    } else {
      setDropsAvailable(drops);
    }
  }, [queryValue, drops]);

  useEffect(() => {
    switch (sortValue) {
      case 'NAME_ASC':
        setDropsAvailableAndSorted(
          orderBy(dropsAvailable, (d) => d.drop.name.toLowerCase(), 'asc')
        );
        break;
      case 'CREATED_AT':
        setDropsAvailableAndSorted(
          orderBy(dropsAvailable, 'drop.createdAt', 'desc')
        );
        break;
      case 'UPDATED_AT':
        setDropsAvailableAndSorted(
          orderBy(dropsAvailable, 'drop.updatedAt', 'desc')
        );
        break;
    }
  }, [sortValue, dropsAvailable]);

  return (
    <>
      <Page
        title={isEmptyState ? undefined : t('drops.list.title')}
        primaryAction={
          isEmptyState
            ? undefined
            : {
                content: t('drops.list.action'),
                onAction: () => {
                  history.push('/drops/create');
                },
              }
        }
        secondaryActions={[
          {
            content: 'Import',
            onAction: () => {
              setImportModal(!importModal);
            },
          },
        ]}
      >
        {!isEmptyState ? (
          <>
            <Card>
              <ResourceList
                sortValue={sortValue}
                sortOptions={SortOptions}
                onSortChange={(selected: SortType) => {
                  setSortValue(selected);
                }}
                filterControl={filterControl}
                items={determinePageSize(
                  dropsAvailableAndSorted,
                  currentPage,
                  pageSize,
                  numberOfPages
                )}
                renderItem={(item: GetDropsResponse) => {
                  const undeployedContract =
                    item.contract && warnContractNotDeployed(item.contract);
                  const undeployedPaymentSplitter =
                    item.paymentSplitter &&
                    warnRoyaltiesNotDeployed(item.paymentSplitter);

                  return (
                    <ContractListItem
                      resourceId={item.drop.id}
                      name={item.drop.name}
                      symbol={''}
                      address={
                        item.contract?.address ?? item.drop.externalAddress!
                      }
                      blockchain={
                        item.contract?.blockchain ??
                        item.drop.externalBlockchain!
                      }
                      ownerStatus={item.contract?.ownerStatus}
                      description={item.contract?.description}
                      ownerAddress={item.contract?.ownerAddress}
                      subtitle={`${item.drop.productsIds.length} NFT${
                        item.drop.productsIds.length > 1 ? 's' : ''
                      }`}
                      mode="DROPS"
                      openseaIndexed={
                        item.contract ? item.contract.openseaIndexed : true
                      }
                      typeOfDrop={
                        item.drop.externalAddress
                          ? item.contract?.address
                            ? 'both'
                            : 'preminted'
                          : 'onDemand'
                      }
                      hasIssue={
                        item.drop.hasIssue ||
                        undeployedContract ||
                        undeployedPaymentSplitter
                      }
                    />
                  );
                }}
              />
            </Card>
            <div className={styles.pagination}>
              <LocalPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                numberOfPages={numberOfPages}
              />
            </div>
          </>
        ) : (
          <SimpleEmptyState
            title={t('dropFormPage.emptyState.title')}
            subtitle={t('dropFormPage.emptyState.subtitle')}
            buttonText={t('dropFormPage.emptyState.buttonText')}
            buttonAction={() => history.push('/drops/create')}
          />
        )}
      </Page>
      {importModal && (
        <PremintedNFTImport
          showModal={importModal}
          setShowModal={setImportModal}
        />
      )}
      <Footer />
    </>
  );
};

export function warnContractNotDeployed(contract: Contract) {
  return (
    !contract.address &&
    DateTime.now().diff(DateTime.fromISO(contract.createdAt), 'minutes')
      .minutes > 10
  );
}

export function warnRoyaltiesNotDeployed(royalties: RoyaltyAddress) {
  return (
    !royalties.contractAddress &&
    royalties.type === 'SPLITTER' &&
    DateTime.now().diff(DateTime.fromISO(royalties.createdAt), 'minutes')
      .minutes > 10
  );
}

export default DropPageList;
