import * as React from 'react';

const PolygonscanIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#2C6ECB" />
    <path
      d="M10.7159 6.18431C10.5192 6.06592 10.2633 6.06592 10.0468 6.18431L8.51176 7.09199L7.46872 7.68396L5.93367 8.59164C5.73686 8.71003 5.48103 8.71003 5.26454 8.59164L4.04436 7.88128C3.84757 7.76289 3.70981 7.54583 3.70981 7.30905V5.90806C3.70981 5.67128 3.82789 5.45422 4.04436 5.33583L5.24486 4.64519C5.44165 4.5268 5.6975 4.5268 5.91399 4.64519L7.11447 5.33583C7.31128 5.45422 7.44904 5.67128 7.44904 5.90806V6.81574L8.49209 6.20405V5.29636C8.49209 5.05957 8.37401 4.84252 8.15753 4.72413L5.93367 3.4218C5.73686 3.30341 5.48103 3.30341 5.26454 3.4218L3.00132 4.72413C2.78482 4.84252 2.66675 5.05957 2.66675 5.29636V7.92074C2.66675 8.15754 2.78482 8.37458 3.00132 8.49297L5.26454 9.79531C5.46135 9.9137 5.71718 9.9137 5.93367 9.79531L7.46872 8.90735L8.51176 8.29565L10.0468 7.40771C10.2436 7.28932 10.4995 7.28932 10.7159 7.40771L11.9164 8.09834C12.1132 8.21673 12.251 8.43378 12.251 8.67057V10.0716C12.251 10.3083 12.1329 10.5254 11.9164 10.6438L10.7159 11.3542C10.5192 11.4725 10.2633 11.4725 10.0468 11.3542L8.84633 10.6635C8.64954 10.5451 8.51176 10.3281 8.51176 10.0913V9.18361L7.46872 9.79531V10.703C7.46872 10.9398 7.5868 11.1568 7.80329 11.2752L10.0665 12.5775C10.2633 12.6959 10.5192 12.6959 10.7356 12.5775L12.9988 11.2752C13.1957 11.1568 13.3334 10.9398 13.3334 10.703V8.07861C13.3334 7.84181 13.2153 7.62477 12.9988 7.50638L10.7159 6.18431Z"
      fill="white"
    />
  </svg>
);

export default PolygonscanIcon;
