import { useMutation, useQueryClient } from 'react-query';
import API from '../utils/api';

const useTurnOffProducts = () => {
  const queryCache = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    async (productIds: number[]) => {
      return API().post('/shopify/products/turnOff', {
        productIds,
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('shopMetrics');
        queryCache.invalidateQueries('products');
        queryCache.invalidateQueries('variants');
      },
    }
  );

  return {
    turnOffProducts: mutateAsync,
    isLoading,
  };
};

export default useTurnOffProducts;
