import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Card, DataTable, Stack, Thumbnail } from '@shopify/polaris';
import t from 'lib/translation';
import styles from './VariantsDisplaySection.module.scss';

interface Variant {
  id: string;
  name: string;
  thumbnail: string;
  status: 'READY' | 'NEEDS_REVIEW';
}

interface VariantsDisplaySectionProps {
  variantList: Variant[];
  currentVariant: string;
  parentImage: string;
}

const VariantsDisplaySection: React.FunctionComponent<
  VariantsDisplaySectionProps
> = ({ variantList, currentVariant, parentImage }) => {
  const history = useHistory();

  const createVariantRow = (
    id: string,
    name: string,
    thumbnail: string,
    currentVariantId: string,
    index: number,
    status: 'READY' | 'NEEDS_REVIEW'
  ) => {
    const isCurrentVariant = String(id) === currentVariantId;

    return [
      <div
        key={index}
        tabIndex={index}
        role="button"
        onClick={() => history.push(`/variant-metadata/${id}`)}
        onKeyDown={() => history.push(`/variant-metadata/${id}`)}
        className={`${styles['custom-row']} ${
          isCurrentVariant ? styles['highlighted-row'] : ''
        }`}
      >
        <Stack wrap={false} alignment={'center'}>
          <Stack.Item fill>
            <Stack spacing={'baseTight'} wrap={false} alignment={'center'}>
              <Thumbnail
                source={thumbnail || parentImage || '/images/placeholder.png'}
                size="small"
                alt={`${name} image`}
              />
              <span
                className={`${isCurrentVariant && styles['highlighted-text']} `}
              >
                {name}
              </span>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            {status === 'NEEDS_REVIEW' && (
              <Badge status={'critical'}>{t('productActionRequired')}</Badge>
            )}
          </Stack.Item>
        </Stack>
      </div>,
    ];
  };

  return (
    <Card>
      <Card.Header title={t('productsSection.metaDataForms.variants.title')} />
      <DataTable
        columnContentTypes={['text', 'numeric']}
        headings={[]}
        hoverable={false}
        rows={variantList.map((item, index) =>
          createVariantRow(
            item.id,
            item.name,
            item.thumbnail,
            currentVariant,
            index,
            item.status
          )
        )}
      />
    </Card>
  );
};

export default VariantsDisplaySection;
