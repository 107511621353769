import React from 'react';
import {
  TextStyle,
  ResourceItem,
  Thumbnail,
  Button,
  Stack,
  Badge,
} from '@shopify/polaris';
import { useProduct } from 'hooks/useProduct';
import t from 'lib/translation';
import { useHistory } from 'react-router-dom';
import { PrintMinimal } from '../../hooks/useGetPrints';

const PrintListItem: React.FC<PrintMinimal> = ({
  productId,
  id,
  templateApplied,
  contractVerified,
}) => {
  const history = useHistory();

  const { data: product } = useProduct(productId.toString());

  return (
    <ResourceItem
      verticalAlignment="center"
      id={productId.toString()}
      onClick={() => {}}
    >
      <Stack alignment="center">
        <Thumbnail
          source={product?.thumbnailUrl ?? '/images/placeholder.png'}
          alt={product?.title ?? ''}
        />
        <Stack.Item fill>
          <TextStyle variation="strong">{product?.title}</TextStyle>
        </Stack.Item>
        {!templateApplied || !contractVerified ? (
          <Badge status={'critical'}>{t('productActionRequired')}</Badge>
        ) : (
          <div></div>
        )}
        <Button onClick={() => history.push(`/prints/edit/${id}`)}>
          {t('tokenGatePage.list.edit')}
        </Button>
      </Stack>
    </ResourceItem>
  );
};

export default PrintListItem;
