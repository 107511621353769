import * as React from 'react';
import { TextStyle, Card, Button, Heading } from '@shopify/polaris';
import styles from './EmptyStatePage.module.scss';

export interface EmptyStatePageProps {
  linkAction?: () => void;
  heading: string;
  body?: string;
  linkText?: string;
}

const EmptyStatePage: React.FC<EmptyStatePageProps> = ({
  linkAction,
  heading,
  body,
  linkText,
}) => {
  return (
    <Card>
      <div className={`${styles.container}`}>
        <Heading>{heading}</Heading>
        {body && <TextStyle variation="subdued">{body}</TextStyle>}
        {linkText && linkAction && (
          <Button
            primary
            onClick={() => {
              linkAction();
            }}
          >
            {linkText}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default EmptyStatePage;
