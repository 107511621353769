import { DisplayText, Modal, Page, Stack } from '@shopify/polaris';
import React, { ReactNodeArray, useCallback, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import GetStarted from 'components/GetStarted';
import { fetchCoreToken } from 'hooks/useGetCoreToken';
import { getVerisartDotComHost, generateAppRedirectUrl } from 'lib/urls';
import useChangePlan from '../../hooks/useChangePlan';
import useSetupShop from '../../hooks/useSetupShop';
import useSession, {
  isAccountCreated,
  isAccountSessionLoaded,
} from '../../hooks/useSession';
import { useIntercom } from 'react-use-intercom';
import { IntercomEvents } from '../../interfaces/IntercomEvents';
import {
  OnboardingModal,
  OnboardingModalFormValues,
  OnboardingStep,
} from './OnboardingModal';
import { FormProvider, useForm } from 'react-hook-form';
import { SELF_CREATOR_DETAILS_PATH } from '../../AppRoutes';
import {
  AppBridgeContextProps,
  getShopDomain,
  useAppBridge,
} from 'containers/AppBridge';

export interface RouterWindow extends Window {
  Intercom(update: string, params: { hide_default_launcher: boolean }): void;
}

declare let window: RouterWindow;

export const openAccountLink = async (
  appBridge: AppBridgeContextProps,
  shopDomain: string
) => {
  const shopifySessionToken = await appBridge.getSessionToken();
  const decoded = jwt_decode(shopifySessionToken) as { aud: string };
  const { token } = await fetchCoreToken({
    shopDomain,
    sessionToken: shopifySessionToken,
  });
  appBridge.redirect(
    'Remote',
    `${getVerisartDotComHost()}/application/link_account?next=${generateAppRedirectUrl(
      shopDomain,
      decoded.aud,
      SELF_CREATOR_DETAILS_PATH
    )}&shopDomain=${shopDomain}&shopifySessionToken=${token}&loginOnly=true`
  );
};

type Distribution =
  | 'equalSpacing'
  | 'leading'
  | 'trailing'
  | 'center'
  | 'fill'
  | 'fillEvenly';

export const OnboardingModalContent: React.FC<{
  title: string;
  actions: ReactNodeArray;
  invertActions?: boolean;
}> = ({ actions, children, invertActions, title }) => {
  let actionsDistribution: Distribution;
  if (React.Children.count(actions.filter((action) => !!action)) < 2) {
    actionsDistribution = invertActions ? 'leading' : 'trailing';
  } else {
    actionsDistribution = 'equalSpacing';
  }
  return (
    <>
      <Modal.Section>
        <DisplayText size="small">{title}</DisplayText>
      </Modal.Section>
      <Modal.Section>{children}</Modal.Section>
      {React.Children.count(actions) > 0 ? (
        <Modal.Section>
          <Stack alignment="center" distribution={actionsDistribution}>
            {actions}
          </Stack>
        </Modal.Section>
      ) : null}
    </>
  );
};

interface CompleteOnboardingProps {
  creatorFirstName: string;
  creatorLastName: string;
  creatorYear: string;
  shopDomain: string;
  shopEmailAddress: string | null;
  sessionToken: string;
  plan: string;
  redirectPath: string;
}

const useCompleteOnboarding = () => {
  const [loading, setLoading] = useState(false);
  const refreshSessionToken = useSession((state) => state.refreshSessionToken);
  const accountCreated = useSession(isAccountCreated);
  const { mutate: setupShop } = useSetupShop();
  const { mutate: changePlan } = useChangePlan();
  const { update, trackEvent } = useIntercom();
  const appBridge = useAppBridge();

  const completeOnboarding = ({
    creatorFirstName,
    creatorLastName,
    creatorYear,
    shopDomain,
    shopEmailAddress,
    sessionToken,
    plan,
    redirectPath,
  }: CompleteOnboardingProps) => {
    setLoading(true);
    if (!accountCreated) {
      setupShop(
        {
          shopDomain,
          sessionToken,
          selfCreatorDetails: {
            name: creatorFirstName,
            lastName: creatorLastName === '' ? null : creatorLastName,
            associatedYear: creatorYear,
            overrideAdminEmail: shopEmailAddress,
          },
          then: (data) => update({ userId: data.accountId }),
        },
        {
          async onSuccess() {
            await refreshSessionToken(appBridge.getSessionToken);
            trackEvent(IntercomEvents.onboardingShopifyComplete);
            return changePlan({ planType: plan, redirectPath });
          },
          onError() {
            setLoading(false);
          },
        }
      );
    } else {
      changePlan({ planType: plan, redirectPath });
    }
  };

  return { completeOnboarding, completingOnboarding: loading };
};

const OnboardingPage: React.FC = () => {
  const appBridge = useAppBridge();
  const { completingOnboarding, completeOnboarding } = useCompleteOnboarding();
  const accountSessionLoaded = useSession(isAccountSessionLoaded);
  const accountCreated = useSession(isAccountCreated);
  const shopDomain = getShopDomain();
  const [steps, setSteps] = useState<OnboardingStep[]>(
    accountCreated ? ['selectplantype'] : []
  );
  const methods = useForm<OnboardingModalFormValues>({
    defaultValues: {
      creatorFirstName: '',
      creatorLastName: '',
      creatorYear: '',
      planType: null,
      userType: null,
      shopEmailAddress: null,
      creatorType: 'individual',
    },
  });
  const userType = methods.watch('userType');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // do not do this if we are not on a mobile device
    if (window.innerWidth > 768) return;

    window.Intercom &&
      window.Intercom('update', {
        hide_default_launcher: true,
      });
  }, []);

  useEffect(() => {
    if (accountSessionLoaded && accountCreated) {
      methods.setValue('userType', 'existing');
    }
  }, [accountSessionLoaded, accountCreated, methods]);

  const pushStep = useCallback(
    (next: OnboardingStep) => {
      setSteps([next, ...steps]);
    },
    [steps]
  );
  const stepBack = useCallback(() => {
    setSteps(steps.slice(1));
  }, [steps]);

  const cancelOnboarding = useCallback(() => {
    methods.setValue(
      'userType',
      accountSessionLoaded && accountCreated ? 'existing' : null
    );
    methods.setValue('planType', null);
    methods.setValue('creatorFirstName', '');
    methods.setValue('creatorLastName', '');
    methods.setValue('creatorYear', '');
    setSteps([]);
  }, [accountSessionLoaded, accountCreated, methods]);

  return (
    <Page fullWidth>
      <GetStarted
        loading={!accountSessionLoaded}
        onStartClicked={() =>
          pushStep(userType === 'existing' ? 'selectplantype' : 'neworexisting')
        }
      />
      <FormProvider {...methods}>
        <OnboardingModal
          mode={'onboardingShopify'}
          stepBack={stepBack}
          onClose={cancelOnboarding}
          completeOnboarding={async (data: OnboardingModalFormValues) => {
            const sessionToken = await appBridge.getSessionToken();
            completeOnboarding({
              creatorFirstName: data.creatorFirstName,
              creatorLastName: data.creatorLastName,
              creatorYear: data.creatorYear,
              shopDomain: shopDomain,
              shopEmailAddress: data.shopEmailAddress,
              sessionToken,
              plan: data.planType!,
              redirectPath: '',
            });
          }}
          completeOnboardingLoading={completingOnboarding}
          pushStep={pushStep}
          steps={steps}
        />
      </FormProvider>
    </Page>
  );
};

export default OnboardingPage;
