import API from 'utils/api';
import { useQuery } from 'react-query';

type UsageChargeResponse = {
  usageCharge: UsageCharge;
};

export type UsageCharge = {
  balanceUsed: string;
  cappedAmount: string;
  inWarning: boolean;
  resetDate: string;
};

export default function useGetUsageCharges(enabled: boolean) {
  return useQuery<UsageChargeResponse>(
    ['usageCharges'],
    async () => {
      const response = await API().get(`shop/me/usageCharges`);

      return response.data;
    },
    {
      enabled,
    }
  );
}
