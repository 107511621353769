import React from 'react';
import { Modal, TextContainer, Banner, List, Spinner } from '@shopify/polaris';
import t, { parameters as p } from 'lib/translation';

export type DeployContractModalProps = {
  hideModal: () => void;
  open: boolean;
  primaryAction: () => void;
  gasCost: string;
  loading: boolean;
};

const DeployContractModal: React.FC<DeployContractModalProps> = ({
  hideModal,
  open,
  primaryAction,
  gasCost,
  loading = false,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        title={t('createContractForm.modal.heading')}
        primaryAction={{
          content: 'Continue',
          onAction: primaryAction,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: hideModal,
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner accessibilityLabel="Loading" size="large" />
              </div>
            ) : (
              <Banner
                title={t('createContractForm.modal.warningTitle')}
                status={'warning'}
              >
                <List>
                  <List.Item>
                    {t('createContractForm.modal.warningBulletTwo')}
                  </List.Item>
                  <List.Item>
                    {p('createContractForm.modal.warningBulletOne', gasCost)}
                  </List.Item>
                  <List.Item>
                    {t('createContractForm.modal.warningBulletThree')}
                  </List.Item>
                </List>
              </Banner>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default DeployContractModal;
