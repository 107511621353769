import * as React from 'react';

const ArrowUpwards = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM10.5858 16.5858L18.5858 8.58579C19.3668 7.80474 20.6332 7.80474 21.4142 8.58579L29.4142 16.5858C30.1953 17.3668 30.1953 18.6332 29.4142 19.4142C28.6332 20.1953 27.3668 20.1953 26.5858 19.4142L22 14.8284V30C22 31.1046 21.1046 32 20 32C18.8954 32 18 31.1046 18 30V14.8284L13.4142 19.4142C12.6332 20.1953 11.3668 20.1953 10.5858 19.4142C9.80474 18.6332 9.80474 17.3668 10.5858 16.5858Z"
      fill="#5C5F62"
    />
  </svg>
);

export default ArrowUpwards;
