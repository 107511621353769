import {
  Banner,
  Button,
  Heading,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import React from 'react';
import t from 'lib/translation';

interface FromEmailFieldProps {
  disabled?: boolean;
  email: string;
  errorMessage?: string;
  isLoading?: boolean;
  verificationRequired: boolean;
  verified: boolean;
  onEmailChange: (email: string) => void;
  onReset: () => void;
}

type FromEmailStatus = 'active' | 'pending' | 'inactive';

interface StatusBannerProps {
  emailAddress: string;
  status: FromEmailStatus;
  onReset: () => void;
}

const StatusBanner: React.FC<StatusBannerProps> = ({
  emailAddress,
  status,
  onReset,
}) => {
  switch (status) {
    case 'active':
      return (
        <Banner status="info">
          <p>
            {t('settingsSection.customiseEmailForm.sections.info.ptOne')}{' '}
            <TextStyle variation="strong">{emailAddress}</TextStyle>
            {t('settingsSection.customiseEmailForm.sections.info.ptTwo')}{' '}
            <Button plain monochrome onClick={() => onReset()}>
              {t('settingsSection.customiseEmailForm.sections.info.button')}
            </Button>
            .
          </p>
        </Banner>
      );
    case 'pending':
      return (
        <Banner status="warning">
          <p>
            {t('settingsSection.customiseEmailForm.sections.warning.ptOne')}
          </p>
        </Banner>
      );
    default:
      return null;
  }
};

export default function FromEmailField({
  disabled = false,
  isLoading = false,
  email,
  errorMessage,
  onEmailChange,
  onReset,
  verificationRequired,
  verified,
}: FromEmailFieldProps) {
  let status: FromEmailStatus;
  if (verificationRequired && !verified) {
    status = 'pending';
  } else if (verificationRequired && verified) {
    status = 'active';
  } else {
    status = 'inactive';
  }

  return isLoading ? (
    <>
      <Heading>{t('settingsSection.customiseEmailForm.form.titleOne')}</Heading>
      <Spinner accessibilityLabel="Checking email verification status" />
    </>
  ) : (
    <Stack vertical spacing="tight">
      <TextField
        id="fromEmail"
        value={email}
        label={t('settingsSection.customiseEmailForm.form.titleOne')}
        onChange={onEmailChange}
        autoComplete="off"
        disabled={disabled}
        error={errorMessage}
      />
      {!errorMessage && (
        <StatusBanner emailAddress={email} status={status} onReset={onReset} />
      )}
    </Stack>
  );
}
