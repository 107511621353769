import React from 'react';
import { Banner } from '@shopify/polaris';
import useShop from '../../hooks/useShop';
import t, { parameters as p } from 'lib/translation';

export const LinkedSuccessBanner: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { data } = useShop();

  return isOpen && data?.shop?.verisartEmail ? (
    <Banner
      status={'success'}
      onDismiss={() => setIsOpen(false)}
      title={t('banners.linkedAccount.title')}
    >
      <p>{p('banners.linkedAccount.description', data.shop.verisartEmail)}</p>
    </Banner>
  ) : null;
};
