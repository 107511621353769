import React, { ReactNode, useState } from 'react';
import { DropZone, Stack, TextStyle, Icon } from '@shopify/polaris';
import CardElement from '../CardElement';
import t from 'lib/translation';
import styles from '../ProductForm.module.scss';
import { NoteMajor } from '@shopify/polaris-icons';
import { FileLoader } from './FileLoader';
import CollectorListItem from './CollectorlistItem';
import {
  collectorRewardExtensions,
  fileMatchesExtension,
} from 'utils/validation';
import { CollectorRewardFile } from '../../../hooks/useCreateDrop';
import mixpanel from 'mixpanel-browser';
import { isAxiosError } from '../../SettingsPage/AdvancedEmailPage';
import { getPlatform } from 'containers/AppBridge';

interface CollectorRewardsProps {
  id: string;
  type: 'PRODUCT' | 'DROP';
  setAsset: (rewards: Array<CollectorRewardFile>) => void;
  assets: Array<CollectorRewardFile>;
  subtitle?: boolean;
  customDelete?: (productId: string, index: number) => void;
  readOnlyRewards?: Array<CollectorRewardFile>;
  fileUploadSuccessMessage: string;
  productType?: string;
  uploadFile: (file: File) => Promise<void>;
}

const CollectorRewards: React.FC<CollectorRewardsProps> = ({
  id,
  type,
  setAsset,
  assets,
  subtitle,
  customDelete,
  readOnlyRewards,
  fileUploadSuccessMessage,
  productType,
  uploadFile,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [error, setError] = useState<ReactNode | null>(null);
  const handleDropZoneDrop = async (
    _dropFiles: File[],
    acceptedFiles: File[],
    _rejectedFiles: File[]
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      if (
        !fileMatchesExtension(_dropFiles[0], collectorRewardExtensions) ||
        _rejectedFiles.length
      ) {
        setError(
          `File is not of type ${collectorRewardExtensions.join(', ')}.`
        );
      } else {
        setUploadedFiles([acceptedFiles[0]]);
        await uploadFile(acceptedFiles[0]);
        if (type === 'DROP') {
          mixpanel.track('Product Additional Added', {
            Platform: getPlatform(),
            Type: 'NFT',
          });
        } else {
          mixpanel.track('Product Additional Added', {
            Platform: getPlatform(),
            Type: 'Certificate',
          });
        }
      }
    } catch (e: unknown) {
      if (
        isAxiosError(e) &&
        e?.response?.data?.message?.includes('media.too.large')
      ) {
        setError(t('productsSection.errors.tooLarge'));
      } else {
        setError(t('productsSection.errors.general'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loading = isLoading && <FileLoader files={uploadedFiles} />;
  return (
    <CardElement
      title={
        type === 'DROP' || productType === 'NFT'
          ? t('productForm.collectorRewards.dropTitle')
          : t('productForm.collectorRewards.productTitle')
      }
    >
      <Stack vertical>
        {subtitle && (
          <div>
            <div className={styles.subtitleWrap}>
              <Stack.Item>
                {productType === 'NFT'
                  ? t('productForm.collectorRewards.nftSubtitle')
                  : t('productForm.collectorRewards.coaSubtitle')}
              </Stack.Item>
            </div>
          </div>
        )}
        <>
          <Stack vertical spacing="loose">
            <Stack.Item fill>
              <div className={styles.dropzone}>
                <DropZone
                  onDrop={handleDropZoneDrop}
                  allowMultiple={false}
                  type={'image'}
                >
                  {loading}
                  {!isLoading && (
                    <div>
                      <div className={styles.textWrap}>
                        <>{t('productForm.uploadButtomText')}</>
                      </div>
                      <div className={styles.upload}>
                        {t('productForm.dropzoneCollectorRewardText')}
                      </div>
                    </div>
                  )}
                </DropZone>
                {error && <TextStyle variation="negative">{error}</TextStyle>}
              </div>
            </Stack.Item>

            {assets.map((el, i) => {
              return (
                <CollectorListItem
                  key={`${i}-${el.fileLabel}`}
                  productId={id}
                  asset={el}
                  assets={assets}
                  setAsset={setAsset}
                  index={i}
                  customDelete={customDelete}
                />
              );
            })}
            {readOnlyRewards?.length && (
              <>
                <Stack vertical>
                  <div className={styles.uppercase}>
                    <TextStyle variation="strong">
                      {t('productForm.collectorRewards.dropRewards')}
                    </TextStyle>
                  </div>
                  <Stack vertical spacing="tight">
                    {readOnlyRewards?.map((item: CollectorRewardFile) => {
                      return (
                        <Stack key={item.fileURL}>
                          <Stack.Item>
                            <Icon source={NoteMajor} color="base" />
                          </Stack.Item>
                          <Stack.Item>{item.fileLabel}</Stack.Item>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </>
      </Stack>
    </CardElement>
  );
};

export default CollectorRewards;
