import { useQuery } from 'react-query';
import { Blockchain } from 'types/Blockchain';
import API from 'utils/api';

export type PrintAuthenticationStatus = {
  contractAddress: string;
  registered: boolean;
  blockchain: Blockchain;
  artBlocksProjectId: string | null;
};

export default function useGetAuthenticatedShopPrint(
  contractAddress: string,
  blockchain: Blockchain | undefined,
  shopId: string,
  artBlocksProjectId: string | null
) {
  return useQuery<PrintAuthenticationStatus>(
    [
      'printRegisterStatus',
      contractAddress,
      blockchain,
      shopId,
      artBlocksProjectId,
    ],
    async () => {
      if (blockchain && contractAddress && shopId) {
        let url = `/shop/print/registerStatus/${contractAddress}/${blockchain}/${shopId}?includeName=true`;

        if (artBlocksProjectId !== null) {
          url += `&artBlocksProjectId=${artBlocksProjectId}`;
        }
        const response = await API().get(url);
        return response.data;
      }
    }
  );
}
