import React, { useCallback, useRef, useState } from 'react';
import { ContextualSaveBar as PolarisContextualSaveBar } from '@shopify/polaris';

export type ButtonState = 'loading' | 'disabled' | 'default';

export interface ContextualBarActions {
  save: ButtonState;
  discard: ButtonState;
}

export type ContextualSaveBarAction = 'save' | 'discard' | 'hide' | 'show';

export type Subscription = {
  action: ContextualSaveBarAction;
  callback: () => void;
};

interface ContextualSaveBarContextProps {
  trigger: (action: ContextualSaveBarAction) => void;
  subscribe: (
    action: ContextualSaveBarAction,
    callback: () => void
  ) => () => void;
  set: (state: ContextualBarActions) => void;
}

export const ContextualSaveBarContext =
  React.createContext<ContextualSaveBarContextProps>({
    trigger: () => {},
    subscribe: () => {
      return () => {};
    },
    set: () => {},
  });

export const useContextualSaveBar = () => {
  const contextualSaveBarContext = React.useContext(ContextualSaveBarContext);
  if (!contextualSaveBarContext) {
    throw new Error(
      'useContextualSaveBar must be used within a ContextualSaveBarContextProvider'
    );
  }
  return contextualSaveBarContext;
};

interface ContextualSaveBarProps {
  children: React.ReactNode;
}

export const ContextualSaveBar: React.FC<ContextualSaveBarProps> = ({
  children,
}) => {
  const [isContextualBarShown, setContextualBarVisibility] =
    useState<boolean>(false);
  const [actionsState, setActionsState] = useState<ContextualBarActions>({
    save: 'default',
    discard: 'default',
  });
  const subscriptionsRef = useRef<Subscription[]>([]);

  const trigger = useCallback((action: ContextualSaveBarAction) => {
    subscriptionsRef.current
      .filter((sub) => sub.action === action)
      .forEach((sub) => sub.callback());

    switch (action) {
      case 'show':
        setContextualBarVisibility(true);
        break;
      case 'hide':
        setContextualBarVisibility(false);
        break;
      default:
        break;
    }
  }, []);
  const subscribe = useCallback(
    (action: ContextualSaveBarAction, callback: () => void) => {
      const newSubscription = { action, callback };
      subscriptionsRef.current.push(newSubscription);

      // Return the unsubscribe function
      return () => {
        subscriptionsRef.current = subscriptionsRef.current.filter(
          (sub) => sub !== newSubscription
        );
      };
    },
    []
  );

  return (
    <ContextualSaveBarContext.Provider
      value={{
        trigger,
        subscribe,
        set: setActionsState,
      }}
    >
      {isContextualBarShown && (
        <PolarisContextualSaveBar
          saveAction={{
            loading: actionsState.save === 'loading',
            disabled: actionsState.save === 'disabled',
            onAction: () => {
              trigger('save');
            },
          }}
          discardAction={{
            loading: actionsState.discard === 'loading',
            disabled: actionsState.discard === 'disabled',
            onAction: () => {
              trigger('discard');
            },
          }}
        ></PolarisContextualSaveBar>
      )}
      {children}
    </ContextualSaveBarContext.Provider>
  );
};
