import Footer from 'components/Footer';
import React, { useState } from 'react';
import { LoadingState } from 'pages/LoadingState/LoadingState';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import ContractsFormPage from '../../components/ContractsForm/ContractsFormPage';

const CreateContractsFormPage = () => {
  const [formType, setFormType] = useState<'Create' | 'Edit'>('Create');
  const { isLoading: isMintingCapabilityLoading, mintingAvailable } =
    useMainnetMintingEnabled();

  return (
    <>
      {isMintingCapabilityLoading ? (
        <LoadingState />
      ) : (
        <ContractsFormPage
          formType={formType}
          onSuccess={() => setFormType('Edit')}
          onTestMode={!mintingAvailable}
        />
      )}
      <Footer />
    </>
  );
};

export default CreateContractsFormPage;
