import { useQuery } from 'react-query';
import API from 'utils/api';

export type FunctionInstalledResponse = {
  status: Boolean;
};

export default function useGetFunctionApplied() {
  return useQuery<FunctionInstalledResponse>(
    ['tokenGateFunctionInstalled'],
    async () => {
      const response = await API().get(`shop/tokenGate/functionInstalled`);
      return response.data;
    }
  );
}
