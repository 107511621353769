import {
  Banner,
  Card,
  Link,
  Modal,
  SkeletonPage,
  Stack,
  Tag,
  TextField,
  TextStyle,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Blockchain } from 'types/Blockchain';
import API from 'utils/api';
import { useQuery } from 'react-query';
import AssetRenderer from 'pages/ProductForm/AssetRenderer';
import { AxiosError } from 'axios';
import t from 'lib/translation';
import useImportNFT from 'hooks/useImportNFT';
import useToast from 'hooks/useToast';
import { useHistory } from 'react-router-dom';
import BlockchainAddress from 'components/BlockchainAddress';
import { truncateString } from 'utils/stringFormatting';
import { openSeaAssetsBaseUrl } from 'utils/blockchain';
import styles from './DropPage.module.scss';
import { copyToClipboard } from 'utils/copyTextToClipboard';
import { CopyIcon } from 'components/JsxIcons';

type UseGetNFTDataResponse = {
  blockchain: Blockchain;
  contractType?: string;
  contractName?: string;
  productionYear?: string;
  assetType?: string;
  title?: string;
  description?: string;
  attributes?: Array<NFTAttributes>;
  tags?: Array<string>;
  thumbnailImage: string;
  contractSymbol?: string;
  animationURL?: string;
};

type NFTAttributes = {
  trait_type: string;
  value: string;
};

const api = API();

const useGetNFTData = (
  blockchain: Blockchain,
  address: string,
  tokenId: string
) =>
  useQuery<UseGetNFTDataResponse, AxiosError<{ message: string }>>(
    ['import_NFT_data', blockchain, address, tokenId],
    async (): Promise<UseGetNFTDataResponse> => {
      const res = await api.get(
        `/shop/drop/getNFTData/${blockchain}/${address}/${tokenId}`
      );
      return res.data;
    },
    { retry: false }
  );

type ParsedNFT = {
  blockchain: Blockchain;
  contractAddress: string;
  tokenId: string;
};

function parseNFTURL(url: string): ParsedNFT {
  const parsed = new URL(url);

  if (parsed.host === 'opensea.io' || parsed.host === 'testnets.opensea.io') {
    const path = parsed.pathname;

    const regex = /\/assets\/([^/]+)\/([^/]+)\/([^/]+)/;
    const match = path.match(regex);
    if (match) {
      const tokenId = match[3];
      const address = match[2];
      var blockchain: Blockchain;
      if (match[1] === 'ethereum') {
        blockchain = Blockchain.ETHEREUM;
      } else if (match[1] === 'sepolia') {
        blockchain = Blockchain.ETHEREUM_SEPOLIA;
      } else if (match[1] === 'matic') {
        blockchain = Blockchain.POLYGON;
      } else {
        throw new Error('Blockchain not supported');
      }
      return {
        blockchain: blockchain,
        contractAddress: address,
        tokenId: tokenId,
      };
    }
  }
  throw new Error('Not Opensea URL');
}

const PreviewComponent: React.FC<{
  blockchain: Blockchain;
  address: string;
  tokenId: string;
  setLoading: (loading: boolean) => void;
}> = ({ blockchain, address, tokenId, setLoading }) => {
  const { data, error, isLoading } = useGetNFTData(
    blockchain,
    address,
    tokenId
  );

  const showToast = useToast((state) => state.showToast);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  if (isLoading) {
    return (
      <Stack vertical alignment="center">
        <Stack.Item fill>
          <SkeletonPage />
        </Stack.Item>
      </Stack>
    );
  }

  let errorMessage: string | null = null;

  if (error) {
    const errorCode = error.response?.data?.message;
    if (errorCode?.includes('nft.no.metadata')) {
      errorMessage = t('premintedNFTs.errors.noMetadata');
    } else if (errorCode?.includes('nft.no.image')) {
      errorMessage = t('premintedNFTs.errors.noImage');
    } else if (errorCode?.includes('not found')) {
      errorMessage = t('premintedNFTs.errors.nftNotFound');
    } else if (errorCode) {
      errorMessage = `${t('premintedNFTs.errors.errorCode')} ${errorCode}`;
    } else {
      errorMessage = t('premintedNFTs.errors.otherError');
    }
  }
  //also check for not indexed yet error message

  if (!errorMessage) {
    if (data?.contractType !== 'ERC721') {
      errorMessage = t('premintedNFTs.errors.contractNotSupported');
    }
  }

  if (errorMessage) {
    return (
      <>
        <Card.Section>
          <Banner
            title={t('premintedNFTs.errors.bannerTitle')}
            status="critical"
          >
            <p>{t('premintedNFTs.errors.bannerSubtitle')}</p>
            <p>{errorMessage}</p>
          </Banner>
        </Card.Section>
      </>
    );
  }

  return (
    <Card>
      {data && (
        <>
          {data.title && (
            <Card.Section>
              <Stack spacing="extraLoose" distribution="fillEvenly">
                <TextStyle variation="strong">
                  {t('premintedNFTs.metadata.title')}
                </TextStyle>
                <TextStyle>{data.title}</TextStyle>
              </Stack>
            </Card.Section>
          )}
          {data.productionYear && (
            <Card.Section>
              <Stack spacing="extraLoose" distribution="fillEvenly">
                <TextStyle variation="strong">
                  {' '}
                  {t('premintedNFTs.metadata.productionYear')}
                </TextStyle>
                <TextStyle>{data.productionYear}</TextStyle>
              </Stack>
            </Card.Section>
          )}
          <Card.Section>
            <Stack spacing="extraLoose" distribution="fillEvenly">
              <TextStyle variation="strong">
                {t('premintedNFTs.metadata.chain')}
              </TextStyle>
              <TextStyle>{t(`blockchain.${data.blockchain}`)}</TextStyle>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack spacing="extraLoose" distribution="fillEvenly">
              <TextStyle variation="strong">
                {t('premintedNFTs.metadata.contractType')}
              </TextStyle>
              <TextStyle>ERC-721</TextStyle>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack spacing="extraLoose" distribution="fillEvenly">
              <TextStyle variation="strong">
                {t('premintedNFTs.metadata.contractAddress')}
              </TextStyle>
              <BlockchainAddress blockchain={blockchain} address={address} />
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack spacing="extraLoose" distribution="fillEvenly">
              <TextStyle variation="strong">
                {t('premintedNFTs.metadata.tokenId')}
              </TextStyle>
              <Stack spacing="none">
                <Link
                  removeUnderline
                  url={`${openSeaAssetsBaseUrl(
                    blockchain
                  )}/${address}/${tokenId}`}
                >
                  <TextStyle>{truncateString(tokenId, 6, 4)}</TextStyle>
                </Link>
                <div
                  className={`${styles['copy-icon']}`}
                  onClick={async (e) => {
                    e.currentTarget.focus();
                    const copied = await copyToClipboard(tokenId);
                    e.stopPropagation();
                    if (copied) showToast('Copied to clipboard', false);
                  }}
                >
                  <CopyIcon />
                </div>
              </Stack>
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack spacing="extraLoose" distribution="fillEvenly">
              <TextStyle variation="strong">
                {t('premintedNFTs.metadata.contractName')}
              </TextStyle>
              <TextStyle>{data.contractName}</TextStyle>
            </Stack>
          </Card.Section>
          {data.contractSymbol && (
            <Card.Section>
              <Stack spacing="extraLoose" distribution="fillEvenly">
                <TextStyle variation="strong">
                  {t('premintedNFTs.metadata.symbol')}
                </TextStyle>
                <TextStyle>{data?.contractSymbol}</TextStyle>
              </Stack>
            </Card.Section>
          )}
          {data.assetType && (
            <Card.Section>
              <Stack spacing="extraLoose" distribution="fillEvenly">
                <TextStyle variation="strong">
                  {t('premintedNFTs.metadata.assetType')}
                </TextStyle>
                <TextStyle>{data.assetType}</TextStyle>
              </Stack>
            </Card.Section>
          )}

          {data.animationURL && data.animationURL !== data.thumbnailImage && (
            <Card.Section>
              <Stack vertical>
                <TextStyle variation="strong">
                  {t('premintedNFTs.metadata.asset')}
                </TextStyle>
                <AssetRenderer asset={data.animationURL} forceVideo />
              </Stack>
            </Card.Section>
          )}
          {data.thumbnailImage && (
            <Card.Section>
              <Stack vertical>
                <TextStyle variation="strong">
                  {!data.animationURL ||
                  data.animationURL === data.thumbnailImage
                    ? t('premintedNFTs.metadata.asset')
                    : t('premintedNFTs.metadata.displayImage')}
                </TextStyle>
                <AssetRenderer asset={data.thumbnailImage} />
              </Stack>
            </Card.Section>
          )}

          {data.description && (
            <Card.Section>
              <Stack spacing="tight" distribution="fillEvenly">
                <TextStyle variation="strong">
                  {t('premintedNFTs.metadata.description')}
                </TextStyle>
                <TextStyle>{data.description}</TextStyle>
              </Stack>
            </Card.Section>
          )}

          {(data.tags || (data.attributes && data.attributes?.length > 0)) && (
            <Card.Section title={t('premintedNFTs.metadata.attributes')}>
              <Stack spacing="tight">
                {data.attributes?.map((item: NFTAttributes) => {
                  return (
                    <Tag key={item.trait_type}>
                      {item.trait_type} : {item.value}
                    </Tag>
                  );
                })}
                {data.tags?.map((item: string, index: number) => {
                  return (
                    <Tag key={t('premintedNFTs.metadata.tags')}>
                      {t('premintedNFTs.metadata.tags')} : {item}
                    </Tag>
                  );
                })}
              </Stack>
            </Card.Section>
          )}
        </>
      )}
    </Card>
  );
};

type PremintedNFTImportType = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const PremintedNFTImport: React.FC<PremintedNFTImportType> = ({
  showModal,
  setShowModal,
}) => {
  const { mutateAsync: importNFT } = useImportNFT();
  const [previewNFT, setPreviewNFT] = useState<ParsedNFT | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [importError, setImportError] = useState<any | null>(null);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isGetting, setIsGetting] = useState<boolean>(false);
  const showToast = useToast((state) => state.showToast);
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = handleSubmit(async (data) => {
    const parsedURL = parseNFTURL(data.url);
    setPreviewNFT(parsedURL);
  });

  const navigateToProductForm = useCallback(
    (productId, success, isExisting) => {
      history.push(
        `/product-metadata/${productId}/nft?success=${success}&isExisting=${isExisting}`
      );
    },
    [history]
  );

  const onImport = handleSubmit(async (data) => {
    try {
      setIsImporting(true);
      const parsedURL = parseNFTURL(data.url);
      const request = {
        blockchain: parsedURL.blockchain,
        contractAddress: parsedURL.contractAddress,
        tokenId: parsedURL.tokenId,
      };
      const response = await importNFT(request);
      setShowModal(false);
      if (response.isExistingDrop) {
        navigateToProductForm(response.productId, true, true);
      } else {
        navigateToProductForm(response.productId, true, false);
      }
    } catch (e) {
      if (e?.response?.data?.message.includes('import.duplicate')) {
        setImportError(t('premintedNFTs.errors.duplicateError'));
      } else {
        setImportError(t('premintedNFTs.errors.otherError'));
      }
      showToast(t('premintedNFTs.toasts.fail'));
    } finally {
      setIsImporting(false);
    }
  });

  return (
    <Modal
      open={showModal}
      title={
        !previewNFT
          ? t('premintedNFTs.modal.importTitle')
          : t('premintedNFTs.modal.previewTitle')
      }
      primaryAction={{
        content: !previewNFT
          ? t('premintedNFTs.modal.buttons.continue')
          : t('premintedNFTs.modal.buttons.import'),
        onAction: !previewNFT ? onSubmit : onImport,

        loading: isImporting || isGetting,
        disabled: importError,
      }}
      secondaryActions={[
        {
          content: t('premintedNFTs.modal.buttons.cancel'),
          onAction: () => setShowModal(false),
          disabled: isImporting || isGetting,
        },
      ]}
      onClose={() => setShowModal(false)}
    >
      {importError && (
        <Card.Section>
          <Banner
            title={t('premintedNFTs.errors.bannerTitleImport')}
            status="critical"
          >
            <p>Here’s some more detail about what went wrong:</p>
            <p>{importError}</p>
          </Banner>
        </Card.Section>
      )}
      {!previewNFT && !importError && (
        <Card title={t('premintedNFTs.modal.cardTitle')}>
          <Card.Section>
            <Controller
              control={control}
              name="url"
              rules={{
                required: 'URL is required',
                validate: (value) => {
                  try {
                    parseNFTURL(value);
                    return true;
                  } catch {
                    return t('premintedNFTs.modal.urlError');
                  }
                },
              }}
              render={({
                field: { name, onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  name={name}
                  disabled={isSubmitting}
                  value={value}
                  label="Link to NFT"
                  labelHidden
                  type="url"
                  autoComplete="off"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  helpText={t('premintedNFTs.modal.urlHelpText')}
                />
              )}
            />
          </Card.Section>
        </Card>
      )}
      {previewNFT && !importError && (
        <PreviewComponent
          blockchain={previewNFT?.blockchain}
          address={previewNFT?.contractAddress}
          tokenId={previewNFT?.tokenId}
          setLoading={setIsGetting}
        />
      )}
    </Modal>
  );
};

export default PremintedNFTImport;
