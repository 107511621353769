import { useQuery } from 'react-query';
import API from 'utils/api';

export interface ProductResource {
  id: number;
  title: string;
  thumbnailUrl: string | null;
  hasIssue: boolean;
  variantHasIssue: boolean;
  dropId: string | null;
  isSoldNft: boolean;
  printId: string | null;
}

interface ProductResponse {
  products: ProductResource[];
}

export const useProducts = (ids: string[], fetch?: boolean) => {
  const {
    data: dataResult,
    isLoading,
    isError,
    error,
  } = useQuery<ProductResponse>(
    [`products`, ids],
    async () => {
      const res = await API().get(`/shopify/products?ids=${ids.join(',')}`);

      if (res.status === 404) {
        throw new Error('Product not found');
      }

      return res.data;
    },
    {
      enabled: fetch,
    }
  );

  return {
    data: dataResult,
    isLoading,
    isError,
    error,
  };
};
