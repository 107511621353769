import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  DataTable,
  Thumbnail,
  TextStyle,
  Button,
  Stack,
  Badge,
} from '@shopify/polaris';
import t from 'lib/translation';
import styles from './VariantsLinkSection.module.scss';
import { useParams } from 'react-router';

interface Variant {
  id: string;
  name: string;
  thumbnail: string;
  status: 'READY' | 'NEEDS_REVIEW';
}

interface VariantsLinkSectionProps {
  variantList: Variant[];
}

const VariantsLinkSection: React.FunctionComponent<
  VariantsLinkSectionProps
> = ({ variantList }) => {
  const history = useHistory();
  const { defaultType } =
    useParams<{ defaultType: 'nft' | 'certificate' | undefined }>();
  const createVariantRow = (
    id: string,
    name: string,
    thumbnail: string,
    status: 'READY' | 'NEEDS_REVIEW'
  ) => {
    return [
      <Stack alignment="center">
        <Stack.Item fill>
          <Stack alignment={'center'}>
            <Thumbnail source={thumbnail} size="small" alt={`${name} image`} />
            <TextStyle>{name}</TextStyle>
          </Stack>
        </Stack.Item>
        <Stack alignment={'center'}>
          {status === 'NEEDS_REVIEW' && (
            <Badge status={'critical'}>{t('productActionRequired')}</Badge>
          )}
          <Button
            onClick={() => {
              history.push(
                `/variant-metadata/${id}${
                  defaultType !== undefined ? '/' + defaultType : ''
                }`
              );
            }}
          >
            Edit
          </Button>
        </Stack>
      </Stack>,
    ];
  };

  return (
    <Card>
      <Card.Header title={t('productsSection.metaDataForms.variants.title')} />
      <div className={`${styles['table-customisations']}`}>
        <DataTable
          columnContentTypes={['text', 'numeric']}
          headings={['Variant', 'Action']}
          rows={variantList.map((item) =>
            createVariantRow(item.id, item.name, item.thumbnail, item.status)
          )}
        />
      </div>
    </Card>
  );
};

export default VariantsLinkSection;
