import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { GetDropsResponse } from './useGetDrops';

export type CollectorRewardFile = {
  fileURL: string;
  fileLabel: string;
};

export enum MintMode {
  CUSTODIAL_MINTING = 'CUSTODIAL_MINTING',
  SIGNABLE_MINTING = 'SIGNABLE_MINTING',
}

export type CreateDropRequest = {
  id: string;
  name: string;
  contractId: string | null;
  productIds: number[];
  paymentSplitterContractId: string | null;
  collectorRewardFiles: CollectorRewardFile[] | null;
  mintMode: MintMode;
};

export default function useCreateDrop() {
  const queryCache = useQueryClient();

  return useMutation<GetDropsResponse, unknown, CreateDropRequest>(
    async (request) =>
      API()
        .post(`/shop/drop`, request)
        .then((res) => {
          return res.data;
        }),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('drop');
        queryCache.invalidateQueries('products');
      },
    }
  );
}
