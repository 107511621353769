import { useQuery } from 'react-query';
import API from '../utils/api';

interface GasFeeLimitGwei {
  mintingGwei: string;
  contractGwei: string;
  paymentSplitGwei: string;
}

export default function useGetGweiFeeLimits() {
  return useQuery<GasFeeLimitGwei>(['gweiFeeLimits'], async () => {
    const response = await API().get(`account/me/gasLimit`);

    return response.data;
  });
}
