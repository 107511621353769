import { useMutation, useQueryClient } from 'react-query';
import API from 'utils/api';
import { GetDropsResponse } from './useGetDrops';
import { CollectorRewardFile, MintMode } from './useCreateDrop';

export type UpdateDropRequest = {
  id: string;
  name: string;
  contractId: string | null;
  productIds: number[];
  paymentSplitterContractId: string | null;
  collectorRewardFiles: CollectorRewardFile[] | null;
  mintMode: MintMode;
};

export default function useUpdateDrop() {
  const queryCache = useQueryClient();

  return useMutation<GetDropsResponse, unknown, UpdateDropRequest>(
    async (request: UpdateDropRequest) =>
      (await API().put(`/shop/drop/${request.id}`, request)).data,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('drop');
        queryCache.invalidateQueries('products');
      },
    }
  );
}
