import React from 'react';
import { useGetRecipients } from '../../hooks/useGetRecipients';
import LoadingState from 'pages/LoadingState';
import useMainnetMintingEnabled from '../../hooks/useMainnetMintingEnabled';
import { RoyaltySplitFormPage } from './RoyaltySplitFormPage';

export const RoyaltySplitPageCreateLoader: React.FC = () => {
  const { data, error } = useGetRecipients();
  const { isLoading: isMintingCapabilityLoading, mintingAvailable } =
    useMainnetMintingEnabled();

  if (error) {
    throw error;
  }

  return data && !isMintingCapabilityLoading ? (
    <RoyaltySplitFormPage
      formType={'Create'}
      recipients={data}
      onTestMode={!mintingAvailable}
    />
  ) : (
    <LoadingState />
  );
};
