import {
  ActionList,
  DatePicker,
  Popover,
  Stack,
  TextField,
} from '@shopify/polaris';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

interface DateTimeInputProps {
  dateLabel: string;
  timeLabel: string;
  value: DateTime;
  onChange: (value: string) => void;
  disabled?: boolean;
}
export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  value,
  timeLabel,
  dateLabel,
  onChange,
  disabled,
}) => {
  const datetime = value.setZone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const [timePopoverActive, setTimePopoverActive] = useState(false);
  const [calendarPopoverActive, setCalendarPopoverActive] = useState(false);
  const [monthToShow, setMonthToShow] = useState<number>(datetime.month - 1);
  const [yearToShow, setYearToShow] = useState<number>(datetime.year);
  const [timeToShow, setTimeToShow] = useState<string>(
    datetime.toFormat('hh:mm a')
  );
  const [timeInputValue, setTimeInputValue] = useState<string>('');

  const setDateTime = useCallback(
    (dateTime: DateTime) => {
      onChange(dateTime.toISO());
    },
    [onChange]
  );

  const handleDateInputChange = useCallback(
    (inputValue: string) => {
      const parsedDate = DateTime.fromFormat(inputValue, 'yyyy-MM-dd');
      if (parsedDate.isValid) {
        setDateTime(
          parsedDate.set({ hour: datetime.hour, minute: datetime.minute })
        );
      }
    },
    [datetime, setDateTime]
  );

  const handleUserInputChange = useCallback(
    (inputValue: string) => {
      setTimeInputValue(inputValue);
      setTimeToShow(inputValue);
    },
    [setTimeInputValue, setTimeToShow]
  );

  const handleTimeSelect = useCallback(
    (selectedTime: string) => {
      setTimePopoverActive(false);
      const parsedTime = DateTime.fromFormat(selectedTime, 'hh:mm a');
      setDateTime(
        datetime.set({ hour: parsedTime.hour, minute: parsedTime.minute })
      );
      setTimeToShow(parsedTime.toFormat('hh:mm a'));
    },
    [datetime, setDateTime]
  );
  const memoizedTimeOptions = useMemo(() => generateTimeOptions(), []);

  const actionListItems = memoizedTimeOptions.map((timeOption) => ({
    content: timeOption,
    onAction: () => handleTimeSelect(timeOption),
  }));

  const inputDate = datetime.toFormat('yyyy-MM-dd');

  return (
    <Stack distribution={'fillEvenly'}>
      <Popover
        fullWidth
        active={calendarPopoverActive}
        activator={
          <TextField
            onFocus={() => setCalendarPopoverActive(true)}
            label={dateLabel}
            value={inputDate}
            onChange={handleDateInputChange}
            autoComplete={'off'}
            disabled={disabled}
          />
        }
        autofocusTarget="first-node"
        onClose={() => {
          setMonthToShow(datetime.month - 1);
          setYearToShow(datetime.year);
          setCalendarPopoverActive(false);
        }}
      >
        <div style={{ padding: '1rem' }}>
          <DatePicker
            onChange={(date) => {
              const parsedInput = DateTime.fromISO(date.start.toISOString());
              setDateTime(
                parsedInput.set({
                  hour: datetime.hour,
                  minute: datetime.minute,
                })
              );
              setCalendarPopoverActive(false);
            }}
            onMonthChange={(month, year) => {
              setMonthToShow(month);
              setYearToShow(year);
            }}
            month={monthToShow}
            year={yearToShow}
            selected={new Date(datetime.toISO())}
          />
        </div>
      </Popover>
      <Popover
        fullWidth
        active={timePopoverActive}
        activator={
          <TextField
            onFocus={() => setTimePopoverActive(true)}
            label={`${timeLabel}`}
            value={timeToShow}
            onChange={handleUserInputChange}
            autoComplete={'off'}
            disabled={disabled}
          />
        }
        autofocusTarget="first-node"
        onClose={() => {
          setTimePopoverActive(false);
          if (timeInputValue !== '') {
            const parsedTime = DateTime.fromFormat(timeInputValue, 'hh:mm a');
            if (parsedTime.isValid) {
              setDateTime(
                datetime.set({
                  hour: parsedTime.hour,
                  minute: parsedTime.minute,
                })
              );
              setTimeToShow(parsedTime.toFormat('hh:mm a'));
            } else {
              setTimeToShow(datetime.toFormat('hh:mm a'));
            }
            setTimeInputValue('');
          }
        }}
      >
        <ActionList items={actionListItems} />
      </Popover>
    </Stack>
  );
};

const generateTimeOptions = () => {
  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
      const time = DateTime.fromObject({ hour: i, minute: j * 30 });
      timeOptions.push(time.toFormat('hh:mm a'));
    }
  }
  return timeOptions;
};
