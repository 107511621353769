import { useQuery, UseQueryResult } from 'react-query';
import API from 'utils/api';
import { Blockchain } from '../types/Blockchain';

export interface GetContractsResponse {
  contracts: Contract[];
}

type ContractType = 'REGULAR' | 'DROP' | 'ART_BLOCKS' | 'HIGHLIGHT';

export enum OwnerStatus {
  OWNED = 'OWNED',
  NOT_OWNED = 'NOT_OWNED',
}

export interface Contract {
  id: string;
  version: string;
  blockchain: Blockchain;
  name: string;
  symbol: string;
  description: string;
  address: string | null;
  ownerAddress: string;
  ownerName: string;
  ownerStatus: OwnerStatus;
  pending: boolean;
  createdAt: string;
  updatedAt: string;
  transaction: string | null;
  type: ContractType;
  openseaIndexed: boolean;
  supportsSignableMinting: boolean;
}

/**
 * VALID will return contracts that you have an APPROVED permission with
 * ALL will return all contracts
 */
type ContractsVisibility = 'VALID' | 'ALL';

export default function useGetContracts(
  flag: ContractsVisibility
): UseQueryResult<GetContractsResponse> {
  return useQuery<GetContractsResponse>(['contract', flag], async () => {
    const skipShare = true; // Share info not used in this app so speeds up things a lot
    const response = await API().get(
      `nft/contract?context=CUSTODIAL&skipShare=${skipShare}&visibility=${flag}`
    );
    if (response.status !== 200) {
      return [];
    }
    return response.data;
  });
}
