import { Card, Link, TextStyle } from '@shopify/polaris';
import React from 'react';

const TestPrintCard = () => {
  return (
    <Card title="Order a test print">
      <Card.Section>
        <TextStyle>
          Place an order to test out the print quality. Order a test print
          Experience the full user journey by ordering from the{' '}
          <Link
            url="https://shopifydemo.verisart.com/products/custom-print-with-tokenid-1"
            removeUnderline
          >
            Verisart Demo Store
          </Link>{' '}
          or by{' '}
          <Link url="https://verisart.typeform.com/to/cSpo5a0j" removeUnderline>
            filling out an order form
          </Link>{' '}
          to test one of your own tokens
        </TextStyle>
      </Card.Section>
    </Card>
  );
};

export default TestPrintCard;
